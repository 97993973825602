/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { AppContext } from "../components/AppProvider";
import { _CK, _API } from "../modules/utils";
import LoginTypeA from "./Template/Type-A/Login/Login";
import LoginTypeB from "./Template/Type-B/Login/Login";
import LoginTypeE from "./Template/Type-E/Login/Login";
import LoginDefault from "./Template/Default/Login/Login";

import TypeARoot from "./Roots/TypeARoot";
import TypeBRoot from "./Roots/TypeBRoot";
import TypeERoot from "./Roots/TypeERoot";
import Default from "./Roots/Default";

import DirectLogin from "./directLogin";
import Prereg from "./preRegister/Preregister";
// import Campaign from "./campaign/page/CampaignMain";
import Campaign from "./campaign/index";
import Preregistration from "./preRegister/Preregistration";
import useStore from "../modules/utils/store";


export default (props) => {
  const { auth, iam } = useContext(AppContext);
  const { saveAuth, saveIam } = useContext(AppContext);
  const {siteInfo, campaignInfo} = useStore();

  if (window.location.hash === "" || !window.location.hash) {
    _CK.clearAuth();
  }

  let eventIdx = "";
  let campaignIdx = "";
  let passCode = "";
  let au = "";
  let pathInfos = [];
  let pathParams = [];
  let email = '';
  let memType = '';
  let sessionIdx = '';

  const pathName = window.location.hash;

  if (pathName) {
    pathInfos = pathName.indexOf("?") === -1 ? pathName.split("/") : pathName.split("?")[0].split("/");
    passCode = pathName.indexOf("passCode=") === -1 ? "" : pathName.split("passCode=")[1].split('&')[0];
    au = pathName.indexOf("au=") === -1 ? "" : pathName.split("au=")[1].split('&')[0];
    email = pathName.indexOf("email=") === -1 ? "" : pathName.split("email=")[1].split('&')[0];
    memType = pathName.indexOf("memType=") === -1 ? "" : pathName.split("memType=")[1].split('&')[0];
    eventIdx = pathInfos[2];
    campaignIdx = pathInfos[2];
    sessionIdx = pathInfos[4];
  }


  const setAuthForDirectLogin = () => {
    if (auth) {
      _CK.setAuth(auth);
      window.removeEventListener("click", setAuthForDirectLogin);
    }
  };
  
  useEffect(() => {
    window.addEventListener("click", setAuthForDirectLogin);
    return () => {
      window.removeEventListener("click", setAuthForDirectLogin);
    };
  });

  if(pathInfos[1] == "event" && pathInfos[3] == 'regi'){
    let channel = pathName.indexOf("channel=") === -1 ? "" : pathName.split("channel=")[1].split('&')[0];
    window.location.href = `/#/event/${eventIdx}/regi`;
    return <Preregistration eventIdx={eventIdx} channel={channel}/>
    
  }

  if(pathInfos[1] == "event" && pathInfos[3] == 'preregister'){
    let channel = pathName.indexOf("channel=") === -1 ? "" : pathName.split("channel=")[1].split('&')[0];
    window.location.href = `/#/event/${eventIdx}/preregister`;
    return <Prereg eventIdx={eventIdx} channel={channel}/>
  }
  
  /* if(pathInfos[1] == "event" && pathInfos[3] == 'campaign'){
    //let channel = pathName.indexOf("channel=") === -1 ? "" : pathName.split("channel=")[1].split('&')[0];
    window.location.href = `/#/event/${eventIdx}/campaign`;
    return <Campaign eventIdx={eventIdx}/>
  } */

  if(pathInfos[1] == "campaign"){
    // window.location.href = `/#/campaign/${campaignIdx}`;
    return <Campaign campaignIdx={campaignIdx} templateIdx={campaignInfo?.templateIdx}/>
  }

  if (pathInfos[1] == "guest") {

    let eventId = pathName.split("event_id/")[1].split("/")[0];
    let sessionId = pathName.split("session_id/")[1].split("/")[0];
    let siteIdx = pathName.indexOf("siteIdx=") === -1 ? "" : pathName.split("siteIdx=")[1].split('&')[0];
    let preregIdx = pathName.indexOf("preregIdx=") === -1 ? "" : pathName.split("preregIdx=")[1].split('&')[0];

    const loginComponent = (
      <DirectLogin
        siteIdx={siteIdx}
        eventIdx={eventId}
        sessionIdx={sessionId}
        preregIdx={preregIdx}
        passCode={passCode}
        targetType={"GUEST"}
      />
    );

   
    setTimeout(() => {
      window.location.href = `/#/event/${eventId}/session/${sessionId}/default`;

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }, 500);

    return loginComponent; 
  }

  if(pathInfos[1] == "medigate"){
    eventIdx = pathName.indexOf("event_id=") === -1 ? "" : pathName.split("event_id=")[1].split('&')[0];
    sessionIdx = pathName.indexOf("session_id=") === -1 ? "" : pathName.split("session_id=")[1].split('&')[0];
    let name = pathName.indexOf("ememer_seq=") === -1 ? "" : pathName.split("ememer_seq=")[1].split('&')[0];
    let companyName = pathName.indexOf("comp_name=") === -1 ? "" : decodeURI(pathName.split("comp_name=")[1].split('&')[0]);
    if(sessionIdx){
      window.location.href = `/#/event/${eventIdx}/session/${sessionIdx}/default`;
    }else{
      window.location.href = `/#/event/${eventIdx}/register`;
    }
    return <DirectLogin eventIdx={eventIdx} sessionIdx={sessionIdx} name={name} companyName={companyName} medigate={true} au={au}/>;
  }  

  if(pathInfos[1] == "external_link"){
    eventIdx = pathName.indexOf("post_id=") === -1 ? "" : pathName.split("post_id=")[1].split('&')[0];
    sessionIdx = pathName.indexOf("session_id=") === -1 ? "" : pathName.split("session_id=")[1].split('&')[0];
    let firstName = pathName.indexOf("FirstName=") === -1 ? "" : pathName.split("FirstName=")[1].split('&')[0];
    let lastName = pathName.indexOf("LastName=") === -1 ? "" : pathName.split("LastName=")[1].split('&')[0];
    let name = decodeURI(lastName)+decodeURI(firstName);
    let email = pathName.indexOf("EmailAddress=") === -1 ? "" : decodeURI(pathName.split("EmailAddress=")[1].split('&')[0]);
    email = email?.replace('%40','@');
    let mobile = pathName.indexOf("MobileNumber=") === -1 ? "" : pathName.split("MobileNumber=")[1].split('&')[0];
    let smsAgree = pathName.indexOf("ISsubscribeforSMS=") === -1 ? "" : pathName.split("ISsubscribeforSMS=")[1].split('&')[0];
    let emailAgree = pathName.indexOf("ISsubscribeforEmail=") === -1 ? "" : pathName.split("ISsubscribeforEmail=")[1].split('&')[0];
    let companyName = pathName.indexOf("comp_name=") === -1 ? "" : decodeURI(pathName.split("comp_name=")[1].split('&')[0]);
    let remoteAddr = document.referrer;
    
    if(sessionIdx){
      window.location.href = `/#/event/${eventIdx}/session/${sessionIdx}/default`;
    }else{
      window.location.href = `/#/event/${eventIdx}/register`;
    }
    return <DirectLogin eventIdx={eventIdx} sessionIdx={sessionIdx} 
            name={name} mobile={mobile} email={email} smsAgree={smsAgree} emailAgree={emailAgree} remoteAddr={remoteAddr}
            companyName={companyName} external={true} au={au}
          />;
  }  

  /* if(au && pathInfos[1] == "event" && pathInfos[3] == 'session'){
    window.location.href = `/#/event/${eventIdx}/${pathInfos[3]}/${sessionIdx}/${pathInfos[5]}`;
    return <DirectLogin eventIdx={eventIdx} passCode={passCode} au={au} email={email} memType={memType}/>;
  } */

  if(pathInfos[1] == "event" && pathInfos[3] == 'session'){
    if(au) {
      window.location.href = `/#/event/${eventIdx}/${pathInfos[3]}/${sessionIdx}/${pathInfos[5]}`;
      return <DirectLogin eventIdx={eventIdx} passCode={passCode} au={au} email={email} memType={memType}/>;
    } /* else {
      window.location.href = `/#/event/${eventIdx}/session/${sessionIdx}/default`;
      return <DirectLogin eventIdx={eventIdx} targetType={targetType}/>;
    }  */
    
  }

  if (
    (passCode || au) &&
    pathInfos.length == 4 &&
    pathInfos[1] == "event" &&
    (pathInfos[3] == "register")
  ) {
    console.log('pathName =', pathName);
    let type = pathName.indexOf("type=") === -1 ? "" : pathName.split("type=")[1].split('&')[0];
    console.log('type3 =', type);
    window.location.href = `/#/event/${eventIdx}/${pathInfos[3]}`;
    return <DirectLogin eventIdx={eventIdx} passCode={passCode} au={au} email={email} memType={memType} type={type}/>;
  }

  if (!_CK.isLogin() && !auth) {
    if(siteInfo?.templateIdx == 1){
      return (
        <LoginTypeA />
      );
    }else if(siteInfo?.templateIdx == 2){
      return (
        <LoginTypeB />
      );
    }else if(siteInfo?.templateIdx == 3){
      return (
        <LoginDefault />
      );
    }else if(siteInfo?.templateIdx == 4){
      return (
        <LoginTypeE />
      );
    }
  }
  
  
  if(siteInfo?.templateIdx == 1){
    return (
      <Router>
        <Switch>
          <Route path="/" component={TypeARoot} />
        </Switch>
      </Router>
    );
  }else if(siteInfo?.templateIdx == 2){
    return (
      <Router>
        <Switch>
          <Route path="/" component={TypeBRoot} />
        </Switch>
      </Router>
    );
  }else if(siteInfo?.templateIdx == 3){
    return (
      <Router>
        <Switch>
          <Route path="/" component={Default} />
        </Switch>
      </Router>
    );
  }else if(siteInfo?.templateIdx == 4){
    return (
      <Router>
        <Switch>
          <Route path="/" component={TypeERoot} />
        </Switch>
      </Router>
    );
  }
  
};
