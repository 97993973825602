import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useContext,
  useCallback,
} from "react";

import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";

//HLS
import { _CFG } from "../../../../modules/config";

import ReactJWPlayerContainer from "./JWPlayer";
// import ReactJWPlayerContainerTypeB from "./jwplayerType-B";
import moment from "moment";
import useStore from "../../../../modules/utils/store";


export default (props) => {
  const { iam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const { consoleCfg, player, setPlayer, clickVideoTime, setSurveyInfo} = props;
  
  const preregIdx = iam?.preregIdx;
  const [waitInterval, setWaitInterval] = useState();
  const {playerSetting, setPlayerSetting} = useStore();
  
  const [delay, setDelay] = useState(1000 * 60);
  const [isRunning, setIsRunning] = useState(false);

  const [url, setUrl] = useState({});

  const [videoTime, setVideoTime] = useState({});

  const [status, setStatus] = useState("CONSOLE_IN");

  const [playList, setPlayList] = useState(null);
  const settingPlayList = () => {
    if(consoleCfg?.serviceType === "ONDEMAND"){
      if (consoleCfg?.consoleVodIdx > 0) {
        const req = {
          path: "/v1/resource/media/info",
          data: {
            idx: consoleCfg?.consoleVodIdx,
          },
        };
        _API.post(req).then((res) => {
          const { data } = res.data;
          var time = data.videoTime.split(':');
          var hour = time[0];
          var min = time[1];
          var sec = time[2];
          var total = (Number(hour) * 60 * 60) + (Number(min) * 60) + Number(sec)
          
          setVideoTime(total); //비디오 총 시간을 초단위로 설정 한 후 on_time event 발생시 저장
          setUrl(data);

          const playlist_onDemend = [
            {
              // file: `https://medinar-files-private-prod.s3.ap-northeast-2.amazonaws.com/2023/10/27/5a98bab0749211ee89917fe0a5276bf9.m3u8`,
              file: `${data?.publicUrl}` || "",
              image: `${consoleCfg?.signedUrlConsolePlayerImg}`,
              controls: true,
            },
          ];
          setPlayList(playlist_onDemend)
        });
      }      
    }else if(consoleCfg?.serviceType === "LIVE"){
      if(consoleCfg?.consoleConsoleType === "HD" || consoleCfg?.consoleConsoleType === "RTC_HD"){
        const playlist_live = [
          {
            file:
              consoleCfg?.consoleHdServerIdx == 10
                ? consoleCfg?.cfUrl +
                  consoleCfg?.consoleEndPoint +
                  "/index.m3u8" +
                  `?eventIdx=${consoleCfg.eventIdx}&sessionIdx=${consoleCfg.sessionIdx}&consoleIdx=${consoleCfg.consoleIdx}&email=${iam.email}&logKey=${logKey}`
                : (consoleCfg?.urlKeyUrl?.indexOf(window.location.protocol) != -1
                    ? consoleCfg?.urlKeyUrl +
                      `?eventIdx=${consoleCfg.eventIdx}&sessionIdx=${consoleCfg.sessionIdx}&consoleIdx=${consoleCfg.consoleIdx}&email=${iam.email}&logKey=${logKey}`
                    : consoleCfg?.urlKeyUrl.replace(
                        consoleCfg?.urlKeyUrl.slice(
                          0,
                          consoleCfg?.urlKeyUrl.indexOf(":") + 1
                        ),
                        window.location.protocol
                      )) +
                  `?eventIdx=${consoleCfg.eventIdx}&sessionIdx=${consoleCfg.sessionIdx}&consoleIdx=${consoleCfg.consoleIdx}&email=${iam.email}&logKey=${logKey}`,
            image: `${consoleCfg?.signedUrlConsolePlayerImg}`,
            controls: true,
          },
        ];
        setPlayList(playlist_live)
      }
    }
    
  };
 

  const pageInTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  let pageOutTime = "";
  const logKey = _CK.getLogKey();


  const hlsConfig = {
    debug: _CFG.isLocal(),
    xhrSetup: (xhr, url) => {
      xhr.setRequestHeader("apikey", _CFG.apiKey);
      xhr.setRequestHeader("authorization", _CK.getAuth());
    },
    // startPosition : 3
  };

  const savePlaylog = ({ siteIdx, eventIdx, sessionIdx, consoleIdx, lastPlayTime, status, videoTime }) => {
    const consoleCountView = _CK.getConsoleCountView();
    console.log("status consoleCountView value : ", consoleCountView);

    var apiPath = "/v1/client/console/playlog/save";

    //onDemand 인경우
    if(consoleCfg?.serviceType === "ONDEMAND"){
      apiPath = "/v1/client/console/vodLog/save";
    }
    
    const req = {
      path: apiPath,
      data: {
        siteIdx,
        eventIdx,
        sessionIdx,
        consoleIdx,
        lastPlayTime,
        logKey,
        consoleCountView,
        pageInTime,
        pageOutTime,
        status,
        preregIdx,
        videoTime,
      },
    };

    _API.post(req).then((res) => {
      let { data } = res;
    });
    
  };

  
  // const loadItem = async (idx) => {
  //   // VOD
  //   if (idx) {
  //     const req = {
  //       path: "/v1/resource/media/info",
  //       data: {
  //         idx: idx,
  //       },
  //     };
  //     await _API.post(req).then((res) => {
  //       const { data } = res.data;
  //       console.log("/media/info : ",data)
  //       var time = data.videoTime.split(':');
  //       var hour = time[0];
  //       var min = time[1];
  //       var sec = time[2];
  //       var total = (Number(hour) * 60 * 60) + (Number(min) * 60) + Number(sec)
        
  //       setVideoTime(total); //비디오 총 시간을 초단위로 설정 한 후 on_time event 발생시 저장
  //       setUrl(data);
  //     });
  //   }
  // };

  let videoInterval = null;
  let currentTime = 0;
  var actionName = "";

  const onPlayerDidMountCallback = useCallback(() => {
    console.log("status:", "onPlayerDidMountCallback");
    saveLog("CONSOLE_IN");
    
  });

  const onPlayerUnMountCallback = useCallback(() => {
    clearInterval(waitInterval);
    pageOutTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    
    saveLog("CONSOLE_OUT");

    console.log("status:", "CONSOLE_OUT");
    setIsRunning(false);
    currentTime = 0;
    window.location.reload();
  });

  var tmpTime = 0;
  const onTimeCallback = useCallback((event) => {
    currentTime = Math.floor(event.currentTime);
    if(consoleCfg?.serviceType === "ONDEMAND"){
      setStatus("ON_TIME");
      
      if(tmpTime != currentTime){
        console.log("ONDEMAND jwplayer : ", "onTime : " + event.position + "["+currentTime+"] " + status);
        saveLog(status);
      }
    
      tmpTime = currentTime;
    }
    
  });
  
  const onCompleteCallback = useCallback((event) => {
    if(consoleCfg?.serviceType === "ONDEMAND"){
      setStatus("COMPLETE");
      saveLog("COMPLETE");
      openSurvey(2)
    }
  });
  
  const openSurvey = (status) => {
    const req = {
      path: "/v1/event/session/surveys/find/menu/connect/survey",
      data : {
        eventIdx: consoleCfg?.eventIdx,
        sessionIdx: consoleCfg?.sessionIdx,
        status : status,
      }
    };
    _API.post(req).then((res) => {
      if(res.data.data.idx > 0){
        const surveyData = {
          show : true,
          idx : res.data.data.idx
        }
        setSurveyInfo(surveyData)
      }else{
        if(status == 1){
          alert('현재 진행중인 서베이가 없습니다.');
        }
        return;
      }
    });
  }

  const onPauseCallback = useCallback((event) => {
    //정지시시 로그 남기고 Pause
    setStatus("PAUSE");
    if(consoleCfg?.serviceType === "ONDEMAND"){
      saveLog("PAUSE");
    }
    setIsRunning(false);
    currentTime = 0;
    console.log("callback:", "videoInterval stop");
  });

  const onErrorCallback = useCallback((event) => {
    //정지시시 로그 남기고 Pause
    setStatus("ERROR");
    if(consoleCfg?.serviceType === "ONDEMAND"){
      saveLog("ERROR");
    }
    setIsRunning(false);
    currentTime = 0;
    console.log("interval:", "videoInterval stop");
  });

  const onResumeCallback = useCallback((event) => {
    //정지시시 로그 남기고 Pause
    setStatus("RESUME");
    
    if(consoleCfg?.serviceType === "ONDEMAND"){
      saveLog("RESUME");
    }

    console.log("callback:", "onResumeCallback");
    console.log("jwplayer : ", "RESUME : " + event.position);

    if(consoleCfg?.serviceType === "LIVE" && (consoleCfg?.consoleConsoleType === "HD" || consoleCfg?.consoleConsoleType === "RTC_HD")){
      console.log("interval: isRunning", isRunning);
      if (isRunning == false) {
        setIsRunning(true);
        setStatus("PLAY");
      }
    }
    
  });

  const onPlayCallback = useCallback((event) => {
    setStatus("PLAY");
    //시작시 로그 남기고 setInterval
    
    if(consoleCfg?.serviceType === "LIVE" && (consoleCfg?.consoleConsoleType === "HD" || consoleCfg?.consoleConsoleType === "RTC_HD")){
      setIsRunning(true);
      console.log("interval:", "videoInterval start");
    }
  });

  const onSeekCallback = useCallback((event) => {
    setStatus("SEEK");
    // event.seek(clickVideoTime);
    console.log("jwplayer : ", "SEEK : " + event);
    console.log("jwplayer : ", "SEEK 2 : " + event.position);
  });

  const onMetaCallback = useCallback((meta) => {
    

  });

  const onReadyCallback = useCallback((player) => {
    console.log("onReadyCallback callback:", player);
    setPlayer(player)
    setPlayerSetting(player)
  });

  useEffect(() => {
    console.log("status changed", "status : " + status);
    if (status != "CONSOLE_IN") {
      saveLog(status);
    }

    console.log("status", status);
  }, [status]);

  useInterval(
    () => {
      saveLog(status);
    },
    isRunning ? delay : null
  );

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      console.log("interval: useInterval-delay:", delay);
      console.log("interval: isRunning", isRunning);
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  function saveLog(status) {
    console.log("it-0", {
      siteIdx: siteInfo.siteIdx,
      eventIdx: consoleCfg.eventIdx,
      sessionIdx: consoleCfg.sessionIdx,
      consoleIdx: consoleCfg.consoleIdx,
      lastPlayTime: currentTime,
      status: status,
      videoTime: videoTime,
    });

    savePlaylog({
      siteIdx: siteInfo.siteIdx,
      eventIdx: consoleCfg.eventIdx,
      sessionIdx: consoleCfg.sessionIdx,
      consoleIdx: consoleCfg.consoleIdx,
      lastPlayTime: currentTime,
      status: status,
      videoTime: videoTime,
    });
  }

  
  useEffect(() => {
    clearInterval(waitInterval);
    if(status == 'CONSOLE_IN' || status == 'PAUSE' || status == 'ERROR'|| status == 'COMPLETE'){
      setWaitInterval(() => {
        return setInterval(() => {
          saveLog('WAIT');
        },1000 * 60)
      })
    }
  },[status])

  useEffect(() => {
    setVideoTime(0);  
  }, [consoleCfg]);

  useEffect(() => {
    // loadItem(consoleCfg?.consoleVodIdx);
    settingPlayList(); 
    if(consoleCfg?.serviceType === "ONDEMAND" && (consoleCfg.videoTime == null || consoleCfg.videoTime == "")){
      $(".video_element").css("display","none")
    }
  }, []);

  return (
    <Fragment>
      <div className="video-wrap">
        <div className="video-frame">
          { playList != null && (
            <ReactJWPlayerContainer
              siteInfo={siteInfo}
              playlist={playList}
              onMetaCallback={onMetaCallback}
              onPlayCallback={onPlayCallback}
              onPlayerDidMountCallback={onPlayerDidMountCallback}
              onPlayerUnMountCallback={onPlayerUnMountCallback}
              onTimeCallback={onTimeCallback}
              onPauseCallback={onPauseCallback}
              onResumeCallback={onResumeCallback}
              onErrorCallback={onErrorCallback}
              onSeekCallback={onSeekCallback}
              onCompleteCallback={onCompleteCallback}
              onReadyCallback={onReadyCallback}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

