/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState } from "react";
import { Fragment } from 'react';
import TopArroow from "../../resources/console/images/icon/top_arrow.svg";
import { AppContext } from "../../components/AppProvider";
import useStore from "../../modules/utils/store";

export default function (props) {
  const { siteInfo } = useStore();
  
  const [termsInfo, setTermsInfo] = useState(null);
  const showTerms = (type, e) => {
    if (e) e.preventDefault();

    $("body").css("overflow", "hidden");
    if (type === "termsUseAgree")
      setTermsInfo({ title: "사이트이용약관", content: siteInfo?.memTermsUseForm });
    else if (type === "termsPrivateAgree")
      setTermsInfo({
        title: "개인정보처리방침",
        content: siteInfo?.memTermsPrivacyForm,
      });
    else if (type === "termsMarket")
      setTermsInfo({
        title: "마케팅정보 수신동의",
        content: siteInfo?.memTermsMarketForm,
      });
    else {
      setTermsInfo(null);
    }
  };

  const modalClose = () => {
    setTermsInfo(null);
    $("body").css("overflow", "auto");
  };



  return (
    <footer id="footer">
      {siteInfo ? (
        <>
        <div className="footer-arrow-wrap">
          <button type="button" className="go-to-top">
              <img src={TopArroow} alt="탑버튼" />
              <span>TOP</span>
          </button>
        </div>
        <div className="footer">
          <div className="wid_1180">
            <pre className="ft_info">
              <p style={{ fontSize: "14px", color: "#888" }}>싱그릭스주 [대상포진바이러스백신(유전자재조합)] 제품설명서는 <a style={{ fontSize: "14px", color: "#555", fontWeight: "bold" }} href="https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetail?itemSeq=202106382" target="_blank"><u>여기</u></a>에서 확인할 수 있습니다.</p>
              <p><br/></p>
              <p style={{fontSize:"14px", color:"#888"}}>[수입판매원]</p>
              <p style={{fontSize:"15px", color:"#333", fontWeight:"bold"}}>(주)글락소스미스클라인</p>
              <p style={{fontSize:"14px", color:"#888"}}>서울특별시 용산구 한강대로 92 Tel. 080-901-4100</p>
              <p style={{ fontSize: "14px", color: "#888" }}>GSK제품 사용 중 발생한 이상사례(부작용)는 080-901-4100 또는 <a style={{ color: "#666666", fontWeight: "500" }} href="mailto:kr-medical.drug-safety@gsk.com" target="_blank">kr-medical.drug-safety@gsk.com</a>으로 보고해 주시기 바랍니다.</p>
              <p style={{fontSize:"14px", color:"#888"}}>최신 제품설명서 전문은 kr.gsk.com에서 확인하실 수 있습니다.</p><br/>
              <p style={{fontSize:"14px", color:"#888"}}>[공동판매원]<br/>GC녹십자 | 경기도 용인시 기흥구 이현로30번길 107 (보정동)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <br/>광동제약㈜ | 서울 서초구 서초중앙로 85 가산빌딩<br/><br/></p>
              <p style={{ color: "rgb(136, 136, 136)", fontSize: "14px" }}><span style={{ fontSize: "14px" }}>Trade marks are owned by or licensed to the GSK group of companies. ⓒ [2023] GSK group of companies or its licensor<br /></span><br /><strong><span style={{ color: "rgb(67, 67, 67)" }}><a style={{ color: "rgb(102, 102, 102)", fontWeight: "bold", textDecoration: "none" }} href="https://privacy.gsk.com/ko-kr/privacy-notice/healthcare-professional" target="_blank">[개인정보처리방침] </a><a style={{ color: "rgb(102, 102, 102)", fontWeight: "bold", textDecoration: "none" }} href="https://terms.gsk.com/ko-kr/pharmaceuticals/default/" target="_blank">[이용약관</a>]</span><br/><br/></strong></p>
              <p style={{ color: "#333", textAlign: "right"}}><span style={{fontSize: "14px"}}><strong style={{ color: "rgb(67, 67, 67)", fontSize: "12px" }}>PM-KR-SGX-WCNT-230001 Jan 2023</strong></span></p>  
              <p style={{textAlign: "right"}}><br/>
              </p>
              <p style={{color:"rgb(102, 102, 102)", textAlign: "right", padding: "0px 10px", fontSize: "11px"}}><br/></p>
            </pre>
          </div>
        </div>
        </>
      ) : null}
      {termsInfo && (
        <Fragment>
          <div className="ft_modal_container">
            <div className="ft_modal_header">
              <div>
                <h2>{termsInfo?.title}</h2>
                <button
                  className="close_btn"
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    modalClose();
                  }}
                  >
                  <img src="https://cdn.onoffevent.com/common/icon_close.svg" />
                </button>
              </div>
            </div>
            <div className="ft_modal_content">
              <div dangerouslySetInnerHTML={{ __html: termsInfo?.content }} />
              {/* <div className="close_button_div">
                <button className="close_button" onClick={modalClose}>
                닫기
                </button>
              </div> */}
            </div>
          </div>
          <div className="modal_bg on"></div>
        
      </Fragment>
      )}
    </footer>
  );
};
