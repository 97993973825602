export default {
  apiKey: "5@Q4&g28{s[CaddM(7:7#RV[",
  session: {
    minute: 30,
    secret: "WEBINARS-20200810",
  },
  cookie: {
    secret: "WEBINARS-20200810",
  },
  slack: {
    errorHook:
      "https://hooks.slack.com/services/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",
  },
  thumbnail: {
    size: 600,
    videoSize: "600x338",
    pointSize: { width: 300, height: 300 },
    pdf: {
      "-resize": "1000x1000",
      "-quality": "100",
    },
  },
  mediaConvert: {
    endpoint: "https://bnklbqvoa.mediaconvert.ap-northeast-2.amazonaws.com",
    role: "arn:aws:iam::460799851857:role/webinarsMediaConvert",
  },
  separator: "/",
  tmpDir: "tmp",
  logDir: "logs",
};

export const AWSDevKeys = {
  accessKeyId: "AKIAWWSOPXVIY7JXVLAW",
  secretAccessKey: "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",
  region: "ap-northeast-2",
};

export const S3UploadPrepare = () => {
  if (process.env.STAGE === "prod") {
    return {
      accessKey: "AKIAWWSOPXVIUBDPQW4X",
      secretKey: "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",
      region: "ap-northeast-2",
      public: {
        bucket: "medinar-files-public-prod",
        url: "https://cdn.livee.kr",
      },
      private: {
        bucket: "medinar-files-private-prod",
        url: "https://ccdn.livee.kr",
      },
    };
  } else if(process.env.STAGE === "stage") {
    return {
      accessKey: "AKIAWWSOPXVIUBDPQW4X",
      secretKey: "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",
      region: "ap-northeast-2",
      public: {
        bucket: "medinar-files-public",
        url: "https://cdn.onoffevent.com",
      },
      private: {
        bucket: "medinar-files-private",
        url: "https://ccdn.onoffevent.com",
      },
    };
  }else  {
    return {
      accessKey: "AKIAWWSOPXVIUBDPQW4X",
      secretKey: "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",
      region: "ap-northeast-2",
      public: {
        bucket: "medinar-files-public",
        url: "https://cdn.onoffevent.com",
      },
      private: {
        bucket: "medinar-files-private",
        url: "https://ccdn.onoffevent.com",
      },
    };
  }
};

export const CloudFront = {
  url:
    process.env.STAGE === "prod" ? "https://ccdn.livee.kr/"
      : process.env.STAGE === "stage" ? "https://ccdn.onoffevent.com/"
      : "https://ccdn.onoffevent.com/",
  options: {
    keypairId: "APKAJU7SMLMY2XAYLU5A",
    privateKeyString:
      "-----BEGIN RSA PRIVATE KEY-----\n" +
      "MIIEpQIBAAKCAQEAxLVmeQqdxeVray/7LoMppysmOHmv7bh8sDqi96sL/8vAjk0n\n" +
      "J6KyQes4uT6UIgOI+Nta6Jbo7ytCTp7InCpOpE4EIxSzxdmW/ICg18TOdHjffQhr\n" +
      "0p/3QupB2CeG/kjSmvCrysGSjfQljeMvhjZrUY+50aOb6ChNAkWuaPV30hUgLKfi\n" +
      "fdPXLRS36X43fjLnLpECJfzoezJh9mTP1tpJ1edqtpIW36tbk0wtDHC9wFFNvfD3\n" +
      "1ZpG6frNO00dxRx2VGxcoORUjhzQjw8qPpms+GshAH4l6Duy9WyQbIxXP5ogfePD\n" +
      "MMcDtFaAChsunhukTbF0zLiapkeHwAc5fJA3QwIDAQABAoIBAHAqaAY5MYH4senq\n" +
      "8DWzgPrbte2aJeFNw3vR07+Z/zua+W+rOtp1AWRpNm0a6+kBTwdoPUxHUQegCS0/\n" +
      "iXDTBwjVm7JNSb1Vt6y/w/eFN5xemEF5ndH6eUnkvir9TxKMf9rtxWfWPTVItGlV\n" +
      "1HGZclSNug16V5pn8JGNHkinP+3AMXHnY8ar6LtFUNyYhJh3QTETe4ZV46kF68om\n" +
      "9+8ax1k+fIjMlNtkJ8SuDC6TYPZXBJa+n8bgULu+lugVcvPQ+FBJgQ0BVMfS+6nj\n" +
      "HpM2EcYWmgkB1oliRjttS/jP5dHjCrsYzI65OqRiobArPNLGhR9fr8/uhGKvbcUq\n" +
      "aDFWdOECgYEA8hwjgLqiut3Wf8N8kvSBkKuicSd7UsgDwoYVXHFOHiQ1IbJniRhF\n" +
      "et2ckp0OKOqrmd0IaLHf8Mu+Z8JlbhEcYGkOeVSIcXhliuVoQ4UkyPiQ1MFbfDkb\n" +
      "jdxzjdy4KBCPLsJuz+CJ71boLJTppHSArJTh4aSyaFYPLmyAV+zyvBsCgYEAz/50\n" +
      "JgpGHjg2LRCqLqKvHulFwRzbh96aWMv2tbxPXQDVY2UwF2BjYFPEczSSB9BYXmoW\n" +
      "JuS0E+pQfbRWN7BbQANwuya/Xsgds/nPZdMkKJkwmTh2xn0SJTIMZprDU/VQtGSH\n" +
      "PlCmrRq9hCwl5qEbxRWZ3AUcu3NPKF6AAfcX0/kCgYEAiOEXwWPDNNrOtpUTDRJn\n" +
      "Xh9rS4YC1x514bhkw0rb23alA1QV7RBn17sLFmjs5H0oX2jzpB1Mepr2HztslPnf\n" +
      "jTJYGIiOS3jCD/D7LZFM5sKBP4fB7U1sIpwMSJwNEbmq+ci7VZOg6Eu+H9Zg8bVc\n" +
      "OjZCLowqwYpGMvzs4sj3wxkCgYEAjhlDdPGz8dVpfeRSwEW46p9oXoS+ntbAEEZY\n" +
      "qNScS+fT4XIyJuZdPmQ8ZJDX66q1lNtZgvzg4nJEx3y7IpTD4rn2/IV+XNzPHjRf\n" +
      "MyvLGkl+YE+pJfYwvWSyAC9fny6MEpJTq4kiNkV8kJ4wzp3nAQC++wgrFw2yWNwn\n" +
      "DDCqANECgYEAysiO9eIwQHEo4Jqu97601h4pvurbvZM8k6ljgiipsk2Anlof353A\n" +
      "p8LSTTaZSTxQ/1QURVKYAn3C+O1NKA60tDw3ozbkd2KpSvhxPy7t3TNQQ2RXzx2i\n" +
      "H5pymTWVVIYpxVpyOCuudYP811NyFq9net790oYgQ9kW5BZaBVigJwI=\n" +
      "-----END RSA PRIVATE KEY-----\n",
  },
};

export const tasInfo = () => {
  return {
    apiHost: "https://api.tason.com/tas-api/send",
    apiKey: "TFJ1P5-NGPDAH-7LITVM-M8G4D1_425",
    apiAuthId: "solution@webinars.co.kr",
    senderEmail: "support@webinars.co.kr",
    senderName: "심포지엄사무국",
  };
};

export const directSendInfo = () => {
  return {
    apiHost: "https://directsend.co.kr/index.php/api_v2/mail_change_word",
    apiKey: "AVYOTiWefGvOeiu",
    sender: "support@livee.co.kr",
    // sender: "support@webinars.co.kr",
    sender_name: "심포지엄사무국",
    username: "webinarswx",
  };
};

export const apiStoreInfo = () => {
  return {
    apiHost: "https://api.apistore.co.kr",
    apiKey:
      "MTM2NDktMTYwMDQxMTYzODM3NS0yODU2MjE5NS1kMTNhLTRiMTEtOTYyMS05NWQxM2EwYjExYjA=",
    apiSMSPath: "/ppurio/1/message/sms/webinars",
    apiSendNumberPath: "/ppurio/2/sendnumber/save/webinars",
    apiTalkPath: "/kko/1/msg/webinars",
    apiTalkCheckPath: "/kko/1/report/webinars",
    senderPhone: "0263426830",
    senderName: "(주)웨비나스",
  };
};

export const tossInfo = () => {
  return {
    // tossClientKey: "live_ck_ADpexMgkW36pv92DDY4rGbR5ozO0",
    // tossSecretKey: "live_sk_d26DlbXAaV0j6AYm7k08qY50Q9RB",
    tossClientKey: "test_ck_dP9BRQmyarYaxl2nE09rJ07KzLNk",
    tossSecretKey: "test_sk_Z0RnYX2w532bMEwwDgx3NeyqApQE",
  };
};

export const APIUrl = () => {
  switch (process.env.STAGE) {
    case "prod":
      return "https://api.livee.kr";
    case "dev":
      return "https://api.onoffevent.com";
    case "stage":
        return "https://api.sharewebinar.com";
    default:
      return "http://localhost:3100";
  }
};

export const DSUrl = () => {
  switch (process.env.STAGE) {
    case "prod":
      return "https://api.livee.kr";
    case "stage":
      return "https://api.sharewebinar.com";      
    default: 
      return "https://api.onoffevent.com";
  }
};

export const HubUrl = () => {
  switch (process.env.STAGE) {
    case "prod":
      return "https://hc.livee.kr";
    case "dev":
      return "https://hub.onoffevent.com";
    case "stage":
      return "https://hc.sharewebinar.com";           
    default:
      return "http://localhost:3000";
  }
};