import _CK from "../utils/cookie";
import _U from "../utils/utilities";

const urlProd = "livee.kr";
const urlTest = "onoffevent.com";
const urlStage = "sharewebinar.com";

const config = {
    apiKey: "5@Q4&g28{s[CaddM(7:7#RV[",
    // tossClientKey: "live_ck_ADpexMgkW36pv92DDY4rGbR5ozO0",
    tossClientKey: "test_ck_dP9BRQmyarYaxl2nE09rJ07KzLNk",
};

config.KAKAO_API_KEY = "ad521e4f18f1309353333d753bf5da65";

config.apiHost = () => {
    const { hostname } = window.location;
    if (hostname.startsWith("local") || hostname.startsWith("test1")) {
        return "http://localhost:3100";
    } else if (hostname.endsWith(urlProd)) {
        return `https://api.${urlProd}`;
    } else if (hostname.endsWith(urlStage)) {
        return `https://api.${urlStage}`;
    } else {
        return `https://api.${urlTest}`;
    }
};

config.wsHost = () => {
    const { hostname } = window.location;
    const authors = {
        apikey: config.apiKey,
    };
    if (_CK.isLogin()) {
        authors.authorization = _CK.getAuth();
    }

    if (hostname.startsWith("local") || hostname.startsWith("test1")) {
        return `ws://localhost:3300?auth=${_U.encode(authors)}`;
    } else if (hostname.endsWith(urlProd)) {
        return `wss://ws.livee.kr?auth=${_U.encode(authors)}`;
    } else if (hostname.endsWith(urlStage)) {
        return `wss://ws.onoffevent.com?auth=${_U.encode(authors)}`;
    } else {
        return `wss://ws.onoffevent.com?auth=${_U.encode(authors)}`;
    }
};

config.isLocal = () => {
    const { hostname } = window.location;
    switch (hostname) {
        case urlTest:
            return false;
        case urlStage:
            return false;
        case urlProd:
            return false;
        default:
            if (hostname.startsWith("local") || hostname.startsWith("test1")) {
                return true;
            } else {
                return false;
            }
    }
};

config.hubUrl = () => {
    const { hostname } = window.location;
    const authors = {
        apikey: config.apiKey,
    };
    if (_CK.isLogin()) {
        authors.authorization = _CK.getAuth();
    }
    if (hostname.startsWith("local") || hostname.startsWith("test1")) {
        return `http://localhost:3000`;
    }else if (hostname.endsWith(urlProd)) {
        return `https://hc.livee.kr`;
    }else if (hostname.endsWith(urlTest)) {
        return `https://hub.onoffevent.com`;
    }else if (hostname.endsWith(urlStage)) {
        return `https://hc.sharewebinar.com`;
    }else {
        return `http://localhost:3000`;
    }
}

export default config;
export const ApolloOptions = () => {
    return { uri: config.apiHost() };
};
