import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '../../../resources/images/fms/icon_close.svg';
import SignatureIcon from '../../../resources/preReg/images/icon/signature.svg';
import Typography from '@mui/material/Typography';
import {Stack,Grid,Box}  from '@mui/material';
import moment from "moment";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useStore from '../../../modules/utils/store';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    width: "100%",
    maxWidth: "830px"
  },
  '.MuiDialogTitle-root': {
    backgroundColor: "#f8f8fe",
    color: "#111",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "normal",
    padding:"20px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2.5),
    borderTop: "0px",
    borderBottom:"1px solid #eee",
  },
  '& .MuiDialogContent-root h3': {
      fontSize: "22px",
      fontWeight:"bold"
  },
  '& .MuiDialogContent-root h4': {
      fontWeight:"bold"
  },
  '& .MuiDialogContent-root b': {
      fontSize: "30px",
      fontWeight: "bold",
      wordBreak: "keep-all"
  },
  '& .MuiDialogContent-root .MuiBox-root': {
    background: "#f7f7fa",
    borderRadius: "6px",
    padding:"20px"
  },
  
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2.5),
  },
  '.MuiButton-root': {
    backgroundColor: "#6a6af8",
    color: "#fff",
    borderRadius: "4px",
    lineHeight:"40px",
    minWidth: "100px",
    height: "40px",
    fontSize:"15px"
  },
  
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 20,
            top: 15,
            padding:"0px"
          }}
        >
          <img src={CloseIcon} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PreRegisterDialog(props) {
  const { open, setOpen, myPreRegInfoList, saveList, preregCompName, selectConnectPath} = props;
  const {savePreregName,setSavePreregName,savePreregMobile,setSavePreregMobile,savePreregEmail,setSavePreregEmail} = useStore();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSavePreregName(null)
    setSavePreregMobile(null)
    setSavePreregEmail(null)
    window.location.reload();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          사전등록 신청
        </BootstrapDialogTitle>
          <DialogContent dividers>
            <Stack sx={{marginBottom:"60px"}}>
              <Typography component="h3" sx={{marginBottom:"30px"}}>사전등록완료</Typography>
              <img src={SignatureIcon} style={{display:"block", margin:"0 auto 6px", width:"75px" }}/>
              <Typography component="b" align="center">심포지엄 사전등록이 완료되었습니다.</Typography>
            </Stack>
            <Stack sx={{marginBottom:"30px"}}>
              <Typography component="p" sx={{ marginBottom: "10px" }}>심포지엄에 등록해 주셔서 감사합니다. 문자는 <strong>*익일 오전 10시*</strong>에 발송됩니다.</Typography>
              {saveList && saveList.map((l,idx) => (
                <Box key={idx}>
                  <Typography component="p">
                    {l.name}&nbsp;{`(`+moment(l.startDate).format("MM/DD")+`)`}
                  </Typography>
                  <Typography component="p">
                    {`: ${l.location}`}
                  </Typography>
                </Box>
              ))}
            </Stack>
            <Stack sx={{marginBottom:"30px"}}>
              <Typography component="h4" sx={{marginBottom:"10px"}}>신청자 정보</Typography>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography>성명</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>{savePreregName}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>병원명</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>{preregCompName}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>모바일</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>{savePreregMobile}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>이메일</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>{savePreregEmail}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>접속경로</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>
                      {
                        selectConnectPath == "1" ? "영업사원 방문" 
                        : selectConnectPath == "2" ? "학회" 
                        : selectConnectPath == "3" ? "이메일" 
                        :  selectConnectPath == "4" ? "메디게이트" 
                        : selectConnectPath == "5" ? "우편물" 
                        : selectConnectPath == "6" ? "기타" 
                        : ""
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
            <Stack>
              <Typography component="h4" sx={{marginBottom:"10px"}}>등록완료 심포지엄({myPreRegInfoList.length}건)</Typography>
              {myPreRegInfoList && myPreRegInfoList.map((l,idx) => (
                <Box key={idx} sx={{marginBottom:"10px"}}>
                  <Typography component="p">{l.sessionName}&nbsp;{`(`+moment(l.startDate).format("MM/DD")+`)`}</Typography>
                  <Typography component="p">{`: ${l.location}`}</Typography>
                </Box>
              ))}
            </Stack>
          </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={(e) => {handleClose()}}>
            확인
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}