import React, { useContext } from "react";
import { AppContext } from "../../components/AppProvider";
import styled from "styled-components";
import { Fragment } from "react";
import close_btn from "../../resources/images/metaverse/m_icon_close.svg";
import m_width_close_btn from "../../resources/console/images/icon/507-2.svg";
import useStore from "../../modules/utils/store";

const SpeakerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  display: flex;
  max-width: 1180px;
  max-height: 660px;
  height: 90%;
  border-radius: 6px;
  overflow: hidden;
  @media (max-width: 787px) {
    height: 100%;
  }
  @media (orientation: landscape) and (max-width: 950px) and (max-device-height: 500px){
    top:unset;
    left:unset;
    transform:unset;
    width:100%;
    height:100%;
  }
`;

const SpeakerModal = (props) => {
  const { siteInfo } = useStore();
  const { managerInfo, setSessionMgrInfoModalShow, defaultProfileImg } = props;

  const modalClose = () => {
    setSessionMgrInfoModalShow(false)
  };
  
  return (
    <Fragment>
      <SpeakerContainer className="speaker_profile_container">
          <div
            className="profile_img"
            style={{
              background:
                managerInfo.signedUrlPhoto != null ? "#000000000" : "#7C7CA0",
            }}
          >
            <div className="m_profile_content_top">
              <h2>연자 정보</h2>
              <button className="close_btn" onClick={modalClose}>
                <img src="https://cdn.onoffevent.com/common/icon_close_white.svg" />
              </button>
            </div>
            <div className="m_profile_flex">
              <h2 className="m_width_inform_text">연자 정보</h2>
              <img
                className="profile_image"
                src={
                  managerInfo.signedUrlPhoto != null
                    ? managerInfo.signedUrlPhoto
                    : defaultProfileImg
                }
                alt="프로필사진"
              />
              <img
                className="background_img"
                src={
                  managerInfo.signedUrlPhoto != null
                    ? managerInfo.signedUrlPhoto
                    : defaultProfileImg
                }
                alt="프로필사진"
              />
              <div className="profile_content_top_text">
                <h2>{managerInfo?.name}</h2>
                <p>{managerInfo?.companyName}</p>
                <p>{managerInfo?.deptName}</p>
              </div>
            </div>
          </div>

          <div className="profile_content">
            <div className="profile_content_top">
              <h2>연자 정보</h2>
              <button className="close_btn" onClick={modalClose}>
                <img src="https://cdn.onoffevent.com/common/icon_close.svg" />
              </button>
            </div>
            <button className="close_btn m_width_close_btn" onClick={modalClose}>
              <img src={ m_width_close_btn} />
            </button>    
            <div className="profile_content_mid">
              <div dangerouslySetInnerHTML={{ __html: managerInfo?.description }} >

              </div>
            </div>
          </div>
        </SpeakerContainer>
    </Fragment>
  );
};

export default SpeakerModal;
