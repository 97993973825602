import React, { useContext,useEffect, useState } from "react";
import { Fragment } from 'react';
import TopArroow from "../../../../resources/console/images/icon/top_arrow.svg";
import { AppContext } from "../../../../components/AppProvider";
import { Link } from "react-router-dom";
import useStore from "../../../../modules/utils/store";

export const Footer = (props) => {
  const { iam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const { history } = props;
  const [termsInfo, setTermsInfo] = useState(null);
  const [openModal, setOpenModal] = useState(null); 
  
  const showTerms = (type, e) => {
    if (e) e.preventDefault();

    $("body").css("overflow", "hidden");
    if (type === "termsUseAgree")
      setTermsInfo({ title: "Site Terms of Use", content: siteInfo?.memTermsUseForm });
    else if (type === "termsPrivateAgree")
      setTermsInfo({
        title: "Privacy policy",
        content: siteInfo?.memTermsPrivacyForm,
      });
    else if (type === "termsMarket")
      setTermsInfo({
        title: "Consent to receive marketing information",
        content: siteInfo?.memTermsMarketForm,
      });
    else {
      setTermsInfo(null);
    }
  };

  const goEventList = (e,sub,item) => {
    if(e) e.preventDefault();
    const confirmChk = window.confirm('Are you sure you want to stop watching and leave this page?');
    if (confirmChk) {
      if (sub == "popUp") {
        window.open(`/#/site/${siteInfo?.idx}/subpage/${item.pageId}/${item.idx}`);
      } else if (sub == "page") { 
        setTimeout(() => {
          history.push(`/site/${siteInfo?.idx}/subpage/${item.pageId}/${item.idx}`)
        }, 1500)
      }
    }
  }

  const modalClose = () => {
    setTermsInfo(null);
    setOpenModal(false);
    $("body").css("overflow", "auto");
  };

  const linkRel = () => { 
    window.open("https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetail?itemSeq=201601746")
  }

  useEffect(() => {
    $("#openModal").click(function (e) {
      e.preventDefault();
      $("body").css("overflow", "hidden");
      setOpenModal(true);
    })
  }, []);
  return (
    <footer id="footer">
      <div className="footer-arrow-wrap">
        <button type="button" className="go-to-top">
            <img src={TopArroow} alt="Top Button" />
            <span>TOP</span>
        </button>
      </div>
      {!['MEDIGATE','ICOD'].includes(iam?.targetType) && (<>
      <div className="footer_inform_cont">
        <div className="wid_1180">
          <ul>
              {siteInfo?.subPageList && siteInfo?.subPageList?.filter((s) => s?.isShortcutShow)?.sort((a, b) => a?.sortNumber - b?.sortNumber)?.map((item, i) => {
                return(
                  <li key={`sub_${i}`}>
                    {item.openType == "PAGE" && (
                      <a onClick={(e) => goEventList(e,"page",item)}>
                        <span>{item?.name}</span>
                      </a>
                    )}
                    {item.openType != "PAGE" && (
                      <a onClick={(e) => goEventList(e, "popUp", item)}>
                        <span>{item?.name}</span>
                      </a>
                    )}
                  </li>
                )
              })}
              {/* <li><Link to={`/site/${siteInfo?.idx}/notice`} target="_blank"><span>Notice</span></Link></li> */}
          </ul>
        </div>
      </div>
      </>)}
      <div className="footer">
        <div className="wid_1180">
          <pre className="ft_info" dangerouslySetInnerHTML={{ __html: siteInfo?.footer?.replace(/\n/g,'') }} />
          {iam?.targetType == "MEMBER" && siteInfo?.memberFooterCheck === "Y" ? (
          <div className="ft_info2" style={{ marginTop: "30px" }}>
            <a onClick={showTerms.bind(this, "termsPrivateAgree")}>Privacy policy.</a>
            <a onClick={showTerms.bind(this, "termsUseAgree")}>Site Terms of Use</a>
          </div>
        ) : (
          ""
        )}
        </div>
      </div>
      {termsInfo && (
        <Fragment>
          <div className="ft_modal_container">
            <div className="ft_modal_header">
              <div>
                <h2>{termsInfo?.title}</h2>
                <button
                  className="close_btn"
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    modalClose();
                  }}
                  >
                  <img src="https://cdn.onoffevent.com/common/icon_close.svg" />
                </button>
              </div>
            </div>
            <div className="ft_modal_content">
              <div dangerouslySetInnerHTML={{ __html: termsInfo?.content }} />
            </div>
          </div>
          <div className="modal_bg on"></div>
      </Fragment>
      )}
      {openModal && (
        <div className="modal_container">
          <button className="modal_close" onClick={modalClose}><img src="https://cdn.onoffevent.com/common/icon_close.svg" alt="닫기"/></button>
          <div className="modal_mid">
            <h4>
              You are attempting to navigate to a different page on the GSK Korea website.<br/>
              By clicking this link, you will be redirected to a different website or web channel.
            </h4>
            <span>
              The page you are about to access may be a related site of GSK Korea, a global GSK site, or an affiliate site where Korean regulations may not apply.
              If you are redirected to a third-party site that is not managed or owned by GSK, GSK is not responsible for the content provided on that site.
            </span>
          </div>
          <div className="btnBox">
            <button className="modalBtn cancelBtn" onClick={modalClose}>Cancle</button>
            <button className="modalBtn confirmBtn" onClick={linkRel}>Continue</button>
          </div>
        </div>
      )}
    </footer>
  );
};
