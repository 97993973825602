/* eslint-disable */
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import moment from "moment";
import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import Layout, { Wrapper } from "../Layouts/Layout";
import {Search} from '../../../../components/MUI/console/Form'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import useStore from "../../../../modules/utils/store";
import OndemandListDetail from "./OndemandList.Detail";
import iconGuide from "../../../../resources/preReg/images/icon/icon-guide.svg";
import iconNotice from "../../../../resources/preReg/images/icon/icon-notice.svg";
import Modal from "../../../../components/UI/preReg/Modal";
import RegisterAccompanyAttendModalOD from "../../../../components/register/Register.AccompanyingAttendModalOD";

export default (props) => {
  const { auth, iam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const { history, location, match } = props;
  const pathName = window.location.hash;
  let pageId = "";
  if(pathName){
    let pathInfos = pathName.indexOf("?") === -1 ? pathName.split("/") : pathName.split("?")[0].split("/");
    pageId = pathInfos[4]
  }
  useEffect(() => {
    let isAble = siteInfo?.subPageList.filter((i) => i.pageId == pageId)[0].isUse;
    if(isAble == 0){
      window.location.href = "/";
    }
  },[]);
  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selected, setSelected] = useState(false);
  const [upComingYnCount, setUpComingYnCount] = useState(0);
  
  const [keyword, setKeyword] = useState("");
  const [ondemandList, setOndemandList] = useState([]);
  const [count, setCount] = useState(0);
 
  const [brandArrays, setBrandArrays] = useState([]);
  const [allBtnSelected, setAllBtnSelected] = useState(true);
  const [brands, setBrands] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(false);

  // Acoompany Datas
  const [preregIdx, setPreregIdx] = useState();
  const [accompanyEventIdx, setAccompanyEventIdx] = useState();
  const [accompanySessionIdx, setAccompanySessionIdx] = useState();
  const [accompanyAttendModal, setAccompanyAttendModal] = useState(false);
  const Modalstyle = {
    overflowX: "hidden",
    overflowY: "auto"
  };

  const getOndemandList = (more) => {
    const moreView = more ?? 0;
    let brandIdxs = [];
    if(brandArrays.length > 0){
      brandIdxs = Array.from(new Set(brandArrays));
    }else{
      brandIdxs = [];
    }

    const req = {
      path: "/v1/ondemand/list",
      data: {
        siteIdx: siteInfo.idx,
        brandIdxs : brandIdxs != null ? brandIdxs : [],
        moreView : moreView,
        keyword: keyword
      },
    };
    _API.post(req).then((res) => {
      const { data, count } = res.data;
      
      if(moreView > 0){
        if(data.length > 0){
          setOndemandList([...ondemandList, data]);
          setCount(count);
        }else{
          alert('There are no Ondemand surveys to add.');
        }
      }else{
        setOndemandList(data);
        setCount(count);
      }
      setLoaded(true);
    });

    setLoaded(true);
  }

  const getBrandList = () => {
    const req = {
      path: "/v1/ondemand/brand/list",
      data: {
        siteIdx: siteInfo.idx
      },
    };
    _API.post(req).then((res) => {
      const { data } = res.data;
      setBrands(data);
    });
  }

  const handleItemClick = (item) => {
    if(item == null){
      if(allBtnSelected == false){
        setBrandArrays(() => {
          const updatedBrands = [];
          setShouldFetch(true); // 상태 업데이트 후에 작업을 수행하기 위해 플래그 설정
          return updatedBrands;
        });
         // 상태 업데이트를 반영하기 위해 brands 배열도 업데이트
        const updatedBrandsList = brands.map((brand) => {
          let newItem = {...brand};
          newItem.selected = 0;
          return newItem;
        });
        setBrands(updatedBrandsList);
        setAllBtnSelected(true)
      }else{
        setAllBtnSelected(false)
      }
    }else{
      const updatedItem = { ...item, selected: item.selected === 0 ? 1 : 0 };
      if(updatedItem.selected === 1) {
        setBrandArrays((prevBrandArrays) => {
          const updatedBrands = [...prevBrandArrays, updatedItem.brandIdx];
          setShouldFetch(true); // 상태 업데이트 후에 작업을 수행하기 위해 플래그 설정
          return updatedBrands;
        });
      } else {
        setBrandArrays((prevBrandArrays) => {
          const updatedBrands = prevBrandArrays.filter((brand) => brand !== updatedItem.brandIdx);
          setShouldFetch(true); // 상태 업데이트 후에 작업을 수행하기 위해 플래그 설정
          return updatedBrands;
        });
      }
  
      // 상태 업데이트를 반영하기 위해 brands 배열도 업데이트
      const updatedBrandsList = brands.map((brand) => 
        brand.brandIdx === item.brandIdx ? updatedItem : brand
      );
      setBrands(updatedBrandsList);
      setAllBtnSelected(false)
    }
  };

  useEffect(() => {
    if (shouldFetch) {
      getOndemandList();
      setShouldFetch(false); // 작업이 완료되면 플래그를 다시 false로 설정
    }
  }, [shouldFetch]);

  useEffect(() => {
    getOndemandList();
    getBrandList(); 
  }, []);
  
  if (!loaded) {
    return (
      <img
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "200px",
          height: "200px",
          display: "block",
          transform: "translate(-50%,-50%)",
        }}
        className="loading_img loading_img4"
        src="https://cdn.onoffevent.com/common/loading.gif"
      />
    );
  }
  return (
    <Layout {...props} bodyClass="page-registration">
      <Wrapper>
        <div id="ondemand_con">
          <div
            className="main_header_bnr_box"
            style={{ backgroundColor: siteInfo?.eventListBgColor ? siteInfo?.eventListBgColor : "#fff" }}
          >
            <img 
              className="main_header_bnr_box_img1"
              src={`${siteInfo?.eventListMainImgUrl
                ? siteInfo?.eventListMainImgUrl
                : `https://cdn.onoffevent.com/bg/Event%20list%20bg.png`
              }`}
            />
            <img 
              className="main_header_bnr_box_img2"
              src={`${siteInfo?.eventListMainImgUrl
                ? siteInfo?.eventListMainImgUrl
                : `https://cdn.onoffevent.com/bg/Event%20list%20bg.png`
              }`}
            />
            {siteInfo?.nameEventListShow == 'Y' ? 
              (<div className="sub_bg_img_hd">
                <h1 style={{ color: siteInfo.nameColor }}>{siteInfo?.title}</h1>
              </div>)
              : ""  
            }
          </div>
          <div className="main_inform">
            <div className="main_inform_text wid_1180">
              <div className="main_inform_list">
                <ul>
                  {siteInfo?.subPageList.map((item, i) => {
                    return(
                      <>
                      {item.isShortcutShow == 1 && (
                        item.pageType == "LINK" ? (
                          <li key={`sub_${i}`}>
                            <a href={`https://${item.link}`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} > {item.name} </a>
                          </li>
                        ) : (
                          <li key={`sub_${i}`}>
                            <a href={`/#/site/${siteInfo.idx}/subpage/${item.pageId}`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} >
                              {item.name} 
                            </a> 
                          </li>
                        )
                      )}
                      </>
                    )
                  })}
                </ul>      
              </div>
            </div>
          </div>
          <div className="main_inform_2">
            <ul>
              <li>
                <div>
                  <img
                    src="https://cdn.onoffevent.com/main/e-booth.png"
                    alt="Go to e-booth"
                  />
                  <span>E-Booth</span>
                </div>
              </li>
              <li>
                <div>
                  <img
                    src="https://cdn.onoffevent.com/main/e-poster.png"
                    alt="Go to e-poster"
                  />
                  <span>E-Poster</span>
                </div>
              </li>
            </ul>
          </div>
          <section className="main_section">
            <div className="main_area event_area wid_1180">
              <h2>{siteInfo?.subPageList.filter((i) => i.pageId == pageId).length > 0 ? siteInfo?.subPageList.filter((i) => i.pageId == pageId)[0].name : ""}</h2>
              <div className="brand_search_box">
                {brands && ( 
                  <ul className="brand_list">
                    <li 
                      onClick={() =>handleItemClick(null)}
                      className={cx({ active: allBtnSelected})}
                    ><button>All</button></li>
                    {brands.map((item, i) => (
                      <li
                        onClick={() => handleItemClick(item)}
                        className={cx({ active: item.selected != 1})}
                      >
                        <button>{item.name}</button>
                      </li>
                    ))}
                  </ul>
                )}
                <div className="search_box">
                  <Search
                    value={keyword}
                    setKeyword={setKeyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onClick={(e) => getOndemandList()}
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="main_area_cont">
                <div className="main_area_ul">
                  <OndemandListDetail 
                    ondemandList={ondemandList}
                    setPreregIdx={setPreregIdx}
                    setAccompanyEventIdx={setAccompanyEventIdx}
                    setAccompanySessionIdx={setAccompanySessionIdx}
                    setAccompanyAttendModal={setAccompanyAttendModal}
                  />
                </div>
                {count > 0 && (  
                  <div className="more_btn">
                    <button onClick={(e) => getOndemandList(ondemandList.length)}><span>More</span></button>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        {accompanyAttendModal && (
          <Modal modalClass="d-block" modalstyle={Modalstyle}>
            <RegisterAccompanyAttendModalOD
              eventIdx={accompanyEventIdx}
              sessionIdx={accompanySessionIdx}
              setAccompanyAttendModal={setAccompanyAttendModal}
              preregIdx={preregIdx}
            />
          </Modal>
        )}
      </Wrapper>
    </Layout>
  );
};

