import React from 'react';
import ImgPhoto from "../../../../resources/console/images/icon/default-user.svg";
import btnMoreinfo from "../../../../resources/preReg/images/icon/btn-moreinfo.svg";
export default (props) => { 
  const { item, setSessionMgrInfoModal } = props;
  return (
    <div className="slide_flex" onClick={(e) => setSessionMgrInfoModal(true, item)} >
      <div className="slide_flex_div">
        <div className="slide_flex_inner_div">
          <img className="slide_img" src={item.signedUrlPhoto || ImgPhoto} alt={"ImgPhto"} />
          <img className="slide_img_more" src={btnMoreinfo} alt="더보기" />
        </div>
      </div>
      <div className="slide_flex_t">
        <b>
          {item?.mgrType === "SEAT" ? "Chairperson" : item?.mgrType === "PANEL" ? "Panelist" : "Speaker"}
        </b>
        <h2>{item?.name}</h2>
        <p>
          {item?.brieflyMark ? item?.brieflyMark : (
            <>
              <span>{item.companyName}</span>
              <span>{item.deptName}</span>
            </>
          )}
        </p>
      </div>
    </div>
  )
}