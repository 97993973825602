import React, { useState, Fragment, useEffect, useContext} from "react";
import { Modal , Button } from "react-bootstrap";
import { _U,_API } from "../../../modules/utils";
import { AppContext } from "../../../components/AppProvider";
import useStore from "../../../modules/utils/store";

function ModalPopup(props) {
  const { showPopup, setShowPopup } = props;
  const { iam } = useContext(AppContext);
  const [modalPopupShow, setModalPopupShow] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalTitleUse, setModalTitleUse] = useState("Y");
  const [modalContents, setModalContents] = useState(null);
  const [closeBtnUse, setCloseBtnUse] = useState("Y");
  const [idx, setIdx] = useState(0);
  const { playerSetting } = useStore();
  
  useEffect(() => {
    if(playerSetting!= null && playerSetting.getFullscreen() == true){
      playerSetting.setFullscreen(false);
    }
    if(idx == 0){
      handleClose();
    }else{
      openModal(idx)
    }
  }, [idx]);

  const handleClose = () => {
    setShowPopup(false);
  };

  const openModal = async (idx) => {
    const req = {
      path: "/v1/event/session/surveys/modal/popup/detail",
      data : {
        idx : idx
      }
    };
    await _API.post(req).then((res) => {
      const { data } = res.data;
      
      setModalTitle(data.title)
      setModalTitleUse(data.titleShow)
      setCloseBtnUse(data.closeBtnUse)
      
      let contents = data.contents;
      if(contents != null && contents != ""){
        contents = contents.replace("sliUname=","user_name="+iam?.name)
        contents = contents.replace("sliUemail=","user_email="+iam?.email)
        contents = contents.replace("sliUcompany=","user_company="+iam?.companyName)
      }
      setModalContents(contents)
      setShowPopup(true);
    });
   
  };

  const onReceivedMessage = (e) => {
    _U.parseMessage(e).then((data) => {
      switch (data.code) {
          case "SOCKET-RECEIVED":
              return data.data ? JSON.parse(data.data) : null;
      }
    }).then((data) => {
      if (data?.mode === "MODAL_POPUP_OPEN") {
        console.log("in")
        setIdx(data.idx)
      } 
      if (data?.mode === "MODAL_POPUP_CLOSE") {
        setIdx(0)  
      }
    });
  };

  useEffect(() => {
    window.addEventListener("message", onReceivedMessage);
    return () => {
      window.removeEventListener("message", onReceivedMessage);
    }; 
  }, []);

  return (
    <Fragment>
      <Modal
        show={showPopup}
        onHide={handleClose}
        backdrop="static"
        className="survey_container consolePopup_container"
      >
        {(modalTitleUse == "Y" || closeBtnUse == "Y") && (
          <div className="modal_header">
            <div className="modal_header_inner">
              <div className="modal_title" id="survey_title">
                {modalTitleUse == "Y" && modalTitle}
              </div>
            </div>
            {closeBtnUse == "Y" && 
              <div className="close" onClick={handleClose}></div>
            }
          </div>
        )}
        <div className="modal_body" style={{ background:"none",height:"100%"}}><div className="modalInnerHtml" dangerouslySetInnerHTML={{ __html: modalContents }}></div></div>
      </Modal>
    </Fragment>
  )
}

export default ModalPopup;