import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import cx from "classnames";
import uuid from "uuid/v1";
import DatePicker from "react-datepicker";
import _ from "lodash";
import * as FilePond from "filepond";
import { FilePond as FilePondForm } from "react-filepond";
import { isMobile } from "react-device-detect";

import { _API } from "../../../modules/utils";
import { Row, Col } from "./Grid";

const Input = styled.input.attrs((props) => {
    return {
        type: props.type || "text",
        className: props.className || "mypage_input",
    };
})``;

const FormGroup = styled.div.attrs((props) => {
    return {
        className: `mb-0 form-group ${props.className ? props.className : ""}`,
    };
})``;

export const Label = styled.label.attrs((props) => {
    return {
        className: props.className || "mypage_label",
    };
})``;

export default (props) => {
    const { title, required, fullSize } = props;
    const { name, type, value, onChange, placeholder, hint } = props;
    const { button, onPlus, onMinus, tail } = props;
    const { selectValue, selectItems, onSelectChange, selectAfter } = props;
    const { isDisabled, isReadOnly } = props;
    const { accept } = props;

    let { id } = props;
    if (!id) {
        id = uuid();
    }
    const noAuth = (e) => {
        e.preventDefault();
        alert("수정권한이 없습니다.");
        return;
    };
    const onClick = (e) => {
        e.preventDefault();
        props.onClick && props.onClick(e);
    };
    const onPlusClick = (e) => {
        e.preventDefault();
        onPlus && onPlus(e);
    };
    const onMinusClick = (e) => {
        e.preventDefault();
        onMinus && onMinus(e);
    };

    
    return (
        <FormGroup>
            {title ? (
                <Label htmlFor={id}>
                    {title} {required && <i className="required" style={{fontStyle: "normal", color: "#E00000"}}>*</i>}
                </Label>
            ) : null}
            {Boolean(selectItems) && !selectAfter && (
                <div className="select-wrap">
                    <select className="custom-select" value={selectValue} onChange={onSelectChange} disabled={isDisabled}>
                        {selectItems.map((item, i) => (
                            <option key={i} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            {type !== "file" && <Input name={name} type={type} id={id} value={value || ""} onChange={onChange} placeholder={placeholder} disabled={isDisabled} readOnly={isReadOnly} />}
            {type === "file" && <Input name={name} type={type} id={id} onChange={onChange} placeholder={placeholder} disabled={isDisabled} readOnly={isReadOnly} accept={accept || "*"} />}
            {Boolean(button) || Boolean(onPlus) || Boolean(onMinus) || Boolean(tail) ? (
                <div className="button-wrap" style={fullSize ? { minWidth: "auto" } : {}}>
                    {Boolean(button) && (
                        <a href="#" className="button button__outline" onClick={isDisabled ? noAuth : onClick}>
                            {button}
                        </a>
                    )}
                    {Boolean(onPlus) && (
                        <a href="#" className="button button__oval" onClick={isDisabled ? noAuth : onPlusClick}>
                            <span className="material-icons">add</span>
                        </a>
                    )}
                    {Boolean(onMinus) && (
                        <a href="#" className="button button__oval" onClick={isDisabled ? noAuth : onMinusClick}>
                            <span className="material-icons">remove</span>
                        </a>
                    )}
                    {Boolean(tail) && <Label>{tail}</Label>}
                </div>
            ) : null}

            {Boolean(selectItems) && selectAfter && (
                <div className="select-wrap">
                    <select className="custom-select" value={selectValue} onChange={onSelectChange} disabled={isDisabled}>
                        {selectItems.map((item, i) => (
                            <option key={i} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {Boolean(hint) && <small className="text-black-50">{hint}</small>}
        </FormGroup>
    );
};

export const TextArea = (props) => {
    const { title, required } = props;
    const { name, value, onChange, placeholder } = props;
    let { id } = props;
    const { isDisabled } = props;
    if (!id) {
        id = uuid();
    }
    return (
        <FormGroup>
            {title ? (
                <Label htmlFor={id}>
                    {title} {required && <i className="required">*</i>}
                </Label>
            ) : null}
            <div className={cx({ "col-sm-8 col-md-8": true, "offset-sm-3 offset-md-3 offset-lg-2": !Boolean(title) })}>
                <textarea name={name} id={id} value={value || ""} onChange={onChange} placeholder={placeholder} className="form-control" style={{ minHeight: 120 }} disabled={isDisabled} />
            </div>
        </FormGroup>
    );
};

export const Radio = (props) => {
    const { title, required, value, items, onChange, hint, children, className, isWrap } = props;
    let { name } = props;
    const { isDisabled } = props;
    if (!name) {
        name = uuid();
    }
    return (
            <div
                className={cx({
                    "d-flex": true,
                    "flex-wrap": isWrap,
                    "align-items-center": true,
                },className)}
            > 
                {items.map((item, i) => (
                    <Label key={i} className="radio mr-2 my-1">
                        <Input type="radio" name={name} value={item.value} onChange={onChange} checked={value === item.value} disabled={isDisabled} />
                        <span></span>&nbsp;&nbsp;
                        <p>{item.label}</p>
                    </Label>
                ))}
                {Boolean(children) && children}
            </div>
    );
};

export const Search = (props) => {
    const { type, id, name, placeholder, value, onChange, onClick } = props;
    const { more, onMore } = props;
    const { children } = props;

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            onClick && onClick();
        }
        props.onKeyDown && props.onKeyDown(e);
    };

    return (
        <div className="search">
            {more ? (
                <div className="space">
                    <a className="underline" onClick={onMore}>
                        {more}
                    </a>
                </div>
            ) : null}

            <div className="form-wrap">
                <div className="search-wrap">
                    <input type={type || "text"} value={value} onChange={onChange} className="form-control" id={id} name={name} placeholder={placeholder || "검색"} onKeyDown={onKeyDown} />
                    <button type="button" onClick={onClick}>
                        <i className="icon icon-search"></i>
                    </button>
                </div>
            </div>
            {Boolean(children) && children}
        </div>
    );
};

export const DateTime = (props) => {
    const { title, required } = props;
    const { date, onDateChange } = props;
    const { times, time, onTimeChange } = props;
    const { isDisabled } = props;
    return (
        <FormGroup className={props.className}>
            {title ? (
                <Label>
                    {title} {required && <i className="required">*</i>}
                </Label>
            ) : null}
            <div className={cx({ "col-sm-6 col-md-5 d-flex align-items-center": true, "offset-sm-3 offset-md-3 offset-lg-2": !Boolean(title) })}>
                <div className="w-40 mr-1">
                    <div className="div-date">
                        <DatePicker className="input-date" selected={date} onChange={onDateChange} dateFormat="yyyy-MM-dd" placeholderText={props.placeholder} disabled={isDisabled} />
                    </div>
                </div>
                {Boolean(times) && (
                    <div className="w-50">
                        <select value={time} onChange={(e) => onTimeChange && onTimeChange(e.target.value)} className="custom-select" disabled={isDisabled}>
                            {times.map((item, i) => (
                                <option key={i} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
        </FormGroup>
    );
};

export const CheckBox = (props) => {
    const { title, required } = props;
    const { items, values, onChange } = props;
    let { name } = props;
    const { isDisabled } = props;
    if (!name) {
        name = uuid();
    }
    return (
        <FormGroup className={props.className}>
            {title ? (
                <Label>
                    {title} {required && <i className="required">*</i>}
                </Label>
            ) : null}
            <div className={cx({ "col-sm-9": true, "offset-sm-3": !Boolean(title) })}>
                <div className="d-flex align-items-center flex-wrap">
                    {items.map((item, i) => (
                        <label key={i} className="checkbox mr-3 my-1">
                            <input type="checkbox" name={name} value={item.value} onChange={onChange} checked={_.findIndex(values, (o) => o === item.value) !== -1} disabled={isDisabled} />
                            <span></span> {item.label}
                        </label>
                    ))}
                </div>
            </div>
        </FormGroup>
    );
};

export const CheckBoxIs = (props) => {
    const { title, required } = props;
    const { items, values, onChange } = props;
    const { isDisabled } = props;

    return (
        <FormGroup className={props.className}>
            {title ? (
                <Label>
                    {title} {required && <i className="required">*</i>}
                </Label>
            ) : null}
            <div className={cx({ "col-sm-9": true, "offset-sm-3": !Boolean(title) })}>
                <div className="d-flex align-items-center flex-wrap">
                    {items.map((item, i) => (
                        <label key={i} className="checkbox mr-3 my-1">
                            <input type="checkbox" name={item.name} value={item.value} onChange={onChange} checked={values[item.name] === item.value} disabled={isDisabled} />
                            <span></span> {item.label}
                        </label>
                    ))}
                </div>
            </div>
        </FormGroup>
    );
};

export const DropDown = (props) => {
    const { title, required } = props;
    const { items, value, onChange, baseItem } = props;
    const { isDisabled } = props;
    return (
        <FormGroup className={props.className}>
            {title ? (
                <Label>
                    {title} {required && <i className="required">*</i>}
                </Label>
            ) : null}
            <div className={cx({ "col-sm-9 col-md-8": true, "offset-sm-3 offset-md-3 offset-lg-2": !Boolean(title) })}>
                <select className="custom-select" value={value} onChange={onChange} disabled={isDisabled}>
                    {baseItem && <option value={baseItem.value}>{baseItem.label}</option>}
                    {items?.map((item, i) => (
                        <option key={i} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </select>
            </div>
        </FormGroup>
    );
};

export const Hint = (props) => {
    return (
        <FormGroup>
            <div
                className={cx({
                    "col-sm-8 col-md-8 col-lg-8": true,
                    "offset-sm-3 offset-md-3 offset-lg-2": true,
                    "text-black-50": true,
                })}
            >
                {props.children}
            </div>
        </FormGroup>
    );
};

export const S3MultiUploader = (props) => {
    const { aws, accept } = props;

    useEffect(() => {
        FilePond.setOptions({
            server: {
                process: (fieldName, file, metadata, load, error, progress, abort) => {
                    const req = { aws, file };
                    _API.upload(req).then((res) => {
                        load();
                        props.onFileUpload && props.onFileUpload(res.data);
                    });
                },
                revert: (uniqueFieldId, load, error) => {},
            },
        });
    }, [aws]);

    if (accept) {
        setTimeout(() => {
            $(".filepond--browser").attr("accept", accept);
        }, 200);
    }

    return (
        <Fragment>
            <Row className="row">
                <Col>
                    <FilePondForm allowMultiple={true} />
                </Col>
            </Row>
        </Fragment>
    );
};
