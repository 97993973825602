import React, { Fragment} from "react";
import useStore from "../../../../modules/utils/store";

export default (props) => {
  const { siteInfo } = useStore();

  return (
    <Fragment>
      <div className="detail_guide_inner_box">
        <h3>문의</h3>
     {/*    {siteInfo?.csRegCheck == "Y" && siteInfo?.csAccessCheck == "N" ? (
          <p>등록 및 접속관련 {siteInfo?.csRegTel}</p>
        ) : siteInfo?.csRegCheck == "N" && siteInfo?.csAccessCheck == "Y" ? (
          <p>등록 및 접속관련 {siteInfo?.csAccessTel}</p>
        ) : siteInfo?.csRegCheck == "Y" && siteInfo?.csAccessCheck == "Y" ? (
          <>
            <p>등록관련 {siteInfo?.csRegTel}</p>
            <p>접속관련 {siteInfo?.csAccessTel}</p>
          </>
        ) : (
          ""
        )} */}
         <p dangerouslySetInnerHTML={{ __html: siteInfo?.csDescription }} />
      </div>
    </Fragment>
  );
};