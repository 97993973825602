import React, { useEffect, useState, useRef } from "react";
import swal from "sweetalert";

import ReactSummernote from "react-summernote";
import "react-summernote/lang/summernote-ko-KR";
import "react-summernote/dist/react-summernote.css";

import { _API } from "../../modules/utils";

const defaultOptions = {
  lang: "ko-KR",
  height: 250,
  dialogsInBody: true,
  toolbar: [
    // ["style", ["style"]],
    ["font", ["bold", "underline", "clear"]],
    // ["fontname", ["fontname"]],
    ["para", ["ul", "ol", "paragraph"]],
    ["table", ["table"]],
    ["insert", ["link", "picture", "video"]],
    ["view", ["fullscreen", "codeview"]],
  ],
};

export default class SNEditor extends React.Component {
  constructor(props) {
    super(props);
    const state = { aws: null };
    this.state = state;
  }

  componentDidMount = () => {
    this.uploadPrepare();
  };

  uploadPrepare = () => {
    _API.get({ path: "/v1/upload" }).then((res) => {
      this.setState({ aws: res.data });
    });
  };

  handleOnChange = (content) => {
    this.props.onChange && this.props.onChange(content);
  };

  handleOnImageSelected = (files) => {
    if (!files || files.length === 0) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 1024 * 1024 * 2) {
        swal("2MB 이하의 파일을 선택해 주세요.");
        return;
      }
    }

    const { aws } = this.state;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const req = { aws, file, path: "SN" };

      _API.upload(req).then((res) => {
        const url = res.data.signedUrl;
        this.summernote && this.summernote.insertImage(url);
      });
    }
  };

  render() {
    const { id, options, height, content } = this.props;

    const snOptions = options || defaultOptions;
    if (height) {
      snOptions.height = height;
    }
    return (
      <ReactSummernote
        id={id}
        value={content}
        options={snOptions}
        onChange={this.handleOnChange.bind(this)}
        onImageUpload={this.handleOnImageSelected.bind(this)}
        ref={(ref) => (this.summernote = ref)}
      />
    );
  }
}
