import React, { useContext, useEffect, useState } from "react";
import { Fragment } from 'react';
import { Link } from "react-router-dom";
import { AppContext } from "../../../../../components/AppProvider";
import TopArroow from "../../../../../resources/console/images/icon/top_arrow.svg";
export const Footer = (props) => {
  const { auth, iam } = useContext(AppContext);
  const { data  } = props;
  const [termsInfo, setTermsInfo] = useState(null);
  const [openModal, setOpenModal] = useState(null); 

  const showTerms = (type, e) => {
    if (e) e.preventDefault();
    $("body").css("overflow", "hidden");
    if (type === "termsUseAgree")
      setTermsInfo({ title: "사이트이용약관", content: siteInfo?.memTermsUseForm });
    else if (type === "termsPrivateAgree")
      setTermsInfo({
        title: "개인정보처리방침",
        content: siteInfo?.memTermsPrivacyForm,
      });
    else if (type === "termsMarket")
      setTermsInfo({
        title: "마케팅정보 수신동의",
        content: siteInfo?.memTermsMarketForm,
      });
    else {
      setTermsInfo(null);
    }
  };
  // 스크롤 이벤트 핸들러
  const toggleVisibility = () => {
    const FooterArrowWrap = $(".footer-arrow-wrap");
    if (window.pageYOffset > 10) { // 300px 이상 스크롤되면 버튼 보이기
      FooterArrowWrap.addClass("active");
    } else {
      FooterArrowWrap.removeClass("active");
    }
  };
  // 컴포넌트 마운트 시 스크롤 리스너 추가 및 언마운트 시 제거
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);
  const modalClose = () => {
    setTermsInfo(null);
    setOpenModal(false);
    $("body").css("overflow", "auto");
  };
  // 스크롤을 맨 위로 올리는 함수
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // 부드러운 스크롤
    });
  };
  const linkRel = () => { 
    window.open("https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetail?itemSeq=201601746")
  }
  
  useEffect(() => {
    $("#openModal").click(function (e) {
      e.preventDefault();
      $("body").css("overflow", "hidden");
      setOpenModal(true);
    })
  }, []);
  return (
    <footer id="footer">
      <div className="footer-arrow-wrap">
        <button onClick={scrollToTop} type="button" className="go-to-top">
            <img src={TopArroow} alt="탑버튼" />
            <span>TOP</span>
        </button>
      </div>
      <div className="footer">
        <div className="wid_1180">
          <pre className="ft_info">
            {/* <div dangerouslySetInnerHTML={{ __html: siteInfo?.footer?.replace(/\n/g,'') }} /> */}
            <div dangerouslySetInnerHTML={{ __html: data }} />
          </pre>
{/*           {iam?.targetType == "MEMBER" && siteInfo?.memberFooterCheck === "Y" ? (
            <div className="ft_info2" style={{ marginTop: "30px" }}>
              <a onClick={showTerms.bind(this, "termsPrivateAgree")}>개인정보처리방침.</a>
              <a onClick={showTerms.bind(this, "termsUseAgree")}>이용약관</a>
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
      {/* {termsInfo && (
        <Fragment>
          <div className="ft_modal_container">
            <div className="ft_modal_header">
              <div>
                <h2>{termsInfo?.title}</h2>
                <button
                  className="close_btn"
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    modalClose();
                  }}
                  >
                  <img src="https://cdn.onoffevent.com/common/icon_close.svg" />
                </button>
              </div>
            </div>
            <div className="ft_modal_content">
              <div dangerouslySetInnerHTML={{ __html: termsInfo?.content }} />
            </div>
          </div>
          <div className="modal_bg on"></div>
      </Fragment>
      )}
      {openModal && (
        <div className="modal_container">
          <button className="modal_close" onClick={modalClose}><img src="https://cdn.onoffevent.com/common/icon_close.svg" alt="닫기"/></button>
          <div className="modal_mid">
            <h4>
              당신은 한국 GSK 웹 사이트에서
              다른 페이지로 이동하려고 하고 있습니다.<br/>
              이 링크를 클릭하면 다른 웹 사이트 또는 웹 채널로 이동하게 됩니다.
            </h4>
            <span>
              지금 이동하려는 페이지는 한국 GSK의 유관 사이트이거나
              한국 규정이 적용되지 않는 글로벌 GSK 사이트 또는 계열사일 수 있습니다.
              GSK가 관리하지 않거나 GSK가 소유하지 않은 제3자 사이트로 이동할 경우,
              GSK는 해당 사이트에서 제공하는 콘텐츠에 대해 책임지지 않습니다.
            </span>
          </div>
          <div className="btnBox">
            <button className="modalBtn cancelBtn" onClick={modalClose}>취소</button>
            <button className="modalBtn confirmBtn" onClick={linkRel}>계속</button>
          </div>
        </div>
      )} */}
    </footer>
  );
};
