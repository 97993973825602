import { createTheme, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1180,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "",
    wordBreak: "keep-all",
    wordWrap: "break-word",
    h2: {
      fontSize: "32px",
      color:"#1a1a1a",
      fontWeight: "900",
      lineHeight: "normal"
    },
    h3: {
      fontSize: "20px",
      color: "#1a1a1a",
      fontWeight: "700",
      lineHeight:"26px"
    },
    h4: {
      fontSize: "15px",
      color: "#646464",
      fontWeight: "400",
      lineHeight:"22px"
    },
    h5: {
      fontSize: "16px",
      color: "#1a1a1a",
      fontWeight: "700",
      lineHeight:"24px"
    },
    h6: {
      fontSize: "20px",
      color: "#646464",
      fontWeight: "700",
      lineHeight:"26px"
    },
    h7: {
      fontSize: "15px",
      color: "#464f60",
      fontWeight: "700",
      lineHeight:"22px"
    },
    h8: {
      fontSize: "15px",
      color: "#404040",
      fontWeight: "400",
      lineHeight:"22px"
    },
    p: {
      fontSize: "15px",
      color: "#646464",
      fontWeight: "400",
      lineHeight:"22px"
    },
    span: {
      fontSize: "13px",
      color: "#646464",
      fontWeight: "400",
      lineHeight:"18px"
    },
    small: {
      fontSize: "12px",
      color: "#93979F",
      fontWeight: "400",
      lineHeight:"18px"
    },
    subtext: {
      fontSize: "13px",
      color: "#646464",
      fontWeight: "500",
      lineHeight:"18px"
    },
    subtext2: {
      fontSize: "14px",
      color: "#464F60",
      fontWeight: "400",
      lineHeight:"normal"
    },
    subtext3: {
      fontSize: "14px",
      color: "#666",
      fontWeight: "400",
      lineHeight:"18px"
    },
    subtext4: {
      fontSize: "14px",
      color: "#464F60",
      fontWeight: "400",
      lineHeight:"18px"
    },
    subtext5: {
      fontSize: "12px",
      color: "#93979F",
      fontWeight: "400",
      lineHeight:"18px"
    },
    subtext6: {
      fontSize: "13px",
      color: "#646464",
      fontWeight: "400",
      lineHeight:"18px"
    },
    summary: {
      fontSize: "12px",
      color: "#646464",
      fontWeight: "400",
      lineHeight:"16px"
    },
    title: {
      fontSize: "26px",
      color: "#5959EF",
      fontWeight: "700",
      lineHeight:"35px"
    },
    alertTitle: {
      fontSize: "26px",
      color: "#FF0000",
      fontWeight: "900",
      lineHeight:"35px"
    },
    desc: {
      fontSize: "15px",
      color: "#646464",
      fontWeight: "400",
      lineHeight:"22px"
    },
    button: {
      fontSize: "15px",
      color: "#464f60",
      fontWeight: "400",
      lineHeight:"22px"
    },
    subtext7: {
      fontSize: "12px",
      color: "#999999",
      fontWeight: "400",
      lineHeight:"18px"
    },
    subtext8: {
      fontSize: "15px",
      color: "#999999",
      fontWeight: "400",
      lineHeight:"22px"
    },
    subtext9: {
      fontSize: "18px",
      color: "#646464",
      fontWeight: "400",
      lineHeight:"22px"
    },
    event_text: {
      fontSize: "11px",
      fontWeight: "400",
      lineHeight: "14px",
      color: "#464f60",
    },
    event_p: {
      fontSize: "15px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#646464",
    },
    event_span: {
      fontSize: "15px",
      fontWeight: "400",
      lineHeight: "22px",
      color: "#464f60",
    },
    event_h2: {
      fontSize: "22px",
      fontWeight: "900",
      lineHeight: "40px",
      color: "#1a1a1a",
    },
    event_h3: {
      fontSize: "20px",
      fontWeight: "800",
      lineHeight: "26px",
      color: "#1a1a1a",
    },
    event_h4: {
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "22px",
      color: "#1a1a1a",
    },
    event_h5: {
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "18px",
      color: "#1a1a1a",
    },
    event_h6: {
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "18px",
      color: "#464f60",
    },
    event_time: {
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "22px",
      color: "#5959ef",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'basic' },
          style: {
            width: "100%",
            fontSize: "15px",
            backgroundColor: "#6a6af8",
            color: "#fff",
            borderRadius: "4px",
            padding: "13px 13px",
            lineHeight: "normal",
            '&:hover': {
              background: "#6e6ef9",
              transition: "all 0.3s"
            },
          },
        },
        {
          props: { variant: 'submit' },
          style: {
            width: "100%",
            fontSize: "20px",
            backgroundImage: "linear-gradient(to bottom, #6460b1, #5533b1)",
            color: "#fff",
            fontWeight: "bold",
            borderRadius: "6px",
            padding: "20px 13px",
            lineHeight: "normal",
            minWidth: "100px",
            '&:hover': {
              backgroundColor: "#5533c9",
              transition: "all 0.3s"
            },
          },
        },
        {
          props: { variant: 'complete' },
          style: {
            fontSize: "15px",
            backgroundColor: "#ececfc",
            color: "#7878a5",
            borderRadius: "4px",
            padding: "10px 13px",
            lineHeight: "normal",
            minWidth: "100px",
          },
        },
        {
          props: { variant: 'general' },
          style: {
            fontSize: "12px",
            backgroundColor: "#fff",
            border:"1px solid #d9d9d9",
            color: "#999999",
            padding: "3px 9px",
            lineHeight: "normal",
            borderRadius: "0px",
            cursor:"default !important"
          },
        },
        {
          props: { variant: 'confirm' },
          style: {
            minWidth: "100px",
            fontSize: "15px",
            backgroundColor: "#6a6af8",
            color: "#fff",
            borderRadius: "4px",
            height: "40px",
            lineHeight: "40px",
            lineHeight: "normal",
            '&:hover': {
              background: "#6e6ef9",
              transition: "all 0.3s"
            },
          },
        },
        {
          props: { variant: 'cancel' },
          style: {
            minWidth:"100px",
            fontSize: "15px",
            backgroundColor: "#ccc",
            color: "#fff",
            borderRadius: "4px",
            height: "40px",
            lineHeight:"40px",
            lineHeight: "normal",
            '&:hover': {
              background: "#ccc",
              transition: "all 0.3s"
            },
          },
        },
      ],
    },
    
  },
});
export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input:focus':{
      borderRadius:"0px"
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        borderBottom: '1px solid #ccc',
        borderRadius:0,
        fontSize: 15,
        padding: '9px 26px 9px 0px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
    },
    '& .MuiSvgIcon-root': {
      background: `url("https://cdn.onoffevent.com/common/arrow_down.svg")`,
      color: "transparent",
      backgroundPosition: "center center",
      backgroundRepeat:"no-repeat"
    },
}));
