import React, { Fragment, useState, useRef, useEffect, useContext } from "react";

import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import swal from "sweetalert";
import moment from "moment";
import styled from "styled-components";
import cx from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import replyImg from "../../../../resources/console/images/icon/icon-s-reply.svg";
import replystImg from "../../../../resources/console/images/icon/st-reply.svg";
import replycountImg from "../../../../resources/console/images/icon/icon-s-replycount.svg";
import replycountOnImg from "../../../../resources/console/images/icon/icon-s-replycount-on.svg";
import replydelImg from "../../../../resources/console/images/icon/icon-s-delete-2.svg";
import { isEmpty } from "lodash";

export default (props) => {
  const { iam } = useContext(AppContext);
  const { consoleCfg } = props;
  const [isSendProc, setIsSendProc] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [questionReplyContent, setQuestionReplyContent] = useState({});
  const [questionContent, setQuestionContent] = useState("");
  const [moreView, setMoreView] = useState({ nextPage: 0, pagePerCount: 10 });
  const [totalCount, setTotalCount] = useState(0);
  const [remainListCount, setRemainListCount] = useState(0);
  const [myQuestion, setMyQuestion] = useState({ onOff: "N" });
  const [toggleMenu, setToggleMenu] = useState({});
 
  const onSetQuestionContent = (e) => {
    if (e.target.value.length <= 500) {
      setQuestionContent(e.target.value);
    }
  };

  const myQuestionView = (e, value) => {
    if (e) e.preventDefault();

    if (value) {
      setMyQuestion((o) => {
        return { ...o, ["onOff"]: value };
      });
      setQuestionList([]);
      setMoreView((o) => {
        return {
          ...o,
          ["nextPage"]: 0,
          ["pagePerCount"]: 10,
        };
      });
    }
  };

  const onSetNextPage = (e) => {
    let num = 0;
    if(document.body.clientWidth+15 < 568){
      num = $(".m_qna_container .question_list_inner_box").length;
    }else{
      num = $(".pc_qna_container .question_list_inner_box").length;
    }
    setMoreView((o) => {
      return { ...o, ["nextPage"]: num };
    });
  };

  const loadQuestionList = (item) => {
    let nextPage = 0;
    let pagePerCount = 0;

    if(item){
      nextPage = item.nextPage;
      pagePerCount = item.pagePerCount;
    } else {
      nextPage = moreView.nextPage;
      pagePerCount = moreView?.pagePerCount;
    }

    const req = {
      path: "/v1/client/question/list",
      data: {
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
        myQuestion: myQuestion.onOff,
        pagePerCount: pagePerCount,
        nextPage: nextPage,
      },
    };

    _API.post(req).then((res) => {
      let { data, totalCount, remainListCount } = res.data;
      setTotalCount(totalCount);
      setRemainListCount(remainListCount);

      if (data.length > 0) {
        if(item && item.gubun == "INSERT"){
          setQuestionList(data)
        }else{
          setQuestionList((o) => {
            const nb = [...o];
            for (let i in data) {
              nb.push(data[i]);
            }
            return nb;
          });
        }
      }

      if(moreView?.pagePerCount == 10){
        setMoreView((o) => {
          return {...o, ['pagePerCount'] : 5}
        })
      }
    });
  };

  const questionSave = () => {
    const req = {
      path: "/v1/client/question/save",
      data: {
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
        consoleIdx: consoleCfg.consoleIdx,
        name: iam.name,
        questionerType: iam.memAuth === "ADMIN" ? "MANAGER" : "ATTENDER",
        questionStatus: iam.memAuth == "ADMIN" ? "REQUEST" : "WAIT",
        content: questionContent,
        regId: iam?.preregIdx,
      },
    };

    _API.post(req).then((res) => {
      let { totalCount, find } = res.data;
      if ( totalCount > 0 ) {
        let item = {division : "QUESTION" , detail : "INSERT"};
        onSend(item);
        setQuestionContent("");
      }
    });
  };

  const questionSetShow = (item) => {
    console.log(item)
    const req = {
      path: "/v1/client/question/save",
      data: {
        idx: item.idx,
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
        consoleIdx: consoleCfg.consoleIdx,
        questionStatus: item.questionStatus,
        regId: iam?.preregIdx,
      },
    };

    _API.post(req).then((res) => {
      let { find } = res.data;
      if ( find ) {
        let item = {division : "QUESTION" , detail : "ONOFF", find : find};
        onSend(item);
      }
    });
  };
  
  const removeQna = (tableName, val) => {
    var confirmChkTxt = tableName == "T_QUESTION" ? "Are you sure you want to delete this question?" : "Are you sure you want to delete this comment?";
    if (window.confirm(confirmChkTxt)) {
      const req = {
        path: "/v1/client/question/delete",
        data: {
          eventIdx: consoleCfg.eventIdx,
          sessionIdx: consoleCfg.sessionIdx,
          tableName: tableName,
          idx: val.idx,
        },
      };

      _API.post(req).then((res) => {
        let { data } = res;

        if (data > 0) {
          let item = {division : tableName == "T_QUESTION" ? "QUESTION" : "COMMENT" , detail : "REMOVE", idx : val.idx, questionStatus : val.questionStatus};
          onSend(item);
          if (!val.idx) {
            $(".qna.scroll-wrap")[0].scrollTop = 0;
          }
        }
      });
    }
  };

  const questionCommentSetShow = (item) => {
    if (item?.showYn) {
      commentSave({
        idx: item.idx,
        showYn: item?.showYn === "Y" ? "N" : "Y",
        eventIdx: consoleCfg?.eventIdx,
        sessionIdx: consoleCfg?.sessionIdx,
        consoleIdx: consoleCfg?.consoleIdx,
        questionGubun: item.questionGubun,
        questionerType: item.questionerType,
        name: item.name,
        contents: item.contents,
        sort: item.sort,
        regId: item.regId,
        regDate: item?.regDate,
        tQuestionIdx: item.tQuestionIdx,
        saveGubun: "UPDATE",
      });
    }
  };

  const commentSave = (item) => {
    const req = {
      path: "/v1/client/comment/save",
      data: {
        idx: item.idx,
        eventIdx: item.eventIdx,
        sessionIdx: item.sessionIdx,
        consoleIdx: item.consoleIdx,
        tQuestionIdx: item.tQuestionIdx,
        contents: item.contents,
        regId: item.regId,
        regDate: item.regDate,
        showYn: item.showYn,
        writeLocation: "CONSOLE",
        name: item.name,
      },
    };

    _API.post(req).then((res) => {
      let { data } = res;
      if (data > 0) {
        setQuestionContent("");
        if (item.answerYn === "Y") {
          changeReplyContent(item, "");
        }
        item.division = "COMMENT";
        item.idx = data;
        onSend(item);
        if (!item.idx) {
          $(".qna.scroll-wrap")[0].scrollTop = 0;
        }
      }
    });
  };

  const changeReplyContent = (item, value) => {
    const key = `answerContent-${item.idx}`;
    if (value.length <= 300) {
      setQuestionReplyContent((o) => {
        return { ...o, [key]: value };
      });
    }
  };

  const questionReply = (item) => {
    if (!questionReplyContent[`answerContent-${item.idx}`]) {
      swal("Please enter your answer.");
      return;
    }
    const params = {};
    params.eventIdx = consoleCfg.eventIdx;
    params.sessionIdx = consoleCfg.sessionIdx;
    params.consoleIdx = consoleCfg.consoleIdx;
    params.tQuestionIdx = item.idx;
    params.contents = questionReplyContent[`answerContent-${item.idx}`];
    params.regId = iam?.preregIdx;
    params.showYn = iam?.memAuth == 'ADMIN' ? 'Y' : "N";
    params.saveGubun = "INSERT";
    params.regDate = new Date();
    params.name = iam?.name;
    commentSave(params);
    questionReplyContent[`answerContent-${item.idx}`] = "";
    setQuestionList((o) => {
      const nb = [...o];
      for (let i in nb) {
        if (nb[i].idx == item.idx) {
          nb[i].commentListShowYn = "N";
          nb[i].showCommentWriteBox = false;
        }
      }
      return nb;
    });
  };

  const setCommentListShowYn = (i) => {
    setQuestionList((o) => {
      const nb = [...o];
      nb[i].commentListShowYn = nb[i]?.commentListShowYn === "Y" ? "N" : "Y";
      return nb;
    });
  };

  const onSend = (item) => {
    if (!questionContent && !questionReplyContent) {
      return;
    }

    if (isSendProc) {
      return;
    }

    setIsSendProc(true);
    _U.postMessage("SOCKET-SEND", {
      action: "FMS",
      data: {
        mode: "FMS",
        siteIdx: consoleCfg?.siteIdx,
        eventIdx: consoleCfg?.eventIdx,
        sessionIdx: consoleCfg?.sessionIdx,
        consoleIdx: consoleCfg?.consoleIdx,
        name: iam?.name,
        memType: iam?.memType,
        companyName: iam?.companyName,
        deptName: iam?.deptName,
        memberIdx: iam?.idx,
        item: item,
      },
    });
    setIsSendProc(false);
  };

  const showTogleReplyWrap = (item) => {
    if (item.idx) $("#reply-wrap-group-" + item.idx).slideToggle();
  };

  
  const onReceivedMessage = (e) => {
    const y = $(window).scrollTop();
    _U.parseMessage(e).then((data) => {
        switch (data.code) {
          case "SOCKET-RECEIVED":
            return data.data ? JSON.parse(data.data) : null;
        }
      }).then((data) => {
        if(!data){
          data = data?.data
        }
        console.log(data)
        if (data?.mode == "FMS" && data?.eventIdx == consoleCfg.eventIdx && data?.sessionIdx == consoleCfg.sessionIdx) {
          if(data?.item.division == "QUESTION"){
            if(data?.item.detail == "INSERT"){
              let item = {
                nextPage : 0,
                pagePerCount : 10,
                gubun : "INSERT",
              }
              loadQuestionList(item);
            } else if(data?.item.detail == "ONOFF"){
              setQuestionList((o) => {
                let nb = [...o];
                if(nb?.filter((q) => q.idx == data?.item.find.idx)?.length > 0){
                  for (let i in nb) {
                    if (nb[i].idx == data?.item.find.idx) {
                      nb[i].questionStatus = data?.item.find.questionStatus;
                    }
                  }
                }
                return nb;
              });
            } else if(data?.item.detail == "REMOVE"){
              if (data?.item?.questionStatus === "REQUEST" || iam.memAuth == "ADMIN") {
                setTotalCount((o) => {
                  return --o;
                });
              }
              const tmp = [];
              setQuestionList((o) => {
                let nb = [...o];
                for (let i in nb) {
                  if (nb[i].idx != data?.item?.idx) {
                    tmp.push(nb[i]);
                  }
                }
                return tmp;
              });
            }  
          } 
        }
        //COMMENT 때문에 살려둠
        if (data?.item?.saveGubun === "INSERT") {
          if (data?.item?.division === "QUESTION") {
            if (
              data?.item?.showYn === "Y" ||
              iam.memAuth == "ADMIN" ||
              data?.item?.regId == iam.preregIdx
            ) {
              setTotalCount((o) => {
                return ++o;
              });
            }
            const tmp = [];
            tmp.push(data.item);
            setQuestionList((o) => {
              const nb = [...o];
              for (let i in nb) {
                tmp.push(nb[i]);
              }
              return tmp;
            });
          } else if (data?.item?.division === "COMMENT") {
            setQuestionList((o) => {
              const nb = [...o];
              for (let i in nb) {
                if (nb[i].idx == data?.item?.tQuestionIdx) {
                  if (nb[i]?.commentList) {
                    let length = nb[i]?.commentList
                      ? nb[i]?.commentList?.length
                      : 0;
                    nb[i].commentList[length] = data?.item;
                  } else {
                    let array = new Array();
                    array.push(data?.item);
                    nb[i].commentList = array;
                  }
                }
              }
              return nb;
            });
          }
        } else if (data?.item?.saveGubun === "REMOVE") {
          if (data?.item?.division === "QUESTION") {
            if (
              data?.item?.showYn === "Y" ||
              iam.memAuth == "ADMIN" ||
              data?.item?.regId == iam.preregIdx
            ) {
              setTotalCount((o) => {
                return --o;
              });
            }
            const tmp = [];
            setQuestionList((o) => {
              const nb = [...o];
              for (let i in nb) {
                if (nb[i].idx != data?.item?.idx) {
                  tmp.push(nb[i]);
                }
              }
              return tmp;
            });
          } else if (data?.item?.division === "COMMENT") {
            var tmp = [];
            setQuestionList((o) => {
              const nb = [...o];
              for (let i in nb) {
                if (nb[i].idx == data?.item?.tQuestionIdx) {
                  const nb2 = nb[i].commentList;
                  for (let j in nb2) {
                    if (nb2[j].idx != data?.item?.idx) {
                      tmp.push(nb2[j]);
                    }
                  }
                  nb[i].commentList = tmp;
                }
              }
              return nb;
            });
          }
        } else if (data?.item?.saveGubun === "UPDATE") {
          if (data?.item?.division === "QUESTION") {
            setQuestionList((o) => {
              let nb = [...o];
              if(nb?.filter((q) => q.idx == data?.item?.idx)?.length > 0){
                for (let i in nb) {
                  if (nb[i].idx == data?.item?.idx) {
                    data.item.commentList = nb[i].commentList;
                    nb[i] = data?.item;
                  }
                }
              }else{
                nb.push(data?.item);
                nb = nb.sort((a, b) => b.regDate - a.regDate);
              }
              return nb;
            });
          } else if (data?.item?.division === "COMMENT") {
            setQuestionList((o) => {
              const nb = [...o];
              for (let i in nb) {
                if (nb[i].idx == data?.item?.tQuestionIdx) {
                  const nb2 = nb[i].commentList;
                  for (let j in nb2) {
                    if (nb2[j].idx == data?.item?.idx) {
                      nb[i].commentList[j] = data?.item;
                    }
                  }
                }
              }
              return nb;
            });
          }
        }
        $("body").scrollTop(y);
      });
  };

  const setShowCommentWriteBox = (q, showYn) => {
    setQuestionList((o) => {
      const nb = [...o];
      for (let i in nb) {
        if (nb[i].idx == q.idx) {
          nb[i].showCommentWriteBox = showYn;
        }
      }
      return nb;
    });
  };
  
  useEffect(() => {
    window.addEventListener("message", onReceivedMessage, myQuestion);
    return () => {
      window.removeEventListener("message", onReceivedMessage, myQuestion);
    };
  }, []);

  useEffect(() => {
    loadQuestionList();
  }, [moreView?.nextPage, myQuestion]);
  
  return (
    <Fragment>
      <div className="qna_container">
        <div className="question_write_box">
          <div className="question_write_box_inner">
            <h2>Q&#38;A</h2>
            <span>{questionContent.length}/500</span>
          </div>
          <div className="question_box_flex">
            <TextareaAutosize
              type="text"
              maxLength="500"
              placeholder="Please enter your question."
              value={questionContent}
              onChange={(e) => onSetQuestionContent(e)}
              style={{ overflow: "hidden" }}
            ></TextareaAutosize>
            <button
              type="button"
              id="button-addon22"
              onClick={(e) => questionSave()}
            >
              Submit
            </button>
          </div>
        </div>


        {/* <PerfectScrollbar useBothWheelAxes={false} suppressScrollX={true}> */}
        <div className="question_list_box">
          <div className="question_list_box_title" style={{marginBottom:`${iam?.memAuth == "ADMIN" ? "10px" : "20px"}`}}>
            <ul>
              <li className={cx({ on: myQuestion.onOff !== "Y" })}>
                <a className="all_question" onClick={(e) => myQuestionView(e, "N")}>
                  All questions
                </a>
              </li>
              <li className={cx({ on: myQuestion.onOff === "Y" })}>
                <a className="my_qustion" onClick={(e) => myQuestionView(e, "Y")}>
                  My questions
                </a>
              </li>
            </ul>
            <p><b>{totalCount}</b> question(s) registered.</p>
          </div>
          <div className={cx({ question_list_wrapper_box: true, admin_question_list_wrapper_box: iam?.memAuth == "ADMIN"})}>
            {!isEmpty(questionList) && questionList?.map((item, i) => (
              <Fragment key={i}>
                <Fragment>
                  {(myQuestion.onOff === "N" || (myQuestion.onOff === "Y" && item.regId == iam.preregIdx)) && (
                    <div
                      style={{display:`${iam.memAuth == "ADMIN" ? "block" : item.questionStatus == "REQUEST" || item.regId == iam.preregIdx ? "block" : "none"}`}}
                      className={cx({
                        on: item.questionStatus === "WAIT" && iam.memAuth == "ADMIN",
                        question_list_inner_box: true,
                        active: toggleMenu[item.idx],
                      })}
                      id={`p_question_tab_${item.idx}`}
                    > 
                    <div className={cx({ question_list_box_desc: true})}>
                      <pre>{item.content}</pre>
                      <div className="question_list_box_date_reply">
                        <span className="question_list_box_date_reply_span">
                          {iam.memAuth == "ADMIN" ?(item?.name != null && item?.name != "" ? item?.name : item?.regId) + ' - ' : ""}{moment(item.regDate).format("MM.DD HH:mm:ss")}
                        </span>
                        <em>
                          <i className="demo-icon icon-comment" onClick={(e) => { showTogleReplyWrap(item) }}></i>
                        </em>
                          <div className="question_list_box_desc_btn" style={{ display: item.showCommentWriteBox == true ? "none": "flex",}}>
                            {iam.memAuth == "ADMIN" &&
                              <div className="qna_check_box">
                                <input
                                  type="checkbox"
                                  id={`switch${i}`}
                                  name="switch"
                                  className="input__on-off"
                                  value={item.questionStatus}
                                />
                                <label
                                  htmlFor={`switch${i}`}
                                  className="label__on-off label__on"
                                  style={{ backgroundColor: item?.questionStatus == "REQUEST" ? "#7758fa" : "#999999",}}
                                  onClick={(e) => questionSetShow(item)}
                                >
                                  <span
                                    className="marble"
                                    style={{ right: item?.questionStatus == "REQUEST" ? "3px" : "19px",}}
                                  ></span>
                                </label>
                              </div>
                            }
                            <button className="reply_btn" onClick={(e) => setShowCommentWriteBox(item, true)} >
                              <img src={replyImg} alt="Comment" />
                              <span>Comment</span>
                            </button>
                            {item?.commentList?.filter((c) => iam.memAuth == "ADMIN" || c.regId == iam.preregIdx || c?.showYn === "Y")?.length > 0 && (
                              <button className="reply_count" onClick={(e) => setCommentListShowYn(i)} >
                                <img src={item?.commentListShowYn === "Y" ? replycountOnImg : replycountImg} alt="Number of comments" />
                                <small>{item?.commentListShowYn === "Y"? "Hide comment": "View comment"}</small>
                                <b style={{ fontWeight: "bold" }}>
                                  {item?.commentList?.filter((c) => iam.memAuth == "ADMIN" || c.regId == iam.preregIdx || c?.showYn === "Y")?.length || 0}
                                </b>
                              </button>
                            )}
                           {(item.regId == iam.preregIdx || iam.memAuth == "ADMIN") && (
                             <button className="reply_del_btn" onClick={(e) => removeQna("T_QUESTION", item)} >
                               <img src={replydelImg} alt="Delete" />
                               <span>{item?.preIdx ? "Retrieve Pre Question" : "Delete"}</span>
                             </button>
                           )}
                          </div>
                      </div>
                      <div className={cx({ comment_write_box: true, active: item?.showCommentWriteBox == true })} >
                        <div className="comment_write_box_top">
                          <h4><span>Comment</span></h4>
                          <p> {questionReplyContent[`answerContent-${item.idx}`]?.length || 0}/300 </p>
                        </div>
                        <div className="comment_write_box_flex" style={{ alignItems: "flex-end" }} >
                          <TextareaAutosize
                            type="text"
                            maxLength="300"
                            style={{ overflowY: "hidden" }}
                            placeholder="Write a comment."
                            aria-label="Write a comment."
                            aria-describedby="button-addon2"
                            value={ questionReplyContent[ `answerContent-${item.idx}` ] || "" }
                            onChange={(e) => changeReplyContent(item, e.target.value) }
                          />
                          <div className="comment_btn_box">
                            <button className="comment_btn_cancel" onClick={(e) => setShowCommentWriteBox(item, false)} >Cancle</button>
                            <button className="comment_btn_save" id="button-addon2" onClick={(e) => questionReply(item)} >Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                     {item?.commentListShowYn === "Y" && (
                       <Fragment>
                         {item?.commentList?.filter((c) => iam.memAuth == "ADMIN" || c.regId == iam.preregIdx || c?.showYn === "Y")?.map((comment, j) => (
                           <Fragment key={j}>
                             {(comment.showYn === "Y" ||
                               iam.memAuth == "ADMIN" ||
                               comment.regId == iam.preregIdx) && (
                               <div
                                 className={cx({
                                   comment_list_box: true,
                                   on:
                                     iam.memAuth == "ADMIN" &&
                                     comment.showYn === "N",
                                   active: toggleMenu[comment.idx],
                                 })}
                               >
                                 <img src={replystImg} alt="댓글" />
                                 <div className="comment_list_box_inner">
                                   <pre>
                                     {/* <strong>Online Support : </strong> */}
                                     {comment.contents}
                                   </pre>

                                   <div className="question_list_box_date_reply">
                                     <span className="question_list_box_date_reply_span">
                                       {iam?.memAuth == 'ADMIN' ?  (comment?.name != null && comment?.name != "" ? comment?.name : "게스트") + ' - ' : ''}{moment(comment.regDate).format("MM.DD HH:mm:ss")}
                                     </span>
                                     <div className="question_list_box_desc_btn">
                                       {iam.memAuth == "ADMIN" && (
                                         <div className="qna_check_box">
                                           <input
                                             type="checkbox"
                                             id={`switch_q${i}_comment${j}`}
                                             name="switch"
                                             className="input__on-off"
                                             value={comment.showYn}
                                           />
                                           <label
                                             htmlFor={`switch_q${i}_comment${j}`}
                                             className="label__on-off label__on"
                                             style={{
                                               backgroundColor:
                                                 comment?.showYn === "Y"
                                                   ? "#7758fa"
                                                   : "#999999",
                                             }}
                                             onClick={(e) =>
                                               questionCommentSetShow(
                                                 comment
                                               )
                                             }
                                           >
                                             <span
                                               className="marble"
                                               style={{
                                                 right:
                                                   comment?.showYn === "Y"
                                                     ? "3px"
                                                     : "19px",
                                               }}
                                             ></span>
                                           </label>
                                         </div>
                                       )}
                                       <button
                                         className="reply_del_btn"
                                         onClick={(e) =>
                                           removeQna(
                                             "T_QUESTION_COMMENT",
                                             comment
                                           )
                                         }
                                       >
                                         {(comment.regId ==
                                           iam.preregIdx ||
                                           iam.memAuth == "ADMIN") && (
                                           <Fragment>
                                             <img
                                               src={replydelImg}
                                               alt="Delete"
                                             />
                                             <span>Delete</span>
                                           </Fragment>
                                         )}
                                       </button>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                             )}
                           </Fragment>
                         ))}
                       </Fragment>
                     )}
                   </div>
                 )}
               </Fragment>
           </Fragment>
          ))}
          
            
        {(isEmpty(questionList) || questionList?.length <= 0) && (
          <div
            className="nonedata_box"
            style={{ textAlign: "center", padding: "20px 0" }}
          >
            <img
              src="https://cdn.onoffevent.com/common/icon_nonedata.svg"
              alt=""
              style={{ width: "70px" }}
            />
            <p style={{ fontSize: "13px", color: "#333333" }}>
              No questions.
            </p>
          </div>
        )}
      </div>
          {remainListCount > 0 && (
            <div className="detail_morebtn">
              <a onClick={(e) => onSetNextPage(e)}>More</a>
            </div>
          )}
        </div>
        {/* </PerfectScrollbar> */}
      </div>
    </Fragment>
  );
};

const CMessage = styled.div`
  margin-bottom: 0.5rem;
  span {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`;
