import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";

import swal from "sweetalert";
import moment from "moment";
import styled from "styled-components";
import cx from "classnames";

import DownloadLink from "react-download-link";

import PerfectScrollbar from "perfect-scrollbar-react";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import fileDownload from "../../../../resources/console/images/icon/Type-B/fileDownload.svg"
import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";

export default (props) => {
  const { iam } = useContext(AppContext);
  const { consoleCfg } = props;

  const [messages, setMessages] = useState([]);
  const [isSendProc, setIsSendProc] = useState(false);
  const endRef = useRef(null);

  const [fileList, setFileList] = useState([]);

  // const scrollToBottom = () => {
  //     endRef?.current?.scrollIntoView({ behavior: "smooth" });
  // };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      onSend();
    }
  };

  const fileDownloadCntSave = (item) => {
    if (!item?.idx) {
      swal("파일이 존재하지 않습니다.");
      return;
    }

    const req = {
      path: "/v1/event/session/files/downloadcnt",
      data: {
        idx: item.idx,
        preregIdx: iam.preregIdx,
        passCode: iam?.passCode,
      },
    };

    _API.post(req).then((res) => {
      let { data } = res;
      console.log(`다운로드 성공 : ` + data);
    });
  };

  const loadList = (page) => {
    const req = {
      path: "/v1/event/session/files/list",
      data: {
        status: "Y",
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
      },
    };
    _API.post(req).then((res) => {
      const { data } = res.data;
      setFileList(data);
    });
  };

  const getDataFromURL = (item) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        fetch(item?.signedUrl)
          .then((response) => response)
          .then((data) => {
            //console.log(data.text());
            console.log(item);
            fileDownloadCntSave(item);
            resolve(data.blob());
          });
      });
    }, 2000);
  /*
    const onSend = (isDelete) => {
        
    };
*/
  const onReceivedMessage = (e) => {
    _U.parseMessage(e)
      .then((data) => {
        switch (data.code) {
          case "SOCKET-RECEIVED":
            return data.data ? JSON.parse(data.data) : null;
        }
      })
      .then((data) => {
        if (data?.mode === "CHAT") {
          console.log("files receive >> " + data);
          if (data.message === "upload_file_alert") loadList();
        }
      });
  };

  useEffect(() => {
    window.addEventListener("message", onReceivedMessage);
    return () => {
      window.removeEventListener("message", onReceivedMessage);
    };
  }, []);

  useEffect(() => {
    loadList();
  }, [consoleCfg]);
  return (
    <Fragment>
      {/* <div className="row">
                <div className="col-12"> */}
      <div className="detail_file_inner_box">
        {/* <div className="card__header">
                    <h6 className="title">첨부자료</h6>
                </div> */}
        {/* <div className="card__body file scroll-wrap" style={{ minHeight: "200px", maxHeight: "200px", position:"relative", overflowY:"auto"}} > */}
          {fileList.length > 0 ? (
          <ul>
            {fileList?.map((item, i) => (
              <li className="detail_free_content" key={i}>
                {/* <a href={`${item.signedUrl}`} download={`${item.name}`} onClick={(e) => { fileDownloadCntSave(item); }} target="_blank">{item.name}</a> */}
                <div style={{ marginBottom: `${item?.fileComment?"4px":"0px"}` }}>
                  <DownloadLink
                    label={
                      item?.customFileName
                        ? item?.customFileName + "." + item.name.split(".")[1]
                        : item.name
                    }
                    filename={
                      item?.customFileName
                        ? item?.customFileName + "." + item.name.split(".")[1]
                        : item.name
                    }
                    exportFile={() => Promise.resolve(getDataFromURL(item))}
                  />
                </div>
                {item?.fileComment && (
                  <p>{item?.fileComment}</p>
                )}
              </li>
            ))}
          </ul>
          ):(
            ""  
          )}
        {/* </div> */}
      </div>
      {/* </div>
            </div> */}
    </Fragment>
  );
};

const CMessage = styled.div`
  margin-bottom: 0.5rem;
  span {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`;
