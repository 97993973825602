import React, { Fragment, useState, useRef, useEffect, useContext } from "react";
import swal from "sweetalert";
import styled from "styled-components";
import cx from "classnames";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import EditImage from "../../../../resources/console/images/icon/icon-s-edit.svg";

export default (props) => {
  const { iam } = useContext(AppContext);
  const { consoleCfg, timeCalculation} = props;
  const [isSendProc, setIsSendProc] = useState(false);
  
  const [notice, setNotice] = useState([]);
  const [noticeContent, setNoticeContent] = useState(null);
  const [noticeChangeForm, setNoticeChangeForm] = useState(false);
  
  const noticeSave = (item) => {
    const req = {
      path: "/v1/event/session/notice/save",
      data: {
        idx: notice.idx,
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
        title: notice.title,
        content: item.content,
        sort: item.sort,
        status: item.status,
      },
    };

    _API.post(req).then((res) => {
      let { data } = res;
      if (data > 0) {
        loadNoticeList();
        onSend();
        setNoticeChangeForm(false);
        $(".alert-wrap").fadeIn();
      }
    });
  };

  const noticeAction = () => {
    if (noticeContent == null || noticeContent == "") {
      swal("공지사항을 입력해 주세요.");
      return;
    }

    const params = {};
    params.eventIdx = consoleCfg.eventIdx;
    params.sessionIdx = consoleCfg.sessionIdx;
    params.consoleIdx = consoleCfg.consoleIdx;
    params.title = `공지사항 [이벤트 : ${consoleCfg.eventIdx} / 세션 : ${consoleCfg.sessionIdx}]`;
    params.name = iam.name;
    params.content = noticeContent;
    params.sort = 1;
    params.status = "Y";

    noticeSave(params);
  };

  const loadNoticeList = () => {
    const req = {
      path: "/v1/client/session/notice/list",
      data: {
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
      },
    };

    _API.post(req).then((res) => {

      let { data } = res.data;

      if (data) {
        setNotice(data);
        setNoticeContent(data.content);
      }
    });
  };

  const onSend = (isDelete) => {
    if (!noticeContent && !isDelete) {
      return;
    }
    if (isSendProc) {
      return;
    }
    setIsSendProc(true);
    _U.postMessage("SOCKET-SEND", {
      action: "NOTICE",
      data: {
        mode: "NOTICE",
        siteIdx: consoleCfg?.siteIdx,
        eventIdx: consoleCfg?.eventIdx,
        sessionIdx: consoleCfg?.sessionIdx,
        consoleIdx: consoleCfg?.consoleIdx,
        name: iam?.name,
        memType: iam?.memType,
        companyName: iam?.companyName,
        deptName: iam?.deptName,
        memberIdx: iam?.idx,
        noticeContent: noticeContent,
      },
    });
    setIsSendProc(false);
  };

  const deleteNoice = (item) => {
    if (!window.confirm("삭제하시겠습니까?")) {
      return;
    }

    const req = {
      path: "/v1/event/session/notice/save",
      data: {
        idx: notice.idx,
        eventIdx: item.eventIdx,
        sessionIdx: consoleCfg?.sessionIdx,
        status: "N",
      },
    };

    _API.post(req).then((res) => {
      let { data } = res;
      if (data > 0) {
        loadNoticeList();
        onSend(true);
        // $('.alert-wrap').fadeIn();
      }
    });
  };

  const onReceivedMessage = (e) => {
    _U.parseMessage(e)
      .then((data) => {
        switch (data.code) {
          case "SOCKET-RECEIVED":
            return data.data ? JSON.parse(data.data) : null;
        }
      })
      .then((data) => {
        if (data?.mode === "NOTICE") {
          if (data.noticeContent) {
            $("#noticeAlert").text(data.noticeContent);
            $(".alert-wrap").fadeIn();
          }
          loadNoticeList();
        }
      });
  };

  const changeNoticeForm = (show) => {
    if (!show) {
      setNoticeContent(notice.content);
    }
    setNoticeChangeForm(show);
  };

  

  useEffect(() => {
    window.addEventListener("message", onReceivedMessage);
    return () => {
      window.removeEventListener("message", onReceivedMessage);
    };
  }, []);

  useEffect(() => {
    loadNoticeList();
  }, [consoleCfg]);

  return (
    <Fragment>
      <div className="detail_free_inner_box">
        <div className="title">
          <h3>
            공지
            {iam?.memAuth === "ADMIN" && !noticeChangeForm && (
              <img
                className="edit_image"
                src={EditImage}
                onClick={(e) => changeNoticeForm(true)}
              />
            )}
          </h3>
          <div className={cx({ ad_notice_button_box: true, on: noticeChangeForm })}>
            <button
              className="ad_notice_cancel"
              onClick={(e) => changeNoticeForm(false)}
            >
              취소
            </button>
            <button
              type="button"
              className="ad_notice_save"
              id="button-addon22"
              onClick={noticeAction}
            >
              저장
            </button>
          </div>
        </div>

        <ul
          className={cx({
            detail_notice_free_content: true,
            on: noticeChangeForm,
          })}
        >
          <li className="detail_free_content">
            {/* <div>{timeCalculation("TEST 01:01 01:01:01 01:02 01:01:02")}</div> */}
            <div>{notice != null && notice.content != null ? timeCalculation(notice.content) :`참여해 주셔서 감사드립니다.\n시청 중 불편사항은 아래 사무국 번호로 문의해 주시기 바랍니다.`}</div>
            {iam?.memAuth === "ADMIN" && (
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  deleteNoice(notice);
                }}
              >
                <i
                  className="demo-con icon-cancel"
                  style={{ fontSize: "small" }}
                ></i>
              </a>
            )}
          </li>
        </ul>

        {iam?.memAuth === "ADMIN" && (
          <div
            className={cx({
              admin_notice_container: true,
              on: noticeChangeForm,
            })}
          >
            <div className="admin_notice_container_inner">
              <textarea
                type="text"
                aria-label="내용을 입력해 주세요."
                aria-describedby="button-addon22"
                value={noticeContent}
                onChange={(e) => setNoticeContent(e.target.value)}
              ></textarea>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const CMessage = styled.div`
  margin-bottom: 0.5rem;
  span {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`;
