import React, { useEffect, useState } from 'react';

export default function SearchMark(props){
  
    const {text, keyword} = props;
    const [parts, setParts] = useState([]);

    useEffect(() => {
        setParts(text?.toString()?.split(new RegExp(`(${keyword?.toString()})`, 'gi')));
    },[keyword])
    
    return(
        <>
           {parts?.map((part, index) =>
                part?.toLowerCase() == keyword?.toString()?.toLowerCase() ? (
                    <mark key={index} style={{fontSize:'inherit', color:'inherit', fontWeight:'inherit', fontStyle:'inherit'}}>
                        {part}
                    </mark>
                ) : (
                    part
                ),
            )} 
        </>
    )
}