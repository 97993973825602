import React, { Fragment,  useState, useRef, useEffect, useContext, } from "react";
import { AppContext } from "../../../../components/AppProvider";
import {  _API, _CK, _U, _AC } from "../../../../modules/utils";
import SessionListDetail from "./SessionList.Detail";
import Modal from "../../../../components/UI/preReg/Modal";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import RegisterAccompanyAttendModal from "../../../../components/register/Register.AccompanyingAttendModal";

import SpeakerModal from "../../../../components/register/Speaker.ModalEn";
import EboothModal from "../../../../components/register/Ebooth.ModalEn";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import ImgPhoto from "../../../../resources/console/images/icon/default-user.svg";
import useStore from "../../../../modules/utils/store";
import Cookies from "universal-cookie";
import moment from "moment";
import { result } from "lodash";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default (props) => {
  const cm = new Cookies();
  const { auth, iam } = useContext(AppContext);
  const { saveAuth, saveIam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const { history, match, sessionInfo } = props;
  // 사이트 정보

  // 이벤트 정보
  const [eventInfo, setEventInfo] = useState({});

  // 세션 리스트
  const [sessionList, setSessionList] = useState([]);
  // 사전등록 세션 리스트
  const [preregSessionList, setPreregSessionList] = useState([]);

  const [managerInfo, setManagerInfo] = useState(null);
  const [sessionMgrInfoModalShow, setSessionMgrInfoModalShow] = useState(false);
  const [eboothModalShow, setEboothModalShow] = useState(false);
  const [accompanyAttendModal, setAccompanyAttendModal] = useState(false);
  const [accompanySessionIdx, setAccompanySessionIdx] = useState();
  const [loaded, setLoaded] = useState(false);

  // E Booth 정보
  const [eboothEventSetting, setEboothEventSetting] = useState(null);
  const [eboothSiteSetting, setEboothSiteSetting] = useState(null);
  const [eboothSectionItem, setEboothSectionItem] = useState(null);
  const [eboothItem, setEboothItem] = useState(null);
  const [eboothList, setEboothList] = useState(null);

  const [allBtnSelected, setAllBtnSelected] = useState(false);
  const [tags, setTags] = useState([]);

  const Modalstyle = {
    overflowX: "hidden",
    overflowY: "auto"
  };
  
  const eventIdx = match?.params.eventIdx;
  
  useEffect(() => {
    checkEventAccess(); 
  }, []);
  useEffect(() => {
    const cData = cm.get(iam.preregIdx);
    if(eboothEventSetting != null && cData == null && cData == undefined){
      setEboothModalShow(true)
    }
  }, [eboothEventSetting]);

  const initPage = () => {
    //애니메이션 설정 (gsap)
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.core.globals("ScrollTrigger", ScrollTrigger);

      ScrollTrigger.matchMedia({
        // desctop
        "(min-width: 768px)": function () {
          gsap.set(".sticky-wrap", {
            marginTop: -60,
            top: 0,
          });
          ScrollTrigger.create({
            trigger: ".button-register-wrap",
            start: "top top",
            // markers: true,
            onEnter: function () {
              gsap.to(".sticky-wrap", {
                marginTop: 0,
                duration: 0.35,
              });
            },
            onLeaveBack: function () {
              gsap.to(".sticky-wrap", {
                marginTop: -60,
                duration: 0.35,
              });
            },
          });
        },
        // mobile
        "(max-width: 767px)": function () {
          gsap.set(".sticky-wrap", {
            top: "120%",
            marginTop: -60,
          });
          ScrollTrigger.create({
            trigger: ".site",
            start: "top top",
            onEnter: function () {
              gsap.to(".sticky-wrap", {
                top: "100%",
                duration: 0.35,
              });
            },
            onLeaveBack: function () {
              gsap.to(".sticky-wrap", {
                top: "120%",
                duration: 0.35,
              });
            },
          });
        },
      });
    }
    const body = document.getElementById("body");
  };

  const loadEventData = () => {
    if (!siteInfo.idx || !eventIdx) {
      alert("This is not the right approach.");
      return;
    }
    const req = {
      path: "/v1/client/event/info",
      data: {
        siteIdx: siteInfo.idx,
        eventIdx,
      },
    };
    _API.post(req).then((res) => {
        const { data } = res;
        if (data) {
          // 이벤트 정보 셋팅
          setEventInfo(data);
        } else {
          swal("There is no event information.");
          history?.push(`/site/${siteInfo.idx}/event/list`);
        }
      }).catch((e) => {
        history?.push(`/site/${siteInfo.idx}/event/list`);
      });
  };

  const loadSessionList = () => {
    if (!eventIdx) {
      return;
    }

    const req = {
      path: "/v1/client/session/list",
      data: {
        eventIdx : eventIdx,
        status: "Y",
        preregIdx: iam.preregIdx,
      },
    };

    _API.post(req).then((res) => {
      const { data } = res.data;

      if (data != null) {
        const pSessionList = [];
        for (let i in data) {
          const session = data[i];
          if (session.preregSessionIdx) {
            pSessionList.push(session);
          }
        }
        setSessionList(data);
        setPreregSessionList(pSessionList);
        setLoaded(true);
      }
    });
  };

  const setEboothSetting = async () => {
    const req = {
      path: "/v1/client/ebooth/settigs",
      data: {
        siteIdx: siteInfo.idx,
        eventIdx: eventIdx || match?.params.eventIdx,
      },
    };
    await _API.post(req).then((res) => {
      const { eboothEventSetting, eboothSiteSetting } = res.data;
      if(eboothSiteSetting != null){
        setEboothSiteSetting(eboothSiteSetting)
      }
      if(eboothEventSetting != null){
        setEboothEventSetting(eboothEventSetting)
      }
    }).catch((e) => {
      console.log(e)
    });
  };

  const loadEboothInfo = async () => {
    const req = {
      path: "/v1/client/ebooth/info",
      data: {
        eventIdx: eventIdx || match?.params.eventIdx,
        preregIdx : iam.preregIdx
      },
    };
    await _API.post(req).then((res) => {
      const { eboothSectionItem, eboothItem, _EBOOTH_LIST } = res.data;
     
      if(eboothSectionItem != null){
        setEboothSectionItem(eboothSectionItem)
      }
      if(eboothItem != null){
        setEboothItem(eboothItem)
      }
      if(_EBOOTH_LIST != null){
        setEboothList(_EBOOTH_LIST)
      }
    }).catch((e) => {
      console.log(e)
    });
  };

  const checkEventAccess = async (callback) => {
    const req = {
      path: "/v1/client/event/access/check",
      data: {
        siteIdx: siteInfo.idx,
        eventIdx: eventIdx || match?.params.eventIdx,
      },
      auth: auth,
    };
    await _API.post(req).then((res) => {
      const { accessKey, data } = res.data;
      if (data) {
        _CK.setAuth(accessKey);
        saveIam(data);
        saveAuth(accessKey);

        initPage();
        loadEventData();
        loadSessionList();
        setEboothSetting();
        loadEboothInfo();
      }
    }).catch((e) => {
      if (iam?.targetType === "GUEST") {
        window.location.href = `/#/event/${iam.eventIdx}/register`;
        window.location.reload();
      } else if (iam?.targetType !== "GUEST") {
        history?.push(`/site/${siteInfo.idx}/event/list`);
      }
    });
  };

  useEffect(() => {
    if (_CK.getLogKey() == null) {
      alert("Please login again.");
      _CK.setConsoleCountView(0);
      window.location.href = `/`;
    }
  }, [eventInfo]);
 
  const getTagList = () => {
    const req = {
      path: "/v1/client/session/tag/list",
      data: {
        eventIdx: eventIdx || match?.params.eventIdx,
      },
    };
    _API.post(req).then((res) => {
      const { data } = res.data;
      setTags(data);
    });
  }
  
  const handleItemClick = (item) => {
    if(item == null){
      // 상태 업데이트를 반영하기 위해 brands 배열도 업데이트
      const updatedTagList = tags.map((tag) => {
        let newItem = {...tag};
        newItem.selected = allBtnSelected == true ? 0 : 1;
        return newItem;
      });
      // 상태 업데이트를 반영하기 위해 brands 배열도 업데이트
      const updatedSessionList = sessionList.map((session) => {
        let newItem = {...session};
        newItem.visible = allBtnSelected == true ? 0 : 1;
        return newItem;
      });
      setTags(updatedTagList);
      setSessionList(updatedSessionList);
      setAllBtnSelected((allBtnSelected == true) ? false : true)
    }else{
      // 상태 업데이트를 반영하기 위해 brands 배열도 업데이트
      const updatedTagList = tags.map((tag) => {
        let newItem = {...tag};
        if(newItem.tagName == item.tagName){
          newItem.selected = (newItem.selected == 1) ? 0 : 1;
        }
        return newItem;
      });

      let selectedTags = updatedTagList.filter((sTag) => sTag.selected == 1);
      // 상태 업데이트를 반영하기 위해 brands 배열도 업데이트
      const updatedSessionList = sessionList.map((session) => {
        let newItem = {...session};
        if(selectedTags.length == tags.length){
          newItem.visible = 1;
          return newItem;
        }else{
          // selectedTags 중 하나라도 session.tagName에 포함되어 있는지 확인
          let includes = selectedTags.some((sTag) => 
            newItem.tagName != null && newItem.tagName.includes(sTag.tagName)
          );
          newItem.visible = includes ? 1 : 0;
          return newItem;
        }
      });

      setTags(updatedTagList);
      setSessionList(updatedSessionList);
      if(updatedTagList.filter((item) => item.selected == 1).length == tags.length){
        setAllBtnSelected(true)
      }else{
        setAllBtnSelected(false)
      }
    }
  };

  useEffect(() => {
    loadSessionList();
    getTagList(); 
  }, []);
  
  if (!loaded) {
    return (
      <img
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "200px",
          height: "200px",
          display: "block",
          transform: "translate(-50%,-50%)",
        }}
        className="loading_img loading_img6"
        src="https://cdn.onoffevent.com/common/loading.gif"
      />
    );
  }
  return (
    <Fragment>
      <SessionListDetail
        eventInfo={eventInfo}
        setEventInfo={setEventInfo}
        sessionList={sessionList}
        setSessionList={setSessionList}
        setAccompanySessionIdx={setAccompanySessionIdx}
        setAccompanyAttendModal={setAccompanyAttendModal}
        setSessionMgrInfoModalShow={setSessionMgrInfoModalShow}
        setManagerInfo={setManagerInfo}
        setEboothModalShow={setEboothModalShow}
        eboothEventSetting={eboothEventSetting}
        handleItemClick={handleItemClick}
        allBtnSelected={allBtnSelected}
        tags={tags}
      />
      {accompanyAttendModal && (
        <Modal modalClass="d-block" modalstyle={Modalstyle}>
          <RegisterAccompanyAttendModal
            eventIdx={eventIdx}
            sessionIdx={accompanySessionIdx}
            setAccompanyAttendModal={setAccompanyAttendModal}
          />
        </Modal>
      )}
      {sessionMgrInfoModalShow && (
        <Modal modalClass="d-block" modalstyle={Modalstyle}>
          <SpeakerModal
            managerInfo={managerInfo}
            setSessionMgrInfoModalShow={setSessionMgrInfoModalShow}
            defaultProfileImg={ImgPhoto}
          />
        </Modal>
      )}
      {eboothModalShow && (
        <Modal modalClass="d-block" modalstyle={Modalstyle}>
          <EboothModal
            setEboothModalShow={setEboothModalShow}
            eboothSiteSetting={eboothSiteSetting}
            eboothEventSetting={eboothEventSetting}
            eboothSectionItem={eboothSectionItem}
            eboothItem={eboothItem}
            eboothList={eboothList}
            loadEboothInfo={loadEboothInfo}
          />
        </Modal>
      )}
    </Fragment>
  );
};
