// Zustand
import create from 'zustand';

const useStore = create(set => ({
    eventIdx:null,
    setEventIdx: value => set({eventIdx: value}),
    sessionIdx:null,
    setSessionIdx: value => set({sessionIdx: value}),
    consoleIdx:null,
    setConsoleIdx: value => set({consoleIdx: value}),
    eventInfo:null,
    setEventInfo: value => set({eventInfo: value}),
    siteTemplateInfo:null,
    setSiteTemplateInfo: value => set({siteTemplateInfo: value}),
    siteInfo:null,
    setSiteInfo: value => set({siteInfo: value}),
    campaignInfo:null,
    setCampaignInfo: value => set({campaignInfo: value}),
    
    // 지울예정
    savePreregName:null,
    setSavePreregName: value => set({savePreregName: value}),
    savePreregMobile:null,
    setSavePreregMobile: value => set({savePreregMobile: value}),
    savePreregEmail:null,
    setSavePreregEmail: value => set({savePreregEmail: value}),
    
    playerSetting:null,
    setPlayerSetting: value => set({playerSetting: value})
}));

export default useStore;

