import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import styled from "styled-components";
import cx from "classnames";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import qna_w_Img from "../../../../resources/console/images/icon/Type-B/qna_w.svg"
import useStore from "../../../../modules/utils/store";

export default (props) => {
  const { siteInfo } = useStore();
  const { consoleCfg } = props;
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [isSendProc, setIsSendProc] = useState(false);
  const [textareaClick, setTextareaClick] = useState(false);
  const endRef = useRef(null);
  const { iam } = useContext(AppContext);

  const onSend = () => {
    if (!message) {
      return;
    }
    if (isSendProc) {
      return;
    }
    const data =  {
      mode: "CHAT",
      siteIdx: siteInfo?.siteIdx,
      eventIdx: consoleCfg?.eventIdx,
      sessionIdx: consoleCfg?.sessionIdx,
      consoleIdx: consoleCfg?.consoleIdx,
      name: iam?.name,
      memType: iam?.memType,
      companyName: iam?.companyName,
      deptName: iam?.deptName,
      memberIdx: iam?.idx,
      preregIdx: iam?.preregIdx,
      regDate: moment().format("MM.DD HH:mm:ss"),
      message,
    }

    setMessages((o) => [...o.slice(-99), data]);

    setIsSendProc(true);
    _U.postMessage("SOCKET-SEND", {
      action: "CHAT",
      data: data,
    });

    setMessage("");
    setIsSendProc(false);
  };

  const foramtText = (text) => {
    let returnMsg = text;
    returnMsg = returnMsg.replace(/\</g, "&lt;").replace(/\>/g, "&gt;");
    if (returnMsg) {
      const qualityRegex = /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/gm;
      let matches = "";
      let times = [];

      while ((matches = qualityRegex.exec(text))) {
        times.push(matches[0]);
      }

      times.forEach((item, i) => {
        const timeList = times[i].split(":");
        const time = timeList[0] * 3600 + timeList[1] * 60 + timeList[2] * 1;
        const mkrTag = `<a onClick="document.getElementsByTagName('video')[0].currentTime = ${time};" style="color:blue; cursor: pointer;">${item}</a>`;
        returnMsg = returnMsg.replaceAll(times[i], mkrTag);
      });
    }

    return <div dangerouslySetInnerHTML={{ __html: returnMsg }} />;
  };

  const onReceivedMessage = (e) => {
    _U.parseMessage(e)
      .then((data) => {
        switch (data.code) {
          case "SOCKET-RECEIVED":
            return data.data ? JSON.parse(data.data) : null;
        }
      })
      .then((data) => {
        if (data?.mode === "CHAT") {
          if (data.message !== "upload_file_alert"){
            if(iam?.preregIdx !== data?.preregIdx){
              setMessages((o) => [...o.slice(-99), data]);
              $("#chattingDiv")[0].scrollTop = $("#chattingDiv")[0].scrollHeight;
            }
          }
        }
      });
  };

  useEffect(() => {
    window.addEventListener("message", onReceivedMessage);
    return () => {
      window.removeEventListener("message", onReceivedMessage);
    };
  }, []);

  $(function () {
    const $chat_content = $(".chat_content");
    $chat_content.scrollTop($chat_content[0].scrollHeight);
  });
  return (
    <Fragment>
      <div>
        <div className="chat_content" id="chattingDiv">
          {messages?.map((item, i) => (
            <CMessage key={i}>
              <p
                className={cx({
                  other_write: true,
                  "label-info": item.memType === "MEMBER",
                  "label-danger": item.memType === "OPERATOR",
                  my_write: item.preregIdx == iam.preregIdx,
                })}
              >
                <span>{foramtText(item.message)}</span>
                <small>{(item.preregIdx != iam.preregIdx) && `${item.name} - `}{item.regDate}</small>
              </p>
            </CMessage>
          ))}
          <div ref={endRef} />
        </div>
        <div className="chat_write" style={{borderTop:`${textareaClick||message?"1px solid #6a6a6a":"1px solid #eaeaea"}`}}>
          <div className="chat_write_content">
            {!message && (
              <img src={qna_w_Img} style={{display:"block",marginRight:"4px"}} alt="질문 글쓰기 아이콘"/>
            )}
            <TextareaAutosize
                type="text"
                maxLength="300"
                placeholder="메세지를 입력해 주세요"
                value={message || ""}
                onChange={(e) => setMessage(e.target.value)}
                style={{ overflow: "hidden" }}
                onFocus={() => setTextareaClick(true)}
                onBlur={() =>  setTextareaClick(false)}
            ></TextareaAutosize>
          </div>
          {(textareaClick || message)&& (
            <div className="chatContent_bottom">
              <div className="chat_write_box_inner">
                <span>{message.length}/300</span>
              </div>
              <div className="chatContent_button_box">
                <button type="button" id="button-addon2" className="cancel" onClick={() => {setMessage("")}}>취소</button> 
                <button type="button" id="button-addon2" onClick={onSend}>등록</button>
              </div>
            </div>
          )}
          
        </div>
      </div>
    </Fragment>
  );
};

const CMessage = styled.div`
  display: flex;
  padding:12px;
`;
