import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReactJWPlayer from 'react-jw-player';
const displayName = 'ReactJWPlayerContainer';
const propTypes = {
  playlist: PropTypes.array.isRequired,
  onMetaCallback: PropTypes.func.isRequired,
  onPlayCallback: PropTypes.func.isRequired,
  onPlayerDidMountCallback: PropTypes.func.isRequired,
  onPlayerUnMountCallback: PropTypes.func.isRequired,
  onTimeCallback: PropTypes.func.isRequired,
  onPauseCallback: PropTypes.func.isRequired,
  onResumeCallback: PropTypes.func.isRequired,
  onErrorCallback: PropTypes.func.isRequired,
  onSeekCallback: PropTypes.func.isRequired,
  onCompleteCallback: PropTypes.func.isRequired,
  onReadyCallback: PropTypes.func.isRequired,
}
var actionName = "";
let exitFullScreenAfterPlay = null;

class ReactJWPlayerContainer extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      siteInfo:props?.siteInfo,
      videoTitle: '',
    };

    this.onReady = this.onReady.bind(this);
    this.onVideoLoad = this.onVideoLoad.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onEnterFullScreen = this.onEnterFullScreen.bind(this);
    this.onExitFullScreen = this.onExitFullScreen.bind(this);
    this.onMeta = this.onMeta.bind(this);
    this.onTime =  this.onTime.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onResume = this.onResume.bind(this);
    this.onError = this.onError.bind(this);
    this.onSeek = this.onSeek.bind(this);
    this.onComplete = this.onComplete.bind(this);

    // each instance of <ReactJWPlayer> needs a unique id.
    // we randomly generate it here and assign to the container instance.
    // this.playerId = someFunctionToRandomlyGenerateId();
    this.playerId = 'VYgkaoeG';
  }

  componentWillUnmount(){
    console.log('jwplayer ', 'componentWillUnmount');
    this.props.onPlayerUnMountCallback();
  };

  componentDidMount(){
    console.log('jwplayer ', 'componentDidUnmount');
    this.props.onPlayerDidMountCallback();
  }

  onReady(event) {
    // interact with JW Player API here
    const player = window.jwplayer(this.playerId);
    var heightvideo = $('.video_element').height();
    var video_con_t = $(".video_con").offset().top;
    var jw_preview = $(".jw-preview").css('background-color');
    var detail_wrap = $('.detail_wrap').css('display');
    $('.video_pre_con').css("display", "none");
    
    $('.video_dummy').css('height', heightvideo + 'px');
    var video_dummyHeight = $('.video_dummy').height();
    $('.jw-overlays').css("opacity", "0");
    if (detail_wrap == "block") { 
      $(".detail_flex_box").addClass("detail_flex_box_1")
    } else {
      $(".detail_flex_box").removeClass("detail_flex_box_1")
    }
    
    // if (window.innerWidth <= 767) {
    //   $('.detail_flex_box').css('margin-top', heightvideo + 60 + 'px');
    // }
    // 창 크기가 변경될 때마다 호출되는 이벤트 리스너
    const handleResize = () => {
      // 현재 화면의 가로 너비와 세로 높이를 가져옵니다.
      var screenWidth = window.innerWidth;
      var screenHeight = window.innerHeight;

      // 비율을 계산합니다.
      var aspectRatio = screenWidth / screenHeight;

      var heightvideo = $('.video_element').height();
      $('.video_dummy').css('height', heightvideo + 'px');
      var video_dummyHeight = $('.video_dummy').height();
      var detail_wrap = $('.detail_wrap').css('display');
      if (detail_wrap == "block") { 
        $(".detail_flex_box").addClass("detail_flex_box_1")
      } else {
        $(".detail_flex_box").removeClass("detail_flex_box_1")
      }
      // if (window.innerWidth <= 767) {
      //   $('.detail_flex_box').css('margin-top', heightvideo + 60 + 'px');
      // } else {
      //   $('.detail_flex_box').css('margin-top','0px');
      // }
      // 여기에 콘솔 로그와 함께 원하는 동작을 추가할 수 있습니다.
      if ((window.innerWidth <= 1024 && window.innerWidth > 767)
        || (!aspectRatio >= 16/9 - 0.01 && !aspectRatio <= 16/9 + 0.01)
        ) {
        if (st + 60 >= video_scr) {
          $(".video_con").addClass("fixed");
          $(".video_dummy").addClass("fixed");
          $(".header").addClass("videofixed");
        } else {
          $(".video_con").removeClass("fixed");
          $(".video_dummy").removeClass("fixed");
          $(".header").removeClass("videofixed");
        }
      }
      if (window.innerWidth >= 1024 || detail_wrap == "flex") {
        $(".video_con").removeClass("fixed");
        $(".video_con").removeClass("m_fixed");
        $(".video_dummy").removeClass("fixed");
      }
    };

    const scrollEvent = () => { 
      
      var st = $(document).scrollTop();
      var detail_wrap = $('.detail_wrap').css('display');
      var attendBox = $('.attend_box');
      if (detail_wrap == "block") { 
        $(".detail_flex_box").addClass("detail_flex_box_1")
      } else {
        $(".detail_flex_box").removeClass("detail_flex_box_1")
      }
      // 현재 화면의 가로 너비와 세로 높이를 가져옵니다.
      var screenWidth = window.innerWidth;
      var screenHeight = window.innerHeight;

      // 비율을 계산합니다.
      var aspectRatio = screenWidth / screenHeight;
      if ($('.m_list_wrapper')) { 
        var mlist = $('.m_list_wrapper').offset().top;
      }
      var sub_bg_img_hd = $('.sub_bg_img_hd').height();
      if ($(".detail_flex_box_1")) { 
        var video_scr = $(".detail_flex_box_1").offset().top;
      }
      
      if (attendBox.length > 0) {
        if ((window.innerWidth <= 1024 && window.innerWidth > 767) ||
          ((!aspectRatio >= 16 / 9 - 0.01 && !aspectRatio <= 16 / 9 + 0.01) ||
          (!aspectRatio >= 16 / 10 - 0.01 && !aspectRatio <= 16 / 10 + 0.01) ||
          (aspectRatio >= 16/9 - 0.01 && aspectRatio <= 16/9 + 0.01))
          ) {
          if (st + 82 >= video_scr) {
            $(".video_con").addClass("fixed");
            $(".video_con").addClass("attedanceFixed");
            $(".video_dummy").addClass("fixed");
            $("#header").addClass("videofixed");
          } else {
            $(".video_con").removeClass("fixed");
            $(".video_con").removeClass("attedanceFixed");
            $(".video_dummy").removeClass("fixed");
            $("#header").removeClass("videofixed");
          }
        }
      } else { 
        if ((window.innerWidth <= 1024 && window.innerWidth > 767) ||
          ((!aspectRatio >= 16 / 9 - 0.01 && !aspectRatio <= 16 / 9 + 0.01) ||
          (!aspectRatio >= 16 / 10 - 0.01 && !aspectRatio <= 16 / 10 + 0.01) ||
          (aspectRatio >= 16/9 - 0.01 && aspectRatio <= 16/9 + 0.01))
          ) {
          if (st + 56 >= video_scr) {
            $(".video_con").addClass("fixed");
            $(".video_dummy").addClass("fixed");
            $("#header").addClass("videofixed");
          } else {
            $(".video_con").removeClass("fixed");
            $(".video_dummy").removeClass("fixed");
            $("#header").removeClass("videofixed");
          }
        }
      }
      

      

      if (window.innerWidth <= 767) {
        if (st + heightvideo >= mlist + 60 ) {
          $('.video_con').addClass("m_fixed");
        } else {
          $('.video_con').removeClass("m_fixed");
        }
      }
    }
    // 창 크기 변경 이벤트 리스너 추가
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', scrollEvent);

    this.props.onReadyCallback(player);
    
  }

  onAdPlay(event) {
    // track the ad play here
  }
  onVideoLoad(event) {
    this.setState({
      videoTitle: event.item.description // this only works with json feeds!
    });

    console.log("jwplayer : ", `onVideoLoad..: event : ${event.status}`);
  }
  onResume(event) {
    console.log("jwplayer : ", "onResume : "+ event.position);
    this.props.onResumeCallback(event);
    
  }
  onEnterFullScreen(event) {
    console.log("jwplayer : ", "onEnterFullScreen......................");
  }
  onExitFullScreen(event) {
    console.log("jwplayer : ", "onExitFullScreen......................");
    exitFullScreenAfterPlay = setInterval(() => {
      console.log('play interval')
      window.jwplayer(this.playerId).play(true);
    },1000);
  }
  onPause(event){
    // console.log("jwplayer : ", "onPause : " + event.position);
    this.props.onPauseCallback(event);
  }
  onTime(event){
    clearInterval(exitFullScreenAfterPlay); //전체화면해제 후 강제 플레이 interval clear
    this.props.onTimeCallback(event);
  }
  onSeek(event){
    // console.log("jwplayer : ", "onSeek : " + event.position);
    this.props.onSeekCallback(event);
  }

  onError(event){
    console.log("jwplayer : ", "onError......................");
    console.log("event : ", event);
    if (event.code == 232001 || event.code == 232403 || event.code == 232404 || event.code == 230001) { 
      $('.jw-error-msg .jw-icon, .jw-error-msg .jw-error-text ,.jw-error-msg .jw-info-container').remove();
      $('.jw-error-msg').append('<div style="display:flex;align-items:center;gap:12px;"><div class="loader"></div><p style="font-size:14px; line-height:18px">현재 연결이 지연되고 있습니다. <br/>문제가 지속될 경우, 페이지를 새로고침해 주시기 바랍니다.</p></div>');
    }
    this.props.onErrorCallback(event);
  }

  onPlay(event) {
    console.log("jwplayer : ", "onPlay : " + event);
    console.log("jwplayer : ", "this props : " + this.props);
    this.props.onPlayCallback(event);
  }
  onMeta(meta){
    // console.log("jwplayer : ", "onMeta");
    this.props.onMetaCallback(meta);
  }
  onComplete(event){
    this.props.onCompleteCallback(event)
  }

  render() {
    return (
      <div className='react-jw-player-container'>
        <h1>{ this.state.videoTitle }</h1>
        <ReactJWPlayer
          playlist={this.props.playlist}
          onReady={this.onReady}
          onVideoLoad={this.onVideoLoad}
          onPlay={this.onPlay}
          onSeek={this.onSeek}
          onMeta={this.onMeta}
          onResume={this.onResume}
          onPause={this.onPause}
          onExitFullScreen={this.onExitFullScreen}
          onEnterFullScreen={this.onEnterFullScreen}
          onTime={this.onTime}
          onComplete={this.onComplete}
          onError={this.onError}
          playerId={this.playerId} // bring in the randomly generated playerId
          playerScript='https://cdn.jwplayer.com/libraries/VYgkaoeG.js'
          isAutoPlay={false}
        />
      </div>
    );
  }
}
ReactJWPlayerContainer.propTypes = propTypes;
// ReactJWPlayerContainer.defaultProps = defaultProps;
ReactJWPlayerContainer.displayName = displayName;
export default ReactJWPlayerContainer;