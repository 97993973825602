import { createTheme, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1180,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: ['sans-serif'].join(','),
    lineHeight: "normal",
    h1: {
      fontSize: "26px",
      fontWeight: "bold",
      lineHeight: "1.15",
      textAlign: "left",
      color: "#333",
      marginBottom:"20px"
    },
    h3: {
      fontSize: "15px",
      color: "#333",
      fontWeight:"bold"
    },
    subtitle1: {
      fontSize: "22px",
      color: "#333",
      fontWeight: "bold",
    },
    subtitle2: {
      fontSize: "15px",
      color: "#4e4b7b",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "15px",
      color: "#333",
    },
    body2: {
      fontSize: "15px",
      color: "#666",
      marginTop: "4px"
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'basic' },
          style: {
            width: "100%",
            fontSize: "15px",
            backgroundColor: "#6a6af8",
            color: "#fff",
            borderRadius: "4px",
            padding: "13px 13px",
            lineHeight: "normal",
            '&:hover': {
              background: "#6e6ef9",
              transition: "all 0.3s"
            },
          },
        },
        {
          props: { variant: 'submit' },
          style: {
            width: "100%",
            fontSize: "20px",
            backgroundImage: "linear-gradient(to bottom, #6460b1, #5533b1)",
            color: "#fff",
            fontWeight: "bold",
            borderRadius: "6px",
            padding: "20px 13px",
            lineHeight: "normal",
            minWidth: "100px",
            '&:hover': {
              backgroundColor: "#5533c9",
              transition: "all 0.3s"
            },
          },
        },
        {
          props: { variant: 'complete' },
          style: {
            fontSize: "15px",
            backgroundColor: "#ececfc",
            color: "#7878a5",
            borderRadius: "4px",
            padding: "10px 13px",
            lineHeight: "normal",
            minWidth: "100px",
          },
        },
        {
          props: { variant: 'general' },
          style: {
            fontSize: "12px",
            backgroundColor: "#fff",
            border:"1px solid #d9d9d9",
            color: "#999999",
            padding: "3px 9px",
            lineHeight: "normal",
            borderRadius: "0px",
            cursor:"default !important"
          },
        },
        {
          props: { variant: 'confirm' },
          style: {
            minWidth: "100px",
            fontSize: "15px",
            backgroundColor: "#6a6af8",
            color: "#fff",
            borderRadius: "4px",
            height: "40px",
            lineHeight: "40px",
            lineHeight: "normal",
            '&:hover': {
              background: "#6e6ef9",
              transition: "all 0.3s"
            },
          },
        },
        {
          props: { variant: 'cancel' },
          style: {
            minWidth:"100px",
            fontSize: "15px",
            backgroundColor: "#ccc",
            color: "#fff",
            borderRadius: "4px",
            height: "40px",
            lineHeight:"40px",
            lineHeight: "normal",
            '&:hover': {
              background: "#ccc",
              transition: "all 0.3s"
            },
          },
        },
      ],
    },
    
  },
});
export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input:focus':{
      borderRadius:"0px"
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        borderBottom: '1px solid #ccc',
        borderRadius:0,
        fontSize: 15,
        padding: '9px 30px 9px 0px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
    },
    '& .MuiSvgIcon-root': {
      background: `url("https://cdn.onoffevent.com/common/arrow_down.svg")`,
      color: "transparent",
      backgroundPosition: "center center",
      backgroundRepeat:"no-repeat"
    },
}));
