import React, { createContext, useState } from "react";

const AppContext = createContext();
const AppProvider = ({ auth: authInProps, iam: iamInProps, cfg: cfgInProps, children }) => {
    const [auth, setAuth] = useState(authInProps);
    const [iam, setIam] = useState(iamInProps);
    const [cfg, setCfg] = useState(cfgInProps);
    const saveAuth = (auth) => {
        setAuth(auth);
    };
    const saveIam = (iam) => {
        if(iamInProps == null){
            setIam(iam);
        }else{
            setIam(iamInProps);
        } 
    };
    const saveCfg = (cfg) => {
        setCfg(cfg);
    };

    return <AppContext.Provider value={{ auth, saveAuth, iam, saveIam, cfg, saveCfg }}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
