import React, { useState, Fragment, useEffect, useContext, useRef} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { _U,_API } from "../../../../modules/utils";
import { AppContext } from "../../../../components/AppProvider";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
function Banner(props) {
  const { consoleCfg,activeTab } = props;
  const { iam } = useContext(AppContext);
  const swiperRef = useRef(null);
  const saveBannerLog = (banner) => {
    if(!banner?.targetLink || banner?.targetLink?.length <= 0){
      return false;
    }
    const req = {
      path: "/v1/event/session/console/banner/log/save",
      data: {
        eventIdx: iam.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
        preregIdx: iam.preregIdx,
        name: iam.name,
        passCode: iam.passCode,
        fileIdx: banner.idx,
        filename: banner.name,
        fileUrl: banner.targetLink,
      },
    };

    _API.post(req).then((res) => {
      window.open(`${banner?.targetLink}`)
    });
  };
  
  const setSwiperStart = () => { 
    if (consoleCfg.bannerFiles?.length > 1) { 
      if (activeTab==4 ) {
          swiperRef.current.swiper.autoplay.paused = false;
          swiperRef.current.swiper.autoplay.running = true;
        }
    } 
  }
  useEffect(() => {
    setSwiperStart();
  },[activeTab,swiperRef]);
  return (
    <Fragment>
        {consoleCfg.bannerFiles == null || consoleCfg?.bannerFiles?.length <= 1 ? (
          <Fragment>
            {consoleCfg.bannerFiles?.map((m, i) => (
              <div className="right_banner_box">
                <a onClick={(e) => saveBannerLog(m)} style={{cursor:m?.targetLink ? 'pointer':'default'}}>
                  <img src={m?.signedUrl} alt={"signedUrl"} />
                </a>
              </div>
            ))}
          </Fragment>
        ) : (
          <div className="slider_banner_box_con">
            <Swiper
              ref={swiperRef}
              spaceBetween={10}
              observer={true}
              observeParents={true}
              loop={true}
              autoplay={{
                delay: consoleCfg?.rollingInterval?.toString() || 2000,
                disableOnInteraction: false,
              }}
              allowTouchMove={false}
              touchRatio={0}
            >
              {consoleCfg.bannerFiles?.map((m, i) => (
                <SwiperSlide key={i}>
                  <Fragment>
                    {m && m.signedUrl && (
                      <div className="slider_banner_box">
                        <a onClick={(e) => saveBannerLog(m)} style={{cursor:m?.targetLink ? 'pointer':'default'}}>
                          <img src={m?.signedUrl} alt={"signedUrl"}/>
                        </a>
                      </div>
                    )}
                  </Fragment>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
        {/* <div className="slider_banner_box_con_empty"></div>
        <div className="slider_banner_box_con_empty"></div> */}
      </Fragment>
  )
}

export default Banner;