import uuid from "uuid/v1";
import path from "path";
import moment from "moment";
import axios from "axios";
import { xml2json } from "xml-js";
import Cookies from "universal-cookie";

import { _CK, _API } from ".";

// const cm = new Cookies();

const _BASE_DOMAIN = "https://gskmedicall.adobeconnect.com";
const _PATH = "/v1/client/streaming/ac";
const ac = {};

// Action Url 가져오기
ac.getUrl = ({ domain, action }) => {
    return `${domain}/api/xml?action=${action}`;
};

// Adobe Connect 연결
ac.connect = ({ name: login, passCode: password, email, attendIdx }, {urlUrl : domain, urlKeyUrl : keyUrl}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const params = { login, password, domain: domain || _BASE_DOMAIN, email, action: "login", attendIdx };
            const meetingId = keyUrl.split(domain)[1].replace("/","");
            params.url = ac.getUrl(params);
            params.meetingId = meetingId;
            const result = await _API.post({ path: _PATH, data: params }).then((res) => res.data);
            const { code } = result.data.results.status._attributes;
            const { breezesession } = result;

            resolve && resolve({code, data:result, breezesession});
        } catch (e) {
            reject(e);
        }
    });
};

export default ac;
