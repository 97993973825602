import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import useStore from "../../../../modules/utils/store";
import { _CFG } from "../../../../modules/config";
import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK , _R, _U } from "../../../../modules/utils";

export const Header = (props) => {
  const { iam, saveAuth, saveIam, auth } = useContext(AppContext);
  const [scrollY, setScrollY] = useState(0);
  const documentRef = useRef(document);
  const [headerMenu, setHeaderMenu] = useState([]);
  const { siteInfo } = useStore();
  
  const listPage = window.location.href.includes('/list');

  const handleScroll = () => {
    const { pageYOffset } = window;
    setScrollY(pageYOffset);
  };
  const onLogout = (e) => {
    if (e) e.preventDefault();
    const confirmChk = window.confirm('로그아웃 하시겠습니까?');
    if(confirmChk){
      saveIam(null);
      saveAuth("");
      _CK.clearLogKey();
      _CK.clearAuth();
      window.location.href = "/"
      window.reload();
    }
  };
  const hubSite = _CFG.hubUrl();

  const goEventList = (e) => {
    if(e) e.preventDefault();

    if((siteInfo?.theme == 'GSK' && iam?.targetType == 'MEDIGATE') || iam?.targetType == 'ICOD'){
      return;
    }
    window.location.href = `/#/site/${siteInfo?.idx}/event/list`;
  }

  useEffect(() => {
    documentRef.current.addEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    console.log(iam)
    if (iam?.memberRoles) {
      let tmpMenu = [{ name: "대시보드", url: hubSite+"/dashboard?au="+_CK.getAuth(), sort: 1 }];

      // 통합관리자 권한을 소유한 경우
      if (_R.isSystemAdmin(iam?.memberRoles)) {
        tmpMenu.push(
          { name: "리소스", url: hubSite+"/resource/dashboard?au="+_CK.getAuth(), sort: 5 },
          // { name: "보고서", url:'/', sort: 5 },
          { name: "사용자", url: hubSite+"/member/dashboard?au="+_CK.getAuth(), sort: 7 },
          // { name: "상담신청", url: "/call", sort: 9 }
        );
      }
      setHeaderMenu((o) => {
        
        // 조직관리자 권한을 소유하거나 그 이상의 권한을 소유한 경우
        if(_R.hasOrgRoles(iam?.memberRoles)){
          tmpMenu.push({ name: "조직", url: hubSite+"/company/list?au="+_CK.getAuth(), sort: 8 });
        }

        // 사이트 관련 권한을 소유하거나 그 이상의 권한을 소유한 경우 또는 이벤트 운영담당자,등록담당자,이벤트뷰어일 경우
        if(
          _R.hasSiteRoles(iam?.memberRoles) || 
          _R.isEventOper(iam?.memberRoles) ||
          _R.isEventMedia(iam?.memberRoles) ||
          _R.isEventViewer(iam?.memberRoles)
        ){
          tmpMenu.push({ name: "사이트", url: hubSite+"/site/list?au="+_CK.getAuth(), sort: 10 });
        }

        // 캠페인 관련 권한을 소유하거나 그 이상의 권한을 소유한 경우
        if(_R.hasCampaignRoles(iam?.memberRoles)){
          tmpMenu.push({ name: "캠페인", url: hubSite+"/campaign/list?au="+_CK.getAuth(), sort: 2 });
        }

        // 이벤트 관련 권한을 소유하거나 그 이상의 권한을 소유한 경우
        if(_R.hasEventRoles(iam?.memberRoles)){
          tmpMenu.push({ name: "이벤트", url: hubSite+"/event?au="+_CK.getAuth(), sort: 3 });
        }

        // 이벤트 관련 권한을 소유하거나 그 이상의 권한을 소유한 경우
        if(_R.hasEventRoles(iam?.memberRoles)){
          tmpMenu.push({ name: "온디맨드", url: hubSite+"/ondemand/list?au="+_CK.getAuth(), sort: 4 });
        }
        
        tmpMenu = tmpMenu?.sort((a, b) => a.sort - b.sort);
        console.log(tmpMenu)
        return tmpMenu;
      });
    }
  }, [iam]);

  return (
    <header id="header">
      <div id="header-freeze" className="header-freeze"></div>
      <div className={cx({ header: true, fixed: scrollY > 0 })}>
        {siteInfo?.logoUseYn === "Y" && listPage === false && (
          <a onClick={(e) => ['GUEST'].includes(iam?.targetType) ? null : goEventList(e)} style={{ cursor: ['MEDIGATE', 'ICOD','GUEST'].includes(iam?.targetType) ? 'default' : 'pointer' }}>
            { siteInfo?.logoImgUrl == null || siteInfo?.logoImgUrl == "" ?
              "" :
              <img src={siteInfo.logoImgUrl} alt={siteInfo?.name} />
            }
          </a>
        )}
        {siteInfo?.logoUseYn === "Y" && listPage === true && (
          <a style={{cursor: ['MEDIGATE','ICOD','GUEST'].includes(iam?.targetType) ? 'default' : 'pointer'}}>
            { siteInfo?.logoImgUrl == null || siteInfo?.logoImgUrl == "" ?
              "" :
              <img src={siteInfo.logoImgUrl} alt={siteInfo?.name} />
            }
          </a>
        )}
        <div className="menu_container">
          <ul className="menu_container_ul">
            {!['MEDIGATE','ICOD'].includes(iam?.targetType) && (
              <li><Link to={`/site/${siteInfo?.idx}/event/list`}>HOME</Link></li>
            )}
            <li><a onClick={onLogout}>LOGOUT</a></li>
          </ul>
          <button className="menubtn">
            <div className="line_box">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
          </button>
          <ul className="menu_box">
            <li className="menu_box_info">
              <p>
                {_U.isSysAdmin(iam.memType) ||
                _U.isSiteAdmin(iam.memType) ||
                _U.isEventAdmin(iam.memType)
                  ? "관리자"
                  : _U.isHost(iam.memType)
                  ? "호스트"
                  : iam?.targetType == "MEMBER"
                  ? "회원"
                  : iam?.targetType == "GUEST"
                  ? "게스트"
                  : iam?.targetType == 'MEDIGATE'
                  ? "API게스트"
                  : iam?.targetType == 'ICOD'
                  ? "API게스트"
                  : "패스코드"}
              </p>
              <span>{iam?.name != null && iam?.name != "" ? iam?.name : "게스트"}님</span>
            </li>
            {!['MEDIGATE','ICOD'].includes(iam?.targetType) && (<>
            <li className="menu_box_list">
              <ul>
                {iam?.memType != "USER" && (
                  <li>
                    <Link to="/invitation" className="link">
                      초대장
                    </Link>
                  </li>
                )}
                  <li>
                    <Link to="/invitation" className="link">
                      마이페이지
                    </Link>
                  </li>
              </ul>
            </li>
            </>)}
            {!['MEDIGATE','ICOD'].includes(iam?.targetType) && (<>
              <li className="menu_box_list">
                <ul>
                  {siteInfo?.subPageList.map((item, i) => {
                    return (
                      <>
                        {item.isNavShow == 1 && (
                          item.pageType == "LINK" ? (
                            <li className="item item-sub" key={i}>
                              <a href={`https://${item.link}`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} > {item.name} </a>
                            </li>
                          ) : (
                            <li className="item item-sub" key={i}>
                              {item.pageId == "event" ? (<a href={`/#/site/${siteInfo?.idx}/event/list`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} > {item.name} </a> ) 
                              : (<a href={`/#/site/${siteInfo.idx}/subpage/${item.pageId}`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} > {item.name} </a> )}
                            </li>
                          )
                        )}
                      </>
                    )})}
                </ul>
              </li>
            </>)}

            {headerMenu && (
              <li className="menu_box_list">
                <ul className="ul-menu-sub">
                  {headerMenu?.map((m) => {
                    return (
                      <li key={m?.sort} className="item item-sub">
                        <a href={m?.url} className="link">
                          {m?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            )}
            <li className="menu_box_loginfo">
              <div className="link" onClick={onLogout}>
                <span className="link">로그아웃</span>
                <img src="https://cdn.onoffevent.com/login/icon_logout.svg" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};
