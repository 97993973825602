import React, { useState, forwardRef,useEffect } from "react";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MuiCheckbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import MuiRadio from "@mui/material/Radio";
import NativeSelect from '@mui/material/NativeSelect';
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import uuid from "uuid/v1";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button"
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../../../Campaign.theme'
import requiredImg from "../../../../../resources/images/campaign/required.png"
import searchImg from "../../../../../resources/images/campaign/search.svg"
const Input = forwardRef((props, ref) => {

  return <TextField
    {...props}
    type={props.type || "text"}
    inputRef={ref}
  />;
});

export default (props) => {
  const { title, required, fullSize, watch, banner, space, mb0, colorName, ml } = props;
  const {
    name,
    type,
    value,
    onChange,
    placeholder,
    hint,
    onKeyPress,
    onBlur,
    time,
    className,
    defaultValue,
    colorTitle,
    previewText,
    autoFocus = false
  } = props;
  const { button, onPlus, onMinus, tail, search,error } = props;
  const { selectValue, selectItems, onSelectChange, selectAfter, fileName } = props;
  const { isDisabled, isReadOnly, sendTypeInfo, imageRef, readOnly } = props;
  const { accept, disabled, errorMsg, checkColor } = props;
  const { checkItems, checkName, checkValue, checkLabel, onCheckChange ,width, mobile} = props;

  let { id } = props;

  if (!id) {
    id = uuid();
  }
  const noAuth = (e) => {
    e.preventDefault();
    alert("수정권한이 없습니다.");
    return;
  };
  const onClick = (e) => {
    e.preventDefault();
    props.onClick && props.onClick(e);
  };

  const onInputClick = (e) => {
    // e.preventDefault();
    props.onInputClick && props.onInputClick(e);
  };
  const onPlusClick = (e) => {
    e.preventDefault();
    onPlus && onPlus(e);
  };
  const onMinusClick = (e) => {
    e.preventDefault();
    onMinus && onMinus(e);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Stack direction={`${!mobile?'row':'column'}`} alignItems="flex-start" sx={{rowGap:"8px",columnGap:"16px"}}>
          {(title || space) && (
            <Typography component="h4" variant="h4" sx={{ minWidth: `${!mobile?"160px":"100%"}`,width: `${!mobile?"160px":"100%"}`, padding:`${!mobile?"9px 0":"0"}`}}>
              {title} {required && (!onMinus) ? <img src={requiredImg} style={{marginTop:"4px", marginLeft:"1px",verticalAlign: "top"}} /> : ""}
            </Typography>
          )}
          <Stack direction="column" sx={{gap:"8px",width:"100%"}}>
            {Boolean(selectItems) && !selectAfter && (
              <FormControl>
                <NativeSelect
                  id="demo-customized-select-native"
                  value={selectValue}
                  onChange={onSelectChange}
                  disabled={isDisabled}
                  sx={{ padding: "4px 0 ", marginRight: "10px" }}
                >
                  {selectItems.map((item, i) => (
                    <option key={`select${i}`} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            )}
            {type == 'Text' &&
              <Stack direction="row" alignItems="center" sx={{ width:`${width?width:"300px"}`  }}>
                {value}
              </Stack>
            }
            {type !== "file" && type !== "color" && type !== "Text" && (
              <Stack direction="row" alignItems="center" sx={{gap:"4px"}}>
                <Input
                  autoFocus={autoFocus}
                  autoComplete={'off'}
                  onChange={onChange}
                  type={type}
                  id={id}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  ref={imageRef}
                  error={error}
                  placeholder={placeholder}
                  onClick={isDisabled ? noAuth : onClick}
                  size="small"
                  sx={{
                    width:`${width?width:"300px"}`,
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0, borderColor: `${(checkColor || errorMsg) ? "#f00" : "#d7d7d7 !important"}` },
                    "& fieldset:hover": { borderColor: `${(checkColor || errorMsg) ? "#f00" : "#d7d7d7 !important"}` },
                    "& .MuiInputBase-input": {
                      padding: "9px 12px",
                      backgroundColor: "#fff",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "4px",
                      color: `${(checkColor || errorMsg) ? "#f00" : "#464f60"}`,
                      backgroundImage: `${search? `url('${searchImg}')` : 'unset'}`,
                      backgroundPosition: `${search ? "calc(100% - 6px) center" : 'unset'}`
                    },
                    "& .MuiOutlinedInput-root.Mui-disabled": { backgroundColor: "#fff !important", cursor: "pointer" },
                    "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": { textFillColor: "#5959EF !important", },
                    "& .MuiInputBase-input.MuiOutlinedInput-input::placeholder": { textFillColor: "#b7b7b7 !important", color: "#cccccc !important",fontSize:"15px" },
                    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": { border: "1px solid #e4e4e4 !important" },
                  }}
                  disabled={disabled}
                  readOnly={isReadOnly}
                />
              </Stack>
            )}
            {type === "file" && (
              <Box sx={{ px: 2, py: 1, border: "1px solid grey", width: "55%" }}>
                <Button variant="outlined" component="label" sx={{ mr: 1 }}>
                  파일선택
                  <input
                    hidden
                    name={name}
                    multiple
                    type="file"
                    style={{ width: "70%" }}
                    onClick={(e) => onInputClick(e)}
                    onChange={onChange}
                    ref={imageRef ? imageRef : banner}
                    accept={accept}
                    disabled={isDisabled}
                  />
                </Button>
                {fileName ? `[ ${fileName} ]` : "[선택된 파일 없음]"}
              </Box>
            )}

            {Boolean(button) ||
              Boolean(onPlus) ||
              Boolean(onMinus) ||
              Boolean(tail) ||
              Boolean(checkItems) ? (
              <Stack direction="row" alignItems="center" sx={{ marginLeft: "4px" }}>
                {Boolean(button) && (
                  <Button
                    name={name}
                    variant="outlined"
                    onClick={isDisabled ? noAuth : onClick}
                    sx={{
                      marginRight: "4px", background: "#fff", color: "#464f60", fontSize: "13px", border: " 1px solid #D7D7D7", minWidth: "55px", lineHeight: "32px", boxSizing: "border-box", height: "32px", borderRadius: "4px", padding: "0 6px",
                      "&:hover": { background: "#fff", border: "1px solid #d7d7d7" }
                    }}
                  >
                    {button}
                  </Button>
                )}
                {Boolean(onPlus) && (
                  <img
                    src={addImg}
                    onClick={isDisabled ? noAuth : onPlusClick}
                    style={{
                      padding: "11px",
                      width: "32px",
                      height: "32px",
                      display: "inline-block",
                      boxSizing: "border-box",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginRight: "4px",
                      border: "1px solid #D7D7D7"
                    }}
                  />
                )}

                {Boolean(onMinus) && (
                  <img
                    src={minusImg}
                    onClick={isDisabled ? noAuth : onMinusClick}
                    style={{
                      padding: "11px",
                      width: "32px",
                      height: "32px",
                      display: "inline-block",
                      boxSizing: "border-box",
                      borderRadius: "4px",
                      cursor: "pointer",
                      border: "1px solid #D7D7D7"
                    }}
                  />
                )}
                {Boolean(checkItems) && (
            /*     <Checkbox
                  title=" "
                  items={checkItems}
                  name="checkName"
                  values={checkValue}
                  onChange={(e) => onCheckChange(e)}
                /> */
                  <label>
                    <input
                      type="checkbox"
                      name={checkName}
                      value={checkValue}
                      checked={checkValue == 'Y' ? true : false}
                      onChange={(e) => onCheckChange(e)}
                    >
                    </input>
                    {checkLabel}
                  </label> 
                )}
              </Stack>
            ) : null}
            {Boolean(selectItems) && selectAfter && (
              <FormControl>
                <NativeSelect
                  id="demo-customized-select-native"
                  value={selectValue}
                  onChange={onSelectChange}
                  disabled={isDisabled}
                  sx={{ padding: "4px 0 ", marginRight: "10px" }}
                >
                  {selectItems.map((item, i) => (
                    <option key={`selectAfter${i}`} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            )}
            {!errorMsg && (
              Boolean(hint) && (<Hint>{hint}</Hint>)
            )}
            {errorMsg?.length > 0 && <Hint dupCheck={errorMsg}>{errorMsg}</Hint>}
          </Stack>
        </Stack>
        
        {Boolean(time) && <small className="text-black-50 ml-2 timer">{`남은시간 ${time}`}</small>}
      </Box>
    </ThemeProvider>
  );
};

export const Checkbox = (props) => {
  const { size, msg,sx ,ml} = props;
  const { items, onChange,hint,children } = props;
  const { isDisabled, height, title, space, required,mobile,styles} = props;
  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 2,
    width: 12,
    height: 12,
    backgroundColor: '#ffffff',
    border: "1px solid #D7D7D7",
    boxSizing: "content-box",
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: '#ddd',
      border:"1px solid #ddd"
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    width: 14,
    height: 14,
    backgroundColor: '#5959EF',
    border: 0,
    '&:before': {
      display: 'block',
      width: 12,
      height: 12,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#5959EF',
    },
  });
  // Inspired by blueprintjs
  function BpCheckbox(props) {
    return (
      <MuiCheckbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        disableRipple
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
      />
    );
  }
  
  return (
      <Stack direction={`${!mobile?'row':'column'}`} alignItems="flex-start" sx={{rowGap:"8px",columnGap:"16px"}}>
        {(title || space) && (
          <Typography component="h4" variant="h4" sx={{ minWidth: `${!mobile?"160px":"100%"}`,width: `${!mobile?"160px":"100%"}`, padding:`${!mobile?"9px 0":"0"}`}}>
            {title} {required ? <img src={requiredImg} style={{marginTop:"4px", marginLeft:"1px",verticalAlign: "top"}} /> : ""}
          </Typography>
        )}
        <Stack sx={{ gap: "4px", ...( styles ? styles: '' ) }}>
          <Stack direction="row" sx={{columnGap:"24px", flexWrap:"wrap"}}>
            {items.map((item, i) => (
              <FormControlLabel
                key={`checkbox${i}`}
                label={true ? item.label : item.label`<span style={{color:"red"}}>1111</span>`}
                control={
                  <BpCheckbox
                    name={item?.name}
                    value={item?.value}
                    onChange={onChange}
                    checked={item?.checked}
                    // disabled={isDisabled}
                    disabled={item?.disabled == true ? true :
                      item?.disabled == false ? false : isDisabled}
                    sx={sx}
                    color="primary"
                    size={size}
                  />
                }
                sx={{display:"flex",alignItems:`${height?"center":"flex-start"}`, marginLeft: `${ml ? ml : 0}`, height: `${height?height:"auto"}`, marginRight: "0", "& .MuiCheckbox-root": { padding: "14px 0 12px", marginRight: "6px" }, "& .MuiTypography-root": { fontSize: "15px", color: "#646464", lineHeight: "22px", padding: "9px 0px", whiteSpace:"break-spaces", wordBreak:"keep-all", wordBreak:"break-word" },"& .MuiFormControlLabel-label.Mui-disabled":{color:"#ccc"} }}
              />
            ))}
          </Stack>
          {Boolean(hint) && (<Hint>{hint}</Hint>)}
          {children}
        </Stack>
      </Stack>

  );
};

export const TextArea = (props) => {
  const { title, required, hint, hintColor, space, mb0, notUseHeight, autoFocus,children } = props;
  const { name, value, onChange, onBlur, placeholder, limit,mobile,width } = props;
  const [ newValue, setNewValue] = useState(value);
  let { id, onFix, style } = props;
  const { isDisabled, isReadOnly } = props;
  const heightStyle = !notUseHeight && { height: 100 };
  if (!id) {
    id = uuid();
  }

  const handleChange = (e) => {
      if(limit && e.target.value?.length > limit){
          return;
      }
      setNewValue(e.target.value);
      props?.onChange && props?.onChange(e);
  }

  return (
    <Stack direction={`${!mobile?'row':'column'}`} alignItems="flex-start" sx={{rowGap:"8px",columnGap:"16px"}}>
      {(title || space) && (
        <Typography component="h4" variant="h4" sx={{ minWidth: `${!mobile?"160px":"100%"}`,width: `${!mobile?"160px":"100%"}`, padding:`${!mobile?"9px 0":"0"}`}}>
          {title} {required ? <img src={requiredImg} style={{marginTop:"4px", marginLeft:"1px",verticalAlign: "top"}} /> : ""}
        </Typography>
      )}

      <Box sx={{ width:`${width?width:"100%"}` }}>
        <Stack sx={{gap:"8px"}}>
          <TextareaAutosize
            id={id}
            aria-label="empty textarea"
            value={newValue}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={placeholder}
            autoFocus={autoFocus}
            style={{
              ...heightStyle,
              width: '100%',
              height: 100,
              background: "#fff",
              border: "1px solid #E4E4E4",
              borderRadius: "4px",
              padding: "8px 12px",
              boxSizing: "border-box",
              overflow: "auto",
              color: "#464f60",
              fontWeight: "500"
            }}
          />
          <Stack direction="row" alignItems="flex-start" justifyContent={`${hint?"space-between":"flex-end"}`} sx={{gap:"12px"}}>
            {Boolean(hint) && (<Hint>{hint}</Hint>)}
            {limit && (
              <Hint>({newValue?.length || 0}/{limit})</Hint>
            )}
          </Stack>
        </Stack>
        {children}
      </Box>
    </Stack>
  );
};

export const Radio = (props) => {
  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 15,
    height: 15,
    backgroundColor: '#fff',
    border: "1px solid #D7D7D7",
    'input:hover ~ &': {
      borderColor: "#5959EF",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    width: 5,
    height: 5,
    backgroundColor: '#fff',
    border: "5px solid #5959EF",
    boxSizing: "content-box",
    'input:hover ~ &': {
      backgroundColor: '#fff',
    },
  });

  // Inspired by blueprintjs
  function BpRadio(props) {
    return (
      <MuiRadio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  const { title, required, items, onChange, hint, children, row, sx, space, mb0,mobile } =
    props;
  let { name, value } = props;
  const { isDisabled } = props;

  if (!name) {
    name = uuid();
  }

  return (
    <Stack direction={`${!mobile?'row':'column'}`} alignItems="flex-start" sx={{rowGap:"8px",columnGap:"16px"}}>
      {(title || space) && (
        <Typography component="h4" variant="h4" sx={{ minWidth: `${!mobile?"160px":"100%"}`,width: `${!mobile?"160px":"100%"}`, padding:`${!mobile?"9px 0":"0"}`}}>
          {title} {required ? <img src={requiredImg} style={{marginTop:"4px", marginLeft:"1px",verticalAlign: "top"}} /> : ""}
        </Typography>
      )}
      <FormControl sx={{ display: "flex", flexDirection: "column", gap: "4px"}}>
        <RadioGroup
          row={row}
          onChange={onChange}
          defaultValue={value}
          sx={{ flexDirection:"row",justifyContent:"flex-start",alignItems: "center", height:"40px",gap:"24px" }}
        >
          {items.map((item, i) => (
            <Box key={`radio${i}`}>
              <FormControlLabel
                disabled={isDisabled}
                name={name}
                value={item?.value}
                sx={{ "&:hover": { cursor: "pointer" } ,display:"flex",alignItems:"center",gap:"6px",marginRight:"0px", marginLeft: "0px", "& .MuiRadio-root": { padding: 0,marginTop:"2px" }, "& .MuiTypography-root": { fontSize: "15px", color: "#464F60", lineHeight: "18px" } }}
                control={<BpRadio disabled={item.disabled || false} />}
                label={item.label}
              />
            </Box>
          ))}
        </RadioGroup>
        {Boolean(hint) && (<Hint>{hint}</Hint>)}
        {children}
      </FormControl>
    </Stack>
  );
};
export const Hint = (props) => {

  const { dupCheck } = props;

  return (
    <Typography component="small" variant="summary" sx={dupCheck ? {color: "red"} : {}}>{props.children}</Typography>
  );
};

export const Tdata = (props) => {
  const { mb0 } = props;
  return (
    <Stack direction="row" alignItems="center" mb={mb0 ? 0 : 4}>
      <Typography component="h4" sx={{ minWidth: "160px",width:"160px", marginRight: "16px" }}></Typography>
      <Typography component="small" variant="summary" sx={{ marginLeft: "auto", color: "red" }}>{props.children}</Typography>
    </Stack>
  );
};