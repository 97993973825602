import React, { useEffect, useState , useRef} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '../../../resources/images/fms/icon_close.svg';
import Typography from '@mui/material/Typography';
import {Stack,Grid,Box}  from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import { BootstrapInput }  from '../../../resources/preRegister/Preregister.theme'
import { _API } from '../../../modules/utils';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    width: "100%",
    maxWidth: "830px"
  },
  '.MuiDialogTitle-root': {
    backgroundColor: "#f8f8fe",
    color: "#111",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "normal",
    padding:"20px"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2.5),
    borderTop: "0px",
    borderBottom:"1px solid #eee",
  },
  '& .MuiDialogContent-root h3': {
      fontSize: "22px",
      fontWeight:"bold"
  },
  '& .MuiDialogContent-root h4': {
      fontWeight:"bold"
  },
  '& .MuiDialogContent-root b': {
      fontSize: "30px",
      fontWeight:"bold"
  },
  '& .MuiDialogContent-root .MuiBox-root': {
    borderRadius: "6px",
    padding:"20px"
  },
  '& .MuiDialogContent-root .MuiBox-root.hospital_list':{
    position: "relative",
    borderRadius: "6px",
    padding:"0px 20px",
    '&:hover': {
      cursor: "pointer"
    },
  },
  '& .MuiDialogContent-root .MuiBox-root.hospital_list .MuiBox-root': {
    padding:"20px 0px",
    borderBottom: "1px solid #eee",
    borderRadius: "0",
    '&:hover': {
      borderBottom: "1px solid #fff",
    },
    '&:hover:after': {
      opacity: "1",
    },
  },
  '& .MuiDialogContent-root .MuiBox-root.hospital_list.on .MuiBox-root': {
    '&:after': {
      opacity: "1",
    },
  },
  '& .MuiDialogContent-root .MuiBox-root.hospital_list .MuiBox-root:after':{
    content: "''",
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    border: "1px solid #333",
    opacity: "0",
    transition: "all 0.3s",
  },

  '& .MuiDialogContent-root .MuiBox-root.hospital_list:last-child .MuiBox-root:after': {borderRadius:"0 0 6px 6px"},
  '& .MuiDialogContent-root .MuiBox-root.hospital_list:first-of-type .MuiBox-root:after': {borderRadius:"6px 6px 0 0"},
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2.5),
  },
  '.MuiButton-root': {
    backgroundColor: "#6a6af8",
    color: "#fff",
    borderRadius: "4px",
    lineHeight:"40px",
    minWidth: "100px",
    height: "40px",
    fontSize: "15px",
    '&:hover': {
      backgroundColor: "#6a6af8",
    },
  },
  '.MuiButton-root.MuiButton-search': {
    fontSize: "15px",
    backgroundColor: "#7878a5",
    borderRadius:"4px",
    color: "#fff",
    minWidth: "100px",
    lineHeight:"40px",
    lineHeight: "40px",
  }
  
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 20,
            top: 15,
            padding:"0px"
          }}
        >
          <img src={CloseIcon} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function hospitalFindDialogs(props) {
  const { open, setOpen, closeAfter } = props;
  const [accessToken, setAccessToken] = useState('');
  const [active, setActive] = useState(false);
  const [sido, setSido] = useState([]);
  const [gungu, setGungu] = useState([]);
  const [sidoCd, setSidoCd] = useState('');
  const [sidoText, setSidoText] = useState('');
  const [gunguText, setGunguText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [searchDetail, setSearchDetail] = useState("none");
  const [hospitalName, setHospitalName] = useState("");
  const [hospitalAddr, setHospitalAddr] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [searchCnt, setSearchCnt] = useState(0);
  const directInput = useRef();

  
  useEffect(() => {
    setSido([])
    setGungu([])
    setSidoCd("")
    setSidoText("")
    setGunguText("")
    setSearchText("")
    setSearch(false)
    setSearchList([])
    setSearchDetail("none")
    setHospitalName("")
    setHospitalAddr("")
    setDepartmentName("")
    getSido();
  }, [open])


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onKeyPress = (e) => {
    if(e.key == "Enter"){
      serachClick()
    }
  }

  const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
        color: "#cccccc",
        fontSize:"15px",
    }
  }));

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  const handleClose = () => {
    
    closeAfter && closeAfter(hospitalName, hospitalAddr, departmentName)
    setOpen(false);
  };

  const getSido = () => {
    const req = {
      path: "/v1/preregi/sido",
    };
    _API.get(req).then((res) => {
      setSido(res.data?.data);
      setAccessToken(res.data?.accessToken)
    }).catch((e) => {
      console.log(e)
    });
  }
  const getGungu = (e) => {
    const value = e.target.value;
    const splitValue = value.split('-');
    setSidoCd(e.target.value);
    setSidoText(splitValue[1])
    const req = {
      path: "/v1/preregi/gungu",
      data: {
        accessToken : accessToken,
        sidoCd : splitValue[0],
      },
    };
    _API.get(req).then((res) => {
      setGungu(res.data?.data);
    }).catch((e) => {
      console.log(e)
    });
  }
  const serachClick = (e) => {
    setSearchCnt(0)
    setHospitalName("")
    setHospitalAddr("")
    setDepartmentName("")
    setSearchDetail("")
    let txt = searchText;
    txt = txt.replace(/ /gi,"");
    if(sidoText == "" || sidoText == null){
      alert("시/도 를 선택해주세요")
      return
    }
    if(gunguText == "" || gunguText == null){
      alert("시/군/구 를 선택해주세요")
      return
    }
    setSearch("loading")
    let count = 0;
    const interval = setInterval(() => {
      if(count == 0){
        const req = {
          path: "/v1/preregi/searchHospital",
          data: {
            sidoText : sidoText,
            gunguText : gunguText,
            searchText : txt,
          },
        }
        _API.get(req).then((res) => {
            if(res.data?.data !== undefined && res.data?.data.length !== undefined){
              setSearchCnt(res.data?.data.length)
            }
            setSearchList(res.data?.data);
            setSearch(true);
            clearInterval(interval)
            return
        }).catch((e) => {
          console.log(e)
          setSearch(true);
        });
      }else if(count == 6){
        clearInterval(interval)
        return
      }
      count++;
    }, 1000);
  }
  const setSearchClick = (idx, name, addr) => {
    if(name != "" && name != null){
      setHospitalName(name)
      setHospitalAddr(addr)
      setSearchDetail("block");
    }else{
      setSearchDetail("direct");
      if (directInput.current) { 
        directInput.current.focus();
      }
    }
  }
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          병(의)원 찾기
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stack direction={{xs:"column",sm:"row"}} mb={2.5}>
            <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" sx={{ width: { xs: "100%", sm: "50%" },marginRight:{ xs: "0px", sm: "14px" }}}>
              <FormControl sx={{width:"100%", marginRight:"10px", marginBottom:{ xs: "20px", sm: "0px" }}}>
                <Select
                  required
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sidoCd}
                  displayEmpty
                  onChange={(e) => {getGungu(e)}}
                  input={<BootstrapInput />}
                  renderValue={
                    sidoCd !== "" ? undefined : () => <Placeholder>시/도 선택</Placeholder>
                  }
                  MenuProps={{
                    PaperProps: {},
                  }}
                >
                  {sido && sido.map((list,idx) => (
                    <MenuItem key={`sido_${idx}`} className="sido" value={list.cd+"-"+list.addr_name}>{list.addr_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{width:"100%", marginBottom:{ xs: "20px", sm: "0px" }}}>
                <Select
                  required
                  sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gunguText}
                  displayEmpty
                  onChange={(e)=>{setGunguText(e.target.value)}}
                  input={<BootstrapInput />}
                  renderValue={
                    gunguText !== "" ? undefined : () => <Placeholder>시/군/구 선택</Placeholder>
                  }
                >
                  {gungu && gungu.map((list,idx) => (
                    <MenuItem key={`gungu_${idx}`} className="gungu" value={list.addr_name}>{list.addr_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack direction={{xs:"column",sm:"row"}} justifyContent="space-between" sx={{ width: { xs: "100%", sm: "50%" } }}>  
              <BootstrapInput 
                placeholder="병(의)원명 검색(띄어쓰기 없이 입력)" 
                sx={{width:"100%", marginRight:"10px",marginBottom:{ xs: "20px", sm: "0px" }}} 
                onChange={(e)=>{setSearchText(e.target.value)}}
                onKeyPress={(e)=>{onKeyPress(e)}}
              />
              <Button variant="search" onClick={(e) => {serachClick()}}>검색</Button>
            </Stack>
          </Stack>
          <Stack direction={{xs:"column",sm:"row"}} alignItems="flex-start">
            <Box sx={{
              position: "relative",
              width: { xs: "100%", sm: "50%" },
              marginRight: "14px",
              marginBottom: { xs: "20px", sm: "0px" },
              border: "1px solid #eeeeee",
              height: "400px",
              overflowY: "auto",
              padding: `${search ? '0px !important' : '20px !important'}`,
            }}>
              {search == true ?
                <Stack>
                  { searchCnt > 0 ? 
                    searchList && searchList.map((list,idx) => (
                      <Box key={`hospital_${idx}`} className={cx({hospital_list:true, on:list?.on})} onClick={(e) => {setSearchClick(idx, list.dutyName,list.dutyAddr)}}>
                        <Box sx={{padding:"20px 0"}}>
                          <Typography sx={{fontWeight:"bold", marginBottom:"6px"}}>{list.dutyName}</Typography>
                          <Typography>{list.dutyAddr}</Typography>
                        </Box>
                      </Box>
                    ))
                  : 
                    searchList && 
                    <Box className={cx({hospital_list:true, on:searchList?.on})} onClick={(e) => {setSearchClick(0, searchList.dutyName,searchList.dutyAddr)}}>
                      <Box sx={{padding:"20px 0"}}>
                        <Typography sx={{fontWeight:"bold", marginBottom:"6px"}}>{searchList.dutyName}</Typography>
                        <Typography>{searchList.dutyAddr}</Typography>
                      </Box>
                    </Box>
                  }
                  <Box className={cx({hospital_list:true, on:searchDetail==="direct"})}  onClick={() => {setSearchClick()}}>
                    <Box sx={{padding:"20px 0"}}>
                      <Typography sx={{fontWeight:"bold"}}>직접입력</Typography>
                    </Box>
                  </Box>
                </Stack>
              : search == false ? 
                <Typography sx={{ borderBottom: "1px solid #eee", paddingBottom: "20px", lineHeight: "normal", fontWeight: "bold" }}>
                  병(의)원명을 검색해 주세요.
                </Typography>
              : search == "loading" && (
                <Stack sx={{position:"absolute", top:"50%", left:"50%",transform:"translate(-50%,-50%)", textAlign:"center"}}>      
                  {/* <CircularProgress color="secondary" sx={{margin:"0 auto 10px"}} /> */}
                  <div className="snippet_box">
                    <div className="snippet" data-title=".dot-flashing">
                      <div className="stage">
                        <div className="dot-flashing"></div>
                      </div>
                    </div>
                  </div>
                  <Typography sx={{ lineHeight: "normal", fontWeight: "bold" }}>
                    병(의)원을 검색중입니다.<br/>
                    잠시만 기다려주세요.      
                  </Typography>
                </Stack>      
                )
              }     
            </Box>
            
            {searchDetail == "block" ? 
                <Box sx={{ width: { xs: "100%", sm: "50%" }, background: "#f7f7fa" }}>
                  <Box sx={{ padding: "0 !important" }}>
                    <Box sx={{padding:"0 !important" }}>
                      <Typography sx={{ marginBottom: "6px" }}>{ searchDetail == "block"}{hospitalName}</Typography>
                      <Typography>{hospitalAddr}</Typography>
                    </Box>
                    {/* <BootstrapInput placeholder="진료과 (직접입력)" onChange={(e) => {setDepartmentName(e.target.value)}} sx={{width:"100%", backgroundColor:"#f7f7fa"}}/> */}
                  </Box>
                </Box>
              : searchDetail == "direct" ? 
                (
                  <Box sx={{ width: { xs: "100%", sm: "50%" }, background: "#f7f7fa"  }}>
                    <Box sx={{ padding: "0 !important" }}>
                      <BootstrapInput autoFocus inputRef={directInput} placeholder="병원명 (직접입력)" onChange={(e) => {setHospitalName(e.target.value)}} sx={{width:"100%", backgroundColor:"#f7f7fa"}}/>
                      <BootstrapInput placeholder="병원주소 (직접입력)" onChange={(e) => {setHospitalAddr(e.target.value)}} sx={{width:"100%", backgroundColor:"#f7f7fa"}}/>
                      {/* <BootstrapInput placeholder="진료과 (직접입력)" onChange={(e) => {setDepartmentName(e.target.value)}} sx={{width:"100%", backgroundColor:"#f7f7fa"}}/> */}
                    </Box>
                  </Box>
                )
              :   
                <Stack>
                  <Box sx={{padding:"0 !important", marginBottom:"20px"}}>
                    <Typography sx={{ marginBottom:"6px"}}></Typography>
                    <Typography></Typography>
                  </Box>
                </Stack>
              }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            확인
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}





