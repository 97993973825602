import React, {Fragment, useState, useRef, useEffect, useLayoutEffect, useContext} from "react";
import { AppContext } from "../../components/AppProvider";
import styled from "styled-components";

// UI
import TypeALayout from "./TypeA/Layout/Layout";
import TypeBLayout from "./TypeB/Layout/Layout";
import TypeELayout from "./TypeE/Layout/Layout";

import { _API, _CK, _U } from "../../modules/utils";
import Socket from "../../components/UI/Socket";
import useStore from "../../modules/utils/store";

export default (props) => {
  const { history, location, match } = props;
  const { eventIdx : eventIdx, sessionIdx: sessionIdx, type: type } = match.params;
  const { siteInfo }= useStore();
  const [loaded, setLoaded] = useState(false);
  const { iam } = useContext(AppContext);
  const [consoleCfg, setConsoleCfg] = useState({});
  const [sessionAttendance, setSessionAttendance] = useState();
  
  //소켓
  const [isConnect, setIsConnect] = useState(false);
  const debugSocket = false; //_CFG.isLocal();

  const onConnected = () => {
    setIsConnect(true);
    _U.postMessage("SOCKET-SEND", {
        action: 'CONNECT',
        data: {
            mode: 'CONNECT',
            siteIdx: siteInfo?.siteIdx,
            eventIdx: siteInfo?.eventIdx,
            sessionIdx: consoleCfg?.sessionIdx,
            consoleIdx: consoleCfg?.consoleIdx,
            name: iam?.name,
            memType: iam?.memType,
            companyName: iam?.companyName,
            deptName: iam?.deptName,
            passCode:iam?.passCode,
            preregIdx: iam.preregIdx,
            sessionName: consoleCfg?.sessionName,
            logKey:iam?.logKey,
            consoleCountView:iam?.consoleCountView
        },
    });
  };

  const onDisconnected = () => {
    setIsConnect(false);
    _U.postMessage("SOCKET-SEND", {
        action: 'DISCONNECT',
        data: {
            mode:'DISCONNECT',
            siteIdx: siteInfo?.siteIdx,
            eventIdx: siteInfo?.eventIdx,
            sessionIdx: consoleCfg?.sessionIdx,
            consoleIdx: consoleCfg?.consoleIdx,
            name: iam?.name,
            memType: iam?.memType,
            companyName: iam?.companyName,
            deptName: iam?.deptName,
            passCode:iam?.passCode,
            preregIdx: iam.preregIdx,
            sessionName: consoleCfg?.sessionName,
            logKey:iam?.logKey,
            consoleCountView:iam?.consoleCountView
        },
    });
  };


  /**
   * 화면 초기화
   *
   * initPage()
   *  - 버튼 클릭 이벤트
   */
  useLayoutEffect(() => {
    _CK.setConsoleCountView(parseInt(_CK.getConsoleCountView()) + 1);
    getSessionAttendanceInfo();
    getConsoleInfo();
  }, []);

  // 이벤트 사이트 환경설정 조회용 (템플릿, 테마 등 설정 필요)
  const getConsoleInfo = async () => {
    const req = {
      path: "/v1/client/console/info",
      data: {
        sessionIdx: sessionIdx,
        siteIdx: siteInfo?.siteIdx
      },
    };

    await _API.post(req).then((res) => {
      let { data } = res;
      if (data) {
        setConsoleCfg(data);
        setLoaded(true);
      }
    })
  };

  const getSessionAttendanceInfo = async () => {
    const req = {
      path: "/v1/client/session/attendance/info",
      data: {
        sessionIdx: sessionIdx,
        passCode: iam?.passCode
      },
    };

    await _API.post(req).then((res) => {
      let { data } = res;
      if (data) {
        setSessionAttendance(data);
        console.log('sessionAttendance', data);
      }
    })
  };

  if (!loaded)
    return (
      <> 
        <img
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              width: "200px",
              height: "200px",
              display: "block",
              transform: "translate(-50%,-50%)",
            }}
            className="loading_img loading_img3"
            src="https://cdn.onoffevent.com/common/loading.gif"
          />
      </>
    );
  else
    if(consoleCfg.consoleTemplateIdx == 6){
      return (
        <Fragment>
          <TypeALayout
            history={history}
            consoleCfg={consoleCfg}
            sessionAttendance={sessionAttendance}
            getSessionAttendanceInfo={getSessionAttendanceInfo}
          >
          </TypeALayout>
          {consoleCfg && (
            <SocketWrapper debug={debugSocket}>
              <Socket
                siteInfo={siteInfo}
                consoleCfg={consoleCfg}
                debug={debugSocket}
                sessionIdx={sessionIdx}
                eventIdx={eventIdx}
                onConnected={onConnected}
                onDisconnected={onDisconnected}
              />
            </SocketWrapper>
          )}
        </Fragment>
      );
    }else if(consoleCfg.consoleTemplateIdx == 7){
      return (
        <Fragment>
          <TypeBLayout
            history={history}
            consoleCfg={consoleCfg}
            sessionAttendance={sessionAttendance}
            getSessionAttendanceInfo={getSessionAttendanceInfo}
          >
          </TypeBLayout>
          {consoleCfg && (
            <SocketWrapper debug={debugSocket}>
              <Socket
                siteInfo={siteInfo}
                consoleCfg={consoleCfg}
                debug={debugSocket}
                sessionIdx={sessionIdx}
                eventIdx={eventIdx}
                onConnected={onConnected}
                onDisconnected={onDisconnected}
              />
            </SocketWrapper>
          )}
        </Fragment>
      );
    }else if(consoleCfg.consoleTemplateIdx == 14){
      return (
        <Fragment>
          <TypeELayout
            history={history}
            consoleCfg={consoleCfg}
            sessionAttendance={sessionAttendance}
            getSessionAttendanceInfo={getSessionAttendanceInfo}
          >
          </TypeELayout>
          {consoleCfg && (
            <SocketWrapper debug={debugSocket}>
              <Socket
                siteInfo={siteInfo}
                consoleCfg={consoleCfg}
                debug={debugSocket}
                sessionIdx={sessionIdx}
                eventIdx={eventIdx}
                onConnected={onConnected}
                onDisconnected={onDisconnected}
              />
            </SocketWrapper>
          )}
        </Fragment>
      );
    }else{
      return (
        <Fragment>
          <TypeALayout
            history={history}
            consoleCfg={consoleCfg}
            sessionAttendance={sessionAttendance}
            getSessionAttendanceInfo={getSessionAttendanceInfo}
          >
          </TypeALayout>
          {consoleCfg && (
            <SocketWrapper debug={debugSocket}>
              <Socket
                siteInfo={siteInfo}
                consoleCfg={consoleCfg}
                debug={debugSocket}
                sessionIdx={sessionIdx}
                eventIdx={eventIdx}
                onConnected={onConnected}
                onDisconnected={onDisconnected}
              />
            </SocketWrapper>
          )}
        </Fragment>
      );
    }
    
};
const SocketWrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  ${(props) => props.debug && `width:450px; height:250px;`}
`;

