import React, { useContext,useEffect, useState } from "react";
import { Fragment } from 'react';
import TopArroow from "../../../../resources/console/images/icon/top_arrow.svg";
import { AppContext } from "../../../../components/AppProvider";
import { Link } from "react-router-dom";
import useStore from "../../../../modules/utils/store";

export const Footer = (props) => {
  const { iam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const { history } = props;
  const [termsInfo, setTermsInfo] = useState(null);
  const [openModal, setOpenModal] = useState(null); 
  
  const showTerms = (type, e) => {
    if (e) e.preventDefault();

    $("body").css("overflow", "hidden");
    if (type === "termsUseAgree")
      setTermsInfo({ title: "사이트이용약관", content: siteInfo?.memTermsUseForm });
    else if (type === "termsPrivateAgree")
      setTermsInfo({
        title: "개인정보처리방침",
        content: siteInfo?.memTermsPrivacyForm,
      });
    else if (type === "termsMarket")
      setTermsInfo({
        title: "마케팅정보 수신동의",
        content: siteInfo?.memTermsMarketForm,
      });
    else {
      setTermsInfo(null);
    }
  };

  const goEventList = (e,sub,item) => {
    if(e) e.preventDefault();
    const confirmChk = window.confirm('시청을 중단하시겠습니까?');
    if (confirmChk) {
      if (sub == "popUp") {
        window.open(`/#/site/subpage/${item.idx}`);
      } else if (sub == "page") { 
        history.push(`/site/subpage/${item.idx}`);
      }
    }
  }

  const modalClose = () => {
    setTermsInfo(null);
    setOpenModal(false);
    $("body").css("overflow", "auto");
  };

  const linkRel = () => { 
    window.open("https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetail?itemSeq=201601746")
  }

  useEffect(() => {
    $("#openModal").click(function (e) {
      e.preventDefault();
      $("body").css("overflow", "hidden");
      setOpenModal(true);
    })
  }, []);
  return (
    <footer id="footer">
      <div className="footer-arrow-wrap">
        <button type="button" className="go-to-top">
            <img src={TopArroow} alt="탑버튼" />
            <span>TOP</span>
        </button>
      </div>
      {!['MEDIGATE','ICOD'].includes(iam?.targetType) && (<>
      <div className="footer_inform_cont">
        <div className="wid_1180">
          <ul>
              {siteInfo?.subPageList && siteInfo?.subPageList?.filter((s) => s?.isShortcutShow)?.sort((a, b) => a?.sortNumber - b?.sortNumber)?.map((item, i) => {
                return(
                  <li key={`sub_${i}`}>
                    {item.openType == "PAGE" && (
                      <a onClick={(e) => goEventList(e,"page",item)}>
                        <span>{item?.name}</span>
                      </a>
                    )}
                    {item.openType != "PAGE" && (
                      <a onClick={(e) => goEventList(e, "popUp", item)}>
                        <span>{item?.name}</span>
                      </a>
                    )}
                  </li>
                )
              })}
              <li><Link to={`/site/${siteInfo?.idx}/notice`} target="_blank"><span>Notice</span></Link></li>
          </ul>
        </div>
      </div>
      </>)}
      <div className="footer">
        <div className="wid_1180">
          <pre className="ft_info" dangerouslySetInnerHTML={{ __html: siteInfo?.footer?.replace(/\n/g,'') }} />
          {iam?.targetType == "MEMBER" && siteInfo?.memberFooterCheck === "Y" ? (
          <div className="ft_info2" style={{ marginTop: "30px" }}>
            <a onClick={showTerms.bind(this, "termsPrivateAgree")}>개인정보처리방침.</a>
            <a onClick={showTerms.bind(this, "termsUseAgree")}>이용약관</a>
          </div>
        ) : (
          ""
        )}
        </div>
      </div>
      {termsInfo && (
        <Fragment>
          <div className="ft_modal_container">
            <div className="ft_modal_header">
              <div>
                <h2>{termsInfo?.title}</h2>
                <button
                  className="close_btn"
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    modalClose();
                  }}
                  >
                  <img src="https://cdn.onoffevent.com/common/icon_close.svg" />
                </button>
              </div>
            </div>
            <div className="ft_modal_content">
              <div dangerouslySetInnerHTML={{ __html: termsInfo?.content }} />
              {/* <div className="close_button_div">
                <button className="close_button" onClick={modalClose}>
                닫기
                </button>
              </div> */}
            </div>
          </div>
          <div className="modal_bg on"></div>
      </Fragment>
      )}
      {openModal && (
        <div className="modal_container">
          <button className="modal_close" onClick={modalClose}><img src="https://cdn.onoffevent.com/common/icon_close.svg" alt="닫기"/></button>
          <div className="modal_mid">
            <h4>
              당신은 한국 GSK 웹 사이트에서
              다른 페이지로 이동하려고 하고 있습니다.<br/>
              이 링크를 클릭하면 다른 웹 사이트 또는 웹 채널로 이동하게 됩니다.
            </h4>
            <span>
              지금 이동하려는 페이지는 한국 GSK의 유관 사이트이거나
              한국 규정이 적용되지 않는 글로벌 GSK 사이트 또는 계열사일 수 있습니다.
              GSK가 관리하지 않거나 GSK가 소유하지 않은 제3자 사이트로 이동할 경우,
              GSK는 해당 사이트에서 제공하는 콘텐츠에 대해 책임지지 않습니다.
            </span>
          </div>
          <div className="btnBox">
            <button className="modalBtn cancelBtn" onClick={modalClose}>취소</button>
            <button className="modalBtn confirmBtn" onClick={linkRel}>계속</button>
          </div>
        </div>
      )}
    </footer>
  );
};
