import React, { useState, useEffect ,useRef, useContext } from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import styled from "styled-components";
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from "moment";
import nl2br from "react-nl2br";

import { AppContext } from '../../../components/AppProvider';
import { _API } from '../../../modules/utils';
import cx from "classnames";


import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { CSSTransition } from 'react-transition-group';
import icon_close from "../../../resources/console/images/icon/icon-close.svg";
import call1_image from "../../../resources/console/images/icon/call1_image.svg";
import icon_select from "../../../resources/console/images/icon/icon_select.svg";
import counsel_complete from "../../../resources/console/images/icon/counsel_complete.svg";
import counsel_cancel from "../../../resources/console/images/icon/counsel_cancel.svg";
import icon_s_calendar from "../../../resources/console/images/icon/icon_s_calendar.svg";
import icon_search_cancel from "../../../resources/console/images/icon/icon_search_cancel.svg";
import icon_search_delete from "../../../resources/console/images/icon/icon_search_delete.svg";
import m_icon_close from "../../../resources/console/images/icon/m_icon_close.svg";


const CounselModal = (props) => {

  const { counselModalOnOff,setCounselModalOnOff,consoleCfg} = props;

  const { iam } = useContext(AppContext);

  const [step , setStep] = useState(1);
  const [beforeStep , setBeforeStep] = useState(1);
  const [showCancelMessage, setShowCancelMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [agreeMoreBtn,setAgreeMoreBtn] =  useState(false);

  const [content, setContent] = useState("");
  const [callDate, setCallDate] = useState("");
  const [callTime, setCallTime] = useState("연락가능한 시간을 선택해 주세요.");
  const [name, setName] = useState(iam?.name||"");
  const [mobile, setMobile] = useState(iam?.mobile||"");
  const [email, setEmail] = useState(iam?.email||"");
  const [companyName, setCompanyName] = useState(iam?.companyName || "");
  const [deptName, setDeptName] = useState(iam?.deptName || "");
  const [isAgreeCheck, setIsAgreeCheck] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [idx, setIdx] = useState(null);

  const reset = () => {
    setContent("");
    setCallDate("");
    setCallTime("연락가능한 시간을 선택해 주세요.");
    setName(iam?.name||"");
    setMobile(iam?.mobile||"");
    setEmail(iam?.email||"");
    setCompanyName(iam?.companyName || "");
    setIsAgreeCheck(false);
    setIsComplete(false);
    setIdx(null);
  }

  const showAlert = message => {
    setMessage(message);
    setShowMessage(true);
  }

  const onChangeContent = (e) => {
    if (e.target.value.length <= 1000) {
      setContent(e.target.value);
    }
  }

  const setShowStep = ()=>{
    setShowCancelMessage(false);
    setStep(5);
  }

  const onSetStep = (before, next) => {
    setStep(next);
    setBeforeStep(before);
  }

  const inputRef = useRef(null);
  function handleFocus() {
    inputRef.current.disabled = false;
    inputRef.current.focus();
  }

  const onCloseModal = () => {
    setCounselModalOnOff(false);

    // 완료 또는 취소완료 후 초기화
    if (step===3 || step===5) {
      newCall();
    }
  }
  
  const onClickCallTime = e => {
    setCallTime(e.target.textContent);
  }

  const newCall = () => {
    reset();
    setStep(1);
  }

  const getCallDateTime = () => {
    switch (callTime) {
      case "08:00 - 10:00":
        return (callDate+"090000");
      case "10:00 - 12:00":
        return (callDate+"110000");
      case "12:00 - 14:00":
        return (callDate+"130000");
      case "14:00 - 16:00":
        return (callDate+"150000");
      case "16:00 - 18:00":
        return (callDate+"170000");
      case "18:00 - 20:00":
        return (callDate+"190000");
      case "20:00 - 22:00":
        return (callDate+"210000");
      default:
        return (callDate+"000000");
    }

  }

  const saveCall = (callback) => {
    const req = {
      path: "/v1/client/call/save",
      data: {
        idx,
        preregIdx:iam.preregIdx,
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
        callDate: getCallDateTime(),
        callTime,
        callContent: content,
        name,
        companyName,
        deptName:iam.deptName,
        deptName,
        mobile,
        email,
      },
    };

    _API.post(req).then((res) => {
      let { data } = res;
      setIdx(data.data);
      callback && callback();
    });
  }

  const onClickDate = (date) => {
    setCallDate(date);
  }


  const onSave = () => {

    if (!callDate) {
      showAlert("희망 상담일시를 선택해 주세요.");
      return;
    }

    if (callTime === "연락가능한 시간을 선택해 주세요.") {
      showAlert("희망 상담일시를 선택해 주세요.");
      return;
    }

    if (!isAgreeCheck) {
      showAlert("개인정보이용 미동의시 상담신청이 불가합니다.");
      return;
    }

    saveCall(() => {
      onSetStep(2, 3);
    });
  }

  const onClickMod = () => {
    if (idx) {     // 완료 후 수정
      saveCall(() => {
        onSetStep(4, 3);
      })
    } else {              // 완료 전 수정
      onSetStep(4, 2);
    }
  }

  const onCancel = () => {
    setShowCancelMessage(true);
  }

  const cancelCall = () => {
    const req = {
      path: "/v1/client/call/cancel",
      data: {
        idx,
        preregIdx:iam.preregIdx,
      },
    };

    _API.post(req).then((res) => {
      setShowStep();
    });
  }

  return (
    <>
      <CSSTransition
        in={props.counselModalOnOff}
        //props.counselModalOnOff
        timeout={500}
        classNames="counsel_modal_animaition"
        unmountOnExit
      >
        <>
          <div className={`counsel_modal`}>
          {props.counselModalOnOff && (
            <button 
              onClick={onCloseModal} 
              className="m_counsel_modal_close"
            >
              <img src={m_icon_close}/>
            </button>
          )}
            {step ==1 && ( // 상담내용 작성
              <CounselModal1
                setCounselModalOnOff={setCounselModalOnOff}
                onSetStep={onSetStep}
                content={content}
                onChangeContent={onChangeContent}
                onCloseModal={onCloseModal}
                showAlert={showAlert}
              />
            )}
            {step == 2 && ( // 상담 저장
              <CounselModal2 
                setCounselModalOnOff={setCounselModalOnOff}
                onSetStep={onSetStep}
                agreeMoreBtn={agreeMoreBtn}
                setAgreeMoreBtn={setAgreeMoreBtn}
                content={content}
                name={name}
                companyName={companyName}
                mobile={mobile}
                email={email}
                callDate={callDate}
                callTime={callTime}
                onClickCallTime={onClickCallTime}
                isAgreeCheck={isAgreeCheck}
                setIsAgreeCheck={setIsAgreeCheck}
                showAlert={showAlert}
                onSave={onSave}
                onCloseModal={onCloseModal}
                onClickDate={onClickDate}
              />
            )}
            {step == 3 && ( // 상담 완료
              <CounselModal3 
                setCounselModalOnOff={setCounselModalOnOff}
                onSetStep={onSetStep}
                content={content}
                name={name}
                companyName={companyName}
                mobile={mobile}
                email={email}
                callDate={callDate}
                callTime={callTime}
                onCloseModal={onCloseModal}
                onCancel={onCancel}
              />
            )}
            {step == 4 && ( // 상담 수정
              <CounselModal4 
                setCounselModalOnOff={setCounselModalOnOff}
                onSetStep={onSetStep}
                beforeStep={beforeStep}
                agreeMoreBtn={agreeMoreBtn}
                setAgreeMoreBtn={setAgreeMoreBtn}
                content={content}
                callDate={callDate}
                callTime={callTime}
                name={name}
                setName={setName}
                companyName={companyName}
                setCompanyName={setCompanyName}
                email={email}
                setEmail={setEmail}
                mobile={mobile}
                setMobile={setMobile}
                onChangeContent={onChangeContent}
                onClickCallTime={onClickCallTime}
                onCloseModal={onCloseModal}
                onClickMod={onClickMod}
                onClickDate={onClickDate}
              />
            )}
            {step == 5 && ( // 상담 취소
              <CounselModal5 
                setCounselModalOnOff={setCounselModalOnOff}
                setStep={setStep}
                content={content}
                callDate={callDate}
                callTime={callTime}
                name={name}
                companyName={companyName}
                email={email}
                mobile={mobile}
                newCall={newCall}
                onCloseModal={onCloseModal}
              />
            )}
          </div>
        </>
      </CSSTransition>

      <MessageAlert
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        onClickYes={()=>setShowMessage(false)}
        message={message}
        />
      <MessageAlert
        showMessage={showCancelMessage}
        setShowMessage={setShowCancelMessage}
        onClickYes={()=>cancelCall()}
        message={"신청을 취소하시겠습니까?"}
        isShowNoButton />
    </>
  )
}


export default CounselModal


const CounselModal1 = (props) =>{

  const { content, onChangeContent, onCloseModal, showAlert } = props;

  const onClickNext = () => {
    if (!content.trim()) {
      showAlert("문의하실 내용을 작성해주세요.");
      return;
    }
    props.onSetStep(1,2)
  }

  return(
    <div className="counsel_modal_inner1">
      <div className="counsel_modal_header">
        <h1>전문가에게 직접 문의하세요.</h1>
        {/* <button onClick={()=>{props.setCounselModalOnOff(false)}}> */}
        <button onClick={onCloseModal}>

          <img src={icon_close} />
        </button>
      </div>
      <div className="counsel_modal_overflowY">
        <div className="counsel_modal_header_content">
          <span>
            WX Call에서 제약회사 담당자와의 일대일 세션을 통해 제품에 대한 지원을 받고 문의하실 수 있습니다.
            브랜드 및 제품 관련 서비스가 필요하신 경우, 상담신청을 해주세요.
          </span>
        </div>
        <div className="counsel_modal_mid">
          <h2>우선 문의하실 내용을 남겨주세요.</h2>
          <textarea value={content} onChange={onChangeContent}></textarea>
          <div className="counsel_modal_mid_img">
            <img src={call1_image} />
          </div>
        </div>
      </div>
      <div className="counsel_modal_bottom">
        <div className="counsel_modal_bottom_btn">
          <button onClick={onClickNext}>계속</button>
        </div>
      </div>
    </div>
  );
}

const CounselModal2 = (props) =>{
  const { content, name, companyName, mobile, email
    , callDate, isAgreeCheck, setIsAgreeCheck, callTime, onClickCallTime
    , showAlert, onSave, onCloseModal, onClickDate} = props;


  const onClickNext = () => {
    onSave();
  }

  return(
    <div className="counsel_modal_inner2">
      <div className="counsel_modal_header">
        <h1>상담신청</h1>
        <button onClick={onCloseModal}>
          <img src={icon_close} />
        </button>
      </div>
      <div className="counsel_modal_overflowY">
        <div className="counsel_modal_header_content">
          <span>
          제품에 대한 안내 / 문의가 필요할 경우,예약을 해주시면 제약회사의 담당자가 선생님께 직접 연락을 
          드려 제품에 대한 안내를 상세히 받아보실 수 있습니다.
          </span>
        </div>
        <div className="counsel_modal_mid">
          <div className="counsel_modal_mid_inner">
            <div className="counsel_modal_inner_flex">
              <h2>문의 내용</h2>
              <span style={{cursor:"pointer"}} onClick={()=>{props.onSetStep(2, 4)}}>문의내용수정</span>
            </div>
              <ContentWrapper>
                <span>
                  {nl2br(content)}
                </span>
              </ContentWrapper>
          </div>
          <SwipCalendar
            onClickEvent={onClickDate}
            callDate={callDate}
          />
          <div className="counsel_modal_mid_inner">
            <h2>희망하는 상담 시간을 선택해주세요.</h2>
            <button 
              className="time_select_container"
              type="button"
              data-toggle="dropdown"
              // onClick={()=>{setArrowRotate(true)}}
            >
              {/* <span>연락가능한 시간을 선택해 주세요.</span> */}
              <span>{callTime}</span>
              <div className="arrow_imgbox">
                <img src={icon_select} />
              </div>
            </button>
            {/* date */}
            <div className="time_select_box dropdown-menu dropdown-menu-right">
              <ul onClick={onClickCallTime}>
                <li>08:00 - 10:00</li>
                <li>10:00 - 12:00</li>
                <li>12:00 - 14:00</li>
                <li>14:00 - 16:00</li>
                <li>16:00 - 18:00</li>
                <li>18:00 - 20:00</li>
                <li>20:00 - 22:00</li>
              </ul>
            </div>
          </div>
          <div className="counsel_modal_mid_inner">
            <h2 style={{marginBottom:"20px"}}>정보가 맞는지 확인 후 개인정보활용동의를 진행해주세요.</h2>
            <div className="counsel_modal_inner_flex">

              <h3>{name}{companyName && `(${companyName})`}</h3>
              <span style={{cursor:"pointer"}} onClick={()=>{props.onSetStep(2,4)}}>정보수정</span>
            </div>
            <p>{(mobile||"").replace(/(01.{1})([0-9]{4})([0-9]*)/g, '$1-$2-$3')}</p>
            <p>{email}</p>
          </div>
          <div className="counsel_modal_mid_inner">
            <div className="counsel_modal_inner_flex">
              <div>
                <div className="privacy_agree_box_inner">
                  <input
                    type="checkbox"
                    name="termPrivateAgree"
                    id="privacy_agree"
                  />
                  <label id="chk_label" htmlFor="privacy_agree" onClick={()=>setIsAgreeCheck(!isAgreeCheck)}>
                    <CheckBox isChecked={isAgreeCheck}/><h3>개인정보이용 동의</h3>
                  </label>
                </div>
              </div>
              <span onClick={()=>{props.setAgreeMoreBtn(!props.agreeMoreBtn)}} style={{cursor:"pointer"}}>
                {props.agreeMoreBtn == true
                  ? "닫기"
                  : "내용보기"
                }
              </span>
            </div>
            <div className={`privacy_agree_inner_textbox ${props.agreeMoreBtn ? "active" : ""}`}>
              <p>
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
                호흡곤란을 겪는 환자에게 Initial therapy로 LAMA/LABA를 권장하십니까?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="counsel_modal_bottom">
        <div className="counsel_modal_bottom_btn">
          <button onClick={()=>{props.onSetStep(1,1)}}>이전</button>
          <button onClick={onClickNext}>계속</button>
        </div>
      </div>
    </div>
  );
}

const CounselModal3 = (props) =>{
  const { content, callDate, callTime, name, companyName, mobile, email, onCloseModal, onCancel } = props;
  return(
    <div className="counsel_modal_inner3">
      <div className="counsel_modal_header">
        <h1>상담신청</h1>
        <button onClick={onCloseModal}>
          <img src={icon_close} />
        </button>
      </div>
      <div className="counsel_modal_overflowY">

        <div className="counsel_modal_header_content">
          <span>
            담당자가 확인 후 상담일정 확정을 위하여 연락드릴 예정입니다.<br/>
            신청하신 정보를 확인해주세요.
          </span>
        </div>
        <div className="counsel_modal_mid">
          <div className="counsel_modal_mid_inner">
            <img src={counsel_complete}/>
            <h5>홍길동 선생님의<br/>상담이 신청되었습니다.</h5>
          </div>
          <div className="counsel_modal_mid_inner counsel_modal_mid_inner1">
            <h4>신청정보</h4>
            <p>{nl2br(content)}</p>
            <div className="d-flex justify-content-between align-items-center">
              {/* <h4>2021년 12월 13일 오전 11시 30분</h4> */}
              <h4>{`${ callDate } ${ callTime }`}</h4>
              <img src={icon_s_calendar}/>
            </div>
          </div>
          <div className="counsel_modal_mid_inner counsel_modal_mid_inner2">
            <p>
              {name}{companyName && `(${companyName})`}<br/>
              {(mobile||"").replace(/(01.{1})([0-9]{4})([0-9]*)/g, '$1-$2-$3')}<br/>
              {email}
            </p>
            <span>해당 정보를 변경하거나 취소하려면 하단 버튼을 이용하세요.</span>
          </div>
        </div>
      </div>
      <div className="counsel_modal_bottom">
        <div className="counsel_modal_bottom_btn">
          <button onClick={onCancel}>신청취소</button>
          <button onClick={()=>{props.onSetStep(3,4)}}>신청수정</button>
          <button onClick={onCloseModal}>신청완료</button>
        </div>
      </div>
    </div>
  );
}

const CounselModal4 = (props) =>{

  const { content, onChangeContent, onClickCallTime, callDate, callTime, name
    , setName, companyName, setCompanyName, email, setEmail, mobile, setMobile
    , onCloseModal, onClickMod, onClickDate } = props;

  return(
    <div className="counsel_modal_inner2 counsel_modal_inner4">
      <div className="counsel_modal_header">
        <h1>상담신청</h1>
        <button onClick={onCloseModal}>
          <img src={icon_close} />
        </button>
      </div>
      <div className="counsel_modal_overflowY">
        <div className="counsel_modal_header_content">
          <span>
            제품에 대한 안내 / 문의가 필요할 경우,예약을 해주시면 제약회사의 담당자가 선생님께 직접 연락을 
            드려 제품에 대한 안내를 상세히 받아보실 수 있습니다.
          </span>
        </div>
        <div className="counsel_modal_mid">
          <div className="counsel_modal_mid_inner">
            <div className="counsel_modal_inner_flex">
              <h2>문의 내용</h2>
            </div>
            <textarea value={content} onChange={onChangeContent} />
          </div>

          <SwipCalendar
            onClickEvent={onClickDate}
            callDate={callDate}
          />

          <div className="counsel_modal_mid_inner">
            <h2>희망하는 상담 시간을 선택해주세요.</h2>
            <button 
              className="time_select_container"
              type="button"
              data-toggle="dropdown"

            >
              <span>{callTime}</span>
              <div className="arrow_imgbox">
                <img src={icon_select} />
              </div>
            </button>
            <div className="time_select_box dropdown-menu dropdown-menu-right">
              <ul onClick={onClickCallTime}>
                <li>08:00 - 10:00</li>
                <li>10:00 - 12:00</li>
                <li>12:00 - 14:00</li>
                <li>14:00 - 16:00</li>
                <li>16:00 - 18:00</li>
                <li>18:00 - 20:00</li>
                <li>20:00 - 22:00</li>
              </ul>
            </div>
          </div>

          <div className="counsel_modal_mid_inner">
            <h2 style={{marginBottom:"20px"}}>정보가 맞는지 확인 후 개인정보활용동의를 진행해주세요.</h2>
            <div className="counsel_modal_mid_inner_input_box">
              <input type="text" onChange={e=>setName(e.target.value)} value={name} placeholder="이름을 입력하세요."/>
              <div style={{position:"relative"}}>
                <input type="text" onChange={e=>setCompanyName(e.target.value)} value={companyName} />
                <img src={icon_search_delete} />
              </div>
              <input type="text" onChange={e=>setMobile(e.target.value)} value={(mobile||"").replace(/(01.{1})([0-9]{4})([0-9]*)/g, '$1-$2-$3')} />
              <input type="text" onChange={e=>setEmail(e.target.value)} value={email} />
            </div>
          </div>
        </div>
      </div>
      <div className="counsel_modal_bottom">
        <div className="counsel_modal_bottom_btn">
          <button onClick={()=>{props.onSetStep(4,props.beforeStep)}}>이전</button>
          <button onClick={onClickMod}>수정완료</button>
        </div>
      </div>
    </div>
  )
} 

const CounselModal5 = (props) =>{
  const { content, callDate, callTime, name, companyName, email, mobile, newCall, onCloseModal } = props;
  return(
    <div className="counsel_modal_inner3 counsel_modal_inner5">
      <div className="counsel_modal_header">
        <h1>상담신청</h1>
        <button onClick={onCloseModal}>
          <img src={icon_close} />
        </button>
      </div>

      <div className="counsel_modal_overflowY">
        <div className="counsel_modal_header_content">
          <span>
            언제제든지 상담을 신청하실 수 있습니다.<br/>
            제품에 대한 안내 / 문의가 필요할 경우, 예약을 해주시면 제약회사의 담당자가 선생님께 직접 
            연락을 드려 제품에 대한 안내를 상세히 받아보실 수 있습니다.
          </span>
          <small style={{cursor:"pointer"}} onClick={newCall}>새로운 상담신청하기</small>
        </div>
        <div className="counsel_modal_mid">
          <div className="counsel_modal_mid_inner">
            <img src={counsel_cancel}/>
            <h5>
              상담신청이<br/>취소되었습니다.
            </h5>
          </div>
          <div className="counsel_modal_mid_inner counsel_modal_mid_inner1">
            <h4>신청정보</h4>
            <p>{nl2br(content)}</p>
            <div className="d-flex justify-content-between align-items-center">
              <h4>{`${ callDate } ${ callTime }`}</h4>
            </div>
          </div>
          <div className="counsel_modal_mid_inner counsel_modal_mid_inner2">
            <p>
              {name}{companyName && `(${companyName})`}<br/>
              {(mobile||"").replace(/(01.{1})([0-9]{4})([0-9]*)/g, '$1-$2-$3')}<br/>
              {email}
            </p>
          </div>
        </div>
      </div>
      <div className="counsel_modal_bottom">
        <div className="counsel_modal_bottom_btn">
          <button onClick={onCloseModal}>확인</button>
        </div>
      </div>
    </div>
  )
}

function InputSample( {setVisible} ) {
  const [text, setText] = useState("");
  const [isListHover1, setIsListHover1] = useState(false);
  const onChange = (e) => {
    setText(e.target.value);
  };
  const onReset = () => {
    setText("");
  };
  return (
  <div className="inputWrap">
    <button 
      className="btnBack" 
      onClick={()=>{setVisible(false)}} 
    >
      <img src={icon_search_cancel} />
    </button>
    <input onChange={onChange} value={text} />
      {
        text &&
        <button 
          className="btnClear" 
          onMouseOver={() => setIsListHover1(true)} 
          onMouseOut={() => setIsListHover1(false)} 
          onClick={onReset}
        >
          <img src={isListHover1 ? icon_search_delete_on : icon_search_delete} />
        </button>
      }
    
    </div>
  );
}

const MessageAlert = props => {
  const { message, isShowNoButton, showMessage, setShowMessage, onClickYes } = props;

  return (
    <>
      <CSSTransition
        in={showMessage}
        timeout={300}
        classNames="cancel_alert"
        unmountOnExit
      >
        <div
          className="cancel_alert_container"
          onClose={() => setShowMessage(false)}
        >
          <h2 style={{textAlign: 'center'}}>
            {message}
          </h2>
          <div>
            {isShowNoButton && (
              <button onClick={() => setShowMessage(false)}>
                아니오
              </button>
            )}
            {/* <button onClick={() => setShowStep()}> */}
            <button onClick={onClickYes}>
              예
            </button>
          </div>
        </div>
      </CSSTransition>
      {showMessage && (
        <div className="bg_fixed"></div>
      )}
    </>
  )
}

const SwipCalendar = props => {
  const { onClickEvent, callDate } = props;

  const [beforeDays, setBeforeDays] = useState(4);
  const [afterDays, setAfterDays] = useState(10);

  const calendarEl = useRef(null);
  const todayEl = useRef(null);

  const today = moment();
  const todayNum = today.clone().dayOfYear();

  useEffect(() =>{
    m_select_day();
    drag_scroll();
  })
  const m_select_day = ()=>{
    $(function(){
      $('.select_m').click(function(){
        $('.select_m').removeClass('active');
        $(this).toggleClass('active');
      })
    })
  }
  const drag_scroll = ()=>{
    var x,left,down;
    $("#slide_test2").mousedown(function(e){
      e.preventDefault();
      down = true;
      x = e.pageX;
      left = $(this).scrollLeft();
    });

    $("body").mousemove(function(e){
      if(down){
        var newX = e.pageX;
        $("#slide_test2").scrollLeft(left - newX + x);
      }
    });

    $("body").mouseup(function(e){down = false;});
  }

  // TODO 추후 DB로 변경할 경우 'YYYY-MM-DD-요일' format으로 검색해서 넣으면 됨
  const getdaysArr = () => {
    const arr = [];
    for (let num = (todayNum - beforeDays); num < (todayNum + afterDays); num++) {
      if (num === todayNum) {
        arr.push([...today.clone().dayOfYear(num).format('YYYY-MM-DD-ddd').split('-'), 'today']);
      } else {
        arr.push(today.clone().dayOfYear(num).format('YYYY-MM-DD-ddd').split('-'));
      }
    }
    return arr;
  }

  const transArr = arr => {
    let acc_index=-1;
    return arr.reduce((acc, cur, index, arr) => {
        if(index === 0 || cur[1] !== arr[index-1][1]) {
            acc_index++;
            acc.push({ym:(cur[0]+cur[1]),days:[]});
        }
        acc[acc_index].days.push(cur)
        return acc;
    },[])
  }

  const scrollFun = e => {

    // TODO padding이 양쪽으로 30px씩 있어서 600 빼주는 것
    const containerWidth = e.target.scrollWidth - 600;
    const currentScroll = e.target.scrollLeft;

    // TODO 이전날짜는 선택할 일이 없어서 로딩 생략
    // if (currentScroll === 10) {
    //   setBeforeDays(beforeDays + 10);
    // }
    if (currentScroll > (containerWidth-10)) {
      setAfterDays(afterDays + 10);
    }
  }

  const onClickToday = () => {
    const adjustWidth = calendarEl.current.offsetWidth / 2 - todayEl.current.offsetWidth+30;
    calendarEl.current.scrollTo({top:0, left:(todayEl.current.offsetLeft-adjustWidth), behavior:'smooth'});
  }

  const onClickDate = (date) => {
    onClickEvent(date);
  }

  return (
    <div className="counsel_modal_mid_inner">
      <div className="counsel_modal_inner_flex">
        <h2>희망하는 상담 날짜를 선택해주세요.</h2>
        <span onClick={onClickToday} className="today_before">오늘</span>
      </div>
      <div className="m_calendar_container">
        <div id="slide_test2" className="swiper-container" ref={calendarEl} onScroll={scrollFun}>
          <div className="swiper-wrapper">
            {transArr(getdaysArr()).map(i => (
              <div key={i.ym} className="select_y swiper-slide">
                <span>{i.ym.slice(4)}월</span> 
                {i.days.map(d => 
                  {return (d[4] && d[4] === "today" ? (
                  <div key={`${ d[0] }${ d[1] }${ d[2] }`}
                    className={cx({
                      "select_m": true,
                      "select_today": true,
                      "active": (callDate == `${ d[0] }${ d[1] }${ d[2] }`)
                    })}
                    ref={todayEl}
                    onClick={e=>onClickDate(`${ d[0] }${ d[1] }${ d[2] }`)}>
                      <div>
                        <small>{d[3]}</small>
                        <b>{d[2]}</b>
                      </div>
                    </div>
                    ) : (
                    <MonthOfDays key={`${ d[0] }${ d[1] }${ d[2] }`} day={d}
                      onClickEvent={onClickDate}
                      isSelected={callDate == `${ d[0] }${ d[1] }${ d[2] }`}
                    />
                  ))}
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <DateWrapper>선택한 날짜: <span>{(callDate||"").replace(/(\d{4})(\d{2})(\d{2})/g, '$1년 $2월 $3일')}</span></DateWrapper>
    </div>
  )
}

const MonthOfDays = props => {
  const { day, isSelected, onClickEvent } = props;
  return (
    <SelectDate className={cx({
      "select_m": true,
      "active": isSelected
    })}
      onClick={e => onClickEvent(`${ day[0] }${ day[1] }${ day[2] }`)} 
    >
        <div>
          <small>{day[3]}</small>
          <b>{day[2]}</b>
        </div>
      </SelectDate>
  )
}

const SelectDate = styled.div`

`;

const ContentWrapper = styled.div`
  max-height: 90px;
  overflow-y: auto;
`;

const CheckBox = styled.span`
    display: inline-block;
    width: 24px;
    height: 24px;

    background: ${props => props.isChecked ?
    `url("https://cdn.onoffevent.com/common/chk_on.svg")` : `url("https://cdn.onoffevent.com/common/chk.svg")` };

    background-repeat:no-repeat;
    cursor: pointer;
    vertical-align: text-bottom;
    margin-right: 5px;
`;

const DateWrapper = styled.div`
  color: #4e4b7b;
  margin-top: 10px;

  span {
    color: #666;
  }
`