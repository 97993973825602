import React, { useState, Fragment, useEffect, useContext} from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import ImgPhoto from "../../../../resources/console/images/icon/default-user.svg";
import btnMoreinfo from "../../../../resources/preReg/images/icon/btn-moreinfo.svg";
import User from "./User"
function UserList(props) {
  const { consoleCfg, setSessionMgrInfoModal } = props;
  return ( 
    <>
    {consoleCfg.managerList == null || consoleCfg?.managerList?.length <= 1 ? (
      <>
        <div className="slide_con slide_con_1">
          {consoleCfg?.managerList?.map((item, i) => (
            <User item={item} setSessionMgrInfoModal={setSessionMgrInfoModal} />
          ))}
        </div>
        <div className="m_slide_con">
          {consoleCfg?.managerList?.map((item, i) => (
            <User item={item} setSessionMgrInfoModal={setSessionMgrInfoModal} />
          ))}
        </div>
      </>
    ) : consoleCfg?.managerList?.length <= 4 ? (
      <>
        <div className="slide_con_flex">
          {consoleCfg?.managerList?.map((item, i) => (
            <User item={item} setSessionMgrInfoModal={setSessionMgrInfoModal} />
          ))}
        </div>
        <div className="m_slide_con">
          <Swiper
            slidesPerView={`auto`}
            spaceBetween={40}
            observer={true}
            observeParents={true}
          >
            {consoleCfg?.managerList?.map((item, i) => (
              <SwiperSlide key={i}>
                <User item={item} setSessionMgrInfoModal={setSessionMgrInfoModal}/>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </>
    ) : (
      <>
        <div className="slide_con">
          <Swiper
            slidesPerView={4}
            observer={true}
            observeParents={true}
            pagination={{ clickable: true }}
          >
            {consoleCfg?.managerList?.map((item, i) => (
              <SwiperSlide key={i}>
                <User item={item} setSessionMgrInfoModal={setSessionMgrInfoModal}/>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="m_slide_con">
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={40}
            observer={true}
            observeParents={true}
          >
            {consoleCfg?.managerList?.map((item, i) => (
              <SwiperSlide key={i}>
                <User item={item} setSessionMgrInfoModal={setSessionMgrInfoModal}/>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </>
    )}
    </>
  )
}

export default UserList;