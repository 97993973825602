import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Stack, Grid, Box } from '@mui/material';
import moment from "moment";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useStore from '../../../../../modules/utils/store';
import closeImg from '../../../../../resources/images/campaign/modal_close.svg'
import Input, { Checkbox, Hint, Radio, TextArea } from "../../../TypeA/component/UI/Input"
import { MuiSelects } from "../../../TypeA/component/UI/CampaignSelect";
import { _API, _CK, _U } from "../../../../../modules/utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    width: "100%",
    maxWidth: "900px",
    borderRadius: "8px",
    backgroundColor: "#fff"
  },
  '.MuiDialogTitle-root': {
    padding: "0px",
    textAlign: "right"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2.5),
    borderTop: "0px",
    borderBottom: "1px solid #eee",
  },
  '& .MuiDialogContent-root h3': {
    fontSize: "22px",
    fontWeight: "bold"
  },
  '& .MuiDialogContent-root h4': {
    fontWeight: "bold"
  },
  '& .MuiDialogContent-root b': {
    fontSize: "30px",
    fontWeight: "bold",
    wordBreak: "keep-all"
  },
  '& .MuiDialogContent-root .MuiBox-root': {
    background: "#f7f7fa",
    borderRadius: "6px",
    padding: "20px"
  },

  '& .MuiDialogActions-root': {
    padding: theme.spacing(2.5),
  },
  '.MuiButton-root': {
    backgroundColor: "#6a6af8",
    color: "#fff",
    borderRadius: "4px",
    lineHeight: "40px",
    minWidth: "100px",
    height: "40px",
    fontSize: "15px"
  },
  '.MuiButton-root:hover': {
    backgroundColor: "#6a6af8",
  },
  '.subtitleText': {
    width: "160px",
    wordWrap: "break-word",
    wordBreak: "keep-all"
  },
  '.subtitleDesc:before': {
    content: "''",
    display: "inline-block",
    width: "1px",
    height: "12px",
    background: "#efefef",
    margin: "0 16px",
  },
  '.cancel_btn': {
    display: "block",
    padding: "8px 16px",
    borderRadius: "4px",
    backgroundColor: "#EBEBF8"
  },
  '.modal_btn': {
    display: "block",
    padding: "8px 16px",
    borderRadius: "4px",
    backgroundColor: "#5959EF"
  },
  '.cont_left_inner4 > div > h6': {
    padding: "8px 0",
    borderBottom: "1px solid #D9D9D9",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  '.cont_left_inner4 .section_box:first-child':{paddingTop:"24px"},
  '@media (max-width:767px)': {
    '.MuiPaper-root': {
      borderRadius: "0px",
    },
    '.subtitleDesc:before': {
      content: 'none',
    },
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            padding: "0px"
          }}
        >
          <img src={closeImg} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PreRegisterDialog(props) {
  const { open, setOpen, myPreRegInfoList, saveList, preregCompName, selectConnectPath, eventList, campaignIdx, mgrName, siteEventLabel, eventLabel, sessionLabel, progressStandard } = props;
  const { savePreregName, setSavePreregName, savePreregMobile, setSavePreregMobile, savePreregEmail, setSavePreregEmail } = useStore();
  const [step, setStep] = useState(1);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [choiceHospitalInputIdx, setChoiceHospitalInputIdx] = useState(null);
  const [saveStatus, setSaveStatus] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
* 섹션, 입력항목 Component
* @param {*} props {item, onDataChange}
* @returns 
*/

  const ChildInput = (props) => {
    const { item,className } = props;

    const [editInput, setEditInput] = useState({});
    console.log(item);
    return (
      <Stack direction="column" sx={{ gap: `${item.name=="기본정보"?"8px":"24px"}` }}>
        {item?.children?.filter((child) =>
          child?.inputType == 'SECTION'
          || (
            child?.useYn == 'Y'
            ||
            child?.isAlwaysShow
            && child?.onlyHost != 'Y'
            && (
              child?.defaultYn == 'Y'
              || (eventList?.filter((e) => e.offlineChecked)?.length > 0 && ['OFF', 'ONOFF'].includes(child?.onOffUse))
              || (eventList?.filter((e) => e.onlineChecked)?.length > 0 && ['ON', 'ONOFF'].includes(child?.onOffUse))
            )
          )
        )?.map((child, i) => {
          return (
              child?.inputType == 'SECTION' ? (
                <Stack className="section_box" key={`${child?.idx}_${i}`} sx={{ gap: "24px", margin: i == item?.children?.length - 1 ? '0px' : '24px 0' }}>
                  <Stack sx={{ gap: "8px" }}>
                    <Typography component="h5" variant="h7" sx={{ display: 'flex', justifyContent: "space-between" }}>
                      {child?.name}
                    </Typography>
                    {child?.description && (
                      <Typography component="p" variant="p" sx={{ paddingBottom: "8px", marginBottom: "24px" }}>{child?.description}</Typography>
                    )}
                  </Stack>
                  {item?.children.length > 0 && (
                    <ChildInput item={child} />
                  )}
                </Stack>
            ) : (
              <div style={{ display: "flex", flexDirection: `${fullScreen ? "column" : "row"}`, width: `100%` }}>
                {(child?.fieldName == 'MANAGER' && ['MANAGER_SELECT','MANAGER_TEXT'].includes(child?.inputType)) ? (
                    <>
                    <Typography component="h4" variant="h4" className="subtitleText" sx={{ padding: `${fullScreen ? "0px" : "7px 0"}`, marginBottom: `${fullScreen ? "8px" : "0"}` }}>{child?.name}</Typography>
                    <Typography component="p" variant="h8" className="subtitleDesc" sx={{ padding: "5px 0" }}>{mgrName ? mgrName : child?.value}</Typography>
                  </>
                  ) :
                ['TEXT', 'EMAIL', 'MOBILE'].includes(child?.inputType) ? (
                  <>
                    <Typography component="h4" variant="h4" className="subtitleText" sx={{ padding: `${fullScreen ? "0px" : "7px 0"}`, marginBottom: `${fullScreen ? "8px" : "0"}` }}>{child?.name}</Typography>
                    <Typography component="p" variant="h8" className="subtitleDesc" sx={{ padding: "5px 0" }}>{child?.value}</Typography>
                  </>
                ) : child?.inputType == 'TEXTAREA' ? (
                  <>
                    <Typography component="h4" variant="h4" className="subtitleText" sx={{ padding: `${fullScreen ? "0px" : "7px 0"}`, marginBottom: `${fullScreen ? "8px" : "0"}` }}>{child?.name}</Typography>
                    <Typography component="p" variant="h8" className="subtitleDesc" sx={{ padding: "5px 0",display:"flex", alignItems:"center" }}>
                      <pre style={{color:" #646464",fontSize: "15px",fontWeight: "400", lineHeight: "22px"}}  dangerouslySetInnerHTML={{ __html: child?.value }} />
                    </Typography>
                  </>
                ) : child?.inputType == 'CHECKBOX' ? (
                  <>
                    <Typography component="h4" variant="h4" className="subtitleText" sx={{ padding: `${fullScreen ? "0px" : "7px 0"}`, marginBottom: `${fullScreen ? "8px" : "0"}` }}>{child?.name}</Typography>
                    <Typography component="p" variant="h8" className="subtitleDesc" sx={{ padding: "5px 0" }}>
                    {/*   {child?.opts?.filter((opt) => opt.checked == true && opt?.isAllCheck == false)?.map((opt, index, array) => {
                        if (array.length === 1 || index === array.length - 1) {
                          return opt.label;
                        } else {
                          return opt.label + ', ';
                        }
                      })} */}
                              {child?.opts?.filter((opt) => opt.checked == true)?.map((opt, index, array) => {
                                if (!opt?.isAllCheck) {
                                  if (array.length === 1 || index === array.length - 1) {
                                    return opt.label;
                                  } else {
                                    return opt.label + ', ';
                                  }
                                } else {
                                  opt.label;
                                }
                              })}
                    </Typography>
                  </>
                ) : child?.inputType == 'RADIO' ? (
                  <>
                    <Typography component="h4" variant="h4" className="subtitleText" sx={{ padding: `${fullScreen ? "0px" : "7px 0"}`, marginBottom: `${fullScreen ? "8px" : "0"}` }}>{child?.name}</Typography>
                    <Typography component="p" variant="h8" className="subtitleDesc" sx={{ padding: "5px 0" }}>
                      {child?.opts?.filter((opt) => opt.checked == true)?.map((opt) => opt.label)}
                    </Typography>
                  </>
                ) : child?.inputType == 'SELECT' ? (
                  <>
                    <Typography component="h4" variant="h4" className="subtitleText" sx={{ padding: `${fullScreen ? "0px" : "7px 0"}`, marginBottom: `${fullScreen ? "8px" : "0"}` }}>{child?.name}</Typography>
                    <Typography component="p" variant="h8" className="subtitleDesc" sx={{ padding: "5px 0" }}>
                      {child?.opts?.filter((opt) => opt.value == child?.value)?.map((opt) => opt.label)}
                    </Typography>
                  </>
                ) : child?.inputType == 'HOSPITAL' ? (
                  <>
                    <Typography component="h4" variant="h4" className="subtitleText" sx={{ padding: `${fullScreen ? "0px" : "7px 0"}`, marginBottom: `${fullScreen ? "8px" : "0"}` }}>{child?.name}</Typography>
                    <Typography component="p" variant="h8" className="subtitleDesc" sx={{ padding: "5px 0" }}>{child?.value}</Typography>
                  </>
                ) : (child?.inputType == 'TERMS' && (eventList?.filter((e) => e.offlineChecked)?.length > 0 && ['OFF', 'ONOFF'].includes(child?.onOffUse))
                || (eventList?.filter((e) => e.onlineChecked)?.length > 0 && ['ON', 'ONOFF'].includes(child?.onOffUse)) || child?.isAlwaysShow) ? (
                  // <Stack direction="row" alignItems="flex-start" sx={{ gap: "16px", paddingTop: "8px" }}>
                    <>
                  <Typography component="h4" variant="h4" className="subtitleText" sx={{ padding: `${fullScreen ? "0px" : "7px 0"}`, marginBottom: `${fullScreen ? "8px" : "0"}` }}>{child?.name}</Typography>
                  <Typography component="p" variant="h8" className="subtitleDesc" sx={{ padding: "5px 0" }}>{child?.checked == true ? 'Agree' : 'DisAgree'}</Typography>
                  {/*   <Box>
                      <Box>
                        <Box sx={{ padding: "12px", background: "#F7F7FA", borderRadius: "4px", margin: "8px 0 8px auto", height: "130px", overflowY: "auto" }}>
                          <div dangerouslySetInnerHTML={{ __html: child?.termsContents }} /> 
                        </Box>
                        <Typography component="p" variant="p" sx={{ marginBottom: "4px" }}>{child?.termsQuestion}</Typography> 
                        <Checkbox
                          items={[{ label: '동의함', value: true, checked: child?.value }]}
                          onChange={(e) => {
                            handleChange(child?.idx, e?.target?.checked);
                          }}
                          height="40px"
                        /> 
                      </Box>
                    </Box> */}
                    </>
                  // </Stack>
                ) 
                  : (
                  <></>
                )}
              </div>
            )
          )
        })}
      </Stack>

    )
  }

  const saveBefore = (e) => {

    if (e) e.preventDefault();

    setSaveStatus(true);
    
  };

  const onSave = async (e) => {

    const req = {
      path: "/v1/campaign/reg/single/save",
      data: {
        campaignIdx: campaignIdx,
        inputs: [...myPreRegInfoList],
        eventList: eventList.filter((e) => e.onlineChecked == true || e.offlineChecked),
        regStatus : 'C',
        noAuthorCheck: true,
        progressStandard: progressStandard

      },
    };

    await _API.post(req).then((res) => {

      const { data } = res;

      if (data) {
        setStep(2);
      }

    });

  };

  /**
 * 입력항목 입력
 * @param {*} newData 
 */
  const onDataChange = (newData) => {
    // 변경된 데이터로 상태 업데이트
    const { item } = findItemByIndex(myPreRegInfoList, newData?.idx);
    Object.assign(item, newData);
  }

  const findItemByIndex = (array, targetIdx, parent = null) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].idx == targetIdx) {
        return { item: array[i], parent: parent };
      }
      else if (array[i]?.children) {
        const result = findItemByIndex(array[i].children, targetIdx, array[i]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  const handleClose = () => {
    setOpen(false);
    setSavePreregName(null)
    setSavePreregMobile(null)
    setSavePreregEmail(null)
    // window.location.reload();
  };

  useEffect(() => {
    if (saveStatus) onSave();
  }, [saveStatus]);
  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen={fullScreen}
    >
      <div style={{display:"flex",alignItems:"center", justifyContent:"space-between", padding: `${fullScreen ? "20px 20px 8px" : "20px 30px 8px"}`}}>
        <Typography component="h2" variant="event_h2">{ step==1?'Confirm Registration Details':'Registration Complete'}</Typography>
        <button><img src={closeImg} onClick={handleClose} style={{display:"block"}} /></button>
      </div>
      {step == 1 ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "60px", flex: "1 1 auto", overflowY: "auto",padding: `${fullScreen ? "0px 20px 0" : "00px 30px 0"}` }}>
          <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "12px", marginTop:"50px" }}>
            <Typography component="h2" variant="title" sx={{textAlign: "center", fontSize:`${fullScreen ? "20px" : "26px"}`,lineHeight:`${fullScreen ? "26px" : "35.41px"}`,fontWeight:`${fullScreen ? "700" : "900"}`,wordBreak: "keep-all", wordWrap: "break-word"}}>Please review your registration details.</Typography>
            <Typography component="p" variant="desc" sx={{textAlign:"center",wordBreak: "keep-all", wordWrap: "break-word"}}>We have confirmed your registration details as follows. Please review the information and complete your registration.</Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
            <div style = {{ display: "flex", flexDirection: "column", gap: "24px" }}>
              {eventList.filter((e) => e.onlineChecked == true || e.offlineChecked).length > 0 && (
                <Typography component="h3" variant="h3" sx={{ padding: "8px 0", borderBottom: "1px solid #d9d9d9" }}>{progressStandard == 'EVENT' ? eventLabel : sessionLabel}</Typography>
              )}
              {eventList.filter((e) => e.onlineChecked == true || e.offlineChecked)?.map((item, i) => {
                return (
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", gap: "9px",paddingBottom:"24px", borderBottom:"1px solid #efefef" }}>
                      <div style={{ display: "flex", flexDirection: "column", gap: "4px", width: "100%" }}>
                        <Typography component="h5" variant="h5">{item.eventName}</Typography>
                        <Typography component="span" variant="span">
                          {item?.startDate && moment(item.startDate).format("YYYY.MM.DD(ddd) HH:mm")}{" "}
                          {item?.endDate && ` ~ `}
                          {item?.endDate &&
                            (moment(item?.startDate).format("YYYY.MM.DD(ddd)") ===
                              moment(item?.endDate).format("YYYY.MM.DD(ddd)")
                              ? moment(item?.endDate).format("HH:mm")
                              : moment(item?.endDate).format("YYYY.MM.DD(ddd) HH:mm"))}
                          {(item?.startDate && item?.endDate) && ' KST'}
                        </Typography>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                        <Typography component="span" variant="span">
                          {(item.onlineChecked && item.offlineChecked) ? 'OnOffLine Attend' :
                            item.onlineChecked ? 'OnLine Attend' :
                              item.offlineChecked ? 'OffLine Attend' : ''
                          }
                        </Typography>
                      </div>
                  </div>
                );
              })}
            </div>
            {myPreRegInfoList?.filter((item) =>
              item?.children?.filter((c) => c?.onlyHost != 'Y')?.length > 0
              && item?.onlyHost != 'Y'
              ||
              (item?.isTermsGroup == 'Y' && item?.children?.length > 0)
              && (
                item?.defaultYn == 'Y'
                || (eventList?.filter((e) => e.offlineChecked)?.length > 0 && ['OFF', 'ONOFF'].includes(item?.onOffUse))
                || (eventList?.filter((e) => e.onlineChecked)?.length > 0 && ['ON', 'ONOFF'].includes(item?.onOffUse))
              )
            )?.map((item, index) => {
              return (
                <div className="cont_left_inner cont_left_inner4" style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                  <Stack sx={{ gap: "12px" }}>
                    <Typography component="h6" variant="h6" sx={{padding:"8px 0"}}>{item?.name}</Typography>
                    {item?.description && (
                      <Typography component="p" variant="p" sx={{ marginBottom: "12px" }}>{item?.description}</Typography>
                    )}
                  </Stack>
                  <ChildInput item={item} />
                </div>
              )
            })}

          </div>
        </div>
      ) : step == 2 && (
        <div style={{ display: "flex", flexDirection: "column", gap: "60px", flex: "1 1 auto", overflowY: "auto",padding:`${fullScreen ? "0px 20px 20px" : "0px 30px 20px"}` }}>
          <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "12px",marginTop:"50px" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
                <Typography component="h2" variant="title" sx={{ textAlign: "center",fontSize:`${fullScreen ? "20px" : "26px"}`,lineHeight:`${fullScreen ? "26px" : "35.41px"}`,fontWeight:`${fullScreen ? "700" : "900"}`,wordBreak: "keep-all", wordWrap: "break-word"}}>Your registration has been completed.</Typography>
              {/* <Typography component="h3" variant="subtext9">#21544874</Typography> */}
            </div>
            <Typography component="p" variant="desc" sx={{ textAlign: "center",wordBreak: "keep-all", wordWrap: "break-word" }}>
              Your registration has been successfully processed.<br /><br />
              Detailed information about the schedule and participation methods will be sent to<br />
              your registered email or mobile number.<br />
              If you have any additional questions, please contact the person in charge.<br />
              {/* 참가신청해 주셔서 감사드립니다.<br />
              총 {eventList.filter((e) => e.onlineChecked == true || e.offlineChecked).length}개의 {siteEventLabel ? siteEventLabel : '이벤트'}에 참가신청이 완료되었습니다. */}
            </Typography>
          </div>
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: `${step==1? "space-between" : step==2 && "flex-end"}` ,padding:`${fullScreen ? "20px 20px" : "30px 20px"}` }}>
        {step == 1 ? (
          <>
            <button className="cancel_btn" onClick={handleClose}>
              <Typography component="span" variant="button" sx={{ color: "#464f60", textTransform: 'none' }}>Cancel</Typography>
            </button>
            <button className="modal_btn" onClick={(e) => saveBefore(e)}>
              <Typography component="span" variant="button" sx={{ color: "#fff", textTransform: 'none' }}>Submit</Typography>
            </button>
          </>
        ): step == 2 && (
          <button className="modal_btn" onClick={() => window.location.reload()}>
            <Typography component="span" variant="button" sx={{ color: "#fff", textTransform: 'none' }}>Complete</Typography>
          </button>
        )}
      </div>
    </BootstrapDialog>
  );
}
