import React, { Fragment, useContext, useEffect, useState } from 'react';
import Marquee from "react-fast-marquee";
import Player from "./Player";
import Banner from "./Banner"
import Guide from "./Guide";
import User from "./User";
import Files from "./Files";
import QNA from "./Qna";
import Chat from "./Chatting";
import Notice from "./Notice";
import cx from "classnames";
import moment from "moment";
import playerDefaultImg2 from "../../../../resources/console/images/jpg/player-default-img2.png";
import SessionMore from "../../../../resources/console/images/icon/btn_sessionmore.svg";
import SessionMore_open from "../../../../resources/console/images/icon/btn_sessionmore_open.svg";
import backBtn from "../../../../resources/console/images/icon/Type-B/back_btn.svg";
import ImgPhoto from "../../../../resources/console/images/icon/default-user.svg";

import { AppContext } from '../../../../components/AppProvider';
import parse, { domToReact } from 'html-react-parser'
import useStore from '../../../../modules/utils/store';
import { _API, _U } from '../../../../modules/utils';

export default (props) => {
  const { iam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  const { consoleCfg, setSessionMgrInfoModal, setIntroduce , sessionicon, setSessionicon, setSurveyInfo, attendanceYn,attendanceState,setAttendModal,sessionAttendance } = props;
  const [clickVideoTime, setClickVideoTime] = useState(0);
  const [player, setPlayer] = useState(null);
  const [toggle, setToggle] = useState(false);

  
  const timeCalculation = (text) => {
    if (consoleCfg.serviceType == "ONDEMAND") {
      let resultText = text;
      let result = null;
      let result2 = null;
      if (text != "" && text != null) {
        if (text.match(/(0[0-9]|1[0-9]|2[0-4]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])/g)) {
          result = text.match(/(0[0-9]|1[0-9]|2[0-4]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])/g);
          if (result != null) {
            for (let i = 0; i < result.length; i++) {
              let ele = result[i];
              let regex = /[0-9]/g;
              let found = ele.match(regex);
      
              // 시
              let hour = Number(found[0] + "" + found[1]) * 60 * 60;
              // 분
              let min = Number(found[2] + "" + found[3]) * 60;
              // 초
              let sec = Number(found[4] + "" + found[5]);
              let total = hour + min + sec
              resultText = resultText.replace(result[i], "<a onclick='" + total + "'>" + result[i] + "</a>");
            }
          }
          text = text.replace(/(0[0-9]|1[0-9]|2[0-4]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])/g, "");
        }
        if (text.match(/(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])/g)) {
          result2 = text.match(/(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])/g);
          if (result2 != null) {
            for (let i = 0; i < result2.length; i++) {
              let ele = result2[i];
              let regex = /[0-9]/g;
              let found = ele.match(regex);
      
              // 분
              let min = Number(found[0] + "" + found[1]) * 60;
              // 초
              let sec = Number(found[2] + "" + found[3]);
              let total = min + sec
              
              resultText = resultText.replace(result2[i], "<a onclick='" + total + "'>" + result2[i] + "</a>")
            }
          }
        }
      }
      return (
        <div>
          {parse(resultText, {
            replace: domNode => {
              if (domNode.name === 'a') {//버튼 이벤트 처리
                let sFuncName = domNode.attribs.onclick;
                delete domNode.attribs.onclick;

                return (
                  <a style={{ cursor: "pointer", color: "#6a6af8" }} onClick={
                    () => { Function('"use strict";return (' + setClickVideoTime(sFuncName) + ')')(); }} >{domToReact(domNode.children, {})}</a>
                );
              }
            }
          })}
        </div>
      )
    } else {
      return (
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      )
    }
  };

  const movePage = (e, url) => {
    if (e) e.preventDefault();

    if (['MEDIGATE', 'ICOD'].includes(iam?.targetType)) {
      return;
    }
    
    if (window.confirm('시청을 중단하시겠습니까?')) {
      window.location.href = url;
    }
  }
  const toggleClick = (name) => {
    if (name == toggle) {
      setToggle("")
    } else {
      setToggle(name);
    }
  }
  useEffect(() => {
    if (player != null) {
      player.seek(clickVideoTime);
    }
  }, [clickVideoTime])
  const [time, setTime] = useState(60);
  const [totalTime] = useState(60);

  useEffect(() => {
    const timer = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      } else {
        clearInterval(timer);
        // 타이머가 종료될 때 실행할 코드 추가
      }
    }, 1000); // 1초마다 감소

    return () => {
      clearInterval(timer); // 컴포넌트가 언마운트될 때 타이머 정리
    };
  }, [time]);

  const progress = ((totalTime - time) / totalTime) * 100;
  //SOCKET RECIEVE
  const onReceivedMessage = (e) => {
    _U.parseMessage(e).then((data) => {
        switch (data.code) {
            case "SOCKET-RECEIVED":
                return data.data ? JSON.parse(data.data) : null;
        }
    }).then((data) => {
        if(data?.mode == 'ATTEND_EXIT_POPUP'){
          const req = {
            path: "/v1/client/session/attendance/status",
            data: {
              sessionIdx: consoleCfg.sessionIdx,
              preregIdx: iam?.preregIdx
            },
          };
      
          _API.post(req).then((res) => {
            let attendData = res.data;
            if(attendData?.entryTime != "" && attendData?.entryTime != null && consoleCfg?.exitPopupUseYn == 'Y') {
              const attendModalOpen = data.value == "Y" ? true : false;
              setAttendModal(attendModalOpen)
            }
          })
        }
    });
  };
  useEffect(() => {
    window.addEventListener("message", onReceivedMessage);
    return () => {
        window.removeEventListener("message", onReceivedMessage);
    };
  }, []);

  return (
    <Fragment>
        <div className="detail_con">
          {attendanceYn && (
            <div class="attend_box"
              style={{background:`${attendanceState? "#12A884" :"#F00"}`}}
            >
              <div className="marquee_box">
                <Marquee className="marquee" speed={70}>
                {attendanceState ? (consoleCfg?.attendConsoleText ? consoleCfg?.attendConsoleText : '현재 출석 중입니다. 퇴장 시 반드시 오른쪽 퇴장하기를 눌러 퇴장 진행 부탁 드립니다.') : (consoleCfg?.exitConsoleText ? consoleCfg?.exitConsoleText : '현재 출석 중이 아닙니다. 출석 처리를 위해 반드시 오른쪽 출석하기를 눌러 출석 진행 부탁 드립니다.')}
                </Marquee>
                <span style={{ background: `${attendanceState ? "linear-gradient(to right,rgba(18,168,132,0.00) 0%,#12A884 100%)" : "linear-gradient(to right,rgba(18,168,132,0.00) 0%,#F00 100%)"}` }}></span>
              </div>
              <button onClick={() => { setAttendModal(true) }}>{attendanceState ? (consoleCfg?.exitPopupUseYn === 'Y' ? "퇴장하기" : '') : "출석하기"}</button>
            </div>
          )}
        <div className="detail_wrap"
          style={{height:`${attendanceYn?"calc(100% - 26px)":"100%"}`}}
        >
            <div className="detail_wrap_con"
              style={{
                backgroundColor: `${consoleCfg?.consoleBgColor != null && consoleCfg?.consoleBgColor != null ? consoleCfg?.consoleBgColor : '#D3D3D3'}`,
                top: `${attendanceYn ? "82px" : "56px"}`
              }}
            >
          
              <div className="video_con">
                {consoleCfg?.signedUrlConsolePlayerImg ? (
                  <div
                    className="video_pre_con"
                    style={{
                      backgroundImage: `url(${consoleCfg?.signedUrlConsolePlayerImg})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "contain",
                    }}
                  ></div>
                ) : (
                  <div
                    className="video_pre_con"
                    style={{
                      backgroundImage: `url(${playerDefaultImg2})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "contain",
                    }}
                  ></div>
                )}
                <div className="video_element">
                  <Player
                    consoleCfg={consoleCfg}
                    setPlayer={setPlayer}
                    player={player}
                    clickVideoTime={clickVideoTime}
                    setSurveyInfo={setSurveyInfo}
                  />
                </div>
                <div className="video_info">
                  <h4> <span>{consoleCfg?.eventName}</span></h4>
                  <p>{consoleCfg?.sessionName}</p>
                </div>
              </div>
            </div>
            <div className="video_dummy"></div>
            <div className="detail_flex_box card-title-position">
              <div className={cx({ session_name_con: true, on: toggle == "session" })}>
                <div className="session_name_con_header">
                  <h4>{consoleCfg.sessionName}</h4>
                  <img src={backBtn} alt="접기 펼치기" onClick={() => { toggleClick("session") }} />
                </div>
                {toggle == "session" && (
                  <div className="session_name_fullcontent">
                    <div className="event_name_con">
                      <a style={{ display: "block", cursor: "pointer", paddingTop: "4px" }} onClick={(e) => movePage(e, `/#/event/${consoleCfg?.eventIdx}/register`)}>
                        <h4>{consoleCfg.eventName}</h4>
                      </a>
                      <img src={backBtn} alt="접기 펼치기" onClick={() => { toggleClick("session") }} />
                    </div>
                    <div className="session_name_con_header">
                      <h4>{consoleCfg.sessionName}</h4>
                      <div className="service_type_box_div">
                        <span className="live_time">
                          {consoleCfg?.serviceType === "LIVE" ? (`${moment(consoleCfg?.startDate).format("HH:mm")} ~ ${moment(consoleCfg?.endDate).format("HH:mm")}`)
                            : consoleCfg?.serviceType === "ONDEMAND" && (`${moment(consoleCfg?.startDate).format("MM.DD(ddd) HH:mm")} ~ ${moment(consoleCfg?.endDate).format("MM.DD(ddd) HH:mm")}`)
                          }
                        </span>
                        {consoleCfg?.serviceType === "LIVE" && (
                          <span className="livebtn">
                            <small>LIVE</small>
                          </span>
                        )}
                        {consoleCfg?.serviceType === "ONDEMAND" && (
                          <span className="ondemandbtn">
                            <small>ON DEMAND</small>
                          </span>
                        )}
                      </div>
                    </div>
                    <p>{consoleCfg?.sessionDescription}</p>
                  </div>
                )}
              </div>
              <div className={cx({ notice_con: true, on: toggle == "notice" })} >
                <Notice
                  consoleCfg={consoleCfg}
                  timeCalculation={timeCalculation}
                />
                {/* <h4>공지<img src={backBtn} alt="공지글 수정" /></h4>          
                  <img src={backBtn} alt="접기 펼치기" onClick={ ()=>{toggleClick("notice")}}/> */}
              </div>

              <div className="m_list_wrapper">
                <ul>
                  {consoleCfg?.consoleQuestionYn === "Y" && (
                    <li onClick={() => handleTabClick(1)}>
                      <span>질문</span>
                    </li>
                  )}
                  {consoleCfg.managerList != null && (
                    <li onClick={() => handleTabClick(2)}>
                      <span>발표자</span>
                    </li>
                  )}
                  {consoleCfg.consoleFiledownYn === "Y" && (
                    <li onClick={() => handleTabClick(3)}>
                      <span>파일</span>
                    </li>
                  )}
                  <li onClick={() => handleTabClick(4)}>
                    <span>배너</span>
                  </li>
                  {consoleCfg.consoleChattingYn === "Y" && (
                    <li onClick={() => handleTabClick(5)}>
                      <span>채팅</span>
                    </li>
                  )}
                </ul>
              </div>
              <div id="scrollableDiv" className="detail_tab_box">
                {/* Q&A */}
                {activeTab === 1 && (
                  consoleCfg.consoleQuestionYn === "Y" && (
                    <div className="qna_container_box">
                      <QNA consoleCfg={consoleCfg} />
                    </div>
                  ))}
                {/* 발표자 */}
                {activeTab === 2 && (
                  consoleCfg.managerList != null && (
                    <div className="detail_left_box_top">
                      <User consoleCfg={consoleCfg} setSessionMgrInfoModal={setSessionMgrInfoModal} />
                    </div>
                  )
                )}
                {/* 첨부파일 */}
                {activeTab === 3 && (
                  consoleCfg.consoleFiledownYn === "Y" && (
                    <div className="detail_file_box">
                      <Files consoleCfg={consoleCfg} />
                    </div>
                    // <div className="detail_file_box">
                    //   <div className="detail_file_inner_box">
                    //     <ul>
                    //       <li className="detail_free_content">
                    //         <div style={{ marginBottom: "4px" }}>
                    //           <a target='_blank' href='https://webinars-143-upload.s3.ap-northeast-1.amazonaws.com/%5BKoSA%5D_HIV_ART_%E1%84%89%E1%85%B1%E1%86%B8%E1%84%80%E1%85%A6+%E1%84%87%E1%85%A2%E1%84%8B%E1%85%AE%E1%84%80%E1%85%B5_%E1%84%8E%E1%85%A9%E1%84%85%E1%85%A9%E1%86%A8%E1%84%8C%E1%85%B5%E1%86%B8.pdf' >
                    //             20240419 [KoSA] HIV ART 쉽게 배우기 (프로그램)_초록집
                    //           </a>
                    //         </div>
                    //       </li>
                    //     </ul>
                    //   </div>
                    // </div>
                  )
                )}
                {/* 배너 */}
                {activeTab === 4 && (
                  consoleCfg.consoleBannerYn === "Y" &&
                  consoleCfg.bannerFiles &&
                  consoleCfg.bannerFiles.length > 0 && (
                    <div className="detail_banner_box_con">
                      <Banner consoleCfg={consoleCfg} activeTab={activeTab} />
                    </div>
                  )
                )}
                {/* 채팅 */}
                {activeTab === 5 && (
                  consoleCfg.consoleChattingYn === "Y" && (
                    <div className="chat_container">
                      <Chat consoleCfg={consoleCfg} />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
    </Fragment>

  );
};




