import { default as ResetStyle } from './ResetStyle';
import { default as HeaderStyle } from './HeaderStyle';
import { default as SidebarStyle } from './SidebarStyle';
import { default as TypeBStyle } from './TypeBStyle';
import { default as CommonStyle } from './CommonStyle';
import { default as FooterStyle } from './FooterStyle';

const style = `
  ${ResetStyle}
  ${HeaderStyle}
  ${SidebarStyle}
  ${CommonStyle}
  ${FooterStyle}
  ${TypeBStyle}
`
export default style;