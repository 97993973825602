import React, { Fragment, useContext, useEffect, useState, useRef, useLayoutEffect } from "react";
import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import styled from "styled-components";
import cx from "classnames";
import { _CFG } from "../../../../modules/config";

import adminSurvey from "../../../../resources/console/images/icon/icon_admin_survey.svg";
import adminConsole from "../../../../resources/console/images/icon/icon_admin_console.svg";
import adminFmsMain from "../../../../resources/console/images/icon/icon_admin_fmsmain.svg";
import adminFmsBroad from "../../../../resources/console/images/icon/icon_admin_fmsbroad.svg";
import qnaSwitchCheck from "../../../../resources/console/images/icon/Type-B/qna_switch_check.svg";
import surveyArrow from "../../../../resources/console/images/icon/40.svg";
import consolePopupEdit from "../../../../resources/console/images/icon/survey_edit.svg";

import useStore from "../../../../modules/utils/store";

export default (props) => {
    const { 
        modalShow
        ,showPopup
        ,consoleCfg
        ,setSurveyPageNum
        ,showConnectUsersViewModal
        ,setShowConnectUsersViewModal
    } = props;
    
   
    return (
        <Fragment>
           <SideBar consoleCfg={consoleCfg} setSurveyPageNum={setSurveyPageNum} modalShow={modalShow} showPopup={showPopup} showConnectUsersViewModal={showConnectUsersViewModal} setShowConnectUsersViewModal={setShowConnectUsersViewModal} />
        </Fragment>
    );
};

const SideBar = (props) => {
    const { modalShow, showPopup, consoleCfg } = props;
    const { setSurveyPageNum, showConnectUsersViewModal, setShowConnectUsersViewModal } = props;
    const { siteInfo } = useStore();
    const { iam } = useContext(AppContext);
    
    const [ step,setStep ] = useState(0);
    const [ sessionInfo,setSessionInfo ] = useState({});
    const [ selectSurvey, setSelectSurvey ] = useState();
    const [ selectModal, setSelectModal ] = useState();

    const [ consoleOpenClose, setConsoleOpenClose ] = useState(false);
    const [ consoleOpenClose2, setConsoleOpenClose2 ] = useState(false);
    const [ leftMenu, setLeftMenu ] = useState('');
    const [ beforeSurvey, setBeforeSurvey ] = useState([]);
    const [ progressSurvey, setProgressSurvey ] = useState([]);
    const [ afterSurvey, setAfterSurvey ] = useState([]);
    const [ modalPopup, setModalPopup ] = useState([]);

    // 세션 참가 체크
    const [connectUserLength,setConnectUserLength] = useState(0);
    const [ checkedIdx, setCheckedIdx ] = useState(0);
    const [ checkedRealTimeIdx, setCheckedRealTimeIdx ] = useState(0);
    const [ checkedModalPopupIdx, setCheckedModalPopupIdx ] = useState(0);
    const [ isSendProc, setIsSendProc ] = useState(false);
    const [ exvalue, setExvalue ] = useState(consoleCfg?.exitPopupUseYn ?? 'N');
    const [ exitPopupOpenValue, setExitPopupOpenValue ] = useState(consoleCfg?.exitPopupOpenUseYn ?? 'N');
    
    const loadSueveys = () => {
        const req = {
            path: "/v1/event/session/surveys/lists",
            data: {
                eventIdx : consoleCfg.eventIdx,
                sessionIdx : consoleCfg.sessionIdx
            },
        };
         _API.post(req).then((res) => {
            setBeforeSurvey(res.data?.before)
            setProgressSurvey(res.data?.progress)
            setAfterSurvey(res.data?.after)
            if(res.data?.menuConn[0] != null){
                if(res.data?.menuConn[0].idx != null && res.data?.menuConn[0].idx > 0){
                    setCheckedIdx(res.data?.menuConn[0].idx)
                }
            }
        });
    }

    const loadModalPopup = () => {
        const req = {
            path: "/v1/event/session/surveys/modal/popup",
            data: {
                eventIdx : consoleCfg.eventIdx,
                sessionIdx : consoleCfg.sessionIdx
            },
        };
         _API.post(req).then((res) => {
            setModalPopup(res.data?.data)
        });
    }

    const loadSession = () => {
        const req = {
            path: "/v1/event/session/surveys/session",
            data: {
                sessionIdx : consoleCfg.sessionIdx
            },
        };
         _API.post(req).then((res) => {
            setSessionInfo(res.data?.session)
        });
    }
   
    const rightConsoleShow = (show) =>{
        setConsoleOpenClose(show);
      }
    const leftConsoleShow = (show) =>{
    setConsoleOpenClose2(show);
    }
    
    const backStep = ()=>{
        if(step ==1){
            setStep(0);
            setLeftMenu('');
        }else if(step==2){
            setStep(1);
        }
    }
    const onSetLeftMenu = (value) =>{
        setStep(1);
        setLeftMenu(value);
    }
    
    const onCheckChange = (e,item) => {
        const { name } = e.target;
        if (name == 'menuConnect') {
            setCheckedIdx(0)
            const check = e.target.checked
            const req = {
                path: "/v1/event/session/surveys/menuConnect",
                data: {
                    surveyIdx: item?.idx,
                    check: check,
                    eventIdx: consoleCfg.eventIdx,
                    sessionIdx: consoleCfg.sessionIdx,
                },
            };
            _API.post(req).then((res) => {
                if (res.data.result == "OK") {
                    setCheckedIdx(item.idx)
                    if (check) {
                        setCheckedIdx(item.idx)
                    } else {
                        setCheckedIdx(0)
                    }
                    onSend && onSend("SURVEY_DATA_MENU_CONN", item, check);
                }
            });
        } else if (name == 'surveyOpen') {
            setCheckedRealTimeIdx(0)
            const check = e.target.checked;
            if (check) {
                setCheckedRealTimeIdx(item.idx)
                onSend && onSend("SURVEY_OPEN", item);
            } else {
                setCheckedRealTimeIdx(0)
                onSend && onSend("SURVEY_CLOSE", item);
            }
        } else if (name == 'modalPopupOpen') {
            setCheckedModalPopupIdx(0)
            const check = e.target.checked;
            console.log(check)
            if (check) {
                setCheckedModalPopupIdx(item.idx)
                onSend && onSend("MODAL_POPUP_OPEN", item);
            } else {
                setCheckedModalPopupIdx(0)
                onSend && onSend("MODAL_POPUP_CLOSE", item);
            }
        } else if (name == 'exitPopup') { 
            const exitPopupUseYn = e.target.value === 'Y' ? 'N' : 'Y';
            setExvalue(exitPopupUseYn);
            onChangeExitPopupUseYn(exitPopupUseYn);
        } else if (name == 'exitPopupOpen') { 
            const exitPopupOpenUseYn = e.target.value === 'Y' ? 'N' : 'Y';
            setExitPopupOpenValue(exitPopupOpenUseYn);
            onChangeExitPopupOpenUseYn(exitPopupOpenUseYn);
        }         
    };

    const onChangeExitPopupUseYn = (exitPopupUseYn) => {
        const item = {exitPopupUseYn : exitPopupUseYn}
        const req = {
            path: "/v1/client/console/exitPopup/save",
            data: {
                eventIdx: consoleCfg?.eventIdx,
                sessionIdx: consoleCfg?.sessionIdx,
                modId: iam?.idx,
                exitPopupUseYn,
            }
        };
      
        _API.post(req).then((res) => {
            const { data, status } = res;
            if (status === 'SUCCESS') {
                alert("The exit popup settings have been saved.");
                onSend && onSend("EXIT_SETTING", item);
            } else {
                alert("An error occurred while saving the exit popup settings.");
            }
        });
    }

    const onChangeExitPopupOpenUseYn = (exitPopupOpenUseYn) => {
        const item = {value : exitPopupOpenUseYn}
        onSend && onSend("ATTEND_EXIT_POPUP", item);
    }

    const onSelectSurvey = (e, survey) => {
        var onT = $(".control_list li.on a span").text();
        $(".text_container_top h2").text(onT);
        $(".control_content_detail").hide();
        setSurveyPageNum(2);
        setSelectSurvey(survey);
        setStep(2);
    }

    const onSelectModal = (e, item) => {
        var onT = $(".control_list li.on a span").text();
        $(".text_container_top h2").text(onT);
        $(".control_content_detail").hide();
        setSelectModal(item);
        setStep(2)
    }

    //SOCKET SEND
    const onSend = (cate,more,checked) => {
        if (isSendProc) {
            return;
        }
        let param = {
            mode: cate,
            siteIdx: siteInfo?.siteIdx,
            eventIdx: consoleCfg?.eventIdx,
            sessionIdx: consoleCfg?.sessionIdx,
            consoleIdx: consoleCfg?.consoleIdx,
            name: iam?.name,
            memType: iam?.memType,
            companyName: iam?.companyName,
            deptName: iam?.deptName,
            memberIdx: iam?.idx,
            checked: checked,
        }
        if(more){
            param = {...param,...more};
        }
        if(cate){
            setIsSendProc(true);
            _U.postMessage("SOCKET-SEND", {
                action: cate,
                data: param,
            });
            setIsSendProc(false);
        }
    };
    //SOCKET RECIEVE
    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "SOCKET-RECEIVED":
                    return data.data ? JSON.parse(data.data) : null;
            }
        }).then((data) => {
            if(data?.mode == 'SURVEY_DATA_MENU_CONN'){
                if(data.checked){
                    setCheckedIdx(data?.idx)
                }else{
                    setCheckedIdx(0)
                }
            }
            if(data?.mode == 'SURVEY_OPEN'){
                setCheckedRealTimeIdx(data?.idx)
            }
            if(data?.mode == 'SURVEY_CLOSE'){
                setCheckedRealTimeIdx(0)
            }
            if(data?.mode == 'MODAL_POPUP_OPEN'){
                setCheckedModalPopupIdx(data?.idx)
            }
            if(data?.mode == 'MODAL_POPUP_CLOSE'){
                setCheckedModalPopupIdx(0)
            }
            if(data?.mode == 'CONN_DISCONN'){
                let uppercase = iam.memAuth.toUpperCase()
                if(uppercase == "ADMIN"){
                    setConnectUserLength(data?.data);
                }
            } 
            if(data?.mode == 'ATTEND_EXIT_POPUP'){
                setExitPopupOpenValue(data.value);
            }
            if(data?.mode == 'EXIT_SETTING'){
                setExvalue(data.exitPopupUseYn);
            }
        });
    };
    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    useEffect(() => {
        loadSession();
        loadSueveys();
        loadModalPopup();
    }, []);

    useEffect(() => {
        console.log('exvalue =', exvalue);
    }, [exvalue]);
    
    return (
        <Wrapper>
            <div 
                className={cx({ admin_console: true, left_console: true, on: !consoleOpenClose2 })}
                style={{zIndex : `${modalShow == true || showPopup == true || showConnectUsersViewModal == true ? "1056" : "999" }`}}
            >
                <div className="text_container_top">
                    <button id="firstB" onClick={(e) => leftConsoleShow(false)} style={{ background: `${step == 1 || step == 2 ? "#353539" : "#3b3b3f"}` }}>
                        <img src="https://cdn.onoffevent.com/common/btn-side-left.svg" />
                    </button>
                    {step == 1 || step == 2 ? (
                        <button id="prevB" onClick={(() => backStep())}>
                            <img src="https://cdn.onoffevent.com/common/btn_side_back.svg" />
                        </button>
                    ) : null}
                    <h2 style={{ paddingRight: `${step == 1 || step == 2 ? "0" : "50px"}` }}>
                        {(step == 1 && leftMenu == "SURVEY"|| step == 2 && leftMenu == "SURVEY") ?
                        "Survey"
                        : (step == 1 && leftMenu == "MODAL" || step == 2 && leftMenu == "MODAL") ?
                        "Console Popup"
                        : (step == 1 && leftMenu == "EXIT" || step == 2 && leftMenu == "EXIT") ?
                        "Exit Popup"
                        :"Control"
                        }
                    </h2>
                </div>
                <div className="text_container_content">
                    <ul className={cx({control_list :true , on :step==1 || step==2})}>
                        {consoleCfg?.consoleSurveyYn == 'Y' && (
                            <li className={cx({ on: "SURVEY" == leftMenu })} style={{borderBottom:`${(step != 1 && step != 2) ? "1px solid #1f1f24":"0"}`}}>
                                <a data-n="#control_content1"
                                    onClick={(e) => onSetLeftMenu("SURVEY")}
                                    target="_blank"
                                >
                                    <img
                                        src={adminSurvey}
                                        alt="Survey"
                                        title="Survey"
                                        style={{ opacity: `${leftMenu=="SURVEY" || (step != 1 && step != 2) ? "1":"0.3"}`}}
                                    />
                                    <span>Survey</span>
                                </a>
                            </li>
                        )}
                        {sessionInfo && sessionInfo.serviceType == "LIVE" && (
                            <>
                            <li className={cx({ on: "MODAL" == leftMenu })} style={{ borderBottom: `${(step != 1 && step != 2) ? "1px solid #1f1f24" : "0"}` }}>
                                <a data-n="#control_content1"
                                    onClick={(e) => onSetLeftMenu("MODAL")}
                                    target="_blank"
                                >
                                    <img
                                        src={adminConsole}
                                        alt="Console Popup"
                                        title="Console Popup"
                                        style={{ opacity: `${leftMenu=="MODAL" || (step != 1 && step != 2) ? "1":"0.3"}`}}
                                    />
                                    <span>Console Popup</span>
                                </a>
                            </li>
                            <li className={cx({ on: leftMenu == true })} style={{borderBottom:`${(step != 1 && step != 2) ? "1px solid #1f1f24":"0"}`}}>
                                <a data-n="#control_content1"
                                    href={`${_CFG.hubUrl()}/#/event/${consoleCfg.eventIdx}/fms?sessionIdx=${consoleCfg.sessionIdx}&au=${_CK.getAuth()}`}
                                    target="_blank"
                                >
                                    <img
                                    src={adminFmsMain}
                                    alt={`FMS Main`}
                                    title={`FMS Main`}
                                    style={{ opacity: `${(step != 1 && step != 2) ? "1":"0.3"}`}}
                                    />
                                    <span>FMS Main</span>
                                </a>
                            </li>
                            <li className={cx({ on: leftMenu == true })} style={{borderBottom:`${(step != 1 && step != 2) ? "1px solid #1f1f24":"0"}`}}>
                                <a data-n="#control_content1"
                                    href={`${_CFG.hubUrl()}/#/event/${consoleCfg.eventIdx}/fms/SendingFms?sessionIdx=${consoleCfg.sessionIdx}&au=${_CK.getAuth()}`}
                                    target="_blank"
                                >
                                    <img
                                    src={adminFmsBroad}
                                    alt={`Transmission FMS`}
                                    title={`Transmission FMS`}
                                    style={{ opacity: `${(step != 1 && step != 2) ? "1":"0.3"}`}}
                                    />
                                    <span>Transmission FMS</span>
                                </a>
                            </li>
                            {consoleCfg?.partiTime == 'E' && (
                                <li className={cx({ on: "EXIT" == leftMenu })} style={{ borderBottom: `${(step != 1 && step != 2) ? "1px solid #1f1f24" : "0"}` }}>
                                    <a data-n="#control_content1"
                                        onClick={(e) => onSetLeftMenu("EXIT")}
                                        target="_blank"
                                    >
                                        <img
                                        src={adminFmsBroad}
                                        alt={`Exit Popup`}
                                        title={`Exit Popup`}
                                        style={{ opacity: `${(step != 1 && step != 2) ? "1":"0.3"}`}}
                                        />
                                        <span>Exit Popup</span>
                                    </a>
                                </li>    
                            )}
                        </>
                        )}
                        
                    </ul>

                    {/* SURVEY */}
                    {(step == 1 && leftMenu == "SURVEY") &&
                        (<div id="control_content1" className={cx({control_content:true, none: step !== 1})}>
                            <Fragment>
                               {sessionInfo && sessionInfo.serviceType != "LIVE" && (
                                <>
                                    <div className="top_title">
                                        <span>Post Survey</span>
                                    </div>
                                    <ul className="control_content_list">
                                        {afterSurvey && afterSurvey.map((item, i) => {
                                            return (
                                                <li key={i}>
                                                    <a onClick={(e) => onSelectSurvey(e,item)}>
                                                        <span>{item.title}</span>
                                                    </a>
                                                    <div className="control_content_list_tool">
                                                        <div>
                                                            <span>Menu Connection</span>
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    name="menuConnect"
                                                                    className="menuConnect"
                                                                    onChange={(e) => { onCheckChange(e,item); }}
                                                                    checked={checkedIdx === item.idx ? true : false}
                                                                />
                                                                <span>
                                                                    <img src={qnaSwitchCheck} alt="check"/>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        {sessionInfo && sessionInfo.serviceType == "LIVE" && (
                                                            <div className="on_off_exporsure">
                                                                <span>Real-time transmission</span>
                                                                <strong className={cx({off:item?.surveyOpen != 'Y'}) } >{item?.surveyOpen == 'Y' ? 'ON' : 'OFF'}</strong>
                                                            </div>
                                                        )}
                                                    </div>
                                                </li>
                                            )        
                                        })}
                                    </ul>
                                </>
                                )}
                                {sessionInfo && sessionInfo.serviceType == "LIVE" && (
                                    <>
                                    <div className="top_title">
                                        <span>Progress Survey</span>
                                    </div>
                                    <ul className="control_content_list">
                                        {progressSurvey && progressSurvey.map((item, i) => {
                                            return (
                                                <li key={i}>
                                                    <a onClick={(e) => onSelectSurvey(e,item)}>
                                                        <span>{item.title}</span>
                                                    </a>
                                                    <div className="control_content_list_tool">
                                                        <div>
                                                            <span>Menu Connection</span>
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    name="menuConnect"
                                                                    className="menuConnect"
                                                                    onChange={(e) => { onCheckChange(e,item); }}
                                                                    checked={checkedIdx === item.idx ? true : false}
                                                                />
                                                                <span>
                                                                    <img src={qnaSwitchCheck} alt="check"/>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        {sessionInfo && sessionInfo.serviceType == "LIVE" && (
                                                            <div className="on_off_exporsure">
                                                                <span>Real-time transmission</span>
                                                                <strong className={cx({off:checkedRealTimeIdx != item.idx})}>{checkedRealTimeIdx === item.idx ? 'ON' : 'OFF'}</strong>
                                                            </div>
                                                        )}
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    </>
                                )}
                                <div className="top_title">
                                    <span>Pre Survey</span>
                                </div>
                                <ul className="control_content_list">
                                   {beforeSurvey && beforeSurvey.map((item, i) => {
                                        return (
                                            <li key={i}>
                                                <a onClick={(e) => onSelectSurvey(e,item)}>
                                                    <span>{item.title}</span>
                                                </a>
                                                <div className="control_content_list_tool">
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Fragment>
                        </div>)
                    }
                    {(step == 2 && leftMenu == "SURVEY") &&  (
                        <div className="control_content_detail">
                            <div><h4>{selectSurvey?.title}</h4></div>
                            {selectSurvey?.type == "PROGRESS" && (
                                <div>
                                    <ul>
                                        <li className="survey_exposure">
                                            <h5>Survey</h5>
                                            <div className="survey_exposure_inner">
                                                <label className="switch" style={{marginRight:"0"}}>
                                                    <input
                                                        type="checkbox"
                                                        name="surveyOpen"
                                                        checked={ checkedRealTimeIdx === selectSurvey.idx ? true : false }
                                                        onChange={(e) => { onCheckChange(e,selectSurvey); }}
                                                    />
                                                    <span>
                                                    </span>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            )}
                            <div>
                                <ul>
                                    <li>
                                        <h5>Division</h5>
                                        <div>
                                            <span>{selectSurvey?.type == 'BEFORE' ? 'Pre Survey' : (selectSurvey?.type == 'AFTER' ? "Post Survey" : "Progress Survey")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <h5>Number of questions</h5>
                                        <div className="">
                                            <span>{selectSurvey?.questionCnt}questions</span>
                                        </div>
                                    </li>
                                    <li className="survey_edit" onClick={() => window.open(`${_CFG.hubUrl()}/#/event/${consoleCfg.eventIdx}/feedback/survey?au=${_CK.getAuth()}`)}>
                                        <h5>Edit survey</h5>
                                        <div>
                                            <a><img src={surveyArrow }/></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}

                    {/* MODAL_POPUP */}
                    {(step == 1 && leftMenu == "MODAL") && (
                        <div id="control_content1" className={cx({ control_content: true, none: step !== 1 })}>
                            <Fragment>
                                <div className="top_title">
                                    <span>Console Popup</span>
                                </div>
                                <ul className="control_content_list">
                                    {modalPopup && modalPopup.map((item, i) => {
                                        return (
                                            <li key={i}>
                                                <a onClick={(e) => onSelectModal(e,item)}>
                                                    <span>{item.title}</span>
                                                </a>
                                                <div className="control_content_list_tool">
                                                    <div className="on_off_exporsure">
                                                        <span>Real-time transmission</span>
                                                        <strong className={cx({off:checkedModalPopupIdx != item.idx})}>{checkedModalPopupIdx === item.idx ? 'ON' : 'OFF'}</strong>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Fragment>
                        </div>
                    )}
                    {(step == 2 && leftMenu == "MODAL") && (
                        <div className="control_content_detail">
                            <div><h4>{selectModal?.title}</h4></div>
                            <div>
                                <ul>
                                    <li className="survey_exposure">
                                        {/* <h5 onClick={()=>{setModalPopupShow(true)}}>팝업</h5> */}
                                        <h5>Console Popup</h5>
                                        <div className="survey_exposure_inner">
                                            <label className="switch" style={{marginRight:"0"}}>
                                                <input
                                                    type="checkbox"
                                                    name="modalPopupOpen"
                                                    checked={checkedModalPopupIdx === selectModal.idx ? true : false}
                                                    onChange={(e) => onCheckChange(e,selectModal)}
                                                />
                                                <span></span>
                                            </label>
                                        </div>
                                    </li>
                                    <li className="console_edit" onClick={() => window.open(`${_CFG.hubUrl()}/#/event/${consoleCfg.eventIdx}/feedback/popup?au=${_CK.getAuth()}`)}>
                                        <h5>Edit Console Popup</h5>
                                        <div>
                                            <a><img src={consolePopupEdit}/></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                    {/* EXIT_POPUP */}
                    {(step == 1 && leftMenu == "EXIT") && (
                        <div id="control_content1" className={cx({ control_content: true, none: step !== 1 })}>
                            {/* <div className="exit_content">
                                <ul>
                                    <li className="">
                                        <h5>퇴장팝업</h5>
                                        <div className="">
                                            <label className="switch" style={{marginRight:"0"}}>
                                                <input
                                                    type="checkbox"
                                                    name="exitPopup"
                                                    value={ exvalue ?? "N" }
                                                    checked={exvalue === 'Y' ? true : false}
                                                    onChange={(e) => { onCheckChange(e); }}
                                                />
                                                <span></span>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div> */}
                            <div className="exit_content">
                                <ul>
                                    <li className="">
                                        <h5>Exit Popup</h5>
                                        <div className="">
                                            <label className="switch" style={{marginRight:"0"}}>
                                                <input
                                                    type="checkbox"
                                                    name="exitPopupOpen"
                                                    value={ exitPopupOpenValue ?? "N" }
                                                    checked={exitPopupOpenValue === 'Y' ? true : false}
                                                    onChange={(e) => { onCheckChange(e); }}
                                                />
                                                <span></span>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>                    
                <div className="console_folding left_console_folding">
                    <button onClick={(e) => leftConsoleShow(true)}>
                        <div className="arrow_div">
                                <img
                                    src="https://cdn.onoffevent.com/common/btn-side-left.svg"
                                    alt=""
                                />
                        </div>
                        <div className="number_div">
                            <h2>Control</h2>
                        </div>
                    </button>
                </div>
            </div>

            <div 
                className={cx({ admin_console: true, right_console: true, on: !consoleOpenClose })}
                style={{zIndex : `${modalShow == true || showPopup == true || showConnectUsersViewModal == true ? "1056" : "999" }`}}
            >
                <div className="admin_console_top">
                    <h2>Connection</h2>
                    <button className="right_console_button" onClick={(e) => rightConsoleShow(false)}>
                    <img
                        src="https://cdn.onoffevent.com/common/btn-side-left.svg"
                    />
                    </button>
                </div>
                <div className="right_console_content">
                    <p>Real-time participants</p>
                    <span className="underline" onClick={() => setShowConnectUsersViewModal(true)}>
                        {connectUserLength}
                    </span>
                </div>
                <div className="console_folding right_console_folding">
                    <button onClick={(e) => rightConsoleShow(true)}>
                        <div className="arrow_div">        
                            <img
                                src="https://cdn.onoffevent.com/common/btn-side-left.svg"
                                alt=""
                            />
                        </div>
                        <div className="number_div">
                            <span>{connectUserLength}</span>
                            <h2>Connection</h2>
                        </div>
                    </button>
                </div>
            </div>
        </Wrapper>
    );
};
const Wrapper = styled.div`

`