import React from 'react';

export default (props) => { 
  const {info,idCheck ,onLoginClick,clickedIdCheck,onSetInfo} = props
  return (
    <div className="login_box member_box">
      <div className="login_input_div">
        <input
          className="login_input"
          id="member_id"
          type="text"
          placeholder="Please enter the Id."
          defaultValue=""
        />
        <img src="https://cdn.onoffevent.com/login/icon-s-my.svg" />
      </div>
      <div className="login_input_div">
        <input
          className="login_input"
          id="member_password"
          type="password"
          placeholder="Please enter the Password."
          defaultValue=""
          onKeyPress={(e) => {
            if (e.key == "Enter"){
              onLoginClick(e);
            } 
          }}
        />
        <img src="https://cdn.onoffevent.com/login/icon-s-password.svg" />
      </div>
      <div className="id_save">
        <input
          type="checkbox"
          id="id_savebox"
          checked={idCheck === "Y"}
          style={{ cursor: "pointer" }}
          onChange={(e) => clickedIdCheck()}
        />
        <label id="chk_label" htmlFor="id_savebox">
          <span></span>Save ID
        </label>
      </div>
      <div className="login_enter">
        <span onClick={(e) => onLoginClick(e)}>
          Enter
        </span>
      </div>
    </div>
  )
}