/* eslint-disable */
import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import moment from "moment";

import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import Layout, { Wrapper } from "../Layouts/Layout";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import useStore from "../../../../modules/utils/store";
import EventListDetail from "./EventList.Detail";

import iconGuide from "../../../../resources/preReg/images/icon/icon-guide.svg";
import iconNotice from "../../../../resources/preReg/images/icon/icon-notice.svg";

export default (props) => {
  const { auth, iam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const { history, location, match } = props;
  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [upComingYnCount, setUpComingYnCount] = useState(0);

  /**
   * 화면 초기화
   *
   * initPage()
   *  - gsap (애니메이션 초기화)
   *  - 모달 버튼 클릭 이벤트
   */
  useEffect(() => {
    if (iam?.targetType == 'MEDIGATE'){
      alert('접근권한이 없습니다.');
      window.history.back();
    }
    else if (iam?.targetType == "GUEST") {
      history.push(`/event/${iam.eventIdx}/register`);
    } else {
      loadList();
      setLoaded(true);
    }
  }, []);

  const loadList = () => {
    if (!siteInfo.idx) {
      alert("올바른 접근이 아닙니다.");
      return;
    }
    const req = {
      path: "/v1/client/site/event/list",
      data: {
        siteIdx: siteInfo.idx,
        status: "Y",
        eventStatus: "Y",
      },
      auth: auth,
    };
    _API.post(req).then((res) => {
      const { data } = res.data;
      if (data) {
        // 사이트 정보 셋팅
        setList(data);
      }
    });
  };

  const action = (event, e) => {
    e.preventDefault();

    if (event?.sessionPubCnt <= 0) {
      alert("예정 중인 세션 정보가 없습니다.");
      return;
    }
    let url = `/event/${event.eventIdx}/register`;
    
    if (iam.eventIdx != event.eventIdx) {
      const req = {
        path: "/v1/client/accessLog/save",
        data: {
          siteIdx: iam.siteIdx,
          eventIdx: event.eventIdx,
          passCode: iam.passCode,
          email: iam.email,
          iam: iam,
          userAgent: _U.getUserAgent(),
        },
      };
      _API.post(req).then((res) => {
        let { data } = res.data;
        _CK.setLogKey(data.logKey);
        if (_CK.getLogKey()) {
          window.location.href = "/#" + url;
          window.location.reload();
        }
      });
    } else {
      if (_CK.getLogKey()) {
        window.location.href = "/#" + url;
        window.location.reload();
      } else {
        alert("새로고침 후 다시 접속해주세요.");
      }
    }
  };

  
  useEffect(() => {
    let count = 0;
    for (let i in list) {
      if (list[i].upcomingYn === "Y") {
        count++;
      }
    }
    setUpComingYnCount(count);
  }, [list]);
  if (!loaded) {
    return (
      <img
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "200px",
          height: "200px",
          display: "block",
          transform: "translate(-50%,-50%)",
        }}
        className="loading_img loading_img4"
        src="https://cdn.onoffevent.com/common/loading.gif"
      />
    );
  }
  return (
    <Layout {...props} bodyClass="page-registration">
      <Wrapper>
        <div
          className="main_header_bnr_box"
          style={{ backgroundColor: siteInfo?.eventListBgColor ? siteInfo?.eventListBgColor : "#fff" }}
        >
          <img 
            className="main_header_bnr_box_img1"
            src={`${siteInfo?.eventListMainImgUrl
              ? siteInfo?.eventListMainImgUrl
              : `https://cdn.onoffevent.com/bg/Event%20list%20bg.png`
              // : `https://cdn.onoffevent.com/common/key-visual.jpg`
            }`}
          />
          <img 
            className="main_header_bnr_box_img2"
            src={`${siteInfo?.eventListMainImgUrl
              ? siteInfo?.eventListMainImgUrl
              : `https://cdn.onoffevent.com/bg/Event%20list%20bg.png`
              // : `https://cdn.onoffevent.com/common/key-visual.jpg`
            }`}
          />
          {siteInfo?.nameEventListShow == 'Y' ? 
            (<div className="sub_bg_img_hd">
              <h1 style={{ color: siteInfo.nameColor }}>{siteInfo?.title}</h1>
            </div>)
            : ""  
          }
        </div>
        <div className="main_inform">
          <div className="main_inform_text wid_1180">
            <div className="main_inform_list">
              <ul>
              {siteInfo?.subPageList.map((item, i) => {
                  return(
                    <>
                    {item.isShortcutShow == 1 && (
                      item.pageType == "LINK" ? (
                        <li key={`sub_${i}`}>
                          <a href={`https://${item.link}`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} >
                            <img src={iconGuide} />
                            {item.name}
                          </a>
                        </li>
                      ) : (
                        <li key={`sub_${i}`}>
                          <a href={`/#/site/${siteInfo.idx}/subpage/${item.pageId}`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} >
                            <img src={iconGuide} />
                            {item.name} 
                          </a> 
                        </li>
                      )
                    )}
                    </>
                  )
                })}
              </ul>      
            </div>
          </div>
        </div>
        <div className="main_inform_2">
          <ul>
            <li>
              <div>
                <img
                  src="https://cdn.onoffevent.com/main/e-booth.png"
                  alt="e-booth 이동하기"
                />
                <span>E-Booth</span>
              </div>
            </li>
            <li>
              <div>
                <img
                  src="https://cdn.onoffevent.com/main/e-poster.png"
                  alt="e-poster 이동하기"
                />
                <span>E-Poster</span>
              </div>
            </li>
          </ul>
        </div>
        <section className="main_section">
          <div className="main_area event_area wid_1180">
            <h2>{siteInfo?.eventLabel ? siteInfo?.eventLabel : `이벤트`}일정</h2>
            <div className="main_area_ul">
              {list?.map((m, i) => (
                <EventListDetail m={m} i={i} action={action}/>
              ))}
            </div>
          </div>
        </section>
      </Wrapper>
    </Layout>
  );
};
