import React, { useState,Fragment, useEffect, useContext,useRef} from 'react';
import styled from "styled-components";
import Button from "../UI/Button"
import Input from "../UI/Form"
import iconCountUp from "../../resources/preReg/images/icon/icon-count-up.svg"
import iconCountDown from "../../resources/preReg/images/icon/icon-count-down.svg"
import { isEmpty, set } from 'lodash';
import { _API, _CK } from '../../modules/utils';
import { AppContext } from '../AppProvider';
import cx from "classnames";
export default (props) => {
  
  const { setAccompanyAttendModal, eventIdx, sessionIdx } = props;
  
  const [text, setText] = useState("");
  const [nextpage, setNextpage] = useState(1);
  const [sessionInfo, setSessionInfo] = useState();
  const [regAccompany, setRegAccompany] = useState();

  const loadSessionInfo = () => {
    const req = {
      path: "/v1/event/session/info",
      data: {
        idx:sessionIdx,
      },
    };
    _API.post(req).then((res) => {
      setSessionInfo(res.data);
    });
  }
  
  const modalClose = () => { 
    setAccompanyAttendModal(false);
    $(body).css("overflow", "auto");
  }

  useEffect(() => {
    loadSessionInfo();
  },[sessionIdx])

  
  return (
    <Fragment>
      <div id="accompany_modal">
        <div className="modal_header">
          <div>
            <h2>동반 참석자 등록</h2>
            <Button
              className="close_btn"
              onClick={modalClose}
              style={{height:"40px"}}
            >
              <img src="https://cdn.onoffevent.com/common/icon_close.svg" />
            </Button>
          </div>
        </div>
        {nextpage == 1 ? (
          <FirstForm setNextpage={setNextpage} eventIdx={eventIdx} sessionIdx={sessionIdx} />
        ): nextpage == 2 ?(
            <SecondForm setNextpage={setNextpage} text={text} eventIdx={eventIdx} 
                        sessionIdx={sessionIdx} sessionInfo={sessionInfo} 
                        regAccompany={regAccompany} setRegAccompany={setRegAccompany}            
            />
        ) : nextpage == 3 && (
          <ThirdForm setNextpage={setNextpage} eventIdx={eventIdx} sessionIdx={sessionIdx} sessionInfo={sessionInfo} regAccompany={regAccompany}/>
        )}
      </div>
    </Fragment>
  )
}

const FirstForm = ({ setNextpage, eventIdx, sessionIdx}) => { 
  const goSymposium = () => {
    const url = `/#/event/${eventIdx}/session/${sessionIdx}/default`;
    const newWindow = window.open(url, '_blank'); // 새 창에서 URL을 엽니다

    if (newWindow) {
      newWindow.onload = function() { 
        // 새 창이 열리고 나서 새로 고침을 수행합니다
        newWindow.location.reload();
      };
    } else {
      // 새 창이 팝업 차단기 때문에 열리지 않았을 경우를 처리합니다
      alert('팝업이 차단되었습니다. 팝업 차단기를 해제해주세요.');
    }
  }
  return (
    <>
      <div className="modal_content">
        <h4>동반 참석자 등록을 진행하시겠습니까?</h4>
        <p>
          세션을 같이 시청하시는 선생님이 계신다면 아래 ‘동반 참석자 등록’을 통해 등록 및 시청해 주시기 바랍니다.<br/>
          동반 참석자 추가 없이 바로 접속을 하시는 경우 ‘심포지엄 바로 입장’을 클릭하시면 됩니다.
        </p>
      </div>
      <div className="modal-footer1">
        <div className="btn_container">
          <Button className="accompany_btn" onClick={() => { setNextpage(2) }}>
            예
            <span>(동반 참석자 등록)</span>
          </Button>
          <Button 
            className="accompany_btn" 
            style={{backgroundColor:"#6a6af8", marginLeft:"5px"}}
            onClick={() => goSymposium()}
          >
            아니오
            <span>(심포지엄 바로 입장)</span>
          </Button>
        </div>
      </div>
    </>
  )
}

const SecondForm = ({ setNextpage, text, eventIdx, sessionIdx, sessionInfo, regAccompany, setRegAccompany }) => { 
  const {iam} = useContext(AppContext);
  const [newAccompany, setNewAccompany] = useState([{}]);
  const [removeAccompany, setRemoveAccompany] = useState([]);
  const [errorMsgs, setErrorMsgs] = useState([]);
  
  const inputRef = useRef([]);
  const onChange = (index, name, value) => { 
    setNewAccompany((o) => {
      const nc = [...o];
      nc?.map((ac, i) => {
        if(i == index){
          ac[name] = value;
        }
      })
      return nc;
    })
  }
  
  const onAcconpanyPlus = () => {
    setNewAccompany((o) => {
      let arr = [...o];
      arr.unshift({});
      return arr;
    });
  };
  const onAccompanyMinus = (index) => {
    
    if (newAccompany?.length == 1) {
      return;
    }

    let confirmChk;
    if(newAccompany[index]?.idx){
      confirmChk = window.confirm(newAccompany[index].name+'님을 삭제하시겠습니까?');
    }else{
      confirmChk = true;
    }

    if(confirmChk){
      if (newAccompany[index]?.idx) {
        setRemoveAccompany((o) => {
          let rmAccompany = [...o];
          rmAccompany.push(newAccompany[index].idx);
          return rmAccompany;
        });
      }
      setErrorMsgs((o) => {
        return [...o]?.splice(index,1);
      })
      setNewAccompany((o) => [...o.slice(0, index), ...o.slice(index + 1)]);
    }
  };

  const loadAccompany = () => {
    const req = {
      path: "/v1/client/accompany/list",
      data: {
        preregIdx:iam?.preregIdx,
        eventIdx:eventIdx,
        sessionIdx:sessionIdx,
        logKey:_CK.getLogKey()
      },
    };
    _API.post(req).then((res) => {
      const { data } = res.data;
      setNewAccompany(isEmpty(data) ? [{}] : data);
    });
  }


  const onSave = () => {
    const accompany = newAccompany;
    
    let errors = [];
    for(let i in accompany){
      const acName = accompany[i]?.name;
      const acHospitalName = accompany[i]?.hospitalName;
      let msg = null;

      if((!acName || acName?.length <= 0) && (!acHospitalName || acHospitalName?.length <= 0)) {
        msg = '이름과 병원명을 입력해주세요.'
        inputRef.current[0].focus(); // 자동 포커스
      }

      if(!msg && (!acName || acName?.length <= 0)) {
        msg = '이름을 입력해주세요.'
        inputRef.current[0].focus(); // 자동 포커스
      }

      if(!msg && (!acHospitalName || acHospitalName?.length <= 0)) {
        msg = '병원명을 입력해주세요.'
        inputRef.current[1].focus(); // 자동 포커스
      }

      if(!msg && acName?.replace(/ /g,'')?.length <= 0 && acHospitalName?.replace(/ /g,'')?.length <= 0) {
        msg = '이름과 병원명은 공백만 입력할 수 없습니다.'
      }

      if(!msg && acName?.replace(/ /g,'')?.length <= 0) {
        msg = '이름은 공백만 입력할 수 없습니다.'
      }

      if(!msg && acHospitalName?.replace(/ /g,'')?.length <= 0) {
        msg = '병원명은 공백만 입력할 수 없습니다.'
      }
     

      if(msg) errors[i] = msg;

    }

    setErrorMsgs(errors);
    if(errors?.length > 0){
      return;
    }


    const req = {
      path: "/v1/client/accompany/save",
      data: {
        preregIdx:iam?.preregIdx,
        eventIdx:eventIdx,
        sessionIdx:sessionIdx,
        accompany: accompany,
        logKey:_CK.getLogKey(),
        consoleCountView: parseInt(_CK.getConsoleCountView()) + 1,
        removeAccompany:removeAccompany
      },
    };
    _API.post(req).then((res) => {
      const { data, resultList } = res.data;
      if(sessionInfo?.accompanyEditYn != 'Y') setRegAccompany(resultList);
      setNextpage(3);
    });
  }

  useEffect(() => {
    if(sessionInfo?.accompanyEditYn == 'Y'){
      loadAccompany();
    }else{
      setNewAccompany(isEmpty(regAccompany) ? [{}] : regAccompany);
    }
  },[sessionInfo]);

  return (
    <>
      <div className="modal_content modal_content2">
        <div style={ {padding:"20px"}}>
          <p style={ {marginBottom:"30px"}}>
            세션을 같이 시청하시는 선생님들이 계신다면 병원명과 성함을 아래 입력창에 각각 입력하여 주시기 바랍니다.<br/>
            2명 이상의 참석자를 입력하실 경우, 아래 ‘+’ 버튼을 클릭한 후 입력하여 주시기 바랍니다.
          </p>
          <h5>{iam?.name != null && iam?.name != "" ? iam?.name : "게스트"}님 외 총 {newAccompany?.length || 0}명</h5>
        </div>
        <div className="modal_content_inner">
          <ul>
            {newAccompany?.map((item ,index) => (
              <li key={index}>
                <div className={cx({
                  accompany_list: true,
                  active: errorMsgs[index],
                })}>
                  <span className="m_index_num">{newAccompany?.length - index}</span>
                  <div className="accompany_list_input">
                    <span className="p_index_num">{newAccompany?.length - index}</span>
                    <input type="text" placeholder="동반참석자 성명" onChange={(e) => onChange(index, 'name', e.target.value)} value={item?.name || ''} name={'name' + index} ref={el => (inputRef.current[0] = el)} />
                    <input type="text" name={'hospitalName' + index} placeholder="동반참석자 병원명" onChange={(e) => onChange(index, 'hospitalName', e.target.value)} value={item?.hospitalName || ''} ref={el => (inputRef.current[1] = el)} />
                    {errorMsgs[index] && (
                      <div className="m_errorMsg">{errorMsgs[index]}</div>
                    )}
                  </div>
                  {errorMsgs[index] && (
                    <div className="errorMsg">{errorMsgs[index]}</div>
                  )}
                </div>
                <div className="accompany_list_imgbox">
                  {newAccompany?.length > 1 && (
                    <img src={iconCountDown} alt="삭제" onClick={() => onAccompanyMinus(index)} />
                  )}
                  {index == 0 && (
                    <img src={ iconCountUp } alt="추가" onClick={() => onAcconpanyPlus()}/>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
        
      </div>
      <div className="modal-footer modal-footer2">
        <Button className="accompany_btn" onClick={() => setNextpage(1)}>이전</Button>
        <Button className="accompany_btn" onClick={() => onSave()}
          style={{ backgroundColor: "#6a6af8", marginLeft:"5px" }}
        >등록완료</Button>
      </div>
    </>
  )
}

const ThirdForm = ({setNextpage, eventIdx, sessionIdx, sessionInfo, regAccompany}) => { 
  const {iam} = useContext(AppContext);
  const [accompany, setAccompany] = useState();

  const loadAccompany = () => {
    const req = {
      path: "/v1/client/accompany/list",
      data: {
        preregIdx:iam?.preregIdx,
        eventIdx:eventIdx,
        sessionIdx:sessionIdx,
        logKey:_CK.getLogKey()
      },
    };
    _API.post(req).then((res) => {
      const { data } = res.data;
      setAccompany(data);
    });
  }
  const goSymposium = function (url) {
    const newWindow = window.open(`/#`+url, '_blank'); // 새 창에서 URL을 엽니다
    if (newWindow) {
      newWindow.onload = function() { 
        // 새 창이 열리고 나서 새로 고침을 수행합니다
        newWindow.location.reload();
      };
    } else {
      // 새 창이 팝업 차단기 때문에 열리지 않았을 경우를 처리합니다
      alert('팝업이 차단되었습니다. 팝업 차단기를 해제해주세요.');
    }
  }

  useEffect(() => {
    if(sessionInfo){
      if(sessionInfo.accompanyEditYn != 'Y') setAccompany(regAccompany);
      else loadAccompany();
    }
  }, [sessionInfo]);
  return (
    <>
      <div className="modal_content modal_content_3">
        <h4>동반참석 등록완료</h4>
        <p style={ {marginBottom:"30px"}}>
          동반참석 등록이 완료되었습니다. 동반 참석자 리스트를 확인해 주세요.
        </p>
        <h5>{iam?.name != null && iam?.name != "" ? iam?.name : "게스트"}님 외 총 {accompany?.length || 0}명</h5>
        <div className="modal_content_inner">
          <ul>
            {accompany?.map((ac, index) => {
              return(
                <li key={index}>
                  <span>{accompany?.length - (index)}</span>
                  <strong>{ac?.name?.length > 10 ? ac?.name?.slice(0,10)+'...' : ac?.name}</strong>
                  <p>{ac?.hospitalName}</p>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="modal-footer modal-footer3">
          <Button
            className="accompany_btn"
            onClick={() => { setNextpage(2) }}
          >이전
          </Button>
          <Button
            className="accompany_btn"
            style={{ backgroundColor: "#6a6af8" }}
            onClick={() => goSymposium(`/event/${eventIdx}/session/${sessionIdx}/default`)}
          >심포지엄접속
          </Button>
        </div>
      </div>
    </>
  )
}