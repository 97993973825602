import { default as ResetStyle } from './ResetStyle';
import { default as EventListStyle } from './EventListStyle';
import { default as OndemandListStyle } from './OndemandListStyle';
import { default as SesssionListStyle } from './SessionListStyle';
import { default as LoginStyle } from './LoginStyle';
import { default as HeaderStyle } from './HeaderStyle';
import { default as FooterStyle } from './FooterStyle';
import { default as CommonStyle } from './CommonStyle';
import { default as MypageStyle } from './MypageStyle';
import { default as ModalStyle } from './ModalStyle';

const style = `
  /*리젯css*/
  ${ResetStyle}
  
  /*공통*/
  ${CommonStyle}
  
  /*로그인페이지*/
  ${LoginStyle}
  
  /*헤더*/
  ${HeaderStyle}
  
  /*이벤트리스트페이지*/
  ${EventListStyle}
  
  /*온디멘드리스트페이지*/
  ${OndemandListStyle}
  
  /*세션리스트페이지*/
  ${SesssionListStyle}
  
  /*마이페이지*/
  ${MypageStyle}
  
  /*푸터*/
  ${FooterStyle}
  
  /*모달css*/
  ${ModalStyle}
  `;

export default style;
