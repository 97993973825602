/* eslint-disable import/no-anonymous-default-export */
import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import WebSocket from "react-websocket";

import { _CFG } from "../../modules/config";
import { _U, _CK } from "../../modules/utils";
import { AppContext } from "../AppProvider";

const _OPTIONS = [
    { action: "BROADCAST", label: "전체" },
    { action: "IAM", label: "내 접속 ID" },
    { action: "ECHO", label: "에코" },
];

export default (props) => {
    const {iam} = useContext(AppContext);
    const { siteInfo,consoleCfg,debug, onConnected, onDisconnected, onMessage } = props;
    
    const urlParams = [];
    
    urlParams.push("&siteIdx=" + siteInfo?.siteIdx);
    urlParams.push("&eventIdx=" + consoleCfg?.eventIdx);
    urlParams.push("&sessionIdx=" + consoleCfg?.sessionIdx);
    urlParams.push("&consoleIdx=" + consoleCfg?.consoleIdx);
    urlParams.push("&sessionName=" + encodeURIComponent(consoleCfg?.sessionName));
    urlParams.push("&passCode=" + iam?.passCode);
    urlParams.push("&logKey=" + iam?.logKey);
    urlParams.push("&consoleCountView=" + _CK.getConsoleCountView());

    const socket = useRef(null);
    const [isConnected, setIsConnected] = useState(false);
    const [debugMessages, setDebugMessages] = useState([]);

    const onConnect = () => {
        setIsConnected(true);
        addDebugMessage(":: Socket Connect!");
        onConnected && onConnected();
    };

    const onDisconnect = () => {
        setIsConnected(false);
        addDebugMessage(":: Socket Disconnect!");
        onDisconnected && onDisconnected();
    };

    const onMessageReceived = (data) => {
        if (data === "OK") {
            return;
        }
        addDebugMessage(data);
        onMessage && onMessage(data);
        try {
            const d = JSON.parse(data);
            const isLocal = _CFG.isLocal();
            _U.postMessage("SOCKET-RECEIVED", JSON.stringify(isLocal ? d.data : d));
        } catch (e) {}
    };

    const addDebugMessage = (message) => {
        if (debug) {
            setDebugMessages((o) => [message, ...o]);
        }
    };

    const onSendMessage = (message) => {
        try {
            socket?.current?.sendMessage(message);
        } catch (e) {}
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "SOCKET-SEND":
                    onSendMessage(JSON.stringify(data.data));
                    break;
            }
        });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, []);

    return (
        <Fragment>
            <WebSocket ref={socket} url={_CFG.wsHost() + urlParams.join("")} onOpen={onConnect} onClose={onDisconnect} onMessage={onMessageReceived} debug={debug} />
            {debug && <DebugForm messages={debugMessages} onSend={onSendMessage} />}
        </Fragment>
    );
};

const DebugForm = (props) => {
    const { messages, onSend } = props;
    const [action, setAction] = useState(_OPTIONS[0].action);
    const [message, setMessage] = useState("");
    const onSendClick = () => {
        const form = { action, data: { mode: "NOTICE", message } };
        onSend && onSend(JSON.stringify(form));
        setMessage("");
    };
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-4">
                    <select value={action} onChange={(e) => setAction(e.target.value)} className="form-control">
                        {_OPTIONS.map((item, i) => (
                            <option key={i} value={item.action}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-8">
                    <div className="input-group">
                        <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} className="form-control" />
                        <div className="input-group-append">
                            <button type="button" className="btn btn-primary" onClick={onSendClick}>
                                <i className="fa fa-send"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <textarea className="form-control" readOnly value={messages?.join("\n")} rows={10} />
                </div>
            </div>
        </Fragment>
    );
};
