import React,{Fragment} from 'react';
import moment from "moment";
import { _CFG } from '../../../../modules/config';

export default (props) => { 
  const { info, passCodeOpt, passcodeNext, isPasswordVisible, onLoginClick,
    preregEventList, setIsPasswordVisible,goEventSessionList,goMypage,passCodeShowTerms,setPassCodeUseAgree,setPassCodePrivacyAgree } = props
    
  return (
    <div className="login_box psc_box">
      {!passcodeNext && (
        <div className="psc_box1">
          {passCodeOpt[0]?.topPhraseFormUse !== 'N' ? (
            <div dangerouslySetInnerHTML={{__html: passCodeOpt[0]?.topPhraseForm}}/>
          ) : ''}
          <div className="login_input_div">
            <input
              className="login_input"
              id="psc_password"
              type={!isPasswordVisible ? "password" : "text"}
              placeholder="패스코드를 입력해 주세요."
              defaultValue=""
              onKeyPress={(e) => {
                if (e.key == "Enter"){
                  onLoginClick(e);
                } 
              }}
            />
              <img
                src="https://cdn.onoffevent.com/login/icon-s-password.svg"
                onClick={(e)=>setIsPasswordVisible(!isPasswordVisible)}
                style={{opacity:`${isPasswordVisible == true? "0.5":"1"}`,cursor:"pointer"}}
              />
          </div>
          <div className="login_enter">
            <span id="passcode_submit_btn" onClick={(e) => onLoginClick(e)}>
              {passCodeOpt[0] != null ? (passCodeOpt[0].loginBtnLabel != null && passCodeOpt[0].loginBtnLabel != "" ? passCodeOpt[0].loginBtnLabel : "심포지엄 확인하기") : "심포지엄 확인하기"}
            </span>
          </div>
        </div>
      )}
      {passcodeNext && (
        <>
        {preregEventList?.length > 0 && (
          <div
            className="psc_box2"
            style={{ display: "block" }}
          >
            <p>
              총 {preregEventList.length}개의 이벤트에 등록이
              되어있습니다.
              <br />
              선택하신 이벤트로 입장하실 수 있습니다.
            </p>
            {preregEventList?.map((item, i) => (
              <Fragment key={i}>
                <div
                  className="login_session psc_session"
                  onClick={(e) =>
                    goEventSessionList(item, e)
                  }
                >
                  <div className="psc_hd">
                    <h4 className="login_session_h4">
                      <a>{item.name}</a>
                    </h4>
                    <p>
                      <img
                        src="https://cdn.onoffevent.com/common/arrow_select.svg"
                        alt="펼쳐보기"
                      />
                    </p>
                  </div>
                  {(item?.startDate && item.endDate) ? (
                  <p className="login_session_p">
                    {moment(item?.startDate).format(
                      "YYYY.MM.DD(ddd) HH:mm"
                    )}{" "}
                    ~{" "}
                    {moment(item?.endDate).format(`
                      ${moment(item?.startDate).format('YYYY.MM.DD') == moment(item?.endDate).format('YYYY.MM.DD') ? 'HH:mm' : 'YYYY.MM.DD(ddd) HH:mm'}`
                    )}
                  </p>
                  )
                  : ""
                    }
                </div>
              </Fragment>
            ))}
          </div>
        )}
          <a href="#" onClick={e => goMypage(e)}  style={{display:"block",textAlign:"right"}}>마이페이지</a>
        </>
      )}
      
    </div>
  )
}