import React,{ Fragment} from 'react';
import moment from "moment";
import 'moment/locale/ko';

import RegisterSessionListServiceType from "../../../common/RegisterSessionListServiceType";
import RegisterConsoleTypeEn from "../../../common/RegisterConsoleTypeEn";
import cx from "classnames";

import SessionUserList from "./SessionLIst.Detail.UserLIst"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import ImgPhoto from "../../../../resources/console/images/icon/default-user.svg";
import btnMoreinfo from "../../../../resources/preReg/images/icon/btn-moreinfo.svg";
import useStore from '../../../../modules/utils/store';
import { _API } from '../../../../modules/utils';

export default (props) => {
  const { eventInfo, item, i, onOff,sessionList,setAccompanySessionIdx, setAccompanyAttendModal,waitTimer,showRef,setManagerInfo,setSessionMgrInfoModalShow,moreView} = props;
  const { siteInfo } = useStore();
  
  return (
    <Fragment key={`view_${i}`}>
      {item.visible == 1 && (
        <div className="sch_b_con">
          <div>
            <h4 className="timeline_h4">
              {/* <span>{moment(item?.startDate).format("HH:mm")}</span> */}
              {moment(item?.startDate).locale("en").format('ddd, DD MMM YYYY HH:mm')}
              {moment(item?.startDate).format("YYYYMMDD") !=
                moment(item?.endDate).format("YYYYMMDD") ?
                ` ~ ${moment(item?.endDate).locale("en").format('ddd, DD MMM YYYY HH:mm')+" KST"}` : " KST"}
            </h4>
            <RegisterSessionListServiceType item={item} onOff={onOff} />
            <div className="sch_b_con_flex">                          
              <div className="sch_b_con_left">
                <div className="session_name">
                  <h5>{item?.name}</h5>
                </div>
                {item?.managerList?.length > 0 && (
                  <div className="session_user_list">
                    {item?.managerList?.length <= 3 ? (
                      <SessionUserList
                        item={item}
                        setSessionMgrInfoModalShow={setSessionMgrInfoModalShow}
                        setManagerInfo={setManagerInfo}
                      />
                    ) : (
                      <SessionUserList
                        item={item}
                        setSessionMgrInfoModalShow={setSessionMgrInfoModalShow}
                        setManagerInfo={setManagerInfo}
                        paginationShow
                      />
                    )}
                  </div>
                )}
              </div>
              {item.eventEnterYn == "Y" && (  
                <div className="sch_b_con_right">
                  <RegisterConsoleTypeEn
                    item={item}
                    onOff={onOff}
                    eventInfo={eventInfo}
                    sessionList={sessionList}
                    setAccompanySessionIdx={setAccompanySessionIdx}
                    setAccompanyAttendModal={setAccompanyAttendModal}
                    waitTimer={waitTimer}
                  />
                </div>
              )}
            </div>
          </div>
          {((item?.sessionIntroUse === 'Y' && item?.description) || item?.tagName) && (
            <div style={{display:"flex",flexDirection:"column", gap:"20px"}}>
              {(item?.description || item?.moreViewUseYn === `Y`) && (
                <div style={{display:"flex",flexDirection:"column", gap:"20px"}}>
                  {item?.description && (
                    <div
                      className={cx({
                        more_btn_p: true,
                        on: item?.moreView || false,
                      })}
                      ref={(element) => (showRef.current[i] = element)}
                      style={{
                        overflowY: item?.moreViewUseYn
                          ? `hidden`
                          : `auto`,
                      }}
                    >
                      {item?.description}
                    </div>
                  )}
                  {item?.moreViewUseYn === `Y` && (
                    <span
                      className={cx({
                        more_btn_span: true,
                        on: item?.moreView || false,
                      })}
                      onClick={(e) => moreView(i)}
                    >
                      <small>
                        {item?.moreView === true ? `Briefly` : `More`}
                      </small>
                    </span>
                  )}
                </div>
              )}
              {item?.tagName && (
                <ul className="hashtag_list">
                  {Array.isArray(item.tagName) ? 
                    item.tagName.map((tItem, index) => (
                      <li key={index}>
                        <span>{tItem}</span>
                      </li>
                    ))
                  : ""}
                </ul>
              )}
            </div>
          )}
        </div>
      )}
    </Fragment>
  )
}