import React, { useContext, useEffect } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
  match,
} from "react-router-dom";
import { AppContext } from "../../components/AppProvider";
import { _CK, _API } from "../../modules/utils";

import { default as ConsoleViews } from "../Console";

import { 
  EventList,
  OndemandList,
  SessionList,
  MyPage,
  Invitation,
  Information,
  Notice,
  SymposiumGuide,
  Subpage,
  JoinSymposium,
  QuestionStorage,
} from "../Template/Type-E/Pages";

import useStore from "../../modules/utils/store";

export default (props) => {
  const { auth, iam, saveAuth, saveIam } = useContext(AppContext);
  const { siteInfo }= useStore();
  const { history, match } = props;
  let baseUrl = `/site/${siteInfo.idx}/event/list`;
  return (
    <Router>
      <Switch>
        {/* 사용여부 확인 필요 */}
        {/* <Route path="/login/:eventIdx" exact component={Login} /> */}
        {/* <Route path="/meta_invitation" exact component={MetaInvitationView} /> */}
        {/* <Route path="/meta_mypage" exact component={MetaMypageView} /> */}
        {/* <Route path="/event/:eventIdx/session/:sessionIdx/meta_main" exact component={MetaMainView} /> */}
        {/* <Route path="/event/:eventIdx/register/payment/:code" component={RegisterPaymentView} /> */}
        {/* <Route path="/join/symposium" exact component={JoinSymposiumView} /> */}
        {/* <Route path="/question/storage" exact component={QuestionStorageView} /> */}
        {/* <Route path="/guestbook" exact component={GuestBookView} /> */}
        
        {/* 템플릿 폴더 전환 완료 */}
        <Route path="/event/:eventIdx/register" component={SessionList} />
        <Route path="/mypage" exact component={MyPage} />
        <Route path="/invitation" exact component={Invitation} />
        <Route path="/information" exact component={Information} />
        <Route path="/join/symposium" exact component={JoinSymposium} />
        <Route path="/question/storage" exact component={QuestionStorage} />
        <Route path="/site/:siteIdx/event/list" component={EventList} />
        
        {/* <Route path="/symposiumguide" exact component={SymposiumGuide}/> */}
        <Route path="/site/:siteIdx/subpage/event" exact component={SymposiumGuide}/>
        <Route path="/site/:siteIdx/subpage/notice" exact component={Notice}/>
        <Route path="/site/:siteIdx/subpage/ondemand" component={OndemandList} />
        <Route path="/site/:siteIdx/subpage/:pageId" component={Subpage} />
        {/* 템플릿 폴더 전환 미완료 */}
        <Route
          path="/event/:eventIdx/session/:sessionIdx/:type"
          exact
          component={ConsoleViews}
        />
        <Redirect path="*" to={baseUrl} />
      </Switch>
    </Router>
  );
};

export const NotFound404 = () => {
  return (
    <div>
      <h1>{window.location.pathname} Resource at path not found.</h1>
    </div>
  );
};
