import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";

export default (props) => {
    return (
        <div className={`modal ${props.modalClass ? props.modalClass : ""}`} id={props.modalId || "modal"} role="dialog" aria-labelledby="modalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false" style={props.modalstyle}>          
            {props.children}
        </div>
    );
};
