import React, { Fragment,useState,useRef,useEffect,useContext } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import styled from "styled-components";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import Layout, { Wrapper,HiddenWrapper } from "../Layouts/Layout";
import { SideBarTypes } from "../Layouts/Layout.Sidebar";
import { Footer } from "../Layouts/Layout.Footer";

import moment from "moment";
import useStore from "../../../../modules/utils/store";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);



export default (props) => {
  const { history, location, match, children, bodyClass, sidebar,closeModal } = props;
  // const cp = { history, location, match, info, eventInfo };
  const { auth, iam } = useContext(AppContext);
  const { saveAuth, saveIam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const [eventInfo, setEventInfo] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [info, setInfo] = useState({});
  const eventIdx = match?.params.eventIdx;

  return (
    <Fragment>
       <Layout>
        <Wrapper>
          <MemberForm eventIdx={eventIdx} />
        </Wrapper>
      </Layout>
    </Fragment>
  );
};

const MemberForm = (props) => {
  const { auth, iam, saveAuth, saveIam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const [info, setInfo] = useState({});
  const regExp = /[^0-9]/g;
  const eventIdx = props?.eventIdx;

  const onSetInfo = (field, value) => {
    setInfo((o) => {
      if (field == "mobile") {
        let newVal = value;
        if (regExp.test(newVal)) {
          newVal = newVal.replace(regExp, "");
        }
        return { ...o, [field]: newVal };
      } else {
        return { ...o, [field]: value };
      }
    });
  };

  const memberSave = (item) => {
    if (!window.confirm("수정하시겠습니까?")) {
      return;
    }

    if (!info.name) {
      alert("성명을 입력해주세요.");
      return;
    }
    if (!info.email) {
      alert("이메일을 입력해주세요.");
      return;
    }
    if (info.passwd && (iam.idx || iam.memberIdx) && !info.repasswd) {
      alert("비밀번호 확인을 입력해주세요.");
      return;
    }
    if (info.passwd && (iam.idx || iam.memberIdx)) {
      if (info.passwd.length < 4) {
        alert("4자리 이상의 비밀번호를 입력해 주세요.");
        return;
      }
      if (!(info.passwd == info.repasswd)) {
        alert("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
        return;
      }
    }
    if (!info.mobile) {
      alert("모바일을 입력해주세요.");
      return;
    }
    if (!info.companyName) {
      alert("회사명을 입력해주세요.");
      return;
    }
    if (!info.deptName) {
      alert("부서명을 입력해주세요.");
      return;
    }

    const req = {
      path: "/v1/client/mypage/save",
      data: {
        passCode: iam.passCode,
        siteIdx: iam.siteIdx,
        eventIdx: iam.eventIdx,
        memberIdx: iam.idx || iam.memberIdx,
        /** 멤버 정보 */
        name: info.name,
        email: info.email,
        passwd: info.passwd,
        repasswd: info.repasswd,
        mobile: info.mobile,
        companyName: info.companyName,
        deptName: info.deptName,
      },
    };

    _API.post(req).then((res) => {
      const { data } = res.data;
      if (data.targetTable) {
        alert("성공적으로 처리되었습니다.");
        window.location.reload();
      } else {
        alert("정보 저장 중 오류가 발생하였습니다.");
      }
    });
  };
 
  useEffect(() => {
    !info?.name && setInfo(iam);
  }, []);

  return (
    <Fragment>
      <>
        <div className="mypage_header_bnr">
          <h2>마이페이지</h2>
        </div>
        <div className="mypage_tab_menu_wrapper">
          <div className="mypage_tab_menu">
            <div className="mypage_tab">
              <Swiper
                spaceBetween={0}
                slidesPerView={"auto"}
                className="mypage_list"
              >
                <SwiperSlide >
                  <Link to="/invitation" className="link">
                    참가예정
                  </Link>
                </SwiperSlide>
               {/*  {siteInfo?.compUseYn === 'Y' && (
                <SwiperSlide>
                  <Link to="/join/symposium" className="link">
                    참가내역
                  </Link>
                </SwiperSlide>
                )} */}
                <SwiperSlide className="active">
                  <Link to="/mypage" className="link">
                    개인정보
                  </Link>
                </SwiperSlide>
                {/* <SwiperSlide>
                  <Link to="/question/storage" className="link">
                    질문보관함
                  </Link>
                </SwiperSlide> */}
              </Swiper>
            </div>
          </div>
        </div>

        <section className="modify_wrap">
          <div className="modify_wrapper">
            <ul>
              <li>
                <label className="mypage_label" htmlFor="username">
                  성명
                </label>
                <input
                  id="username"
                  className="mypage_input"
                  type="text"
                  // placeholder="e.g. 홍길동"
                  value={info?.name}
                  onChange={(e) => onSetInfo("name", e.target.value)}
                  readOnly={true}
                />
              </li>
              <li>
                <label className="mypage_label" htmlFor="useremail">
                  이메일
                </label>
                <input
                  id="useremail"
                  className="mypage_input"
                  type="email"
                  // placeholder="e.g. abc@webinars.co.kr"
                  value={info?.email}
                  onChange={(e) => onSetInfo("email", e.target.value)}
                  readOnly={true}
                />
              </li>
              <li>
                <label className="mypage_label" htmlFor="userphone">
                  모바일
                </label>
                <input
                  id="userphone"
                  className="mypage_input"
                  type="text"
                  // placeholder="e.g. 01012345678"
                  value={info?.mobile}
                  onChange={(e) => onSetInfo("mobile", e.target.value)}
                  readOnly={true}
                />
              </li>
              <li>
                <label className="mypage_label" htmlFor="usercompany">
                  회사명
                </label>
                <input
                  id="usercompany"
                  className="mypage_input"
                  type="text"
                  // placeholder="e.g. 한국병원"
                  value={info?.companyName}
                  onChange={(e) => onSetInfo("companyName", e.target.value)}
                  readOnly={true}
                />
              </li>
              <li>
                <label className="mypage_label" htmlFor="userpartname">
                  부서명
                </label>
                <input
                  id="userpartname"
                  className="mypage_input"
                  type="text"
                  // placeholder="e.g. 소아과"
                  value={info?.deptName}
                  onChange={(e) => onSetInfo("deptName", e.target.value)}
                  readOnly={true}
                />
              </li>
            </ul>
          </div>
          <div className="modify_wrapper mt-4">
            <span className="mypage_label" style={{ marginBottom: "0" }}>
              ※ 개인정보 수정을 희망하시는 경우 심포지엄 사무국으로 연락
              부탁드립니다.
            </span>
          </div>
        </section>
      </>
    </Fragment>
  );
};

