import * as Detect from "react-device-detect";
import qs from "query-string";const utils = {};



utils.isEmail = (email) => {
  if (!email) {
    return false;
  }
  // const reg = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  const reg = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (reg.test(email)) {
    return true;
  }
  return false;
};

utils.isMobilePhoneNumber = (mobile) => {
  if (!mobile) {
    return false;
  }
  const reg = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/;
  return reg.test(mobile);
  
};

utils.isCampaignPhoneNumber = (mobile) => {
  const reg = /^[0-9]*$/;
   return reg.test(mobile);
};

utils.isModPhoneNumber = (mobile) => {
  const reg = mobile.replace(/\D/g, '');
   return reg
};

utils.encode = (o) => {
  return btoa(encodeURIComponent(JSON.stringify(o)));
};

utils.decode = (str) => {
  return JSON.parse(decodeURIComponent(atob(str)));
};

utils.postMessage = (code, data) => {
  window.postMessage(
    JSON.stringify({ code: code, data: data }),
    window.location.origin
  );
};

utils.parseMessage = (e) => {
  return new Promise((resolve, reject) => {
    if (!e.data) {
      reject && reject();
      return;
    }
    let data = null;
    try {
      data =
        typeof e.data === "string" && e.data.startsWith("{")
          ? JSON.parse(e.data)
          : e.data;
    } catch (e) {
      reject && reject();
      return;
    }
    try {
      if (data && data.code) {
        resolve && resolve(data);
      }
    } catch (e) {
      reject && reject(e);
    }
  });
};

utils.isMobile = () => {
  const UserAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (
    UserAgent.match(
      /iPhone|iPad|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
    ) != null ||
    UserAgent.match(/LG|SAMSUNG|Samsung/) != null
  ) {
    return true;
  } else {
    return false;
  }
};

utils.getUserAgent = () => {
  let userAgent = {};
  let device = "";
  if (Detect.isDesktop) device = "pc";
  else if (Detect.isTablet) device = "tablet";
  else if (Detect.isMobile) device = "mobile";
  else if (Detect.isSmartTV) device = "smartTV";
  else if (Detect.isWearable) device = "wearable";
  else device = "unknown";

  userAgent.browserName = Detect.browserName;
  userAgent.browserVersion = Detect.browserVersion;
  userAgent.osName = Detect.osName;
  userAgent.osVersion = Detect.osVersion;
  userAgent.device = device;

  return userAgent;
};

utils.isValidateUrl = (url) => {
  var regexp =
    /https?:\/\/w{0,3}\w*?\.(\w*?\.)?\w{2,3}\S*|www\.(\w*?\.)?\w*?\.\w{2,3}\S*|(\w*?\.)?\w*?\.\w{2,3}[\/\?]\S*/;
  return regexp.test(url);
};

utils.isHost = (type) => {
  return type === "EO" || type === "SU" || type === "AGENCY";
};

utils.isSiteAdmin = (type) => {
  return type === "SITE_ADMIN";
};

utils.isEventAdmin = (type) => {
  return type === "EVENT_ADMIN";
};

utils.isSysAdmin = (type) => {
  return type === "SYSTEM_ADMIN" || type === "DEVELOPER";
};

utils.GetParams = (location) => {
  const search = location?.search;
  if (!search) return null;
  const p = qs.parse(search);
  return p;
};

export default utils;
