import React,{Fragment} from 'react';
import moment from "moment";
import { _CFG } from '../../../../modules/config';

export default (props) => { 
  const { info, passCodeOpt, passcodeNext, isPasswordVisible,
    passCodePrivacyOpen, passCodeUseOpen, passCodeOverseaOpen, onLoginClick, onSetInfo,
    preregEventList, setIsPasswordVisible,goEventSessionList,goMypage,passCodeShowTerms,setPassCodeUseAgree,setPassCodePrivacyAgree,setPassCodeOverseaAgree } = props
  return (
    <div className="login_box psc_box">
      {!passcodeNext && (
        <div className="psc_box1">
          {passCodeOpt[0]?.topPhraseFormUse !== 'N' ? (
            <div dangerouslySetInnerHTML={{__html: passCodeOpt[0]?.topPhraseForm}}/>
          ) : ''}
          <div className="login_input_div">
            <input
              className="login_input"
              id="psc_password"
              type={!isPasswordVisible ? "password" : "text"}
              placeholder="Please enter your passcode."
              defaultValue=""
              onKeyPress={(e) => {
                if (e.key == "Enter"){
                  onLoginClick(e);
                } 
              }}
            />
              <img
                src="https://cdn.onoffevent.com/login/icon-s-password.svg"
                onClick={(e)=>setIsPasswordVisible(!isPasswordVisible)}
                style={{opacity:`${isPasswordVisible == true? "0.5":"1"}`,cursor:"pointer"}}
              />
          </div>
          {(passCodePrivacyOpen == true || passCodeUseOpen == true || passCodeOverseaOpen == true) && (
            <div className="agree_form">
              <ul>    
                {passCodePrivacyOpen == true && (
                  <li>
                    <p>
                      <input type="checkbox" id="login_agree_box2" onChange={(e) => setPassCodePrivacyAgree(e.target.checked)}/>
                      <label id="chk_label" htmlFor="login_agree_box2" >
                        <span></span>
                        {`${passCodeOpt[0].termsPrivacyFormLabel} ${passCodeOpt[0]?.termsPrivacyFormEsUse == 'Y' ? '(Required)' : '(Selected)'}`}
                      </label>
                    </p>
                    <a style={{ cursor: "pointer",whiteSpace:"nowrap" }} onClick={passCodeShowTerms.bind(this, "termsPrivateAgree")}>
                      View Detail
                    </a>
                  </li>
                )}
                {passCodeOverseaOpen == true && (
                  <li>
                    <p>
                      <input type="checkbox" id="login_agree_box3" onChange={(e) => setPassCodeOverseaAgree(e.target.checked)}/>
                      <label id="chk_label" htmlFor="login_agree_box3">
                        <span></span>
                        {/* {passCodeOpt[0].termsOverseaLabel} */}
                        {`${passCodeOpt[0]?.termsOverseaLabel} ${passCodeOpt[0]?.termsOverseaEsUse == 'Y' ? '(Required)' : '(Selected)'}`}
                        </label>
                    </p>
                    <a style={{ cursor: "pointer",whiteSpace:"nowrap"}} onClick={passCodeShowTerms.bind(this,"termsOverseaAgree")}>
                      View Detail
                    </a>
                  </li>
                )}
                {passCodeUseOpen == true && (
                  <li>
                    <p>
                      <input type="checkbox" id="login_agree_box" onChange={(e) => setPassCodeUseAgree(e.target.checked)}/>
                      <label id="chk_label" htmlFor="login_agree_box">
                        <span></span>
                        {/* {passCodeOpt[0].termsUseFormLabel} */}
                        {`${passCodeOpt[0]?.termsUseFormLabel} ${passCodeOpt[0]?.termsUseFormEsUse == 'Y' ? '(Required)' : '(Selected)'}`}
                      </label>
                    </p>
                    <a style={{ cursor: "pointer" }} onClick={passCodeShowTerms.bind(this,"termsUseAgree")}>
                      View Detail
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
          <div className="login_enter">
            <span id="passcode_submit_btn" onClick={(e) => onLoginClick(e)}>
              {passCodeOpt[0] != null ? (passCodeOpt[0].loginBtnLabel != null && passCodeOpt[0].loginBtnLabel != "" ? passCodeOpt[0].loginBtnLabel : "Check out the symposium") : "Check out the symposium"}
            </span>
          </div>
        </div>
      )}
      {passcodeNext && (
        <>
        {preregEventList?.length > 0 && (
          <div
            className="psc_box2"
            style={{ display: "block" }}
          >
            <p>
              You are registered for a total of {preregEventList.length} event(s).
              <br />
              You can enter by selecting the event below.
            </p>
            {preregEventList?.map((item, i) => (
              <Fragment key={i}>
                <div
                  className="login_session psc_session"
                  onClick={(e) =>
                    goEventSessionList(item, e)
                  }
                >
                  <div className="psc_hd">
                    <h4 className="login_session_h4">
                      <a>{item.name}</a>
                    </h4>
                    <p>
                      <img
                        src="https://cdn.onoffevent.com/common/arrow_select.svg"
                        alt="Expand"
                      />
                    </p>
                  </div>
                  {(item?.startDate && item.endDate) ? (
                  <p className="login_session_p">
                    {moment(item?.startDate).locale("en").format("ddd, DD MMM YYYY HH:mm")}{" "}
                    ~
                    {" "}{moment(item?.endDate).locale("en").format(`
                      ${moment(item?.startDate).locale("en").format('YYYY.MM.DD') == moment(item?.endDate).locale("en").format('YYYY.MM.DD') ? 'HH:mm' : 'ddd, DD MMM YYYY HH:mm'}`
                    )+" KST"}
                  </p>
                  )
                  : ""
                    }
                </div>
              </Fragment>
            ))}
          </div>
        )}

          <a href="#" onClick={e => goMypage(e)}  style={{display:"block",textAlign:"right"}}>My Page</a>
        </>
      )}
      
    </div>
  )
}