import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { AppContext } from "../../components/AppProvider";
import { _CK, _U, _R } from "../../modules/utils";
import { _CFG } from "../../modules/config";
import useStore from "../../modules/utils/store";

const isDeveloper = (type) => {
  return type === "DEVELOPER";
};

const isSysAdmin = (type) => {
  return type === "SYSTEM_ADMIN" || type === "DEVELOPER";
};

export const Header = (props) => {
  const {history} = props;
  const { iam, saveAuth, saveIam, auth } = useContext(AppContext);
  const { siteInfo } = useStore();
  const [cnt, setCnt] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const documentRef = useRef(document);

  const listPage = window.location.href.includes('/list');

  const handleScroll = () => {
    const { pageYOffset } = window;
    setScrollY(pageYOffset);
  };
  const onLogout = (e) => {
    if (e) e.preventDefault();
    const confirmChk = window.confirm('로그아웃 하시겠습니까?');
    if(confirmChk){
      saveIam(null);
      saveAuth("");
      _CK.clearLogKey();
      _CK.clearAuth();
      // history.push('');
      window.location.href = "/"
      window.reload();
    }
  };

  const movePage = (url, type, e) => {
    if (e) e.preventDefault();
    if (type === "PAGE") {
      window.location.href = url;
      window.location.reload();
    } else {
      window.open(url, "_blank");
    }
  };

  const hubSite = _CFG.hubUrl();

  const goEventList = (e) => {
    if(e) e.preventDefault();
    history.push(`/site/${siteInfo?.idx}/event/list`);
  }

  useEffect(() => {
    documentRef.current.addEventListener("scroll", handleScroll);
  });

  return (
    <header id="header">
      <div id="header-freeze" className="header-freeze"></div>
      <div className={cx({ header: true, fixed: scrollY > 0 })}>
        {siteInfo?.logoUseYn === "Y" && listPage === false && (
          <a onClick={(e) => goEventList(e)} style={{cursor: iam?.targetType == 'MEDIGATE' ? 'default' : 'pointer'}}>
            { siteInfo?.logoImgUrl == null || siteInfo?.logoImgUrl == "" ?
              "" :
              <img src={siteInfo.logoImgUrl} alt={siteInfo?.name} />
            }
          </a>
        )}
        {siteInfo?.logoUseYn === "Y" && listPage === true && (
          <a style={{cursor: iam?.targetType == 'MEDIGATE' ? 'default' : 'pointer'}}>
            { siteInfo?.logoImgUrl == null || siteInfo?.logoImgUrl == "" ?
              "" :
              <img src={siteInfo.logoImgUrl} alt={siteInfo?.name} />
            }
          </a>
        )}
      </div>
    </header>
  );
};
