import React from "react";
import styled from "styled-components";

export const Row = styled.div.attrs((props) => {
    return { className: !props.className && "row" };
})``;

export const Col = styled.div.attrs((props) => {
    return { className: !props.className && "col" };
})``;
