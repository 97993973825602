import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Helmet } from "react-helmet";
import { AppContext } from "../../../../components/AppProvider";
import {  _API, _CK, _U, _AC } from "../../../../modules/utils";

import styled from "styled-components";
import { Link } from "react-router-dom";
import cx from "classnames";

import SessionListDetail from "./SessionList.Detail";
import Modal from "../../../../components/UI/preReg/Modal";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import RegisterAccompanyAttendModal from "../../../../components/register/Register.AccompanyingAttendModal";


//Speaker.Modal
import SpeakerModal from "../../../../components/register/Speaker.Modal";
import { Loading } from "../../../../components/UI/Loading";

import moment from "moment";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import ImgPhoto from "../../../../resources/console/images/icon/default-user.svg";
import useStore from "../../../../modules/utils/store";


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default (props) => {
  const { auth, iam } = useContext(AppContext);
  const { saveAuth, saveIam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const { history, match, sessionInfo } = props;
  // 사이트 정보

  // 이벤트 정보
  const [eventInfo, setEventInfo] = useState({});

  // 세션 리스트
  const [sessionList, setSessionList] = useState([]);
  // 사전등록 세션 리스트
  const [preregSessionList, setPreregSessionList] = useState([]);

  const [managerInfo, setManagerInfo] = useState(null);
  const [sessionMgrInfoModalShow, setSessionMgrInfoModalShow] = useState(false);
  const [accompanyAttendModal, setAccompanyAttendModal] = useState(false);
  const [accompanySessionIdx, setAccompanySessionIdx] = useState();
  const [loaded, setLoaded] = useState(false);

  const [allBtnSelected, setAllBtnSelected] = useState(true);
  const [tags, setTags] = useState([]);

  const Modalstyle = {
    overflowX: "hidden",
    overflowY: "auto"
  };
  
  const eventIdx = match?.params.eventIdx;
 
  const initPage = () => {
    //애니메이션 설정 (gsap)
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.core.globals("ScrollTrigger", ScrollTrigger);

      ScrollTrigger.matchMedia({
        // desctop
        "(min-width: 768px)": function () {
          gsap.set(".sticky-wrap", {
            marginTop: -60,
            top: 0,
          });
          ScrollTrigger.create({
            trigger: ".button-register-wrap",
            start: "top top",
            // markers: true,
            onEnter: function () {
              gsap.to(".sticky-wrap", {
                marginTop: 0,
                duration: 0.35,
              });
            },
            onLeaveBack: function () {
              gsap.to(".sticky-wrap", {
                marginTop: -60,
                duration: 0.35,
              });
            },
          });
        },
        // mobile
        "(max-width: 767px)": function () {
          gsap.set(".sticky-wrap", {
            top: "120%",
            marginTop: -60,
          });
          ScrollTrigger.create({
            trigger: ".site",
            start: "top top",
            onEnter: function () {
              gsap.to(".sticky-wrap", {
                top: "100%",
                duration: 0.35,
              });
            },
            onLeaveBack: function () {
              gsap.to(".sticky-wrap", {
                top: "120%",
                duration: 0.35,
              });
            },
          });
        },
      });
    }
    const body = document.getElementById("body");
  };

  const loadEventData = () => {
    if (!siteInfo.idx || !eventIdx) {
      alert("올바른 접근이 아닙니다.");
      return;
    }
    const req = {
      path: "/v1/client/event/info",
      data: {
        siteIdx: siteInfo.idx,
        eventIdx,
      },
    };
    _API.post(req).then((res) => {
        const { data } = res;
        if (data) {
          // 이벤트 정보 셋팅
          setEventInfo(data);
        } else {
          swal("이벤트 정보가 없습니다.");
          history?.push(`/site/${siteInfo.idx}/event/list`);
        }
      }).catch((e) => {
        history?.push(`/site/${siteInfo.idx}/event/list`);
      });
  };

  const loadSessionList = () => {
    if (!eventIdx) {
      return;
    }

    const req = {
      path: "/v1/client/session/list",
      data: {
        eventIdx,
        status: "Y",
        preregIdx: iam.preregIdx,
      },
    };

    _API.post(req).then((res) => {
      const { data } = res.data;

      if (data != null) {
        const pSessionList = [];
        for (let i in data) {
          const session = data[i];
          if (session.preregSessionIdx) {
            pSessionList.push(session);
          }
        }
        setSessionList(data);
        setPreregSessionList(pSessionList);
        setLoaded(true);
      }
    });
  };

  const setSessionMgrInfoModal = (show, item) => {
    setSessionMgrInfoModalShow(show);
    setManagerInfo(item);

    if (show){
      setTimeout(() => {
        $("#mgrInfoModal").modal("show");
      }, 10);
    } else {
      $("#mgrInfoModal").modal("hide");
    }
  };

  const checkEventAccess = async () => {
    const req = {
      path: "/v1/client/event/access/check",
      data: {
        siteIdx: siteInfo.idx,
        eventIdx: eventIdx || match?.params.eventIdx,
      },
      auth: auth,
    };
    await _API.post(req).then((res) => {
      const { accessKey, data } = res.data;
      if (data) {
        _CK.setAuth(accessKey);
        saveIam(data);
        saveAuth(accessKey);

        initPage();
        loadEventData();
        loadSessionList();
      }
    }).catch((e) => {
      if (iam?.targetType === "GUEST") {
        window.location.href = `/#/event/${iam.eventIdx}/register`;
        window.location.reload();
      } else if (iam?.targetType !== "GUEST") {
        history?.push(`/site/${siteInfo.idx}/event/list`);
      }
    });
  };

  useEffect(() => {
    if (_CK.getLogKey() == null) {
      alert("다시 로그인 해주세요.");
      _CK.setConsoleCountView(0);
      window.location.href = `/`;
    }
  }, [eventInfo]);

  const getTagList = () => {
    const req = {
      path: "/v1/client/session/tag/list",
      data: {
        eventIdx: eventIdx || match?.params.eventIdx,
      },
    };
    _API.post(req).then((res) => {
      const { data } = res.data;
      console.log(data)
      setTags(data);
    });
  }
  
  const handleItemClick = (item) => {
    if(item == null){
      // 상태 업데이트를 반영하기 위해 brands 배열도 업데이트
      const updatedTagList = tags.map((tag) => {
        let newItem = {...tag};
        newItem.selected = allBtnSelected == true ? 0 : 1;
        return newItem;
      });
      // 상태 업데이트를 반영하기 위해 brands 배열도 업데이트
      const updatedSessionList = sessionList.map((session) => {
        let newItem = {...session};
        newItem.visible = allBtnSelected == true ? 0 : 1;
        return newItem;
      });
      setTags(updatedTagList);
      setSessionList(updatedSessionList);
      setAllBtnSelected((allBtnSelected == true) ? false : true)
    }else{
      // 상태 업데이트를 반영하기 위해 brands 배열도 업데이트
      const updatedTagList = tags.map((tag) => {
        let newItem = {...tag};
        if(newItem.tagName == item.tagName){
          newItem.selected = (newItem.selected == 1) ? 0 : 1;
        }
        return newItem;
      });

      let selectedTags = updatedTagList.filter((sTag) => sTag.selected == 1);
      // 상태 업데이트를 반영하기 위해 brands 배열도 업데이트
      const updatedSessionList = sessionList.map((session) => {
        let newItem = {...session};
        if(selectedTags.length == tags.length){
          newItem.visible = 1;
          return newItem;
        }else{
          // selectedTags 중 하나라도 session.tagName에 포함되어 있는지 확인
          let includes = selectedTags.some((sTag) => 
            newItem.tagName != null && newItem.tagName.includes(sTag.tagName)
          );
          newItem.visible = includes ? 1 : 0;
          return newItem;
        }
      });

      setTags(updatedTagList);
      setSessionList(updatedSessionList);
      if(updatedTagList.filter((item) => item.selected == 1).length == tags.length){
        setAllBtnSelected(true)
      }else{
        setAllBtnSelected(false)
      }
    }
  };
  
  useEffect(() => {
    getTagList()
    checkEventAccess();
  }, []);

  if (!loaded) {
    return (
      <img
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "200px",
          height: "200px",
          display: "block",
          transform: "translate(-50%,-50%)",
        }}
        className="loading_img loading_img6"
        src="https://cdn.onoffevent.com/common/loading.gif"
      />
    );
  }
  return (
    <Fragment>
      <SessionListDetail
        eventInfo={eventInfo}
        setEventInfo={setEventInfo}
        sessionList={sessionList}
        setSessionList={setSessionList}
        setAccompanySessionIdx={setAccompanySessionIdx}
        setAccompanyAttendModal={setAccompanyAttendModal}
        setSessionMgrInfoModalShow={setSessionMgrInfoModalShow}
        setManagerInfo={setManagerInfo}
        handleItemClick={handleItemClick}
        allBtnSelected={allBtnSelected}
        tags={tags}
      />
      {accompanyAttendModal && (
        <Modal modalClass="d-block" modalstyle={Modalstyle}>
          <RegisterAccompanyAttendModal
            eventIdx={eventIdx}
            sessionIdx={accompanySessionIdx}
            setAccompanyAttendModal={setAccompanyAttendModal}
          />
        </Modal>
      )}
      {sessionMgrInfoModalShow && (
        <Modal modalClass="d-block" modalstyle={Modalstyle}>
          <SpeakerModal
            managerInfo={managerInfo}
            setSessionMgrInfoModalShow={setSessionMgrInfoModalShow}
            defaultProfileImg={ImgPhoto}
          />
        </Modal>
      )}
    </Fragment>
  );
};
