import React from "react";
import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
const StyleButton = styled(MuiButton)({
  minWidth:53,
  fontSize: 14,
  padding: '6px 10px',
  lineHeight: 1.5,
  color:"#fff",
  backgroundColor: '#5959EF',
  fontFamily:"Noto Sans KR",
  '&:hover': {
    backgroundColor: '#5959E0',
    borderColor: '#5959E0',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#5959EF',
    borderColor: '#5959E6',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(89, 89, 239,.5)',
  },
  
});

const ColorButton = styled(MuiButton)(({ theme }) => ({
  minWidth:53,
  fontSize: 14,
  padding: '6px 10px',
  lineHeight: 1.5,
  color: "#464F60",
  fontWeight:500,
  backgroundColor: "#EBEBF8",
  '&:hover': {
    backgroundColor: "#EBEBFf",
  },
}));
export const Button = (props) => {
  const { type, onClick,sx,children } = props;
  return (
    type != "outlined" ? (
      <StyleButton onClick={onClick} sx={sx}>{children}</StyleButton>
    ) : (
      <ColorButton onClick={onClick} sx={sx}>{children}</ColorButton>
    )
  );
}

export const ImgButton = (props) => { 
  const { type, text, onClick,sx, children } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={sx}
    >
      {children}
    </IconButton>
  );
}
export default Button;
