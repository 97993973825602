import React from "react";
import { Chip } from '@mui/material';

export default function (props) {
  const { text, bgColor ,margin, cursor, fontSize, size} = props;
  return (
    <Chip
      label={text}
      sx={{
        display: "inline-block",
        border: `1px solid 
          ${text == "ONLINE" ? "#B871FF"
          : text == "ONLINE/OFFLINE" ? "#FFBE5C"
          : text == "OFFLINE" ? "#18C7B2"
          : text == "LIVE/ONDEMAND" ? "#95C57E"
          : text == "LIVE" ? "#FF7373"
          : text == "ONDEMAND" ? "#8F8FFF"
          : text == "HOST" ? "#5959EF"
          :"none"
          }
        `,
        
        backgroundColor:`${bgColor?"#ddd":"#fff"}`,
        borderRadius: "4px",
        fontSize: 10,
        height: "18px",
        fontWeight:"400",
        margin:`${margin?margin:0}`,
        marginRight: "4px",
        '& .MuiChip-label': {
          padding: "0px 6px", fontSize: fontSize || "10px", lineHeight: "15px",
          color: ` 
          ${text == "ONLINE" ? "#B871FF"
          : text == "ONLINE/OFFLINE" ? "#FFBE5C"
          : text == "OFFLINE" ? "#4DE4D2"
          : text == "LIVE/ONDEMAND" ? "#95C57E"
          : text == "LIVE" ? "#FF7373"
          : text == "ONDEMAND" ? "#8F8FFF"
          : text == "HOST" ? "#5959EF"
          : bgColor ? "#646464"
          :"#646464"
          }
        `,},
        cursor:`${cursor?"pointer":"default"}`,
      }}
      size={size || "small"}
    />
  )
}