import React, { Fragment, useState, useRef, useEffect, useContext } from "react";
import swal from "sweetalert";
import styled from "styled-components";
import cx from "classnames";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import EditImage from "../../../../resources/console/images/icon/Type-B/notice_edit.svg";
import arrow from "../../../../resources/console/images/icon/Type-B/notice_arrow.svg";

export default (props) => {
  const { iam } = useContext(AppContext);
  const { consoleCfg, timeCalculation} = props;
  const [isSendProc, setIsSendProc] = useState(false);
  
  const [notice, setNotice] = useState([]);
  const [noticeContent, setNoticeContent] = useState(null);
  const [noticeChangeForm, setNoticeChangeForm] = useState(false);
  const [noticeOpen, setNoticeOpen] = useState(false);
  const [noticeHeight, setNoticeHeight] = useState(false);

  const noticeSave = (item) => {
    const req = {
      path: "/v1/event/session/notice/save",
      data: {
        idx: notice.idx,
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
        title: notice.title,
        content: item.content,
        sort: item.sort,
        status: item.status,
      },
    };

    _API.post(req).then((res) => {
      let { data } = res;
      if (data > 0) {
        loadNoticeList();
        onSend();
        setNoticeChangeForm(false);
        $(".alert-wrap").fadeIn();
      }
    });
  };

  const noticeAction = () => {
    if (noticeContent == null || noticeContent == "") {
      swal("공지사항을 입력해 주세요.");
      return;
    }
   

    const params = {};
    params.eventIdx = consoleCfg.eventIdx;
    params.sessionIdx = consoleCfg.sessionIdx;
    params.consoleIdx = consoleCfg.consoleIdx;
    params.title = `공지사항 [이벤트 : ${consoleCfg.eventIdx} / 세션 : ${consoleCfg.sessionIdx}]`;
    params.name = iam.name;
    params.content = noticeContent;
    params.sort = 1;
    params.status = "Y";
    
    noticeSave(params);
  };

  const loadNoticeList = () => {
    const req = {
      path: "/v1/client/session/notice/list",
      data: {
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
      },
    };

    _API.post(req).then((res) => {

      let { data } = res.data;

      if (data) {
        setNotice(data);
        setNoticeContent(data.content);
      }
    });
  };

  const onSend = (isDelete) => {
    if (!noticeContent && !isDelete) {
      return;
    }
    if (isSendProc) {
      return;
    }
    setIsSendProc(true);
    _U.postMessage("SOCKET-SEND", {
      action: "NOTICE",
      data: {
        mode: "NOTICE",
        siteIdx: consoleCfg?.siteIdx,
        eventIdx: consoleCfg?.eventIdx,
        sessionIdx: consoleCfg?.sessionIdx,
        consoleIdx: consoleCfg?.consoleIdx,
        name: iam?.name,
        memType: iam?.memType,
        companyName: iam?.companyName,
        deptName: iam?.deptName,
        memberIdx: iam?.idx,
        noticeContent: noticeContent,
      },
    });
    setIsSendProc(false);
  };

  const onReceivedMessage = (e) => {
    _U.parseMessage(e)
      .then((data) => {
        switch (data.code) {
          case "SOCKET-RECEIVED":
            return data.data ? JSON.parse(data.data) : null;
        }
      })
      .then((data) => {
        if (data?.mode === "NOTICE") {
          if (data.noticeContent) {
            $("#noticeAlert").text(data.noticeContent);
            $(".alert-wrap").fadeIn();
          }
          loadNoticeList();
        }
      });
  };

  const changeNoticeForm = (show) => {
    if (!show) {
      setNoticeContent(notice.content);
    }
    setNoticeChangeForm(show);
  };

  const editform = () => { 
    changeNoticeForm(true);
    setNoticeOpen(false);
  }

  useEffect(() => {
    window.addEventListener("message", onReceivedMessage);
    return () => {
      window.removeEventListener("message", onReceivedMessage);
    };
  }, []);

  useEffect(() => {
    loadNoticeList();
  }, [consoleCfg]);
  useEffect(() => {
    let noticeContent = $(".detail_notice_free_content > pre > div")
    let ncHeight = noticeContent.height();
    setNoticeHeight(ncHeight);
  })
  return (
    <Fragment>
      <div className={cx({ detail_free_inner_box: true})}>
        <div className={cx({ detail_notice_free_content: true, on: noticeChangeForm,open: noticeOpen})} >
          {/* <div>{timeCalculation("TEST 01:01 01:01:01 01:02 01:01:02")}</div> */}
          {notice != null && notice.content != null ? <pre>{timeCalculation(notice.content)}</pre> : `참여해 주셔서 감사드립니다.\n시청 중 불편사항은 아래 사무국 번호로 문의해 주시기 바랍니다.`}
          <div className="img_box">
            {noticeHeight > 60 && (
              <img src={arrow} alt="접기 펼치기" className="moreView" onClick={() => {setNoticeOpen(!noticeOpen)}} />
            )}
            {iam?.memAuth === "ADMIN" && !noticeChangeForm &&(
              <img
                className="edit_image"
                src={EditImage}
                onClick={(e) => editform()}
              />
            )}
          </div>
        </div>
        {noticeOpen && (  
          <div className="detail_notice_full_content">
            {notice != null && notice.content != null ? <pre>{timeCalculation(notice.content)}</pre> : `참여해 주셔서 감사드립니다.\n시청 중 불편사항은 아래 사무국 번호로 문의해 주시기 바랍니다.`}
            <div className="img_box">
              {noticeHeight > 60 && (
                <img src={arrow} alt="접기 펼치기" className="moreView" onClick={() => { setNoticeOpen(!noticeOpen) }} />
              )}
              {(iam?.memAuth === "ADMIN" && !noticeChangeForm ) && (
                <img
                  className="edit_image"
                  src={EditImage}
                  onClick={(e) => editform()}
                />
              )}
            </div>
          </div>
        )}
        {iam?.memAuth === "ADMIN" && (
          <div className={cx({
                admin_notice_container: true,
                on: noticeChangeForm,
              })}>
            <div className={cx({ admin_notice_text: true, on: noticeChangeForm })}>
              <div className="admin_notice_container_inner">
                <textarea
                  type="text"
                  maxLength="500"
                  placeholder=""
                  row="5"
                  value={noticeContent}
                  onChange={(e) => setNoticeContent(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className={cx({ ad_notice_button_box: true, on: noticeChangeForm })}>
              <span>{noticeContent != null && noticeContent != "" ? noticeContent.length : 0}/500</span>
              <div style={{display:"flex",alignItems:"center",gap:"4px"}}>
                <button
                  className="cancel"
                  onClick={(e) => changeNoticeForm(false)}
                >
                  취소
                </button>
                <button
                  type="button"
                  className="ad_notice_save"
                  id="button-addon22"
                  onClick={noticeAction}
                >
                  등록
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const CMessage = styled.div`
  margin-bottom: 0.5rem;
  span {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`;
