import React, { useContext, useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import styled from 'styled-components';
import close from "../../../resources/console/images/icon/attend_close.svg"
import { AppContext } from '../../../components/AppProvider';
import { _API } from '../../../modules/utils';
export default (props) => {
  const { iam } = useContext(AppContext);
  const { attendModal,setAttendModal,attendanceState,setAttendanceState, consoleCfg, sessionAttendance, getSessionAttendanceInfo } = props;
  
  const handleClose = () => {
    setAttendModal(false);
  };
  const attendanceStateChange = () => {
    attendanceState ? sessionAttendanceExit() : sessionAttendanceEntry();
  }

  const movePage = (url) => {
    if(window.confirm('시청을 중단하시겠습니까?')){
      window.location.href = url;
    }
  }

  // 세션 출석하기
  const sessionAttendanceEntry = async () => {
    if(sessionAttendance?.entryTime){
      setAttendanceState(true);
      setAttendModal(false);
      return;
    }
    const req = {
      path: "/v1/client/session/attendance/entry",
      data: {
        sessionIdx: consoleCfg.sessionIdx,
        preregIdx: iam?.preregIdx,
        passCode: iam?.passCode
      },
    };

    await _API.post(req).then((res) => {
      let { idx, status } = res;
      if (status === 'SUCCESS') {
        setAttendanceState(true);
        setAttendModal(false);
        getSessionAttendanceInfo();
      }
    });
  };

  // 세션 퇴장하기
  const sessionAttendanceExit = async () => {
    const req = {
      path: "/v1/client/session/attendance/exit",
      data: {
        sessionIdx: consoleCfg.sessionIdx,
        preregIdx: iam?.preregIdx,
        passCode: iam?.passCode
      },
    };

    await _API.post(req).then((res) => {
      let { affectedIdx, status } = res;
      if (status === 'SUCCESS') {
        setAttendanceState(false);
        setAttendModal(false);
        getSessionAttendanceInfo();
        movePage(`/#/event/${consoleCfg?.eventIdx}/register`);
        // movePage(`/#/site/${consoleCfg?.siteIdx}/event/list`);
      }
    })
  };

  return (
    
    <Modal
        show={attendModal}
        onHide={handleClose}
        backdrop="static"
        id="attendModal"
    >
      <Box> 
        <div className="attend_modal">
          <div className="attend_header">
            <h2>{attendanceState ? "퇴장하시겠습니까?" : "출석하시겠습니까?"}</h2>
            {consoleCfg?.popupCloseUseYn === 'Y' && <button onClick={handleClose}><img src={close} /></button>}
          </div>
          <div className="attend_content" dangerouslySetInnerHTML={{__html: attendanceState ? (consoleCfg?.exitPopupText ? consoleCfg?.exitPopupText : `
              출석버튼을 체크하지 않으면 참가 인정이 안될 수 있습니다.<br/>
              반드시 강의 시청 전후로 출석하기/퇴장하기 버튼을 클릭해 주세요.
            `) : (consoleCfg?.attendPopupText ? consoleCfg?.attendPopupText : `
              출석버튼을 체크하지 않으면 참가 인정이 안될 수 있습니다.<br/>
              반드시 강의 시청 전후로 출석하기/퇴장하기 버튼을 클릭해 주세요.
            `)}}></div>
          <div className="attend_footer">
            <button className="confirm" onClick={attendanceStateChange}>{attendanceState ? "예" : "출석하기"}</button>
            {consoleCfg?.popupCloseUseYn === 'Y' && <button onClick={handleClose}>아니요</button>}
          </div>
        </div>
      </Box>
    </Modal>
  )
}

const Box = styled.div`
  @media (max-width: 767px) {
    .attend_modal .attend_footer button { width: 100%; }
  }
`