import React, { useContext, useEffect, useState } from "react";
import { Fragment } from 'react';
import TopArroow from "../../../../resources/console/images/icon/top_arrow.svg";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../components/AppProvider";
import useStore from "../../../../modules/utils/store";
export const Footer = (props) => {
  const { auth, iam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const { footerClass, eventInfo } = props;
  const [termsInfo, setTermsInfo] = useState(null);
  
  const showTerms = (type, e) => {
    if (e) e.preventDefault();
    $("body").css("overflow", "hidden");
    if (type === "termsUseAgree")
      setTermsInfo({ title: "사이트이용약관", content: siteInfo?.memTermsUseForm });
    else if (type === "termsPrivateAgree")
      setTermsInfo({
        title: "개인정보처리방침",
        content: siteInfo?.memTermsPrivacyForm,
      });
    else if (type === "termsMarket")
      setTermsInfo({
        title: "마케팅정보 수신동의",
        content: siteInfo?.memTermsMarketForm,
      });
    else {
      setTermsInfo(null);
    }
  };

  const modalClose = () => {
    setTermsInfo(null);
    $("body").css("overflow", "auto");
  };

  return (
    <footer id="footer">
      <div className="footer-arrow-wrap">
        <button type="button" className="go-to-top">
            <img src={TopArroow} alt="탑버튼" />
            <span>TOP</span>
        </button>
      </div>
      {!['MEDIGATE','ICOD'].includes(iam?.targetType) && (<>
      <div className="footer_inform_cont">
        <div className="wid_1180">
          <ul>
            {siteInfo?.subPageList.map((item, i) => {
              return(
                <>
                {item.isShortcutShow == 1 && (
                  item.pageType == "LINK" ? (
                    <li>
                      <a href={`https://${item.link}`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} >
                        <span>{item?.name}</span>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a href={`/#/site/${siteInfo.idx}/subpage/${item.pageId}`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} >
                        <span>{item?.name}</span>
                      </a> 
                    </li>
                  )
                )}
                </>
              )
            })}
          </ul>
        </div>
      </div>
      </>)}
      <div className="footer">
        <div className="wid_1180">
          <pre className="ft_info">
            <div dangerouslySetInnerHTML={{ __html: siteInfo?.footer?.replace(/\n/g,'') }} />
          </pre>
          {iam?.targetType == "MEMBER" && siteInfo?.memberFooterCheck === "Y" ? (
            <div className="ft_info2" style={{ marginTop: "30px" }}>
              <a onClick={showTerms.bind(this, "termsPrivateAgree")}>개인정보처리방침.</a>
              <a onClick={showTerms.bind(this, "termsUseAgree")}>이용약관</a>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {termsInfo && (
        <Fragment>
          <div className="ft_modal_container">
            <div className="ft_modal_header">
              <div>
                <h2>{termsInfo?.title}</h2>
                <button
                  className="close_btn"
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    modalClose();
                  }}
                  >
                  <img src="https://cdn.onoffevent.com/common/icon_close.svg" />
                </button>
              </div>
            </div>
            <div className="ft_modal_content">
              <div dangerouslySetInnerHTML={{ __html: termsInfo?.content }} />
            </div>
          </div>
          <div className="modal_bg on"></div>
      </Fragment>
      )}
    </footer>
  );
};
