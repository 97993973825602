import React, { Fragment,useState,useEffect, useContext} from 'react';
import Layout, { Wrapper } from "../Layouts/Layout";
import { useInView } from 'react-intersection-observer';
import { Link } from "react-router-dom";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import { Box } from '@mui/material';
import NoneImg from "../../../../resources/console/images/icon/noneimg.png";
import loadedImg from "../../../../resources/console/images/icon/Type-B/loaded.png";
import useStore from '../../../../modules/utils/store';
import { AppContext } from '../../../../components/AppProvider';
import { _API, _CK, _U } from "../../../../modules/utils";

export default (props) => { 
  const [isLoading, setIsLoading] = useState(false);
  const { siteInfo } = useStore();
  const { ondemandList,setPreregIdx,setAccompanyEventIdx,setAccompanySessionIdx,setAccompanyAttendModal } = props;
  const { iam,auth } = useContext(AppContext);
  
  const goSymposium = async (item, url) => {
    let preregIdx = 0;
    if(iam.targetType != 'GUEST'){
      const req = {
        path: "/v1/ondemand/prereg/chk",
        data: {
          email: iam.email,
          sessionIdx: item.sessionIdx,
          targetType: iam.targetType == null ? 'MEMBER' : iam.targetType, 
        },
      };
     await _API.post(req).then((res) => {
        const { data } = res.data;
        if(data == 0){
          alert("신청하지 않은 다시보기 입니다.")
          return;
        }else{
          preregIdx = data;
        }
      });
    }
    if(item?.accompanyYn == 'Y'){
      setAccompanyEventIdx(item?.eventIdx)
      setAccompanySessionIdx(item?.sessionIdx);
      setAccompanyAttendModal(true);
      setPreregIdx(preregIdx)
      $(body).css("overflow", "hidden");
    }else{
      const newWindow = window.open(`/#`+url, '_blank'); // 새 창에서 URL을 엽니다

      if (newWindow) {
        newWindow.onload = function() { 
          // 새 창이 열리고 나서 새로 고침을 수행합니다
          newWindow.location.reload();
        };
      } else {
        // 새 창이 팝업 차단기 때문에 열리지 않았을 경우를 처리합니다
        alert('팝업이 차단되었습니다. 팝업 차단기를 해제해주세요.');
      }
    }
  };

  useEffect(() => {
    setIsLoading(false);
  }, [ondemandList]);

  return (
    <>
      {ondemandList.map((m, i) => { 
        return (  
          <li className="main_area_li" key={i}>
            <div className="main_area_img_box">
              <img className="main_area_img" src={(m.thumbnailUrl == null || m.thumbnailUrl == "") ? NoneImg : m.thumbnailUrl} alt="" />
              <small>{m.videoTime}</small>
            </div>
            <div className="main_area_text_box_cont">
              <div className="main_area_text_box">
                <a href={iam.targetType != "GUEST" ? `/#/site/${siteInfo.idx}/event/list` : ""}>{m.eventName}</a>
                <h4><a onClick={(e) => { $(".modal-stack").remove(); goSymposium(m, `/event/${m?.eventIdx}/session/${m.sessionIdx}/default`); }}> {m.name}</a></h4>
                  <p>
                    {moment(m?.startDate).locale('ko').format("YYYY년 MMM DD일 (ddd)")}
                    {moment(m?.startDate).format("YYYYMMDD") != moment(m?.endDate).format("YYYYMMDD") && ` ~ ${moment(m?.endDate).locale('ko').format("YYYY년 MMM DD일 (ddd)")}`}
                  </p>
                <div className="user_list">
                  {(m.userList || []).map((uItem,index) => (
                    <span key={index}>{uItem}</span>
                  ))}
                </div>
                <div className="brand_con">
                  {Array.isArray(m.brandName) ? m.brandName.map((bItem, index) => (
                    <div key={index}>{bItem}</div>
                  )) : (
                    <div>{m.brandName}</div>
                  )}
                </div>
                

              </div>
              <button 
                onClick={(e) => { $(".modal-stack").remove(); goSymposium(m, `/event/${m?.eventIdx}/session/${m.sessionIdx}/default`); }}
                className="main_area_button_box">
                <span>심포지엄 다시 보기</span>
                {m?.accompanyYn == 'Y' ? (<span>(동반참석 진행)</span>) : '' }
              </button>
            </div>
          </li>
        )
      })}
      {isLoading && <img className="loaded_img" src={loadedImg} />}

    </>
  )
}