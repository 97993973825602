import React, { Fragment, useState, useRef, useEffect, useContext } from "react";
import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import useStore from "../../../../modules/utils/store";
import Layout, { Wrapper } from "../Layouts/Layout";

export default (props) => {
    const { iam } = useContext(AppContext);
    const { siteInfo } = useStore();

    const { history, location, match } = props;
    const { pageId } = match.params;
    let subject = "";
    let content = "";
    let pageType = "";
    
    if(siteInfo?.subPageList){
        for(let i in siteInfo.subPageList){
            if(siteInfo.subPageList[i].pageId == pageId){
                subject = siteInfo.subPageList[i].name;
                content = siteInfo.subPageList[i].content;
                pageType = siteInfo.subPageList[i].pageType;
                break;
            }
        }
    }

    useEffect(() => {
        let isAble = siteInfo?.subPageList.filter((i) => i.pageId == pageId)[0].isUse;
        if(isAble == 0){
            window.location.href = "/";
        }
    },[]);

    return (
        <Fragment>
            <Layout {...props} bodyClass="page-registration">
                <Wrapper>
                    <div className="mypage_header_bnr">
                        <h2 className="subpage_title wid_1180">
                            {subject}
                        </h2>
                    </div>
                    <div className="subpage_container" style={{padding:"0 20px"}}>
                        <div className="subpage_wrapper wid_1180">
                            <div className="subpage_box" dangerouslySetInnerHTML={{__html:content}} />    
                        </div>
                    </div>
                </Wrapper>
            </Layout>
        </Fragment>
    );
    
};
