import React, {Fragment, useState, useRef, useEffect, useLayoutEffect, useContext} from "react";

import TypeACampaign from "./TypeA/page/CampaignMain";
import TypeBCampaign from "./TypeB/page/CampaignMain";

export default (props) => {

    const { history, location, match, campaignIdx, templateIdx } = props;

    if (templateIdx != 11) {
        return (
            <Fragment>
                <TypeACampaign
                    history={history}
                    campaignIdx={campaignIdx}
                >
                </TypeACampaign>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <TypeBCampaign
                    history={history}
                    campaignIdx={campaignIdx}
                >
                </TypeBCampaign>
            </Fragment>
        );
    }

};