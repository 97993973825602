/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from "../../../../components/AppProvider";
import { _API } from '../../../../modules/utils';

import Button from "../../../../components/UI/Button";
import moment from "moment";

import Layout, { Wrapper,HiddenWrapper } from "../Layouts/Layout";
import Paging from '../../../../components/pagination/pagination';
import NoticeModal from '../../../../components/notice/Notice.Modal';
import Modal from '../../../../components/UI/preReg/Modal';
import useStore from '../../../../modules/utils/store';

export default (props) => {
  const {iam} = useContext(AppContext);
  const { siteIdx } = props?.match?.params;
  const { siteInfo } = useStore();
  const { history, location, match } = props;
  const pathName = window.location.hash;
  let pageId = "";
  if(pathName){
    let pathInfos = pathName.indexOf("?") === -1 ? pathName.split("/") : pathName.split("?")[0].split("/");
    pageId = pathInfos[4]
  }
    useEffect(() => {
    let isAble = siteInfo?.subPageList.filter((i) => i.pageId == pageId)[0].isUse;
    if(isAble == 0){
      window.location.href = "/";
    }
  },[]);

  const [list, setList] = useState();
  const [paging, setPaging] = useState(null);
  const [detailIdx, setDetailIdx] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [reload, setReload] = useState(false);
  const [formClean, setFormClean] = useState(false);

  const onShowDetail = (idx) => {
    setDetailIdx(idx)
    setShowDetail(true);
  }

  const loadList = (page) => {
    const req = {
      path: "/v1/client/site/notice/list",
      data: {
        siteIdx: siteIdx,
        page: page || 1,
        numPerPage: 10,
        pagePerBlock: 5,
      },
    };
    _API.post(req).then((res) => {
      const { data, paging } = res.data;
      setList(data);
      
      const pagingItems = [];
      
      if (paging) {
        for (let i = paging.startPageNo; i <= paging.endPageNo; i++) {
          pagingItems.push(i);
        }
      }
      paging.pagingItems = pagingItems;
      setPaging(paging);
    });
  };

  const modalOpen = (idx) => {

    detailIdx ? setFormClean(false) : setFormClean(true); 
    $('#noticeModal').addClass('d-block');
    $(body).css("overflow", "hidden");
  }

  const modalClose = () => {
    $('#noticeModal').removeClass('d-block');
    $(body).css("overflow", "auto");
  }

  const saveAfter = () => {
    modalClose();
    if(showDetail) setReload(true);
    else loadList(1);

    setReload(false);
  }

  useEffect(() => {
    if(!showDetail) {
      setDetailIdx(null);
      loadList(paging?.pageNo);
    }
  },[showDetail])

  useEffect(() => {
    loadList(1);
  },[siteIdx])
  

  return (
    <Layout {...props} bodyClass="page-registration">
      <Wrapper>
        <div className="mypage_header_bnr" style={{backgroundImage:"url('https://cdn.onoffevent.com/subpage/siteimg.jpg')"}}>
          <h2 className="subpage_title wid_1180">{siteInfo?.subPageList.filter((i) => i.pageId == pageId).length > 0 ? siteInfo?.subPageList.filter((i) => i.pageId == pageId)[0].name : ""}</h2>
        </div>
        <div className="subpage_container">
          <div className="subpage_wrapper wid_1180">
            {!showDetail && iam?.memAuth == 'ADMIN' && (
              <div className='basic_btn_div'>
                <button
                  className="basic_btn"
                  onClick={(e) => modalOpen()}
                >
                  Writing
                </button>
              </div>
            )}
            {!showDetail ? (
              <>
              <table className="question_storage_table">
                <thead>
                  <tr align="center" height="34">
                    <th>Title</th>
                    <th width="150">Author</th>
                    <th width="200">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {list?.map((item,i) => (  
                    <tr align="center" key={i} onClick={() => onShowDetail(item?.idx)}>
                      <td align="left">{item?.title}</td>
                      <td>Manager</td>
                      <td>{item?.regDate && moment(item?.regDate).locale("en").format('ddd, DD MMM YYYY HH:mm')+" KST"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="m_notice_list_box">
                <ul>
                  {list?.map((item, i) => (
                    <li key={i} onClick={() => onShowDetail(item?.idx)}>
                      <h4>{item?.title}</h4>
                      <p>Manager <span>{moment(item?.regDate).locale("en").format('ddd, DD MMM YYYY HH:mm')+" KST"}</span></p>
                    </li>
                  ))}
                </ul>
              </div>
              <Paging paging={paging} loadList={loadList} />
              </>
            ) : (
              <Detail idx={detailIdx} show={setShowDetail} modalOpen={modalOpen} reload={reload} saveAfter={saveAfter}/>
            )}
          </div>
        </div>
      </Wrapper>

      <Modal modalId="noticeModal">
        <NoticeModal idx={detailIdx} siteIdx={siteIdx} modalClose={modalClose} saveAfter={saveAfter} formClean={formClean}/>
      </Modal>
    </Layout>
  )
}

const Detail = (props) => { 

  const {iam} = useContext(AppContext);
  const {idx} = props;
  const [info, setInfo] = useState();

  const loadData = () => {
    const req = {
      path: "/v1/client/site/notice/info",
      data: {
        idx: idx
      },
    };
    _API.post(req).then((res) => {
      const { data } = res.data;
      setInfo(data);
    });
  };


  const onDelete = () => {
    if (confirm('Do you want to delete?')) {
      const req = {
        path: "/v1/client/site/notice/delete",
        data: {
          idx: idx
        },
      };
      _API.post(req).then((res) => {
        const { data } = res.data;
        alert('It has been deleted.');
        window.location.reload();
      });
    } else { 
      alert('Canceled.');
    }
  }

  useEffect(() => {
    loadData();
  },[]);

  useEffect(() => {
    if(props?.reload) loadData();
  },[props?.reload]);

  return (
    <>
      <div className="list_top">
        <h2>{info?.title}</h2>
        <div className="list_top_text">
          <span>Manager</span>
          <p>{moment(info?.regDate).locale("en").format('ddd, DD MMM YYYY HH:mm KST')}</p>
        </div>
      </div>
      <div className="list_content" dangerouslySetInnerHTML={{__html:info?.contents}} />
      <div className="d-flex justify-content-between">
        <Button onClick={() => props?.show && props?.show(false)} className="list_btn">List</Button>
        {(iam?.idx == info?.regId || iam?.memAuth == 'ADMIN') && (
          <div className="d-flex">
            <Button onClick={() => props?.modalOpen && props?.modalOpen(idx)} className="list_btn mr-2">Update</Button>
            <Button onClick={() => onDelete()} className="list_btn">Delete</Button>
          </div>
        )}
      </div>
    </>
  )
}