import React, {useState} from "react";
import { BootstrapInput }  from '../../../resources/preRegister/Preregister.theme'
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import checkIcon from '../../../resources/preReg/images/icon/chk.svg';
import checkOnIcon from '../../../resources/preReg/images/icon/chk_on.svg'; 
import Stack from '@mui/material/Stack';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";

import SearchIcon from "../../../resources/preReg/images/icon/SearchIcon.svg"
import deleteImg from "../../../resources/preReg/images/icon/delete.svg"
const Input = (props) => {
    return (
        <BootstrapInput 
           {...props}
        />
    )
}

export default (props) => {

    const {required, fullWidth, placeholder, name, onClick, onChange, disabled, sx, value, readOnly} = props;

    return(
        <>
        {name && (
            <Typography variant="subtitle2" component="h4">{name}{required && <i className="required">*</i>}
            </Typography>
        )}
        <Input
            required={required}
            fullWidth={fullWidth}
            placeholder={placeholder}
            onClick={onClick}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            value={value}
            readOnly={readOnly}
        />
        </>
    )
};

export const TextArea = (props) => {

    const {fullWidth, multiline, rows, value, onChange, sx, placeholder,maxLength} = props;
    
    return (
        <TextField
            inputProps={{
                maxLength: maxLength,
            }}
            fullWidth={fullWidth}
            multiline={multiline}
            rows={rows}
            value={value}
            onChange={onChange}
            sx={sx}
            placeholder={placeholder}
       /> 
    )
} 

export const CheckBox = (props) => {

    const {icon, checkedIcon, lSx, cSx, label, onChange, checked } = props;

    return (
        <>
        <FormControlLabel
            control={
                        <Checkbox icon={icon} checkedIcon={checkedIcon} onChange={onChange} checked={checked}/>
                    }
            label={<Typography sx={lSx}>{label}</Typography>}
            sx={cSx}
        />    
        </>
    )
};

 export const DropDown = (props) => {

    const {required, name, sx, value, displayEmpty, onChange, renderValue, MenuProps, list} = props;

    return (
        <>
            {name && (
                <Typography variant="subtitle2" component="h4">{name}{required && <i className="required">*</i>}</Typography>
            )}
            <FormControl fullWidth>
             <Select
                sx={sx}
                value={value}
                displayEmpty={displayEmpty}
                onChange={onChange}
                input={<BootstrapInput />}
                renderValue={renderValue}
                MenuProps={MenuProps}
                list={list}
                >
                {list?.map((item, i) => {
                    return (
                        <MenuItem key={i} value={item?.value}>{item?.label}</MenuItem>
                    )
                })}
            </Select>
         </FormControl> 
        </>
    )
} 

export const DropDownCheck = (props) => {

    const {variant, component} = props;
    const {required, value, name, onChange, renderValue, sx, MenuProps, list, onClick, listDelete, array, hint, text} = props;
    return (
        <>
        {name && (
            <Typography variant={variant} component={component}>{name}{required && <i className="required">*</i>}</Typography>
        )}
        {text && (
            <Typography variant={variant} component={component} sx={{color:"red", fontSize:"12px"}}>{text}</Typography>
        )}
        <FormControl fullWidth>
        <Select
            multiple
            displayEmpty
            value={value}
            onChange={onChange}
            input={<BootstrapInput />}
            renderValue={renderValue}
            sx={sx}
            MenuProps={MenuProps}
        >
        {list && 
            list.map((item, i) => {
                return (
                    <MenuItem 
                        key={i} 
                        value={item.idx} 
                        onClick={() => onClick(item)}
                    >
                    <Checkbox 
                        icon={<img src={checkIcon} />} 
                        checkedIcon={<img src={checkOnIcon} />} 
                        checked={array?.filter((li) => li?.idx == item.idx)?.length > 0} 
                    />
                    <ListItemText
                        primary={`${item.name} (${moment(item.startDate).format("MM/DD")})` }
                        aria-selected="false" 
                        selected={array?.filter((li) => li?.idx == item.idx)?.length > 0}
                    />
                    </MenuItem> 
                )
            })
        }
        </Select>
        </FormControl>
        {hint && (  
            <Typography variant="body2" component="p" sx={{marginBottom:"10px"}}>{hint}</Typography>        
        )}
        

        {array?.map((item, key) => {
            return (
                <Stack key={`li_${key}`} direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: "20px", background: "#f7f7fa", borderRadius: "6px", marginBottom: "10px" }}>
                    <Box sx={{width:"85%"}}>
                        <Typography>{`${item.name} (${moment(item.startDate).format("MM/DD")})`}</Typography>
                        <Typography>: {`${item.location}`}</Typography>
                    </Box>
                    <CancelIcon onClick={(e) => {listDelete(item)}} sx={{color:"#d0d0de", width:"28px", height:"28px", cursor:"pointer"}} />
                </Stack>
            );
        }) 
        }
        </>
    )
};

export const Search = (props) => {
  const { type, id, name, placeholder, value, onChange, onClick, setKeyword, loadList, maxWidth } = props;
  const { more, onMore } = props;
  const { children } = props;

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      onClick && onClick();
    }
    props.onKeyDown && props.onKeyDown(e);
  };
  const onReset = (e) => {
    setKeyword && setKeyword("")
    props?.onReset && props?.onReset();
  }

  return (
    <Box
        component="div"
        sx={{ display: "flex", alignItems: "center",minWidth:"160px", maxWidth: maxWidth || 160, width:"100%", background: "#fff", height: "40px", boxShadow: "none", border: "1px solid #d7d7d7", borderRadius: "4px", gap:"6px" }}
      >
        <>
            <InputBase
                value={value}
                onChange={onChange}
                sx={{ flex: 1, color: "#464f60",height:"100%", padding:"6px 0 6px 12px","& .MuiInputBase-input": { padding: 0, lineHeight: "22px", fontSize: "15px", height: "22px"} }}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                inputProps={{ "aria-label": "search google maps" }}
            />
            {value ? <img src={deleteImg} onClick={onReset} style={{ display: "block" ,cursor: "pointer" }} /> : null}
        </>
        <IconButton
            type="button"
            sx={{ color: "#1C1B1F", padding:"0",paddingRight:"8px" ,"&:hover": { backgroundColor: "unset" } }}
            aria-label="search"
            onClick={onClick}
        >
            <img src={SearchIcon} />
        </IconButton>
    </Box>
  );
};