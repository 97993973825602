import React, { Fragment, useContext, useEffect, useState, useLayoutEffect } from "react";
import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _R, _U } from "../../../../modules/utils";
import useStore from "../../../../modules/utils/store";

import { Header } from "./Layout.Header";
import SideBar from "./Layout.Sidebar.js";
import { initPage } from '../Pages/Init';
import { Footer } from "./Layout.Footer";
import Console from "../Pages/Console";

// 수정완료 
import AttendModal from '../../Common/attendModal';
import CounselModal from "../../Common/counselModal";
import SurveyModal from "../../Common/surveyModal";
import ModalPopup from "../../Common/modalPopup";
import SpeakerModal from "../../Common/SpeakerModal";
import SessionModal from "../../Common/SessionModal";
import ConnectUsersViewModal from "../../Common/ConnectUsersView"

import { DynamicStyles } from "../Resources/GlobalStyle";


export default (props) => {
  const { history, consoleCfg, sessionAttendance, getSessionAttendanceInfo } = props;
  const { siteInfo } = useStore();
  const { iam } = useContext(AppContext);
  
  // Survey Modal
  const [show, setShow] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState({});

  // Modal Popup
  const [showPopup, setShowPopup] = useState(false);
  
  // Counsel Modal
  const [counselModalOnOff, setCounselModalOnOff] = useState(false);

  // Speaker Modal
  const [managerInfo, setManagerInfo] = useState(null);

  // Session Modal
  const [introduceDivision, setIntroduceDivision] = useState(false);
  const [introduceDivisionShow, setIntroduceDivisionShow] = useState(false);

  // Console
  const [sessionicon, setSessionicon] = useState(false);
  
  // attendance check
  const [attendanceYn, setAttendanceYn] = useState(consoleCfg?.partiTime === 'E' ? true : false); /* 출석/퇴장 사용여부 */
  const [attendanceState, setAttendanceState] = useState(sessionAttendance?.entryTime ? true : false); /* 출석 상태 */
  const [attendModal, setAttendModal] = useState(sessionAttendance?.entryTime ? false : true);


  const [showConnectUsersViewModal, setShowConnectUsersViewModal] = useState(false);
  const [surveyPageNum, setSurveyPageNum] = useState(0);

  const setIntroduce = (show, division) => {
    setIntroduceDivisionShow(show);
    setIntroduceDivision(division);
    if (show)
      setTimeout(() => {
        $("body").css("overflow-y", "hidden");
        $("#sessionModal").modal("show");
        setSessionicon(false);
      }, 10);
    else {
      $("body").css("overflow-y", "auto");
      $("#sessionModal").modal("hide");
    }
  };

  const setSessionMgrInfoModal = (show, item) => {
    setManagerInfo(item);
    if (show)
      setTimeout(() => {
        $("body").css("overflow-y", "hidden");
        $("#mgrInfoModal").modal("show");
      }, 10);
    else {
      $("body").css("overflow-y", "auto");
      $("#mgrInfoModal").modal("hide");
    }
  };

  useEffect(() => {
    if (consoleCfg.eventIdx == null || siteInfo.siteIdx == null) {
      alert("이벤트 정보가 없습니다.");
      history.push(`/site/${consoleCfg.eventIdx}/event/list`);
    }
    initPage();

    $(".ft_modal_container").click(function () {
      $("body").css("overflow-y", "hidden");
    });

    if (props.counselModalOnOff == true) {
      $("body").css("overflow", "hidden");
    } else {
      $("body").css("overflow", "auto");
    }
  }, []);

  useEffect(() => {
    if (surveyPageNum == 0) {
      var controlC = "컨트롤";
      // $('.control_list').removeClass('on');
      $("#prevB").hide();
      $("#firstB").css("background", "#3b3b3f");
      $(".text_container_top h2").text(controlC);
      
    } else if (surveyPageNum == 1) {
      var controlT = "서베이";
      $("#prevB").show();
      // $(".control_list > li").removeClass("on");
      $(".text_container_top h2").text(controlT);
      $("#firstB").css("background", "#353539");
      if ($(".control_list").hasClass("on")) {
        // $('.left_console').css("background","#353539");
      }
    } else if (surveyPageNum == 2) {
      var controlT = "서베이";
      $(".text_container_top h2").text(controlT);
    }
  }, [surveyPageNum]);

  return (
    <>
    <div id="gsk_version" className="site">
      <div id="bg-over" />
      <Header history={history} consoleCfg={consoleCfg} setSurveyInfo={setSurveyInfo} setCounselModalOnOff={setCounselModalOnOff} />
      <section className={"section-content"}>
        <>
        <Console
          // 있어야됨
          consoleCfg={consoleCfg}
          setSessionMgrInfoModal={setSessionMgrInfoModal}
          setIntroduce={setIntroduce}
          sessionicon={sessionicon}
          setSessionicon={setSessionicon}
          setSurveyInfo={setSurveyInfo}
          setAttendanceYn={setAttendanceYn}
          attendanceYn={attendanceYn}
          setAttendanceState={setAttendanceState}
          attendanceState={attendanceState}
          setAttendModal={setAttendModal}
        /> 
          {_R.isSystemAdmin(iam?.memberRoles) && (
            <Fragment>
              <SideBar
                modalShow={show}
                showPopup={showPopup}
                consoleCfg={consoleCfg}
                setSurveyPageNum={setSurveyPageNum}
                showConnectUsersViewModal={showConnectUsersViewModal}
                setShowConnectUsersViewModal={setShowConnectUsersViewModal}
              />
              <ConnectUsersViewModal
                consoleCfg={consoleCfg}
                showConnectUsersViewModal={showConnectUsersViewModal}
                setShowConnectUsersViewModal={setShowConnectUsersViewModal}
              />
            </Fragment>
          )}
          {attendanceYn && attendModal && (
            <AttendModal
              attendModal={attendModal}
              setAttendModal={setAttendModal}
              setAttendanceState={setAttendanceState}
              attendanceState={attendanceState}
              consoleCfg={consoleCfg}
              sessionAttendance={sessionAttendance}
              getSessionAttendanceInfo={getSessionAttendanceInfo}
            />
          )}
          {/* 수정완료 */}
          <CounselModal
            consoleCfg={consoleCfg}
            counselModalOnOff={counselModalOnOff}
            setCounselModalOnOff={setCounselModalOnOff}
          />
          
          <SurveyModal
            show={show}
            setShow={setShow}
            surveyInfo={surveyInfo}
            setSurveyInfo={setSurveyInfo}
            consoleCfg={consoleCfg}
          />
          <ModalPopup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
          />
          {managerInfo && (
            <SpeakerModal
              managerInfo={managerInfo}
              setSessionMgrInfoModal={setSessionMgrInfoModal}
            />
          )}
          {introduceDivisionShow && (
            <SessionModal
              introduceDivision={introduceDivision}
              consoleCfg={consoleCfg}
              EventData
              setIntroduce={setIntroduce}
            />
          )}
        </>
      </section>
      <Footer history={history}/>
    </div>
    <DynamicStyles consoleStyle={true} />
    </>
  );
};
