import React, {useContext, useState, useEffect, useRef, Fragment} from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { AppContext } from "../../../../components/AppProvider";
import { _CK, _U, _R, _API } from "../../../../modules/utils";
import { _CFG } from "../../../../modules/config";
import useStore from "../../../../modules/utils/store";


export const Header = (props) => {
  const { iam, saveAuth, saveIam } = useContext(AppContext);
  const { history, consoleCfg, setSurveyInfo,setCounselModalOnOff } = props;
  const { siteInfo } = useStore();
  const [scrollY, setScrollY] = useState(0);
  const documentRef = useRef(document);
  const [cnt, setCnt] = useState(0);
  const [isSendProc, setIsSendProc] = useState(false);
  const [headerMenu, setHeaderMenu] = useState([]);

  const handleScroll = () => {
    const { pageYOffset } = window;
    setScrollY(pageYOffset);
  };
  const onLogout = (e) => {
    e.preventDefault();
    const confirmChk = window.confirm("로그아웃 하시겠습니까?");
  
    if(confirmChk){
      saveIam(null);
      saveAuth("");
      _CK.clearLogKey();
      _CK.clearAuth();
      // history.push('');
      window.location.href = "/"
      window.reload();
    }
  };

  const goEventList = (e,sub,item) => {
    if(e) e.preventDefault();

    const confirmChk = window.confirm('시청을 중단하시겠습니까?');
    if(confirmChk){
      sub == "notice" ? (
        history.push(`/site/${siteInfo?.idx}/notice`)
      ) : sub == "page" ? (  
        history.push(`/site/subpage/${item.idx}`)
      ) : sub == "popUp" ? (
        window.open(`/#/site/subpage/${item.idx}`)
      ): sub == "invitation" ? (
        history.push(`/invitation`)
      ) : sub == "mypage" ? (
        history.push(`/mypage`)
      ) : iam?.targetType != 'MEDIGATE' ? history.push(`/site/${siteInfo?.idx}/event/list`)
      : history.push(`/event/${consoleCfg?.eventIdx}/register`)
    }
  }

  const onSend = (cate,more) => {
    if (isSendProc) {
        return;
    }
    let param = {
        mode: cate,
        siteIdx: consoleCfg?.siteIdx,
        eventIdx: consoleCfg?.eventIdx,
        sessionIdx: consoleCfg?.sessionIdx,
        consoleIdx: consoleCfg?.consoleIdx,
        name: iam?.name,
        memType: iam?.memType,
        companyName: iam?.companyName,
        deptName: iam?.deptName,
        memberIdx: iam?.idx,
    }

    if(more){
        param = {...param,...more};
    }

    if(cate){
        setIsSendProc(true);
        _U.postMessage("SOCKET-SEND", {
            action: cate,
            data: param,
        });
        setIsSendProc(false);
    }
};

  const openSurvey = (status,clickStatus) => {
    const req = {
      path: "/v1/event/session/surveys/find/menu/connect/survey",
      data : {
        eventIdx: consoleCfg?.eventIdx,
        sessionIdx: consoleCfg?.sessionIdx,
        status : status,
      }
    };
    _API.post(req).then((res) => {
      if(res.data.data.idx > 0){
        const surveyData = {
          show : true,
          idx : res.data.data.idx
        }
        setSurveyInfo(surveyData)
        if(clickStatus == "click"){
          setSurveyInfo((o) => {
            let si = {...o};
            si.clickStatus = `click`;
            return si;
          })
        }
      }else{
        if(status == 1){
          alert('현재 진행중인 서베이가 없습니다.');
        }
        return;
      }
    });
  }

  useEffect(() => {
    openSurvey(0);
  },[]);

  const hubSite = _CFG.hubUrl();
  useEffect(() => {
    if (iam?.memberRoles) {
      let tmpMenu = [{ name: "대시보드", url: hubSite+"/dashboard?au="+_CK.getAuth(), sort: 1 }];

      // 통합관리자 권한을 소유한 경우
      if (_R.isSystemAdmin(iam?.memberRoles)) {
        tmpMenu.push(
          { name: "리소스", url: hubSite+"/resource/dashboard?au="+_CK.getAuth(), sort: 5 },
          // { name: "보고서", url:'/', sort: 5 },
          { name: "사용자", url: hubSite+"/member/dashboard?au="+_CK.getAuth(), sort: 7 },
          // { name: "상담신청", url: "/call", sort: 9 }
        );
      }
      setHeaderMenu((o) => {
        
        // 조직관리자 권한을 소유하거나 그 이상의 권한을 소유한 경우
        if(_R.hasOrgRoles(iam?.memberRoles)){
          tmpMenu.push({ name: "조직", url: hubSite+"/company/list?au="+_CK.getAuth(), sort: 8 });
        }

        // 사이트 관련 권한을 소유하거나 그 이상의 권한을 소유한 경우 또는 이벤트 운영담당자,등록담당자,이벤트뷰어일 경우
        if(
          _R.hasSiteRoles(iam?.memberRoles) || 
          _R.isEventOper(iam?.memberRoles) ||
          _R.isEventMedia(iam?.memberRoles) ||
          _R.isEventViewer(iam?.memberRoles)
        ){
          tmpMenu.push({ name: "사이트", url: hubSite+"/site/list?au="+_CK.getAuth(), sort: 10 });
        }

        // 캠페인 관련 권한을 소유하거나 그 이상의 권한을 소유한 경우
        if(_R.hasCampaignRoles(iam?.memberRoles)){
          tmpMenu.push({ name: "캠페인", url: hubSite+"/campaign/list?au="+_CK.getAuth(), sort: 2 });
        }

        // 이벤트 관련 권한을 소유하거나 그 이상의 권한을 소유한 경우
        if(_R.hasEventRoles(iam?.memberRoles)){
          tmpMenu.push({ name: "이벤트", url: hubSite+"/event?au="+_CK.getAuth(), sort: 3 });
        }

        // 이벤트 관련 권한을 소유하거나 그 이상의 권한을 소유한 경우
        if(_R.hasEventRoles(iam?.memberRoles)){
          tmpMenu.push({ name: "온디맨드", url: hubSite+"/ondemand/list?au="+_CK.getAuth(), sort: 4 });
        }
        
        tmpMenu = tmpMenu?.sort((a, b) => a.sort - b.sort);
        console.log(tmpMenu)
        return tmpMenu;
      });
    }
  }, [iam]);

  // 헤더 픽스드
  documentRef.current.addEventListener("scroll", handleScroll);
  return (
    <Fragment>
      <header id="header">
      <div id="header-freeze" className="header-freeze"></div>
      <div className={cx({ header: true, fixed: scrollY > 0 })}>
          {siteInfo?.logoUseYn === "Y" && siteInfo?.logoImgUrl?.length > 0 ? (
            <a onClick={(e) => ['MEDIGATE','ICOD','GUEST'].includes(iam?.targetType) ? null : goEventList(e)} style={{cursor: ['MEDIGATE','ICOD','GUEST'].includes(iam?.targetType) ? 'default':'pointer'}}>
              { siteInfo?.logoImgUrl == null || siteInfo?.logoImgUrl == "" ?
                "" :
                <img src={siteInfo.logoImgUrl} alt={siteInfo?.name} />
              }
            </a>
          ) : (
          <a onClick={(e) => ['MEDIGATE','ICOD','GUEST'].includes(iam?.targetType) ? null : goEventList(e)} style={{cursor: ['MEDIGATE','ICOD','GUEST'].includes(iam?.targetType) ? 'default':'pointer'}}>
            { siteInfo?.logoImgUrl == null || siteInfo?.logoImgUrl == "" ?
              "" :
              <img src={siteInfo.logoImgUrl} alt={siteInfo?.name} />
            }
          </a>
        )}
          
          <div className="menu_container">
            {consoleCfg?.consoleSurveyYn == 'Y' && (
              <span className="m_header_survey"><a onClick={(e) => openSurvey(1,"click")}>SURVEY</a></span>
            )}
            <ul className="menu_container_ul">
              {!['MEDIGATE','ICOD'].includes(iam?.targetType) && (
                <li><a onClick={(e) => goEventList(e)} style={{cursor: 'pointer'}}>HOME</a></li>
              )}
              {consoleCfg?.consoleSurveyYn == 'Y' && (
                <li><a onClick={(e) => openSurvey(1,"click")}>SURVEY</a></li>
              )}
              {consoleCfg?.callConsoleYn === "Y" && (<li><a onClick={() => { setCounselModalOnOff(true) }}>상담신청</a></li>)}
              {/* <li><a>Q&amp;A</a></li> */}
              <li><a onClick={onLogout}>LOGOUT</a></li>
            </ul>
          <button className="menubtn">
            <div className="line_box">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
          </button>
          <ul className="menu_box">
            <li className="menu_box_info">
              <p>
                {_U.isSysAdmin(iam.memType) ||
                _U.isSiteAdmin(iam.memType) ||
                _U.isEventAdmin(iam.memType)
                  ? "관리자"
                  : _U.isHost(iam.memType)
                  ? "호스트"
                  : iam?.targetType == "MEMBER"
                  ? "회원"
                  : iam?.targetType == "GUEST"
                  ? "게스트"
                  : iam?.targetType == 'MEDIGATE'
                  ? "API게스트"
                  : iam?.targetType == 'ICOD'
                  ? "API게스트"
                  : "패스코드"}
              </p>
              <span>{iam?.name != null && iam?.name != "" ? iam?.name : "게스트"}님</span>
            </li>
            {!['MEDIGATE','ICOD'].includes(iam?.targetType) && (<>
            <li className="menu_box_list">
              <ul>
                {iam?.memType != "USER" && (
                  <li>
                    <Link to="/invitation" className="link">
                      초대장
                    </Link>
                  </li>
                )}
                  <li>
                    <Link to="/invitation" className="link">
                      마이페이지
                    </Link>
                  </li>
              </ul>
            </li>
            </>)}
            {!['MEDIGATE','ICOD'].includes(iam?.targetType) && (<>
              <li className="menu_box_list">
                <ul>
                  {siteInfo?.subPageList.map((item, i) => {
                    return (
                      <>
                        {item.isNavShow == 1 && (
                          item.pageType == "LINK" ? (
                            <li className="item item-sub" key={i}>
                              <a href={`https://${item.link}`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} > {item.name} </a>
                            </li>
                          ) : (
                            <li className="item item-sub" key={i}>
                              {item.pageId == "event" ? (<a href={`/#/site/${siteInfo?.idx}/event/list`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} > {item.name} </a> ) 
                              : (<a href={`/#/site/${siteInfo.idx}/subpage/${item.pageId}`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} > {item.name} </a> )}
                            </li>
                          )
                        )}
                      </>
                    )})}
                </ul>
              </li>
            </>)}

            {headerMenu && (
              <li className="menu_box_list">
                <ul className="ul-menu-sub">
                  {headerMenu?.map((m) => {
                    return (
                      <li key={m?.sort} className="item item-sub">
                        <a href={m?.url} className="link">
                          {m?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            )}
            <li className="menu_box_loginfo">
              <div className="link" onClick={onLogout}>
                <span className="link">로그아웃</span>
                <img src="https://cdn.onoffevent.com/login/icon_logout.svg" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
    </Fragment>
  );
};
