import { default as TypeAStyle } from './TypeAStyle';
import { default as ResetStyle } from './ResetStyle';
import { default as HeaderStyle } from './HeaderStyle';
import { default as CommonStyle } from './CommonStyle';
import { default as ModalStyle } from './ModalStyle';
import { default as FooterStyle } from './FooterStyle';

const style = `
  ${ResetStyle}
  ${HeaderStyle}
  ${CommonStyle}
  ${FooterStyle}
  ${TypeAStyle}
  ${ModalStyle}
`
export default style;