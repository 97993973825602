import React, {Fragment, useEffect, useState} from 'react';
import cx from "classnames";
import useStore from '../../modules/utils/store';
import { _AC, _API, _U } from '../../modules/utils';
import { useContext } from 'react';
import { AppContext } from '../../components/AppProvider';

export default (props) => { 
  const { iam,auth } = useContext(AppContext);
  const { item, onOff ,eventInfo ,setAccompanySessionIdx,setAccompanyAttendModal,waitTimer} = props;
  const { siteInfo } = useStore();

  const [preregExistent, setPreregExistent] = useState(null);
  const setPrereg = async () => {
    const prereg = iam.preregIdx ? await _API.prereg(iam.preregIdx, auth) : "";
    setPreregExistent(prereg);
  };

  const goSymposium = function (item, url) {
    let joinSessionChk = false;

    for (let i in preregExistent?.data?.sessionList) {
      if (item?.idx === preregExistent?.data?.sessionList[i]?.idx) {
        joinSessionChk = !joinSessionChk;
        break;
      }
    }
    if (!joinSessionChk) {
      alert("This is an unregistered session.");
      return false;
    }

    if (onOff(item) == "ENTER_OK" || onOff(item) == "ONAIR") {
      if(item?.accompanyYn == 'Y'){
        setAccompanySessionIdx(item?.idx);
        setAccompanyAttendModal(true);
        $(body).css("overflow", "hidden");
      }else{
        const newWindow = window.open(`/#`+url, '_blank'); // 새 창에서 URL을 엽니다

        if (newWindow) {
          newWindow.onload = function() { 
            // 새 창이 열리고 나서 새로 고침을 수행합니다
            newWindow.location.reload();
          };
        } else {
          // 새 창이 팝업 차단기 때문에 열리지 않았을 경우를 처리합니다
          alert('Pop-ups are blocked. Please disable your pop-up blocker.');
        }
      }
    } else if(onOff(item) == "END"){
      return false;
    } else if(onOff(item) == "BEFORE" && item?.cAccessTime == 0){
      alert(`Entry is currently not available. Please check the entry time again.`);
      return false;
    }  else {
      // alert(`You can enter the symposium ${item?.cAccessTime}minutes in advance.`);
      alert(`Entry is currently not available. Please check the entry time again.`);
      return false;
    }
  };

  useEffect(() => {
    setPrereg();
  }, []);
  return(
    <Fragment>
       {item.eventEnterYn == "Y" && (
        <>
          <div className="pre">
            <Fragment>
              <button onClick={(e) => { $(".modal-stack").remove(); goSymposium(item, `/event/${eventInfo?.eventIdx}/session/${item.idx}/default`); }}
                      className={cx({ basic_btn: true, basic_btn_off: onOff(item) == "BEFORE" || onOff(item) == "END"})} >
                {item?.accessButtonLabel || `Symposium admission`}
                {item?.accompanyYn == 'Y' ? (<span>(Accompanied attendance)</span>) : '' }
              </button>
            </Fragment>   
          </div>
          {item?.consoleType && (
            <Fragment>
              {onOff(item) == "BEFORE" ? (
                <p className="ent_span">
                  <span> Entry available in</span><b className="time_b">{waitTimer(item)}</b>
                  {/* <span>You can enter the </span>
                  <small>
                    {siteInfo?.eventLabel ? siteInfo?.eventLabel : 'Session'} after <b className="time_b">{waitTimer(item)}</b>
                  </small> */}
                </p>
              ) : onOff(item) == "ENTER_OK" ? (
                <p className="ent_span">
                  Admission to the { siteInfo?.eventLabel ? siteInfo?.eventLabel :'Session'} is currently open.
                </p>
              ) : onOff(item) == "ONAIR" ? (
                <p className="ent_span">
                  This { siteInfo?.eventLabel ? siteInfo?.eventLabel :'Session'} is currently in progress.
                </p>
              ) : (
                <p className="ent_span">
                  <small>This { siteInfo?.eventLabel ? siteInfo?.eventLabel :'Session'} has ended.</small>
                </p>
              )}
            </Fragment>
          )}
        </>
       )}
    </Fragment>
  )
}