import React from 'react';
import ImgPhoto from "../../../../resources/console/images/icon/default-user.svg";

export default (props) => {
  const { consoleCfg,setSessionMgrInfoModal } = props;
  return (
    <>
      {consoleCfg.managerList != null || consoleCfg?.managerList ? (
          consoleCfg?.managerList?.map((item, i) => (
            <div className="slide_flex" onClick={(e) =>setSessionMgrInfoModal(true, item)}>
              <div className="slide_flex_div">
                <div className="slide_flex_inner_div">
                  <img
                    className="slide_img"
                    src={item.signedUrlPhoto || ImgPhoto}
                    alt={"ImgPhto"}
                  />
                </div>
              </div>
              <div className="slide_flex_t">
                <b>
                  {item?.mgrType === "SEAT"
                    ? "좌장"
                    : item?.mgrType === "PANEL"
                      ? "패널"
                      : "연자"}
                </b>
                <h2>{item?.name}</h2>
                <p>
                  {item?.brieflyMark ? item?.brieflyMark : (
                    <>
                      <span>{item.companyName}</span>
                      <span>{item.deptName}</span>
                    </>
                  )}
                </p>
              </div>
            </div>
          ))
      ) : null}
    </>
  )
}