import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import ImgPhoto from "../../../../resources/console/images/icon/default-user.svg";
import btnMoreinfo from "../../../../resources/preReg/images/icon/btn-moreinfo.svg";
export default (props) => { 
  const { item,setSessionMgrInfoModalShow ,setManagerInfo,paginationShow } = props;
  
  return (
    <div className="slide_con">
      <Swiper
        observer={true}
        observeParents={true}
        pagination={ paginationShow? true : false }
        breakpoints={{
          0: {
            slidesPerView: "auto",  //브라우저가 0보다 클 때
            spaceBetween: 30,
            allowTouchMove: item?.managerList?.length == 1 ? false : true,
          },
          768: {
            slidesPerView: 3,  //브라우저가 480보다 클 때
            spaceBetween: 30,
            allowTouchMove: item?.managerList?.length <= 3 ? false : true,
            pagination:{clickable:true}
          },
        }}
        style={{paddingBottom:`${paginationShow?'30px':'0px'}`}}
      >
          {item?.managerList?.map((item2, k) => (
          <SwiperSlide key={k}>
            <div
              className="slide_flex"
              onClick={(e) => (setSessionMgrInfoModalShow(true), setManagerInfo(item2))}
            >
              <div className="slide_flex_div">
                <div className="slide_flex_inner_div">
                  <img
                    className="slide_img"
                    src={
                      item2?.signedUrlPhoto
                        ? item2?.signedUrlPhoto
                        : ImgPhoto
                    }
                    alt="프로필사진"
                  />
                  <img
                    className="slide_img_more"
                    src={btnMoreinfo}
                    alt="더보기"
                  />
                </div>
              </div>
              <div className="slide_flex_t">
                <b>
                  {item2?.mgrType === "SEAT"
                    ? "좌장"
                    : item2?.mgrType === "PANEL"
                    ? "패널"
                    : "연자"}
                </b>
                <h2>{item2?.name}</h2>
                <p>
                  {item2?.brieflyMark ? item2?.brieflyMark : (
                    <>
                      <span>{item2.companyName}</span>
                      <span>{item2.deptName}</span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}