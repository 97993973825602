import React, { useState,useEffect,useRef} from "react";
import { Typography, Box, InputBase, MenuItem, FormControl } from "@mui/material";
import MuiSelect from "@mui/material/Select"
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const Select = (props) => {
  const { value, onChange, children, defaultValue } = props;
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <MuiSelect
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        renderValue={(selected) => {
          if (selected.length == 0) {
            return <em>{defaultValue}</em>;
          }
          return selected;
        }}
        displayEmpty
        size="small"
        isDisabled
      >
        {children}
      </MuiSelect>
    </FormControl>
  );
};

export const MuiSelects = (props) => {
  const [position, setPosition] = useState(0);
  const { name, value, onChange, items, size, defaultValue, pageList, originSortNumber, isDisabled,title,space,required } = props;
  const inputComponent = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setPosition(inputComponent?.current? (inputComponent?.current?.getBoundingClientRect()?.left + 30): 0);
  }, [inputComponent]);
  const StyleInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #D7D7D7',
      fontSize: 15,
      height: "40px !important",
      lineHeight: "40px",
      padding: 0,
      boxSizing: 'border-box',
      paddingLeft:"12px",
      color: "#464F60"
    },
    '& .MuiSvgIcon-root': {
      width: "16px",
      height: "20px", 
      color: "#464f60",
      right: "10px",
      fontSize: "20px",
    },

  }));
  return (
    <FormControl sx={{ minWidth: 68, height: 40}} size={size || "small"}>
      <MuiSelect
        inputRef={inputComponent || null}
        name={name}
        value={value}
        displayEmpty={true}
        onChange={onChange}
        defaultValue={defaultValue}
        renderValue={(selected) => {
          /* if (selected =='' ) {
            return <em>{defaultValue}</em>;
          } */
          let data = items.filter((item) => {
            if (item.value == selected) {
              return selected = item.label
            }
          })
          return selected;
        }}
        sx={{ height: "40px", "& .MuiList-root": {background:"#666"} }}
        input={<StyleInput />}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          PaperProps: {
            sx: {
              width:"auto",
              marginTop:"3px",
              border:"1px solid #d7d7d7",
              borderRadius: "4px",
              boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.08)",
              '& .MuiMenuItem-root': {
              }, 
            },
          },
        }}
      >
          {items.map((item, i) => (
            <MenuItem 
              key={`${name}_select_${i}`}
              value={item?.value}
              disabled={isDisabled &&
                pageList?.filter((p) => p?.sortNumber == item?.value)
                  ?.length > 0 && item.value != originSortNumber
              }
              sx={{ fontSize:"13px", margin: "0 8px",lineHeight:"18px",borderRadius: "4px",paddingTop:"7px", paddingBottom:"7px", "&.Mui-selected": {backgroundColor:"rgba(243, 242, 255, 1) !important"},"&:hover":{backgroundColor:"rgba(243, 242, 255, 1)"} }}
            >
              {item?.label}
            </MenuItem>
          ))}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
