import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components"; 
import enquire from "enquire-js";
import moment from "moment";
import GlobalStyles, {
    ThemeProvider,
    Theme,
    DynamicStyles,
} from "../Resources/GlobalStyle";
import { Header } from "./Layout.Header";
import SideBar from "./Layout.Sidebar";
import { Footer } from "./Layout.Footer";
import { _API, _CK, _U } from "../../../../modules/utils";
import { AppContext } from "../../../../components/AppProvider";
import useStore from "../../../../modules/utils/store";


export default (props) => {
    const { history, location, match, children, bodyClass, sidebar, info } = props;

    const { eventInfo } = props;
    const cp = { history, location, match, info, eventInfo };
    
    useEffect(() => {
        initPage();
    }, []);

    useEffect(() => {
        $("body").removeClass().addClass(`page ${bodyClass ? bodyClass : ""} ${sidebar ? "has-sidebar" : ""}`);
        return () => {
            $("body").removeClass();
        };
    }, [bodyClass]);
    
    return (
        <SitePage>
            <Header {...cp} />
                <div className="content-area">{children}</div>
            <Footer {...cp} />
            <DynamicStyles />
        </SitePage>
    );
};

export const Wrapper = (props) => {
    const { children, childContainer } = props;
    return (
        <section id="section" className="section">
            {children}
            {childContainer && childContainer}
        </section>
    );
};

export const HiddenWrapper = (props) => {
    const { iam } = useContext(AppContext);
    const { siteInfo } = useStore();
    const { eventInfo, preregSessionList } = props;
    
    // 이벤트 참가
    const saveEventAttend = () => { 
        const req = {
            path: "/v1/client/streaming/attend",
            data: {
                    idx                 : iam.attendIdx
                ,   eventIdx            : iam.eventIdx
                ,   targetType          : iam.targetType
                ,   onfType             : iam.onfType
                ,   memberIdx           : iam.memberIdx
                ,   adBookIdx           : iam.adBookIdx
                ,   preregIdx           : iam.preregIdx
                ,   passCode            : iam.passCode
                ,   name                : iam.name
                ,   mobile              : iam.mobile
                ,   email               : iam.email
                ,   companyName         : iam.companyName
                ,   deptName            : iam.deptName
                ,   managerIdx          : iam.managerIdx
                ,   termsPrivateAgree   : iam.termsPrivateAgree
                ,   termsUseAgree       : iam.termsUseAgree
                ,   smsAgree            : iam.smsAgree
                ,   memo                : iam.memo
                ,   importantYn         : iam.importantYn
            },
        };
        
        _API.post(req).then((res) => {
            let { data } = res.data;
            if (data) {
                console.log(" saveEventAttend : " + data);
                // setAttendIdx(data);
            }
        });
    
    };
    
    return (
        <div className="sticky-wrap">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="menu-wrap">
                            <div className="share-wrap">
                                <a href="#" className="button button__icon" title="share" data-toggle="modal" data-target="#socialModal">
                                    <i className="demo-icon icon-share"></i>
                                </a>
                            </div>
                            <div className="button-wrap">
                                {
                                    preregSessionList?.length < 1 &&
                                    ( 
                                        eventInfo.preregUseYn === "Y" ? 
                                        (<a href="#" className="button button__outline" onClick={(e) => {e.preventDefault(); alert("참가 신청된 세션이 없습니다. 세션참가 후 진행해주시기 바랍니다."); }}>
                                                {siteInfo.labelName}
                                        </a>) 
                                    :
                                        (<a href="#" className="button button__outline" onClick={(e) => {e.preventDefault();!iam?.attendIdx && saveEventAttend();}} data-toggle="modal" data-target={eventInfo?.accompanyYn === "Y" ? "#accompanyModal" : "#connectModal"}>
                                                {siteInfo.labelName}
                                        </a>) 
                                    )
                                }
                                {
                                    preregSessionList?.length > 0 && 
                                    (
                                        <a href="#" className="button button__outline" onClick={(e) => {e.preventDefault();!iam?.attendIdx && saveEventAttend();}} data-toggle="modal" data-target={eventInfo?.accompanyYn === "Y" ? "#accompanyModal" : "#connectModal"}>
                                                {siteInfo.labelName}
                                        </a>
                                    ) 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SitePage = styled.div.attrs((props) => {
    return {
        id: "gsk_version",
        className: `site ${props.className ? props.className : ""}`,
    };
})``;

const initPage = () => {
    const $win = $(window);
    const body = document.getElementById("body");
    const menuToggle = document.querySelector(".menu-toggle");
    const siteHeaderWrap = document.querySelector(".site-header-wrap");
    const siteHeader = document.querySelector(".site-header");
    const headerFreeze = document.getElementById("header-freeze");
    
    const menubtn = document.querySelector(".menubtn");
    const menubox = document.querySelector(".menu_box");
    const header = document.querySelector("#header");

    // 메뉴 관련
    menubtn.addEventListener("click", (e) => {
        if(-1 !== menubox.className.indexOf("on")){
            menubtn.classList.remove("on");
            menubox.classList.remove("on");
            headerFreeze.classList.remove("on");
        } else{
            menubtn.classList.add("on");
            menubox.classList.add("on");
            headerFreeze.classList.add("on");
        }
    });
   
    headerFreeze.addEventListener("click", (e) => {
         menubtn.click();
    });

    let didScroll = false,
        lastScrollTop = 0,
        $btnGoTop = $('.go-to-top'),
        $footerArrowWrap = $('.footer-arrow-wrap');
    $win.scroll(function(e) {
        didScroll = true;
    });

    setInterval( function() {
        if ( didScroll ) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $win.scrollTop();
        lastScrollTop = st;

        if (  lastScrollTop > 100 ) {
            $footerArrowWrap.addClass('active');
        } else {
            $footerArrowWrap.removeClass('active');
        }
    }
    $btnGoTop.on('click', function() {
        $('html, body').animate({scrollTop: 0}, 'slow');
        return false;
    });

    const menuactionToggle = document.querySelectorAll(".menuaction-toggle");
    for (let i = 0; i < menuactionToggle.length; i++) {
        menuactionToggle[i].addEventListener("click", (e) => {
            if (-1 !== e.currentTarget.parentElement.className.indexOf("show")) {
                e.currentTarget.parentElement.classList.remove("show");
            } else {
                e.currentTarget.parentElement.classList.add("show");
            }
        });
        menuactionToggle[i].addEventListener("focusout", (e) => {
            setTimeout(function () {
                e.target.parentElement.classList.remove("show");
            }, 200);
        });
    }

    // checkbox
    const checkBoxes = document.querySelectorAll('input[type="checkbox"][data-group]:not([value=""]');
    const checkAlls = document.querySelectorAll(".check-all");

    const checkboxCount = (e) => {
        let cnt = 0;
        const target = e.currentTarget.closest("ul").querySelector(".foot");
        const thisGroup = e.currentTarget.getAttribute("data-group");
        const menuAction = document.querySelector(".menu-action");

        const checkboxGroup = document.querySelectorAll("[data-group=" + thisGroup + "]");
        for (let i = 0; i < checkboxGroup.length; i++) {
            if (checkboxGroup[i].checked === true) cnt++;
        }

        if (cnt > 0) {
            target?.classList.add("active");
            if (menuAction !== null) menuAction.classList.add("active");
        } else {
            target?.classList.remove("active");
            if (menuAction !== null) menuAction.classList.remove("active");
        }
    };

    const checkboxToggle = (thisGroup, target) => {
        const targetGroup = document.querySelectorAll("[data-group=" + thisGroup + "]");
        for (let i = 0; i < targetGroup.length; i++) {
            target ? (targetGroup[i].checked = true) : (targetGroup[i].checked = false);
        }
    };

    [].forEach.call(checkBoxes, (checkBox) => {
        checkBox.addEventListener("change", (e) => {
            checkboxCount(e);
        });
    });

    [].forEach.call(checkAlls, (checkAll) => {
        checkAll.addEventListener("click", (e) => {
            const thisGroup = e.currentTarget.getAttribute("data-group");
            const target = e.target.checked;
            checkboxToggle(thisGroup, target);
        });
    });

    // 모달 창 복수로 띄울대
    $(document).on("show.bs.modal", ".modal", (e) => {
        if (e.target.id) {
            const zIndex = 1040 + 10 * ($(".modal:visible").length + 1);
            $("#" + e.target.id).css("z-index", zIndex);
            setTimeout(() => {
                $(".modal-backdrop")
                    .not(".modal-stack")
                    .css("z-index", zIndex - 1)
                    .addClass("modal-stack");
            }, 0);
        }
    });

    const hasSubmenus = document.querySelectorAll(".has-submenu");
    const subMenus = document.querySelectorAll(".has-submenu > .link");
    for (var i = 0; i < subMenus.length; i++) {
        subMenus[i].addEventListener("click", (e) => {
            e.preventDefault();
            if (-1 === e.currentTarget.parentNode.className.indexOf("active")) {
                for (let j = 0; j < hasSubmenus.length; j++) {
                    hasSubmenus[j].classList.remove("active");
                }
                e.currentTarget.parentNode.classList.add("active");
            } else {
                e.currentTarget.parentNode.classList.remove("active");
            }
        });
    }  
};
