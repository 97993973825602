import React from 'react';

export default function SessionModal(props){
  const {introduceDivision, consoleCfg, setIntroduce } = props;
  const modalClose = () => {
    setIntroduce && setIntroduce(false, null)
  };

  return(
    <div className="modal" id="sessionModal" data-backdrop="static" role="dialog" aria-labelledby="modalLabel">
      <div className="ft_modal_container">
        <div className="ft_modal_header">
          <div>
            <h2>{introduceDivision == 'session' ? 'Session' : 'Event'}introduction</h2>
            <button
              className="close_btn"
              href=""
              onClick={(e) => {
                e.preventDefault();
                modalClose();
              }}
            >
              <img src="https://cdn.onoffevent.com/common/icon_close.svg" />
            </button>
          </div>
        </div>
        <div className="ft_modal_content">
          <h2 className="session_subject">{introduceDivision == 'session' ? consoleCfg?.sessionName : consoleCfg?.eventName}</h2>
          {introduceDivision == 'session' ? consoleCfg?.sessionDescription : consoleCfg?.intro}
        </div>
      </div>
    </div>
  )
}