/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useEffect, useContext } from "react";
import { ThemeProvider } from '@mui/material/styles';
import { theme }  from '../../resources/preRegister/Preregister.theme'
import { AppContext } from "../../components/AppProvider";
import { _API } from "../../modules/utils";
import PreRegisterDialog from "../../components/UI/preRegister/preRegisterDialog"
import HospitalFindDialog from "../../components/UI/preRegister/hospitalFindDialog"
import AlertDialog from "../../components/UI/preRegister/alertDialog"
import { DynamicStyles } from "../common/Resources/GlobalStyle";
import create from 'zustand';

// import required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
SwiperCore.use([Navigation, Pagination, Autoplay])
import { Swiper, SwiperSlide } from "swiper/react";
import Input, { TextArea, CheckBox, DropDownCheck as MuiSelectCheck, DropDown as MuiSelect } from "../../components/MUI/console/Form";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import Footer from "./Prereg.Footer";

import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import checkIcon from '../../resources/preReg/images/icon/chk.svg';
import checkOnIcon from '../../resources/preReg/images/icon/chk_on.svg';

import MainImg from '../../resources/images/prereg/1.png';
import SecImg from '../../resources/images/prereg/2.png';
import Seoul1 from '../../resources/images/prereg/3.png';
import Seoul2 from '../../resources/images/prereg/4.png';
import Incheon from '../../resources/images/prereg/5.png';
import Pangyo from '../../resources/images/prereg/6.png';
import Daegu from '../../resources/images/prereg/7.png';
import Busan from '../../resources/images/prereg/8.png';
import { resolve, toNamespacedPath } from "path";
import moment from 'moment';
import PreRegNameInput from "./PreRegName.Input";
import PreRegEmailInput from "./PreRegEmail.Input";
import PreRegMobileInput from "./PreRegMobile.Input";
import useStore from "../../modules/utils/store";

export default (props) => {
    const { eventIdx, channel } = props;
    const accessPath = [
        { label: "영업사원 방문", value: "1" },
        { label: "학회", value: "2" },
        { label: "이메일", value: "3" },
        { label: "메디게이트", value: "4" },
        { label: "우편물", value: "5" },
        { label: "기타", value: "6" },
    ]
    // 이벤트 정보
    const [eventInfo, setEventInfo] = useState({});
    const [sessionList, setSessionList] = useState([]);
    const [regionName, setRegionName] = useState([]);
    const [preregOpen, setPreregOpen] = useState(false);
    const [hospitalOpen, setHospitalOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertAnswer, setAlertAnswer] = useState(false);
    const [list, setList] = useState([]);
    // const [preregName, setPreregName] = useState("정경수");
    // const [preregMobile, setPreregMobile] = useState("01094629313");
    // const [preregCompName, setPreregCompName] = useState("DEVELOOP");
    // const [preregName, setPreregName] = useState("");
    // const [preregMobile, setPreregMobile] = useState("");
    // const [preregEmail, setPreregEmail] = useState("");
    const [preregCompName, setPreregCompName] = useState("");
    const [preregCompAddr, setPreregCompAddr] = useState("");
    const [urlConnectPath, setUrlConnectPath] = useState("");
    const [selectConnectPath, setSelectConnectPath] = useState("");
    const referrer = document.referrer;
    const [detailConnectPath, setDetailConnectPath] = useState(referrer);
    const [preregQuestion, setPreregQuestion] = useState("");
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [useChecked, setUseChecked] = useState(false);
    const [myPreRegInfoList, setMyPreRegInfoList] = useState([]);
    const [curPreRegInfoList, setCurPreRegInfoList] = useState([]);
    const saveList = [];

    var preregName = "";
    var preregMobile = "";
    var preregEmail = "";

    const {setSavePreregName,setSavePreregMobile,setSavePreregEmail} = useStore();

    const onChange = (event) => {
        const {
            target: { value },
        } = event;
        setRegionName(
            typeof value === "string" ? value.split(",") : value
        );
    };
    const regionChk = (obj) => {
        setList((o) => {
        let li = [...o];
        if (li?.filter((item) => item?.idx == obj.idx)?.length > 0) {
            li = li?.filter((item) => item?.idx != obj.idx);
        } else {
            li.push(obj);
        }
        li = li?.sort((a, b) => a.idx - b.idx);
        
        return li;
        });
    };
    const MenuProps = {
        PaperProps: {
            sx: {
                borderRadius: "4px",
                boxShadow:" 3px 3px 10px 0 rgba(0, 0, 0, 0.24)",
                border: "1px solid #4e4b7b",
                ".MuiList-root": {
                    paddingTop: "0",
                    paddingBottom: "0",
                    padding:"5px 10px",
                },
                ".MuiList-root .MuiMenuItem-root": {
                    backgroundColor: "#fff",
                    padding: 0,
                    padding: "5px 0"
                },
                ".MuiList-root .MuiMenuItem-root:hover": {
                    backgroundColor:"#fff"
                },
                ".MuiList-root .MuiMenuItem-root.Mui-selected": {
                    backgroundColor:"#fff"
                },
                ".MuiList-root .MuiMenuItem-root span": {
                    padding: 0,
                    marginRight:"6px"
                },
            }
        }
    };

    const openDialog = (gubun) => { 
        if (gubun == "pre") {
            setPreregOpen(true);
        } else if (gubun == "hos") {
            setHospitalOpen(true);
        } else if (gubun == "alert") { 
            setAlertOpen(true);
        }
    }
    const usePlaceholderStyles = makeStyles(theme => ({
        placeholder: {
            color: "#cccccc",
            fontSize:"15px",
        }
    }));

    const Placeholder = ({ children }) => {
        const classes = usePlaceholderStyles();
        return <div className={classes.placeholder}>{children}</div>;
    };

    const listDelete = (obj) => {  
        setList((o) => {
            let li = [...o];
            return li?.filter((item) => item.idx != obj.idx); 
        });
    }
    const closeAfter = (hName, hAddr, dName) => {
        setPreregCompName(hName);
        setPreregCompAddr(hAddr);
    }
    const preregSave = () => {
        if(window.confirm("입력하신 내용으로 등록하시겠습니까?")){
            preregName = document.getElementById('preregName').value;
            preregMobile = document.getElementById('preregMobile').value;
            preregEmail = document.getElementById('preregEmail').value;

            setSavePreregName(preregName)
            setSavePreregMobile(preregMobile)
            setSavePreregEmail(preregEmail)

            if(list.length == 0){
                alert("하나 이상의 심포지엄을 선택해주세요")
                return;
            }
            
            let valname = preregName.trim()
            if(valname == "" || valname == null){
                alert("이름을 입력해주세요")
                return;
            }
            preregName = valname;

            let check = /^(?:(010\d{4})|(01[1|6|7|8|9]\d{3,4}))(\d{4})$/
            if(preregMobile == "" || preregMobile == null){
                alert("모바일을 입력해주세요")
                return;
            } else if (!check.test(preregMobile)) {
                alert("모바일을 확인해주세요")
                return;
            } 
            
            // var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
            // var regExp2 = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}.[a-zA-Z]{2,3}$/i;
            // if(preregEmail == "" || preregEmail == null){
            //     alert("이메일을 입력해주세요")
            //     return;
            // } else if (!regExp.test(preregEmail) && !regExp2.test(preregEmail)) {
            //     alert("이메일을 확인해주세요")
            //     return;
            // }

            if(preregCompName == "" || preregCompName == null){
                alert("병원명을 입력해주세요")
                return;
            }  
            if(selectConnectPath == "" || selectConnectPath == null){
                alert("접속경로를 선택해주세요")
                return;
            }      
            if(privacyChecked == false){
                alert("개인정보수집에 대해 동의해주세요")
                return;
            }     
            if(useChecked == false){
                alert("GSK 정보 수신에 동의해주세요")
                return;
            }  
            
            async1(list).then(async2).then(async3).then(result => {console.log(result);});
        }
    }
    
    function async1 (list) {
        return new Promise(function(resolve, reject) {
            const sessionIdxs = [...new Set(list?.map((item) => item?.idx))];
            const req = {
                path: "/v1/preregi/my/prereg",
                data: {
                eventIdx,
                sessionIdxs,
                preregName,
                preregMobile,
                preregCompName,
                },
            };
            _API.post(req).then((res) => {
                const data = res.data?.data;
                resolve(data);
            }).catch((e) => {
                console.log(e)
            });
        });
    }
    function async2 (param) {
        return new Promise(function(resolve, reject) {
            let str = "";
            let cnt = 0;           
            let compareCnt = 0;
            for (let i = 0; i < param.length; i++) {
                if( i == 0 ){
                    str += `${param[i].sessionName}(${moment(param[i].startDate).format("MM/DD")})`
                }else{
                    str += `, ${param[i].sessionName}(${moment(param[i].startDate).format("MM/DD")})`
                }
                for (let j = 0; j < list.length; j++) {
                    if(parseInt(param[i].sessoinIdx) == parseInt(list[j].idx)){
                        compareCnt++;
                    }
                }
            }
            
            if(parseInt(compareCnt) == parseInt(list.length)){
                alert(`${str}은 이미 등록된 심포지엄입니다.`);
                return;
            }
            let saveConfirm = 0;
            if(param.length > 0){
                if(window.confirm(`${str}은 이미 등록된 심포지엄입니다. 이미 등록한 심포지엄을 제외한 신규 심포지엄을 등록하시겠습니까?`)){
                    saveConfirm = 1;
                }else{
                    saveConfirm = 0;
                }
            }else{
                saveConfirm = 1;                
            }
            if(saveConfirm == 1){
                for (let i = 0; i < list.length; i++) {
                    const li = list[i];
                    const sessionIdx = li.idx;
                    let sameVal = true;
                    
                    for (let j = 0; j < param.length; j++) {
                        const elm = param[j];
                        
                        if(parseInt(sessionIdx) == parseInt(elm.sessoinIdx)){
                            sameVal = false;
                            break;
                        }
                    }

                    if(sameVal){
                        cnt++;
                        const req = {
                            path: "/v1/preregi/save",
                            data: {
                            eventIdx,
                            sessionIdx,
                            preregName,
                            preregMobile,
                            preregEmail,
                            preregCompName,
                            preregCompAddr,
                            preregQuestion,
                            urlConnectPath,
                            selectConnectPath,
                            detailConnectPath
                            },
                        };
                        _API.post(req).then((res) => {
                            saveList.push(li);
                        }).catch((e) => {
                            console.log(e)
                        });
                    }
                }
            }
            setCurPreRegInfoList(saveList)
            resolve(cnt)
        });
    }
    function async3 (param) {
        return new Promise(function(resolve, reject) {
            if(param > 0){
                alert("정상적으로 등록 되었습니다.") 
                const req = {
                    path: "/v1/preregi/my/prereg/list",
                    data: {
                        eventIdx,
                        preregName,
                        preregMobile,
                        preregCompName,
                    },
                    };
                _API.post(req).then((res) => {
                    setMyPreRegInfoList(res.data?.data);
                    openDialog("pre") 
                }).catch((e) => {
                    console.log(e)
                });
            }else{
                alert("새롭게 추가된 심포지엄이 없습니다.") 
            }
        });
    }
    
    const searchEvent = () => {
        const req = {
            path: "/v1/preregi/event",
            data: {
            eventIdx:eventIdx,
            },
        };
        _API.post(req).then((res) => {
            setEventInfo(res.data?.data)
        })
        .catch((e) => {
            console.log(e)
        });
    }

    const preRegEventList = () => {
        const req = {
            path: "/v1/preregi/events/list",
            data: {
                eventIdx,
            },
        };
        _API.post(req).then((res) => {
            setSessionList(res.data?.data);
        })
        .catch((e) => {
            console.log(e)
        });
    }

    const pdfClick = (i) => {
        switch (i) {
            case 0:
                window.open(
                    `https://wx-direct.s3.ap-northeast-2.amazonaws.com/Shingrix_Seoul_1110.pdf`,
                    "_blank"
                );
            break;
            case 1:
                window.open(
                    `https://wx-direct.s3.ap-northeast-2.amazonaws.com/Shingrix_Seoul_1124.pdf`,
                    "_blank"
                );
            break;
            case 2:
                window.open(
                    `https://wx-direct.s3.ap-northeast-2.amazonaws.com/Shingrix_Incheon_1207.pdf`,
                    "_blank"
                );
            break
            case 3:
                window.open(
                    `https://wx-direct.s3.ap-northeast-2.amazonaws.com/Shingrix_Pangyo_1205.pdf`,
                    "_blank"
                );
            break
            case 4:
                window.open(
                    `https://wx-direct.s3.ap-northeast-2.amazonaws.com/Shingrix_Deagu_1122.pdf`,
                    "_blank"
                );
            break
            case 5:
                window.open(
                    `https://wx-direct.s3.ap-northeast-2.amazonaws.com/Shingrix_Busan_1208.pdf`,
                    "_blank"
                );
            break
            default:
                alert("준비중입니다.")
            break;
        }
    }

    

    useEffect(() => {
        searchEvent();
        preRegEventList();
        if(channel != "" && channel != null){
            setUrlConnectPath(channel)
        }
    }, [])


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{padding:"0 20px"}}>
                <Container  maxWidth="lg" sx={{marginBottom:"150px",paddingLeft:"0 !important", paddingRight:"0 !important"}}>
                    <Box className="preregister_con">
                        <Typography variant="h1" component="h1" sx={{marginBottom:"50px"}}>{eventInfo.name}</Typography>
                        {/* <Swiper
                            observer={true}
                            observeParents={true}
                            pagination={{
                                clickable: true,
                            }}
                            loop={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false
                            }}
                            modules={[Autoplay, Pagination, Navigation]}

                        >
                            <SwiperSlide>
                                <img style={{display:"block", width:"100%"}} src={SecImg}/>
                            </SwiperSlide>   
                            <SwiperSlide>
                                <img style={{display:"block", width:"100%"}} src={Seoul1}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img style={{display:"block", width:"100%"}} src={Seoul2}/>
                            </SwiperSlide>     
                            <SwiperSlide>
                                <img style={{display:"block", width:"100%"}} src={Incheon}/>
                            </SwiperSlide>   
                            <SwiperSlide>
                                <img style={{display:"block", width:"100%"}} src={Pangyo}/>
                            </SwiperSlide>   
                            <SwiperSlide>
                                <img style={{display:"block", width:"100%"}} src={Daegu}/>
                            </SwiperSlide>   
                            <SwiperSlide>
                                <img style={{display:"block", width:"100%"}} src={Busan}/>
                            </SwiperSlide>         
                        </Swiper> */}
                        <Typography sx={{color:"red", fontSize:"26px", marginBottom:"20px",fontWeight:"bold"}}>&#60;싱그릭스 론칭 심포지엄 신청 마감 안내&#62;</Typography>
                        <Typography sx={{color:"#333333", fontSize:"16px", fontWeight:"500"}}>
                            선생님, 안녕하세요.<br />
                            “싱그릭스 론칭 심포지엄 신청을 마감”하게 되었습니다.<br />
                            예상보다 많은 관심과 성원으로 부득이하게 일찍 마감하게 된 점 양해 부탁드립니다.<br />
                            감사합니다.
                        </Typography>
                    </Box>
                    
                    {/* <Stack direction={{ sm:"row", }}>
                        <Box sx={{ width: { xs: "100%", sm: "calc(100% - 300px)" }, marginRight: {xs:"0px",sm:"30px"} }}>
                            <Box>
                                <Typography variant="subtitle1" component="h2" mb={1.2}>사전신청 정보 입력</Typography>
                                <Typography variant="body1" component="p" mb={3.8}>
                                아래 내용을 입력해 주세요. *표기는 필수값입니다.
                                </Typography>
                                <Box sx={{ marginBottom: "20px"}}>
                                    <MuiSelectCheck
                                        required={true}
                                        variant="subtitle2"
                                        component="h4"
                                        value={regionName}
                                        name="참석날짜"
                                        text="보이지 않는 심포지엄은 마감된 심포지엄입니다."
                                        onChange={onChange}
                                        onClick={regionChk}
                                        listDelete={listDelete}
                                        array={list}
                                        renderValue={(selected) => {
                                            if (selected) {
                                                return <Typography sx={{ color: "#ccc" }}>참석할 심포지엄을 선택해 주세요. </Typography>;
                                            }
                                        }}
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                        MenuProps={MenuProps}
                                        list={sessionList}
                                        hint="한번에 여러 심포지엄 선택가능 합니다."
                                    />
                                </Box>
                                <Box sx={{ marginBottom: "20px" }}>
                                    <Typography variant="subtitle2" component="h4">성명*</Typography>
                                    <PreRegNameInput />
                                </Box>
                                <Box sx={{ marginBottom: "20px" }}>
                                    <Typography variant="subtitle2" component="h4">모바일*</Typography>
                                    <PreRegMobileInput />
                                </Box>
                                <Box sx={{ marginBottom: "20px" }}>
                                    <Typography variant="subtitle2" component="h4">이메일*</Typography>
                                    <PreRegEmailInput />
                                </Box>
                                <Box sx={{ marginBottom: "20px" }}>
                                    <Input
                                        required={true}
                                        fullWidth
                                        placeholder="한국병원"
                                        name="병원명"
                                        onClick={() => openDialog("hos")}
                                        value={preregCompName}
                                        sx={{
                                            '.MuiInputBase-input': (theme) => ({
                                                cursor:"pointer"
                                        })}}
                                    />
                                </Box>
                                <Box sx={{ marginBottom: "60px" }}>
                                    <MuiSelect
                                        required={true}
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 }
                                        }}
                                        name="접속경로"
                                        value={selectConnectPath}
                                        displayEmpty
                                        onChange={(e)=>{setSelectConnectPath(e.target.value)}}
                                        renderValue={
                                            selectConnectPath !== "" ? undefined : () => <Placeholder>심포지엄을 알게 된 경로를 선택해 주세요.</Placeholder>
                                        }
                                        MenuProps={MenuProps}
                                        list={accessPath}
                                    /> 
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant="subtitle1" component="h2" mb={3.8}>개인정보수집동의</Typography>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{marginBottom:"10px"}}>
                                    <Typography variant="subtitle2" component="h4">개인정보수집 이용동의서</Typography>
                                    <Button variant="general">보건의료 전문가</Button>
                                </Stack>
                                <Box sx={{background:"#f7f7fa", padding:"10px", height:"130px", overflowY:"auto", marginBottom:"10px"}}>
                                    <Typography variant="h3" component="h3">주식회사 글락소스미스클라인</Typography><br/>
                                    (이하 ‘회사’라고 합니다)은 귀하의 개인정보를 소중하게 여기며, 귀하의 개인정보를  처리(수집, 이용, 제공 등)함에 있어 아래와 같이 주의를 기울이고 있습니다.<br/><br/>
                                    -수집하려는 개인정보의 항목<br/>
                                    -개인정보의 이용 목적<br/>
                                    -개인정보의 보유 및 이용 기간<br/>
                                    -개인정보를 제공받는 자<br/>
                                    -개인정보에 대한 권리<br/><br/>
                                    회사는 귀하의 개인정보를 보호하기 위해 개인정보 처리방침을 수립하고 준수하고  있습니다. 개인정보 처리방침에 대한 자세한 내용은 아래 웹사이트를 참조하십시오<br/><br/>
                                    <a href="https://privacy.gsk.com/ko-kr/privacy-notice/" target="_blank">https://privacy.gsk.com/ko-kr/privacy-notice/</a><br/><br/>
                                     
                                    <Typography variant="h3" component="h3">회사가 수집하려는 개인정보의 항목은 무엇입니까?</Typography><br/>
                                    -일반 개인 정보<br/>
                                    성명, 주소, 소속 의료기관명 및 주소, 전공, 연락처(휴대전화번호), 이메일 주소<br/><br/><br/>
                                    <Typography variant="h3" component="h3">회사의 개인정보의 수집∙ 이용목적은 무엇입니까?</Typography><br/>
                                    귀하께서 제공해 주시는 성명, 소속 의료기관명 주소, 연락처(휴대전화번호), 이메일주소,  의학적 소견 등 모든 정보는 GSK의 제품에 대한 마케팅 커뮤니케이션, 프로모션  서비스(웹 세미나 및 행사에 대한 예약 및 초대를 포함) 외 홍보 활동과 의약품 연구계획,  의약학 정보의 취합 및 제공 등을 위해 수집 및 이용됩니다.<br/><br/><br/>
                                    <Typography variant="h3" component="h3">회사는 얼마나 오랫동안 수집한 개인정보를 보유 및 이용합니까? (개인정보의 보유 및 이용 기간)</Typography><br/>
                                    회사는 귀하의 개인정보를 법령에서 요구하는 기간 또는 회사와 관련된 법적 조치 또는  조사와 관련하여 필요한 경우 보관할 것입니다. 그 이외에는 귀하의 개인정보를 아래 기간  동안 보유합니다.<br/>
                                    개인정보를 수집한 날로부터 5년 동안 보유합니다.<br/><br/><br/>
                                    <Typography variant="h3" component="h3">개인정보 및 민감정보의 위탁에 대한 고지</Typography><br/>
                                    회사의 개인정보 처리업무의 위탁에 관한 고지 및 개인정보처리방침은 GSK
                                    웹사이트(<a href="https://privacy.gsk.com/ko-kr/privacy-notice/" target="_blank">https://privacy.gsk.com/ko-kr/privacy-notice/</a>)를 통해 확인하실 수 있습니다.<br/><br/><br/>
                                    <Typography variant="h3" component="h3">귀하의 권리</Typography><br/>
                                    귀하는 위 개인정보 정보수집 및 이용에 대한 수락 여부를 자유롭게 결정하실 수 있으며,  필요한 최소한의 정보 외의 개인정보 수집에 동의하지 아니할 수 있으며, 언제라도  개인정보 제공 및 수집이용 동의를 철회 및 중단할 권리가 있습니다. 귀하께서 정보수집 및  이용을 수락하지 않거나, 기존의 개인정보 수집 이용에 대한 동의를 철회할 경우, 해당  개인정보를 이용하여 회사에서 제공하는 서비스 전부 또는 일부를 제공받으실 수 없습니다.<br/><br/><br/>
                                    <Typography variant="h3" component="h3">연락처</Typography><br/>
                                    회사의 개인정보 수집 이용에 대해 추가적인 문의가 있거나, 회사의 개인정보 처리 방침에  대한 보다 자세한 설명이 필요하신 경우 아래의 연락처로 문의하실 수 있습니다.<br/><br/>
                                    Email:  KR-PH.Privacy@gsk.com<br/>
                                    Phone: 02-709-4114<br/><br/>
                                </Box>
                                <Typography variant="body1" component="p">
                                    ※ 회사의 개인정보 수집 및 이용에 관한 설명을 모두 이해하였고, 위와 같이 개인정보를 수집  이용하는데 동의하십니까?
                                </Typography>
                                <Box sx={{ marginBottom: "20px", textAlign: "right" }}>
                                    <CheckBox
                                        icon={<img src={checkIcon} />}
                                        checkedIcon={<img src={checkOnIcon} />}
                                        lSx = {{lineHeight:"normal"}}
                                        label= "동의함"
                                        sx={{marginRight:0}}
                                        onChange={(e) => {setPrivacyChecked(e.target.checked)}}
                                        
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant="subtitle1" component="h2" mb={3.8}>GSK 정보 수신동의</Typography>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{marginBottom:"10px"}}>
                                    <Typography variant="subtitle2" component="h4">GSK 정보 수신 동의서</Typography>
                                    <Button variant="general">보건의료 전문가</Button>
                                </Stack>
                                <Box sx={{background:"#f7f7fa", padding:"10px", height:"120px", overflowY:"auto", marginBottom:"10px"}}>
                                    GSK 계열 회사로부터 약품, 연구계획, 제품, 웨비나 및 이벤트 세부 정보 서비스 등에 대한 이메일, 문자메시지, 카카오톡 등을 포함한 전자매체 메시지의 수신에 동의하시면 확인란을 선택하십시오. 동의 항목에 체크하지 않는 방식으로 동의하지 않을 수 있으며, 동의할 경우 추후 언제든지 메시지 수신을 거부할 수 있습니다. 귀하와의 소통을 위해 사용되었던 이전 이메일 수신 동의서는 귀하가 이메일 수신을 거부할 때까지 유효합니다.
                                </Box>
                                <Typography variant="body1" component="p">
                                    ※ 이메일 수신에 동의하십니까?
                                </Typography>
                                <Box sx={{ marginBottom: "60px", textAlign: "right" }}>
                                <CheckBox
                                    icon={<img src={checkIcon} />}
                                    checkedIcon={<img src={checkOnIcon} />}
                                    lSx = {{lineHeight:"normal"}}
                                    label= "동의함"
                                    sx={{marginRight:0}}
                                    onChange={(e) => {setUseChecked(e.target.checked)}}
                                    
                                />
                                </Box>
                                <Button variant="submit" sx={{ marginBottom: "20px" }} onClick={() => {preregSave()}}>사전등록하기</Button>
                            </Box>
                        </Box>
                        <Box sx={{ width: { xs: "100%", sm: "300px" }, marginBottom: {xs: "30px"} }}>
                            <Typography variant="subtitle1" component="h2" mb={1.2}>심포지엄 초청장 확인</Typography>
                            <Grid container>
                                <Grid item xs={12} sm={12} sx={{ marginBottom: "10px"}}>
                                    <Button variant="basic" onClick={() => pdfClick(0)} sx={{ justifyContent: "flex-start",textAlign:"left" }}>서울(11/10)</Button>
                                </Grid>
                                <Grid item xs={12} sm={12} sx={{ marginBottom: "10px"}}>
                                    <Button variant="basic" onClick={() => pdfClick(1)} sx={{ justifyContent: "flex-start",textAlign:"left" }}>서울(11/24)</Button>
                                </Grid>
                                <Grid item xs={12} sm={12} sx={{ marginBottom: "10px"}}>
                                    <Button variant="basic" onClick={() => pdfClick(2)} sx={{ justifyContent: "flex-start",textAlign:"left" }}>인천(12/07)</Button>
                                </Grid>
                                <Grid item xs={12} sm={12} sx={{ marginBottom: "10px"}}>
                                    <Button variant="basic" onClick={() => pdfClick(3)} sx={{ justifyContent: "flex-start",textAlign:"left" }}>판교(12/05)</Button>
                                </Grid>
                                <Grid item xs={12} sm={12} sx={{ marginBottom: "10px"}}>
                                    <Button variant="basic" onClick={() => pdfClick(4)} sx={{ justifyContent: "flex-start",textAlign:"left" }}>대구(11/22)</Button>
                                </Grid>
                                <Grid item xs={12} sm={12} sx={{ marginBottom: "10px"}}>
                                    <Button variant="basic" onClick={() => pdfClick(5)} sx={{ justifyContent: "flex-start",textAlign:"left" }}>부산(12/08)</Button>
                                </Grid>
                            </Grid>
                            <Box mt={2}>
                                <Typography variant="body1" component="p">
                                    <b>* 사전등록 변경 및 취소 문의</b><br/>
                                    웨비나스 (02-6342-6830)<br/><br/>

                                    <b>* 심포지엄 관련 문의</b><br/>
                                    GSK (080-901-4100)<br/>
                                </Typography>
                            </Box>
                        </Box>
                    </Stack> */}
                    <PreRegisterDialog 
                        open={preregOpen} 
                        setOpen={setPreregOpen}
                        myPreRegInfoList={myPreRegInfoList}
                        saveList={curPreRegInfoList}
                        preregName={preregName}
                        preregCompName={preregCompName}
                        preregMobile={preregMobile}
                        preregEmail={preregEmail}
                        selectConnectPath={selectConnectPath}
                    />   
                    <HospitalFindDialog 
                        open={hospitalOpen} 
                        setOpen={setHospitalOpen}
                        closeAfter={closeAfter}
                    />   
                    <AlertDialog open={alertOpen} setOpen={setAlertOpen} setAlertAnswer={setAlertAnswer}/>   
                </Container>
                <DynamicStyles />
            </Box>
        </ThemeProvider>
    );
    
};
