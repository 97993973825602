import React, { useContext, useEffect, useState } from 'react';
import { Search } from '../../../components/UI/console/Form';
import SearchMark from '../../../components/UI/SearchMark';
import { Modal } from "react-bootstrap";
import { _API,_CK } from '../../../modules/utils';
import { AppContext } from '../../../components/AppProvider';
import useStore from '../../../modules/utils/store';
import moment, { months } from "moment";

export default function ConnectUsersViewModal(props){
    const { iam } = useContext(AppContext);
    const { siteInfo } = useStore();
    const { consoleCfg,showConnectUsersViewModal,setShowConnectUsersViewModal } = props;
    const [connectUsers, setConnectUsers] = useState(null);
    const [keyword, setKeyword] = useState('');

    const now = () => {
        const nowMoment = moment();
        const now = nowMoment.format('YYYY-MM-DD HH:mm:ss');

        return now;
    }
    const getConnectUsers = () => {
        const req = {
            path: "/v1/client/console/user/list",
            data: {
              siteIdx: siteInfo?.siteIdx,
              eventIdx: consoleCfg?.eventIdx,
              sessionIdx: consoleCfg?.sessionIdx,
              consoleIdx: consoleCfg?.consoleIdx,
            },
        };
        _API.post(req).then((res) => {
            console.log(res)
            setConnectUsers(res.data);
        });
    }

    const refreshButton = (e) => {
        e.target.text = `(${now()}) 새로고침`;
        setConnectUsers(null);
        getConnectUsers();
    }

    useEffect(() => {
        if(showConnectUsersViewModal == true){
            getConnectUsers();
        }
    }, [showConnectUsersViewModal]);


    
    const handleClose = () => {
        setConnectUsers(null)
        setShowConnectUsersViewModal(false);
    };

    return(
        <Modal
            show={showConnectUsersViewModal}
            onHide={handleClose}
            backdrop="static"
            className="survey_container"
            style={{zIndex:"1051"}}
        >
            <div className="ft_modal_header">
                <div>
                    <h2>실시간 참가자</h2>
                    <button
                        className="close_btn"
                        onClick={handleClose}
                    >
                    <img src="https://cdn.onoffevent.com/common/icon_close.svg" />
                    </button>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-4 pl-5 pr-5">
                <span>총 <b>{connectUsers?.length || 0}</b>명 중 
                    <b>{connectUsers?.filter((u) => (
                    u?.preregIdx?.toString()?.includes(keyword) ||
                    u?.name?.toString()?.includes(keyword) ||
                    u?.passCode?.toString()?.includes(keyword) ||
                    u?.companyName?.toString()?.includes(keyword) ||
                    u?.deptName?.toString()?.includes(keyword)))?.length || 0}
                    </b>명
                </span>
                <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                    <button onClick={(e) => refreshButton(e)}>(최종 업데이트&nbsp;:&nbsp;{now()})&nbsp;새로고침</button>
                    <Search type="text" value={keyword} onChange={(e) => setKeyword(e?.target?.value || '')} delButton/>
                </div>
            </div>
            <div className="ft_modal_content" style={{paddingTop:'0px',marginTop:'15px'}}>
                <table className="question_storage_table">
                    <thead>
                        <tr align="center" height="34">
                            <th width="10%" style={{position:'sticky', top:'-1px'}}>참가자ID</th>
                            <th width="15%" style={{position:'sticky', top:'-1px'}}>성명</th>
                            <th width="15%" style={{position:'sticky', top:'-1px'}}>접속계정</th>
                            <th width="20%" style={{position:'sticky', top:'-1px'}}>병원명</th>
                            <th width="20%" style={{position:'sticky', top:'-1px'}}>진료과</th>
                            <th width="20%" style={{position:'sticky', top:'-1px'}}>접속일시</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!connectUsers || connectUsers == null || connectUsers?.length <= 0 ? (
                            <tr><td className="text-center" colSpan="6">실시간 참가자가 없습니다.</td></tr>
                        ) : (
                        <>
                            {connectUsers?.filter((u) => (
                                u?.preregIdx?.toString()?.includes(keyword) ||
                                u?.name?.toString()?.includes(keyword) ||
                                u?.passCode?.toString()?.includes(keyword) ||
                                u?.companyName?.toString()?.includes(keyword) ||
                                u?.deptName?.toString()?.includes(keyword)))?.map((item,i) => (  
                                    
                                    <tr align="center" key={i}>
                                        <td><SearchMark text={item?.preregIdx} keyword={keyword}/></td>
                                        <td><SearchMark text={item?.name} keyword={keyword}/></td>
                                        <td><SearchMark text={item?.passCode} keyword={keyword}/></td>
                                        <td><SearchMark text={item?.companyName || '-'} keyword={keyword}/></td>
                                        <td><SearchMark text={item?.deptName || '-'} keyword={keyword}/></td>
                                        <td>{item?.startedAt && moment(item?.startedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    </tr>
                                    
                            ))}
                        </>
                        )}
                    </tbody>
                </table>
            </div>
        </Modal>

    )
}