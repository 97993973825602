import React, { Fragment, useContext, useEffect, useState} from 'react';
import Marquee from "react-fast-marquee";
import Player from "./Player";
import Banner from "./Banner"
import UserList from "./UserList"
import Guide from "./Guide";
import Files from "./Files";
import QNA from "./Qna";
import Chat from "./Chatting";
import Notice from "./Notice";



import cx from "classnames";
import moment from "moment";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import playerDefaultImg2 from "../../../../resources/console/images/jpg/player-default-img2.png";
import SessionMore from "../../../../resources/console/images/icon/btn_sessionmore.svg";
import SessionMore_open from "../../../../resources/console/images/icon/btn_sessionmore_open.svg";

import { AppContext } from '../../../../components/AppProvider';
import parse, { domToReact } from 'html-react-parser'
import useStore from '../../../../modules/utils/store';
import { _API, _U } from '../../../../modules/utils';

export default (props) => {
  const { iam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  const { consoleCfg, setSessionMgrInfoModal, setIntroduce , sessionicon, setSessionicon, setSurveyInfo, attendanceYn,attendanceState,setAttendModal } = props;
  const [clickVideoTime ,setClickVideoTime] = useState(0);
  const [player ,setPlayer] = useState(null);

  const timeCalculation = (text) => {
    if(consoleCfg.serviceType == "ONDEMAND"){
      let resultText = text;
      let result = null;
      let result2 = null;
      if (text != "" && text != null) { 
        if(text.match(/(0[0-9]|1[0-9]|2[0-4]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])/g)){
          result = text.match(/(0[0-9]|1[0-9]|2[0-4]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])/g);
          if(result != null){
            for (let i = 0; i < result.length; i++) {
              let ele = result[i];
              let regex = /[0-9]/g;
              let found = ele.match(regex);
      
              // 시
              let hour = Number(found[0]+""+found[1])*60*60;
              // 분
              let min = Number(found[2]+""+found[3])*60;
              // 초
              let sec = Number(found[4]+""+found[5]);
              let total = hour+min+sec
              resultText = resultText.replace(result[i],"<a onclick='"+total+"'>"+result[i]+"</a>");
            }
          }
          text = text.replace(/(0[0-9]|1[0-9]|2[0-4]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])/g,"");
        }
        if(text.match(/(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])/g)){
          result2 = text.match(/(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])/g);
          if(result2 != null){
            for (let i = 0; i < result2.length; i++) {
              let ele = result2[i];
              let regex = /[0-9]/g;
              let found = ele.match(regex);
      
              // 분
              let min = Number(found[0]+""+found[1])*60;
              // 초
              let sec = Number(found[2]+""+found[3]);
              let total = min+sec
              
              resultText = resultText.replace(result2[i],"<a onclick='"+total+"'>"+result2[i]+"</a>")
            }
          }
        }
      }
      return (
        <div>
          {parse(resultText,{replace: domNode =>{
            if (domNode.name === 'a'){//버튼 이벤트 처리
              let sFuncName = domNode.attribs.onclick;
              delete domNode.attribs.onclick;

              return (
                <a style={ {cursor:"pointer", color:"#6a6af8"}} onClick={
                  () => { Function('"use strict";return (' + setClickVideoTime(sFuncName) +')')(); }} >{domToReact(domNode.children, {})}</a>
              );
            }
          }})}
        </div>
      )
    }else{
      return (
        <div dangerouslySetInnerHTML={{__html: text }}></div>
      )
    }
  };

  const movePage = (e, url) => {
    if(e) e.preventDefault();

    if(['MEDIGATE','ICOD'].includes(iam?.targetType)){
      return;
    }
    
    if(window.confirm('시청을 중단하시겠습니까?')){
      window.location.href = url;
    }
  }

    //SOCKET RECIEVE
    const onReceivedMessage = (e) => {
      _U.parseMessage(e).then((data) => {
          switch (data.code) {
              case "SOCKET-RECEIVED":
                  return data.data ? JSON.parse(data.data) : null;
          }
      }).then((data) => {
        if(data?.mode == 'ATTEND_EXIT_POPUP'){
          const req = {
            path: "/v1/client/session/attendance/status",
            data: {
              sessionIdx: consoleCfg.sessionIdx,
              preregIdx: iam?.preregIdx
            },
          };
      
          _API.post(req).then((res) => {
            let attendData = res.data;
            if(attendData?.entryTime != "" && attendData?.entryTime != null && consoleCfg?.exitPopupUseYn == 'Y') {
              const attendModalOpen = data.value == "Y" ? true : false;
              setAttendModal(attendModalOpen)
            }
          })
        }
      });
    };
    useEffect(() => {
      window.addEventListener("message", onReceivedMessage);
      return () => {
          window.removeEventListener("message", onReceivedMessage);
      };
    }, []);

    
  useEffect(() => {
    if(player != null){
      player.seek(clickVideoTime);
    }
  }, [clickVideoTime])

  return (
    <Fragment>
      {attendanceYn && (
        <div class="attend_box"
          style={{background:`${attendanceState? "#12A884" :"#F00"}`}}
        >
          <div className="marquee_box">
            <Marquee className="marquee" speed={70} >
              {attendanceState ? (consoleCfg?.attendConsoleText ? consoleCfg?.attendConsoleText : '현재 출석 중입니다. 퇴장 시 반드시 오른쪽 퇴장하기를 눌러 퇴장 진행 부탁 드립니다.') : (consoleCfg?.exitConsoleText ? consoleCfg?.exitConsoleText : '현재 출석 중이 아닙니다. 출석 처리를 위해 반드시 오른쪽 출석하기를 눌러 출석 진행 부탁 드립니다.')}
            </Marquee>
            <span
              style={{ background: `${attendanceState ? "linear-gradient(to right,rgba(18,168,132,0.00) 0%,#12A884 100%)" : "linear-gradient(to right,rgba(18,168,132,0.00) 0%,#F00 100%)"}` }}
            ></span>
          </div>
          <button onClick={() => { setAttendModal(true)}}>{attendanceState ? (consoleCfg?.exitPopupUseYn === 'Y' ? "퇴장하기" : '') : "출석하기"}</button>
        </div>
      )}
      <div className="detail_header_bnr"
      style={{ top: `${attendanceYn ? "86px" : "60px"}` }}>
        <img src={ consoleCfg?.signedUrlConsoleBg ? consoleCfg.signedUrlConsoleBg : "https://cdn.onoffevent.com/bg/Console bg.png" }/>
      </div>
      <div className="detail_con" style={{ marginTop: `${attendanceYn ? "26px" : "0px"}` }}>
        <div className="sub_bg_img_hd">
          {consoleCfg?.signedUrlConsoleMv?.length > 0 ? (
            <a onClick={(e) => movePage(e, `/#/event/${consoleCfg?.eventIdx}/register`)} style={{cursor: ['MEDIGATE','ICOD'].includes(iam?.targetType) ? 'default' : 'pointer'}}>
              <img
                src={consoleCfg?.signedUrlConsoleMv}
                alt="메인타이틀"
              />
            </a>
          ) : (
            <a onClick={(e) => movePage(e, `/#/event/${consoleCfg?.eventIdx}/register`)} style={{cursor:  ['MEDIGATE','ICOD'].includes(iam?.targetType) ? 'default' : 'pointer'}}>
              <img
                src="https://cdn.onoffevent.com/bg/main_console.png"
              />
            </a>
          )}
        </div>
        <div className="detail_wrap wid_1180">
          <div className="video_con wid_1180"
          style={{top:`${attendanceYn ? "86px" : "60px"}`}}>
            {consoleCfg?.signedUrlConsolePlayerImg ? (
              <div
                className="video_pre_con"
                style={{
                  backgroundImage: `url(${consoleCfg?.signedUrlConsolePlayerImg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "contain",
                }}
              ></div>
            ) : (
              <div
                className="video_pre_con"
                style={{
                  backgroundImage: `url(${playerDefaultImg2})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "contain",
                }}
              ></div>
            )}
            <div className="video_element">
              <Player
                consoleCfg={consoleCfg}
                setPlayer={setPlayer}
                player={player}
                clickVideoTime={clickVideoTime}
                setSurveyInfo={setSurveyInfo}
              />
            </div>
            <div className="video_info">
              <h4>
                <span>{consoleCfg?.eventName}</span>
              </h4>
              <p>{consoleCfg?.sessionName}</p>
            </div>
          </div>
          <div className="video_dummy"></div>

          <div className="detail_flex_box card-title-position">
            <div className="detail_left_box">
              <div className="detail_left_box_top">
                <div className="service_type_box">
                  <div className="service_type_box_div">
                    {consoleCfg?.serviceType === "LIVE" && (
                      <span className="livebtn">
                        <small>LIVE</small>
                      </span>
                    )}
                    {consoleCfg?.serviceType === "ONDEMAND" && (
                      <span className="ondemandbtn">
                        <small>On Demand</small>
                      </span>
                    )}
                    <span className="live_time">
                      {consoleCfg?.serviceType === "LIVE" ? (`${moment(consoleCfg?.startDate).format("HH:mm")} ~ ${moment(consoleCfg?.endDate).format("HH:mm")}`)
                      : consoleCfg?.serviceType === "ONDEMAND" &&(`${moment(consoleCfg?.startDate).format("MM.DD(ddd) HH:mm")} ~ ${moment(consoleCfg?.endDate).format("MM.DD(ddd) HH:mm")}`)
                      }
                    </span>
                  </div>
                  {(consoleCfg?.eventIntroUse !== "N" || consoleCfg?.sessionIntroUse !== "N") && (
                    <span
                      className="more_btn_span"
                      onClick={() => {setSessionicon(!sessionicon) }}
                    >
                      <img src={ sessionicon === true ? SessionMore_open : SessionMore}/>
                    </span>
                  )}
                  {sessionicon ? (
                    <>
                      <div className="tooltip_background" onClick={()=>{setSessionicon(false)}}></div>
                      <div className="tooltip_con">
                        <ul>
                          {consoleCfg?.sessionIntroUse !== "N" && (
                            <li onClick={() => setIntroduce(true, "session")}>
                              <a>세션소개 보기</a>
                            </li>
                          )}
                          {consoleCfg?.eventIntroUse !== "N" && (
                            <li onClick={() => setIntroduce(true, "event")}>
                              <a>이벤트소개 보기</a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
                <p>{consoleCfg?.sessionName}</p>
                <div className="detail_left_box_top_inner">
                  <UserList 
                    consoleCfg={consoleCfg}
                    setSessionMgrInfoModal={setSessionMgrInfoModal}
                  />
                </div>
              </div>
              
              {/* Q&A */}
              {consoleCfg.consoleQuestionYn === "Y" && (
                  <div className="pc_qna_container">
                    <QNA  consoleCfg={consoleCfg}/>
                  </div>
              )}
              {/* 채팅 */}
              {consoleCfg?.consoleChattingYn === "Y" && (
                <div className="chat_container pc_chat_container">
                  <Chat consoleCfg={consoleCfg}/>
                </div>
              )}
              <div class="m_detail_content">
                <div className={cx({ m_list_wrapper: true })}>
                  <ul>
                    {consoleCfg?.consoleQuestionYn === "Y" && (
                      <li className="active" onClick={() => handleTabClick(1)}>
                        <span>질문</span>
                      </li>
                    )}
                    {consoleCfg.consoleFiledownYn === "Y" && (
                      <li onClick={() => handleTabClick(2)}>
                        <span>파일</span>
                      </li>
                    )}
                    <li className="notice_li" onClick={() => handleTabClick(3)}>
                      <span>공지</span>
                    </li>
                    {consoleCfg.consoleBannerYn === "Y" && (
                      <li onClick={() => handleTabClick(4)}>
                        <span>배너</span>
                      </li>
                    )}
                    {consoleCfg.consoleChattingYn === "Y" && (
                      <li onClick={() => handleTabClick(5)}>
                        <span>채팅</span>
                      </li>
                    )}
                  </ul>
                </div>
                {/* 모바일 Q&A */}
                {activeTab === 1 && (
                  consoleCfg.consoleQuestionYn === "Y" && (
                    <div className="m_qna_container">
                      <QNA consoleCfg={consoleCfg}/>
                    </div>
                ))}
                {/* 모바일 첨부파일 */}
                {activeTab === 2 && (
                  consoleCfg.consoleFiledownYn === "Y" && (
                    <div className="detail_file_box">
                      <Files consoleCfg={consoleCfg}/>
                    </div>
                ))}
                {/* 모바일 공지 */}
                {activeTab === 3 && (
                  <div className="detail_free_box">
                    <Notice
                      consoleCfg={consoleCfg}
                      timeCalculation={timeCalculation}
                    />
                  </div>
                )}
                {/* 모바일 배너 */}
                {activeTab === 4 && (
                  consoleCfg.consoleBannerYn === "Y" &&
                  consoleCfg.bannerFiles &&
                  consoleCfg.bannerFiles.length > 0 && (
                    <div className="detail_banner_box_con">
                      <Banner consoleCfg={consoleCfg} activeTab={activeTab}/>
                    </div>
                  ))}
                {/* 모바일 채팅 */}
                {activeTab === 5 && (
                  consoleCfg.consoleChattingYn === "Y" && (
                    <div className="chat_container">
                      <Chat consoleCfg={consoleCfg}/>
                    </div>
                  )
                )}
              </div>
              <div className="detail_guide_box m_detail_guide_box">
                {/* 안내 */}
                <Guide />
              </div>
            </div>
            
            <div className="detail_right_box">
              {/* 배너 */}
              {consoleCfg.consoleBannerYn === "Y" &&
                consoleCfg.bannerFiles &&
                consoleCfg.bannerFiles.length > 0 && (
                <div className="detail_banner_box_con">
                  <Banner consoleCfg={consoleCfg}/>
                </div>
              )}
              {/* 공지 */}
              <div className="detail_free_box">
                <Notice consoleCfg={consoleCfg} timeCalculation={timeCalculation}/>
              </div>
              {/* 첨부파일 */}
              {consoleCfg.consoleFiledownYn === "Y" && (
                <div className="detail_file_box">
                  <Files  consoleCfg={consoleCfg}/>
                </div>
              )}
              {/* 안내 */}
              <div className="detail_guide_box"> <Guide /></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
    
  )
}