import React from "react";
import styled from "styled-components";
import cx from "classnames";

export const ButtonMenu = styled.div.attrs((props) => {
  return { className: props.className || "button-menu" };
})``;

export const TabMenu = (props) => {
  const { items, value, description, children } = props;
  return (
    <div className="menu">
      {children}

      {items?.length > 0 && (
        <ul className="ul-tab-menu">
          {items?.map((item, i) => (
            <li
              key={i}
              className={cx({
                item: true,
                active: (!item.value && !value) || item.value === value,
              })}
            >
              <a
                className="link"
                onClick={props.onClick?.bind(this, item.value)}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      )}
      {description ? <div className="space">{description}</div> : null}
    </div>
  );
};

const Button = styled.button.attrs((props) => {
  return {
    type: props.type || "button",
    className: !props.className && "btn btn-default",
    style: props.style
  };
})``;

export default Button;
