import React, { Fragment } from 'react';
import { _API, _CK, _U, _AC } from "../../modules/utils";
import moment from "moment";

export default (props) => {
  const{ item ,onOff} = props;
  
  return (
    <div className="livebtn_float">
      <Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {item?.serviceType === "LIVE" &&
          onOff(item) == "ONAIR" ? (
            <span className="onair_btn">
              <small>ON-AIR</small>
            </span>
          ) : item?.serviceType === "LIVE" && (
            <span className="livebtn">
              <small>LIVE</small>
            </span>
          )}
          {item.serviceType === "ONDEMAND" && (
            <span className="ondemandbtn">
              <small>On Demand</small>
            </span>
          )}
          <span className="live_time">
            {moment(item?.startDate).locale("en").format("HH:mm")} ~{" "}
            {moment(item?.endDate).locale("en").format("HH:mm")+" KST"}{" "}
          </span>
        </div>
      </Fragment>
    </div>
  )
}

