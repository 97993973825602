/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import { _API } from "../../modules/utils";
import SNEditor from "../UI/SNEditor";
import Button from "../UI/Button";
import Input, { Radio, DropDown as Select } from "../UI/preReg/Form";

export default (props) => {

    const { idx, siteIdx, formClean } = props;
    const [info, setInfo] = useState();
    const [contents, setContents] = useState();
    const [saveStatus, setSaveStatus] = useState(false);

    const onSetInfo = (e) => {
        const {name, value} = e.target;
        setInfo((o) => {
            return {...o, [name]:value}
        })
    }
    const modalClose = () => { 
      if(!idx) {
        setInfo({});
        setContents('');
      }
      props?.modalClose && props?.modalClose();
    }

    const loadData = () => {
        const req = {
            path: "/v1/client/site/notice/info",
            data: {idx: idx},
        };
        _API.post(req).then((res) => {
            const { data } = res.data;
            setContents(data?.contents);
            setInfo(data);
        });
    };

    const saveBefore = () => {
        if ($(".note-editor").length > 0) {
          if ($(".note-editor").hasClass("codeview")) {
            $(".btn-codeview").trigger("click");
          }
        }
        setSaveStatus(true);
    };

    const onSave = (e) => {
        if(e) e.preventDefault();

        if(!info?.title || info?.title?.replace(/ /g,'')?.length <= 0){
            alert('제목을 입력해주세요.');
            return;
        }

        if(!contents || contents?.replace(/ /g,'')?.length <= 0){
            alert('내용을 입력해주세요.');
            return;
        }

        const req = {
          path: "/v1/client/site/notice/save",
          data: {
            idx: idx,
            siteIdx:siteIdx,
            title:info?.title,
            contents:contents
          },
        };
        _API.post(req).then((res) => {
          const { data } = res.data;
          alert('Notice에 등록되었습니다.');
          props?.saveAfter && props?.saveAfter();
        });
        if(!idx) {
          setInfo(null);
          setContents(null); 
        }
        setSaveStatus(false);
    }

    useEffect(() => {
        if(saveStatus) onSave();
    },[saveStatus])
    
    useEffect(() => {
        if(idx){
            loadData();
        }
        if(formClean === true) {
          setInfo(null);
          setContents(null); 
        }
    },[idx, formClean])
    return (
      <Fragment>
        <div id="accompany_modal">
          <div className="modal_header">
            <div>
              <h2>Notice</h2>
              <Button
                className="close_btn"
                onClick={modalClose}
                style={{height:"40px"}}
              >
                <img src="https://cdn.onoffevent.com/common/icon_close.svg" />
              </Button>
            </div>
          </div>
          <div className="modal_content" style={{height : "calc(100% - 60px)"}}>
            <div className="modal_content_inner">
                <label>제목</label>
                <input
                    type="text"
                    placeholder="제목을 입력해주세요."
                    name="title"
                    value={info?.title || ""}
                    onChange={(e) => onSetInfo(e)}
                />
            </div>
            <div className="modal_content_inner">
                <label>내용</label>
                <SNEditor
                    content={contents || ''}
                    onChange={setContents}
                />
            </div>
            <div className="btn_container d-flex justify-content-end">
              <Button className="accompany_btn" style={{
                background:"#6a6af8"
              }} onClick={() => saveBefore()}>저장</Button>
            </div>
        </div>
        </div>
      </Fragment>
    )
  }