import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import {MuiSelects} from "../../../../components/MUI/console/Select"
import cx from "classnames";
import { Helmet } from "react-helmet";
import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import Paging from '../../../../components/pagination/pagination';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);


import Layout, { Wrapper,HiddenWrapper } from "../Layouts/Layout";
import moment from "moment";
import useStore from "../../../../modules/utils/store";
import { round } from "lodash";
import { Dialog, DialogContent } from "@mui/material";

export default (props) => {
  const { auth, cfg, iam } = useContext(AppContext);
  const { saveAuth, saveIam } = useContext(AppContext);
  const { history, match } = props;
  const [siteInfo, setSiteInfo] = useState(cfg);
  const [eventInfo, setEventInfo] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [info, setInfo] = useState({});
  const eventIdx = match?.params.eventIdx;

  return (
    <Fragment>
        <Layout>
          <Wrapper>
            <EventForm eventIdx={eventIdx} />
          </Wrapper>
        </Layout>
    </Fragment>
  );
};

const EventForm = (props) => {
  const { auth, cfg, iam } = useContext(AppContext);
  const { saveAuth, saveIam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const [info, setInfo] = useState({});
  const [list, setList] = useState([]);
  const [attendStatus, setAttendStatus] = useState("Y");
  const [paging, setPaging] = useState(null);
  const [dataLoadingShow, setDataLoadingShow] = useState(false);

  const onPageChange = (page) => {
    loadList(page);
  };

  const loadList = (page) => {
    const req = {
      path: "/v1/client/mypage/join/symposium/list",
      data: {
        siteIdx: iam.siteIdx,
        passCode: iam.passCode,
        page: page ?? 1,
        numPerPage: 10,
        pagePerBlock: 5,
      },
    };
    _API.post(req).then((res) => {
      let { data, paging } = res.data;
      setList(data);
      const pagingItems = [];
        
      if (paging) {
        for (let i = paging.startPageNo; i <= paging.endPageNo; i++) {
          pagingItems.push(i);
        }
      }
      paging.pagingItems = pagingItems;
      setPaging(paging);
    });
  };

  const loadPdf = async (item) => {
    setDataLoadingShow(true);
    let division = 0;
    if(siteInfo.idx == 65){
      division = 1;
    }
    // else if(siteInfo.idx == 65){
    //   division = 2;
    // }
    else{
      division = 2;
    }
    const date = new Date(item.startDate);
    const formattedDate = formatDate(date);
    if(item.dataUrl == null){
      const req = {
        // path: "http://localhost:3030/xlsx",
        path: "https://log.livee.kr/xlsx",
        data: {
         name : iam.name
          ,companyName : iam.companyName 
          ,div :division
          ,sessionIdx : item.sessionIdx
          ,preregIdx : iam.preregIdx
        },
        ondemand: true,
      };
      await _API.post(req).then((res) => {
        const { result } = res.data;
        loadList()
        setDataLoadingShow(false);
        window.open('about:blank').location.href = result
      });
    }else{
      loadList()
      setDataLoadingShow(false);
      window.open('about:blank').location.href = item.dataUrl
    }    
  };

  function formatDate(date) {
    // 연도를 가져옴
    const year = date.getFullYear();
    // 월을 가져오고 두 자리 형식으로 변환 (01-12)
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    // 일을 가져오고 두 자리 형식으로 변환 (01-31)
    const day = ('0' + date.getDate()).slice(-2);
  
    // 형식을 맞춰 문자열로 반환
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    loadList();
  }, [iam?.siteIdx]);

  return (
    <Fragment>
      <div className="mypage_header_bnr">
        <h2>My page</h2>
      </div>
      <div className="mypage_tab_menu_wrapper">
        <div className="mypage_tab_menu">
          <div className="mypage_tab">
            <Swiper
              spaceBetween={0}
              slidesPerView={"auto"}
              className="mypage_list"
            >
              <SwiperSlide>
                <Link to="/invitation" className="link">
                  Planned to participate
                </Link>
              </SwiperSlide>
              {/* <SwiperSlide className="active">
                <Link to="/join/symposium" className="link">
                  Participation details
                </Link>
              </SwiperSlide> */}
              <SwiperSlide>
                <Link to="/mypage" className="link">
                  Personal information
                </Link>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div className="content_inner">
        <div className="wid_1180">
          {/* <div className="alarm_box">
            <p>참가 내역 집계가 완료되지 않은 이벤트가 있습니다.</p>
            <span>참가 내역은 행사 종료 후 데이터 갱신을 통해 확인하실 수 있으니 별도의 안내에 따라 확인 부탁 드립니다.</span>
          </div> */}
          <div className="attend_history_table">
            <div className="attend_history_table_header">
              <ul>
                <li><span>Participation event</span></li>
                <li><span>Total progress time</span></li>
                <li><span>First participation start date</span></li>
                <li><span>Last participation start date</span></li>
                {siteInfo?.compStatePubYn === 'Y' && (
                  <>
                    <li><span>Participation time(%)</span></li>
                    <li><span>Participation status</span></li>
                  </>
                )}
              </ul>
            </div>
            <ul className="attend_history_table_content">
            {list != null && list.length > 0 ? (
                list?.map((item, i) => {
                  return(
              <li>
                <div>
                  <ul>
                    <li>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {item.serviceTypes.split(',').map((item) => {
                          return (
                            <>
                              {item == '라이브' && (
                                <span className="livebtn">
                                  <small>LIVE</small>
                                </span>
                              )}
                              {item == '온디맨드' && (
                                <span className="ondemandbtn">
                                  <small>On Demand</small>
                                </span>
                              )}
                            </>
                          )
                        })}
                      </div>
                    </li>
                    <li><a className="event_title">{item.eventName}</a></li>
                    <li><span className="event_date">{moment(item.startDate).locale("en").format('ddd, DD MMM YYYY HH:mm')} ~ {moment(item.endDate).locale("en").format('ddd, DD MMM YYYY HH:mm')+" KST"}</span></li>
                  </ul>
                </div>
                <div><small>Total progress time</small><span>{item.eventPeriod ?? '-'} minute</span></div>
                <div><small>First attendance date</small><span>{item.entryTime ? moment(item.entryTime).locale("en").format('ddd, DD MMM YYYY HH:mm')+" KST" : '-'}</span></div>
                <div><small>Last attendance date </small><span>{item.exitTime ? moment(item.exitTime).locale("en").format('ddd, DD MMM YYYY HH:mm')+" KST" : '-'}</span></div>
                {siteInfo?.compStatePubYn === 'Y' && (
                <>
                <div><small>Participation time(%)</small><span>{item.participationPeriod ?? '-'}분({item.eventPeriod ? (round(item.participationPeriod / item.eventPeriod)*100) : 0}%)</span></div> 
                <div className="attend_confirm_box">
                  <small>Participation status</small>
                  <div>
                    <b>{item.participationStatus}</b>
                    {item.participationStatus === '참가완료' && <button className="attend_confirm" onClick={(e) => loadPdf(item)}>Participation certificate</button>}
                  </div>
                </div>
                {item.participationStatus === '참가완료' && <button className="attend_confirm m_attend_confirm" onClick={(e) => loadPdf(item)}>Participation certificate</button>}
                </>
                )}
              </li>
                  )
                })
              ) : (
                <li>There are no participation events.</li>
                )}
            </ul>
          </div>
          <Paging paging={paging} loadList={loadList} pageCallback={onPageChange} />
        </div>
      </div>
      {dataLoadingShow && (
        <Dialog open={Boolean(dataLoadingShow)} maxWidth={"sm"} fullWidth>
          <DialogContent>
            <div className="p-2">
              <p style={{ textAlign: "center" }}>Creating PDF..</p>
              <p style={{ textAlign: "center" }}>Please wait for a moment.</p>
              <p style={{ textAlign: "center" }}>
                <img
                  src="https://cdn.onoffevent.com/common/hub_data_loading.gif"
                  style={{ width: "20%" }}
                />
              </p>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </Fragment>
  );
};
