import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component"
import swal from "sweetalert";
import moment from "moment";

import styled from "styled-components";
import cx from "classnames";

import TextareaAutosize from "react-textarea-autosize";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import qnaSwitchCheck from "../../../../resources/console/images/icon/Type-B/qna_switch_check.svg";
import commentReply from "../../../../resources/console/images/icon/Type-B/comment_reply.svg";
import loadedImg from "../../../../resources/console/images/icon/Type-B/loaded.png";
import qna_w_Img from "../../../../resources/console/images/icon/Type-B/qna_w.svg"
import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import _, { isEmpty } from "lodash";

export default (props) => {
  const { iam } = useContext(AppContext);
  const { consoleCfg } = props;
  const [isSendProc, setIsSendProc] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [questionReplyContent, setQuestionReplyContent] = useState({});
  const [questionContent, setQuestionContent] = useState("");
  const [moreView, setMoreView] = useState({ nextPage: 0, pagePerCount: 10 });
  const [totalCount, setTotalCount] = useState(0);
  const [remainListCount, setRemainListCount] = useState(0);
  const [myQuestion, setMyQuestion] = useState({ onOff: "N" });
  const [toggleMenu, setToggleMenu] = useState({});
  const [textareaClick, setTextareaClick] = useState(false);
  const [actionStatus, setActionStatus] = useState(false);
  const [toggleReply, setToggleReply] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [loaded, setLoaded] = useState(false);


  const onSetQuestionContent = (e) => {
    if (e.target.value.length <= 500) {
      setQuestionContent(e.target.value);
    }
  };

  const myQuestionView = (e, value) => {
    if (e) e.preventDefault();

    if (value) {
      setMyQuestion((o) => {
        return { ...o, ["onOff"]: value };
      });
      setQuestionList([]);
      setMoreView((o) => {
        return {
          ...o,
          ["nextPage"]: 0,
          ["pagePerCount"]: 10,
        };
      });
    }
  };

  const loadQuestionList = () => {
    const req = {
      path: "/v1/client/question/list",
      data: {
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
        myQuestion: myQuestion.onOff,
        pagePerCount: null,
        nextPage: null,
      },
    };

    _API.post(req).then((res) => {
      let { data, totalCount, remainListCount } = res.data;
      setTotalCount(totalCount);
      setRemainListCount(remainListCount);

      console.log(data)
      if (data.length > 0) {
        setQuestionList(data)
      }
    });
  };

  const questionSave = () => {
    const req = {
      path: "/v1/client/question/save",
      data: {
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
        consoleIdx: consoleCfg.consoleIdx,
        name: iam.name,
        questionerType: iam.memAuth === "ADMIN" ? "MANAGER" : "ATTENDER",
        questionStatus: iam.memAuth == "ADMIN" ? "REQUEST" : "WAIT",
        content: questionContent,
        regId: iam?.preregIdx,
      },
    };

    _API.post(req).then((res) => {
      let { totalCount, find } = res.data;
      console.log(res.data)
      if ( totalCount > 0 ) {
        let item = {division : "QUESTION" , detail : "INSERT"};
        onSend(item);
        setQuestionContent("");
      }
    });
  };

  const questionSetShow = (item) => {
    console.log(item)
    const req = {
      path: "/v1/client/question/save",
      data: {
        idx: item.idx,
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
        consoleIdx: consoleCfg.consoleIdx,
        questionStatus: item.questionStatus,
        regId: iam?.preregIdx,
      },
    };

    _API.post(req).then((res) => {
      let { find } = res.data;
      if ( find ) {
        let item = {division : "QUESTION" , detail : "ONOFF", find : find};
        onSend(item);
      }
    });
  };
  
  const removeQna = (tableName, val) => {
    var confirmChkTxt = tableName == "T_QUESTION" ? "해당 질문을 삭제하시겠습니까?" : "해당 댓글을 삭제하시겠습니까?";
    if (window.confirm(confirmChkTxt)) {
      const req = {
        path: "/v1/client/question/delete",
        data: {
          eventIdx: consoleCfg.eventIdx,
          sessionIdx: consoleCfg.sessionIdx,
          tableName: tableName,
          idx: val.idx,
        },
      };

      _API.post(req).then((res) => {
        let { data } = res;

        if (data > 0) {
          let item = {division : tableName == "T_QUESTION" ? "QUESTION" : "COMMENT" , detail : "REMOVE", idx : val.idx, questionStatus : val.questionStatus};
          onSend(item);
          if (!val.idx) {
            $(".qna.scroll-wrap")[0].scrollTop = 0;
          }
        }
      });
    }
  };

  const questionCommentSetShow = (item) => {
    if (item?.showYn) {
      commentSave({
        idx: item.idx,
        showYn: item?.showYn === "Y" ? "N" : "Y",
        eventIdx: consoleCfg?.eventIdx,
        sessionIdx: consoleCfg?.sessionIdx,
        consoleIdx: consoleCfg?.consoleIdx,
        questionGubun: item.questionGubun,
        questionerType: item.questionerType,
        name: item.name,
        contents: item.contents,
        sort: item.sort,
        regId: item.regId,
        regDate: item?.regDate,
        tQuestionIdx: item.tQuestionIdx,
        saveGubun: "UPDATE",
      });
    }
  };

  const commentSave = (item) => {
    const req = {
      path: "/v1/client/comment/save",
      data: {
        idx: item.idx,
        eventIdx: item.eventIdx,
        sessionIdx: item.sessionIdx,
        consoleIdx: item.consoleIdx,
        tQuestionIdx: item.tQuestionIdx,
        contents: item.contents,
        regId: item.regId,
        regDate: item.regDate,
        showYn: item.showYn,
        writeLocation: "CONSOLE",
        name: item.name,
      },
    };

    _API.post(req).then((res) => {
      let { data } = res;
      if (data > 0) {
        setQuestionContent("");
        if (item.answerYn === "Y") {
          changeReplyContent(item, "");
        }
        item.division = "COMMENT";
        item.idx = data;
        onSend(item);
        if (!item.idx) {
          $(".qna.scroll-wrap")[0].scrollTop = 0;
        }
      }
    });
  };

  const changeReplyContent = (item, value) => {
    const key = `answerContent-${item.idx}`;
    if (value.length <= 300) {
      setQuestionReplyContent((o) => {
        return { ...o, [key]: value };
      });
    }
  };

  const questionReply = (item) => {
    if (!questionReplyContent[`answerContent-${item.idx}`]) {
      swal("답변을 입력해 주세요.");
      return;
    }
    const params = {};
    params.eventIdx = consoleCfg.eventIdx;
    params.sessionIdx = consoleCfg.sessionIdx;
    params.consoleIdx = consoleCfg.consoleIdx;
    params.tQuestionIdx = item.idx;
    params.contents = questionReplyContent[`answerContent-${item.idx}`];
    params.regId = iam?.preregIdx;
    params.showYn = iam?.memAuth == 'ADMIN' ? 'Y' : "N";
    params.saveGubun = "INSERT";
    params.regDate = new Date();
    params.name = iam?.name;
    commentSave(params);
    questionReplyContent[`answerContent-${item.idx}`] = "";
    setQuestionList((o) => {
      const nb = [...o];
      for (let i in nb) {
        if (nb[i].idx == item.idx) {
          nb[i].commentListShowYn = "N";
          nb[i].showCommentWriteBox = false;
        }
      }
      return nb;
    });
  };

  const setCommentListShowYn = (i) => {
    setQuestionList((o) => {
      const nb = [...o];
      nb[i].commentListShowYn = nb[i]?.commentListShowYn === "Y" ? "N" : "Y";
      return nb;
    });
  };

  const onSend = (item) => {
    if (!questionContent && !questionReplyContent) {
      return;
    }

    if (isSendProc) {
      return;
    }
    
    setIsSendProc(true);
    _U.postMessage("SOCKET-SEND", {
      action: "FMS",
      data: {
        mode: "FMS",
        siteIdx: consoleCfg?.siteIdx,
        eventIdx: consoleCfg?.eventIdx,
        sessionIdx: consoleCfg?.sessionIdx,
        consoleIdx: consoleCfg?.consoleIdx,
        name: iam?.name,
        memType: iam?.memType,
        companyName: iam?.companyName,
        deptName: iam?.deptName,
        memberIdx: iam?.idx,
        item: item,
      },
    });
    setIsSendProc(false);
  };

  const showTogleReplyWrap = (item) => {
    if (item.idx) $("#reply-wrap-group-" + item.idx).slideToggle();
  };

  
  const onReceivedMessage = (e) => {
    const y = $(window).scrollTop();
    _U.parseMessage(e).then((data) => {
      console.log(e)
        switch (data.code) {
          case "SOCKET-RECEIVED":
            return data.data ? JSON.parse(data.data) : null;
        }
      }).then((data) => {
        console.log(data)
        if(!data){
          data = data?.data
        }
        console.log(data)
        if (data?.mode == "FMS" && data?.eventIdx == consoleCfg.eventIdx && data?.sessionIdx == consoleCfg.sessionIdx) {
          if(data?.item.division == "QUESTION"){
            if(data?.item.detail == "INSERT"){
              loadQuestionList();
            } else if(data?.item.detail == "ONOFF"){
              setQuestionList((o) => {
                let nb = [...o];
                if(nb?.filter((q) => q.idx == data?.item.find.idx)?.length > 0){
                  for (let i in nb) {
                    if (nb[i].idx == data?.item.find.idx) {
                      nb[i].questionStatus = data?.item.find.questionStatus;
                    }
                  }
                }
                return nb;
              });
            } else if(data?.item.detail == "REMOVE"){
              if (data?.item?.questionStatus === "REQUEST" || iam.memAuth == "ADMIN") {
                setTotalCount((o) => {
                  return --o;
                });
              }
              const tmp = [];
              setQuestionList((o) => {
                let nb = [...o];
                for (let i in nb) {
                  if (nb[i].idx != data?.item?.idx) {
                    tmp.push(nb[i]);
                  }
                }
                return tmp;
              });
            }  
          } 
        }
        //COMMENT 때문에 살려둠
        if (data?.item?.saveGubun === "INSERT") {
          if (data?.item?.division === "QUESTION") {
            if (
              data?.item?.showYn === "Y" ||
              iam.memAuth == "ADMIN" ||
              data?.item?.regId == iam.preregIdx
            ) {
              setTotalCount((o) => {
                return ++o;
              });
            }
            const tmp = [];
            tmp.push(data.item);
            setQuestionList((o) => {
              const nb = [...o];
              for (let i in nb) {
                tmp.push(nb[i]);
              }
              return tmp;
            });
          } else if (data?.item?.division === "COMMENT") {
            setQuestionList((o) => {
              const nb = [...o];
              for (let i in nb) {
                if (nb[i].idx == data?.item?.tQuestionIdx) {
                  if (nb[i]?.commentList) {
                    let length = nb[i]?.commentList
                      ? nb[i]?.commentList?.length
                      : 0;
                    nb[i].commentList[length] = data?.item;
                  } else {
                    let array = new Array();
                    array.push(data?.item);
                    nb[i].commentList = array;
                  }
                }
              }
              return nb;
            });
          }
        } else if (data?.item?.saveGubun === "REMOVE") {
          if (data?.item?.division === "QUESTION") {
            if (
              data?.item?.showYn === "Y" ||
              iam.memAuth == "ADMIN" ||
              data?.item?.regId == iam.preregIdx
            ) {
              setTotalCount((o) => {
                return --o;
              });
            }
            const tmp = [];
            setQuestionList((o) => {
              const nb = [...o];
              for (let i in nb) {
                if (nb[i].idx != data?.item?.idx) {
                  tmp.push(nb[i]);
                }
              }
              return tmp;
            });
          } else if (data?.item?.division === "COMMENT") {
            var tmp = [];
            setQuestionList((o) => {
              const nb = [...o];
              for (let i in nb) {
                if (nb[i].idx == data?.item?.tQuestionIdx) {
                  const nb2 = nb[i].commentList;
                  for (let j in nb2) {
                    if (nb2[j].idx != data?.item?.idx) {
                      tmp.push(nb2[j]);
                    }
                  }
                  nb[i].commentList = tmp;
                }
              }
              return nb;
            });
          }
        } else if (data?.item?.saveGubun === "UPDATE") {
          if (data?.item?.division === "QUESTION") {
            setQuestionList((o) => {
              let nb = [...o];
              if(nb?.filter((q) => q.idx == data?.item?.idx)?.length > 0){
                for (let i in nb) {
                  if (nb[i].idx == data?.item?.idx) {
                    data.item.commentList = nb[i].commentList;
                    nb[i] = data?.item;
                  }
                }
              }else{
                nb.push(data?.item);
                nb = nb.sort((a, b) => b.regDate - a.regDate);
              }
              return nb;
            });
          } else if (data?.item?.division === "COMMENT") {
            setQuestionList((o) => {
              const nb = [...o];
              for (let i in nb) {
                if (nb[i].idx == data?.item?.tQuestionIdx) {
                  const nb2 = nb[i].commentList;
                  for (let j in nb2) {
                    if (nb2[j].idx == data?.item?.idx) {
                      nb[i].commentList[j] = data?.item;
                    }
                  }
                }
              }
              return nb;
            });
          }
        }
        $("body").scrollTop(y);
      });
  };

  const setShowCommentWriteBox = (q, showYn) => {
    setQuestionList((o) => {
      const nb = [...o];
      for (let i in nb) {
        if (nb[i].idx == q.idx) {
          nb[i].showCommentWriteBox = showYn;
        }
      }
      return nb;
    });
  };

  const fetchMoreData = () => {
    setTimeout(() => {
      setMoreView((o) => {
        return {...o,['nextPage']: moreView?.nextPage + 10}
      });
    }, 500);
  };

  useEffect(() => {
    window.addEventListener("message", onReceivedMessage, myQuestion);
    return () => {
      window.removeEventListener("message", onReceivedMessage, myQuestion);
    };
  }, []);

  useEffect(() => {
    loadQuestionList();
  }, [moreView?.nextPage, myQuestion]);
  
  return (
    <Fragment>
      <div className="qna_container">
        <div style={{  zIndex: "1" , background:"#fff"}}>
          <div className="question_write_box" style={{borderRadius:`${textareaClick || questionContent?"4px 4px 0 0" : "2px"}`, padding: `${textareaClick || questionContent?"12px 12px" : "7px 8px"}`,border:`${textareaClick || questionContent? "none":"1px solid #EAEAEA"}`}}>
            <div className="question_box_flex">
              <div style={{display:"flex", justifyContent:"flex-start",alignItems:"center", width:"100%", marginBottom:`${textareaClick || questionContent ?'12px':'0px'}`}}>
                <TextareaAutosize
                  type="text"
                  maxLength="500"
                  placeholder="질문을 입력해 주세요"
                  value={questionContent}
                  onChange={(e) => onSetQuestionContent(e)}
                  style={{ overflow: "hidden" }}
                  onFocus={() => setTextareaClick(true)}
                  onBlur={() =>  setTextareaClick(false)}
                ></TextareaAutosize>
                {!(textareaClick || questionContent) && (  
                  <button type="button" id="button-addon22" onClick={(e) => questionSave()}>등록</button>
                )}
              </div>
              {(textareaClick || questionContent) && (
                <div className="questionContent_button_box">
                  <span>{questionContent.length}/500</span>
                  <div style={{display:"flex",alignItems:"center",gap:"4px"}}>
                    <button type="button" className="cancel" id="button-addon22" onClick={(e) => setQuestionContent("")}>
                      취소
                    </button>
                    <button type="button" id="button-addon22" onClick={(e) => questionSave()}>
                      등록
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={cx({
              question_list_box_title: true,
              admin_question_list_box_title: iam?.memAuth == "ADMIN",
            })}
            >
            <ul>
              <li className={cx({ on: myQuestion.onOff !== "Y" })}>
                <a
                  className="all_question"
                  onClick={(e) => myQuestionView(e, "N")}
                >
                  전체질문보기
                </a>
              </li>
              <li className={cx({ on: myQuestion.onOff === "Y" })}>
                <a
                  className="my_qustion"
                  onClick={(e) => myQuestionView(e, "Y")}
                >
                  내질문보기
                </a>
              </li>
            </ul>
            <p>
              <b>질문 {totalCount}</b>
            </p>
          </div>
        </div>
        <div className="question_list_box">
          <div
            className={cx({
              question_list_wrapper_box: true,
              admin_question_list_wrapper_box: iam?.memAuth == "ADMIN",
            })}
          >
            {questionList.length > 0 ? (
              <InfiniteScroll
                dataLength={questionList?.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<div style={{padding:"10px",textAlign:"center"}}>
                  <img className="loaded_img" src={loadedImg} />
                </div>}
                scrollableTarget="scrollableDiv"
              >
                {!isEmpty(questionList) && questionList?.map((item, i) => (
                  <Fragment key={i}>
                      <Fragment>
                        {(myQuestion.onOff === "N" || (myQuestion.onOff === "Y" && item.regId == iam.preregIdx)) && (
                          <div
                            style={{display:`${iam.memAuth == "ADMIN" ? "block" : item.questionStatus == "REQUEST" || item.regId == iam.preregIdx ? "block" : "none"}`}}
                            className={cx({
                              question_list_inner_box: true,
                              on: item.questionStatus === "WAIT" && iam.memAuth == "ADMIN",
                              active: toggleMenu[item?.idx],
                            })}
                            id={`p_question_tab_${item.idx}`}
                          >
                            <div className={cx({ question_list_box_desc: true})}> 
                              <pre>{item?.content}</pre>
                              <div className="question_list_box_date_reply">
                                {item?.answerYn === "Y" && (
                                  <em>
                                    <i className="demo-icon icon-comment" onClick={(e) => { showTogleReplyWrap(item); }} ></i>
                                  </em>
                                )}
                                <div className="question_list_box_desc_btn">
                                  <div className="question_btn_box">
                                    <button
                                      className="reply_btn"
                                      onClick={(e) =>
                                        setShowCommentWriteBox(item, true)
                                      }
                                    >
                                      <span>댓글</span>
                                    </button>
                                    {item?.commentList?.filter((c) => iam?.memAuth == "ADMIN" || c.regId == iam.preregIdx || c?.showYn === "Y")?.length > 0 && (
                                      <button
                                        className="reply_count"
                                        onClick={(e) => setCommentListShowYn(i)}
                                      >
                                        <small>
                                          {item?.commentListShowYn === "Y"
                                            ? "댓글숨기기"
                                            : "댓글보기"}
                                        </small>
                                        <b>
                                          {item?.commentList?.filter((c) => iam.memAuth == "ADMIN" || c.regId == iam.preregIdx || c?.showYn === "Y")?.length || 0}
                                        </b>
                                      </button>
                                    )}
                                    {(item.regId == iam.preregIdx || iam.memAuth == "ADMIN") && (
                                      <button className="reply_del_btn" onClick={(e) => removeQna("T_QUESTION", item) } >
                                        <span> {item?.preIdx ? "사전질문 회수" : "삭제"} </span>
                                      </button>
                                    )}
                                  </div>
                                  <div className="check_date_box">
                                    <span>
                                      {iam.memAuth == "ADMIN" ?(item?.name != null && item?.name != "" ? item?.name : item?.regId) + ' - ' : ""}{moment(item.regDate).format("MM.DD HH:mm:ss")}
                                    </span>
                                    {iam.memAuth == "ADMIN" && (
                                      <div className="qna_check_box">
                                        <input
                                          type="checkbox"
                                          id={`switch${i}`}
                                          name="switch"
                                          className="input__on-off"
                                          value={item?.questionStatus}
                                        />
                                        <label
                                          htmlFor={`switch${i}`}
                                          className="label__on-off label__on"
                                          style={{
                                            backgroundColor: item?.questionStatus === "REQUEST" ? "#5959EF" : "#ffffff",
                                            border:item?.questionStatus === "REQUEST" ? "0": "1px solid #d7d7d7",
                                          }}
                                          onClick={(e) => questionSetShow(item)}
                                        >
                                          <img style={{position: "absolute", top: "50%",left: "6px",transform: "translateY(-50%)"}} src={qnaSwitchCheck} alt="check" />
                                          <span
                                            className="marble"
                                            style={{
                                              right: item?.questionStatus === "REQUEST"  ? "5px" : "17px",
                                              backgroundColor:item?.questionStatus === "REQUEST" ? "#fff" : "#d7d7d7"
                                            }}
                                          ></span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={cx({ comment_write_box: true, active: item?.showCommentWriteBox == true })}>
                                <div className="comment_write_box_flex">
                                  <img src={commentReply} alt="댓글달기" />
                                  <div className="comment_write_box_text">
                                    <TextareaAutosize
                                      type="text"
                                      maxLength="300"
                                      style={{
                                        overflowY: "hidden"
                                      }}
                                      placeholder="댓글을 입력해 주세요"
                                      value={ questionReplyContent[ `answerContent-${item?.idx}`] || ""}
                                      onChange={(e) =>changeReplyContent(item, e.target.value)}
                                    />
                                    <div className="comment_write_box_con">
                                      <span>{questionReplyContent[`answerContent-${item?.idx}`]?.length || 0} / 300</span>
                                      <div className="comment_btn_box">
                                        <button
                                          className="cancel"
                                          onClick={(e) => setShowCommentWriteBox(item, false) }
                                        >
                                          취소
                                        </button>
                                        <button
                                          className="comment_btn_save"
                                          id="button-addon2"
                                          onClick={(e) => questionReply(item)}
                                        >
                                          등록
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            {item?.commentListShowYn === "Y" && (
                              <Fragment>
                                {item?.commentList?.filter((c) => iam?.memAuth == "ADMIN" || c?.regId == iam?.preregIdx || c?.showYn === "Y")?.map((comment, j) => (
                                  <Fragment key={j}>
                                    {(comment?.showYn === "Y" ||
                                      iam?.memAuth == "ADMIN" ||
                                      comment?.regId == iam?.preregIdx) && (
                                      <div
                                        className={cx({
                                          comment_list_box: true,
                                          on:
                                            iam?.memAuth == "ADMIN" &&
                                            comment?.showYn === "N",
                                          active: toggleMenu[comment?.idx],
                                        })}
                                      >
                                        <img src={commentReply} alt="댓글" />
                                        <div className="comment_list_box_inner">
                                          <pre>
                                            {/* <strong>Online Support : </strong> */}
                                            {comment?.contents}
                                          </pre>

                                          <div className="question_list_box_date_reply">
                                            <div className="question_list_box_desc_btn">
                                              <div className="question_btn_box">
                                              {(comment?.regId == iam?.preregIdx || iam?.memAuth == "ADMIN") && (
                                                <button
                                                  className="reply_del_btn"
                                                  onClick={(e) =>removeQna("T_QUESTION_COMMENT",comment)}
                                                >
                                                  <span>삭제</span>
                                                </button>
                                              )}
                                              </div>
                                              <div className="check_date_box">
                                                <span className="question_list_box_date_reply_span">
                                                  {iam?.memAuth == 'ADMIN' ?  (comment?.name != null && comment?.name != "" ? comment?.name : "게스트") + ' - ' : ''}{moment(comment.regDate).format("MM.DD HH:mm:ss")}
                                                </span>
                                                {iam?.memAuth == "ADMIN" && (
                                                  <div className="qna_check_box">
                                                    <input
                                                      type="checkbox"
                                                      id={`switch_q${i}_comment${j}`}
                                                      name="switch"
                                                      className="input__on-off"
                                                      value={comment?.showYn}
                                                    />
                                                    <label
                                                      htmlFor={`switch_q${i}_comment${j}`}
                                                      className="label__on-off label__on"
                                                      style={{
                                                        backgroundColor:comment?.showYn === "Y"? "#5959EF": "#FFF",
                                                        border:comment?.showYn === "Y"? "0": "1px solid #d7d7d7",
                                                      }}
                                                      onClick={(e) =>questionCommentSetShow(comment)}
                                                    >
                                                      <img style={{position: "absolute", top: "50%",left: "6px",transform: "translateY(-50%)"}} src={qnaSwitchCheck} alt="check" />
                                                      <span
                                                        className="marble"
                                                        style={{
                                                          right: comment?.showYn === "Y" ? "5px" : "17px",
                                                          backgroundColor:comment?.showYn === "Y" ? "#fff" : "#d7d7d7"
                                                        }}
                                                      ></span>
                                                    </label>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Fragment>
                                ))}
                              </Fragment>
                            )}
                          </div>
                        )}
                      </Fragment>
                  </Fragment>
                ))}
              </InfiniteScroll>
            ): (
              (isEmpty(questionList) || questionList?.length <= 0) && (
                <div
                  className="nonedata_box"
                  style={{ textAlign: "center", padding: "20px 0" }}
                >
                  <img
                    src="https://cdn.onoffevent.com/common/icon_nonedata.svg"
                    alt=""
                    style={{ width: "70px" }}
                  />
                  <p style={{ fontSize: "13px", color: "#333333" }}>
                    질문이 없습니다.
                  </p>
                </div>
              )
            )}
            
            
          </div>
        </div>
        {/* </PerfectScrollbar> */}
      </div>
    </Fragment>
  );
};

const CMessage = styled.div`
  margin-bottom: 0.5rem;
  span {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`;
