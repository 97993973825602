import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '../../../resources/images/fms/icon_close.svg';
import Typography from '@mui/material/Typography';
import {Stack,Grid,Box}  from '@mui/material';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    width: "100%",
    maxWidth: "530px"
  },
  '& .MuiDialogContent-root': {
    padding:"0 40px 30px",
    borderTop: "0px",
    borderBottom: "0px",
    marginTop: "70px",
    textAlign:"center"
  },
  '& .MuiDialogContent-root p':{
    fontSize: "22px",
    fontWeight: "bold"
  },
  '& .MuiDialogContent-root p strong':{
    fontSize: "22px",
    fontWeight: "bold",
    color:"#6a6af8"
  },
  '& .MuiDialogActions-root': {
    margin: "0px auto 40px",
    padding: "0"
  }, 
  
}));

export default function AlertDialog(props) {
  const { open, setOpen,setAlertAnswer } = props;
  const handleClose = (answer) => {
    setOpen(false);
    setAlertAnswer(answer);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 20,
            top: 15,
            padding:"0px"
          }}
        >
          <img src={CloseIcon} />
        </IconButton>
        <DialogContent dividers>
          <Typography component="p">
            <Typography component="strong">'심포지엄명'</Typography>은 이미 등록된 심포지엄입니다.<br/> 
            제외하고 등록을 진행하시겠습니까?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="cancel" autoFocus onClick={handleClose(false)}>
            취소
          </Button>
          <Button variant="confirm" autoFocus onClick={handleClose(true)}>
            확인
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}