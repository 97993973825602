import React from 'react';
import cx from "classnames";
import prevbtn from "../../resources/preReg/images/icon/prevbtn.svg";
import prevUpperbtn from "../../resources/preReg/images/icon/prevUpperbtn.svg";
import nextbtn from "../../resources/preReg/images/icon/nextbtn.svg";
import nextUpperbtn from "../../resources/preReg/images/icon/nextUpperbtn.svg";

export default function Paging (props) {
    const {paging, loadList} = props;
    return(
        <div className="table_pagination">
            <ul>
                <li onClick={(e) => loadList(paging?.startPageNo)}>
                    <a>
                        <img src={prevUpperbtn} />
                    </a>
                </li>
                <li
                    onClick={(e) =>
                        loadList(paging?.prevPageNo < 1 ? 1 : paging?.prevPageNo)
                    }
                >
                    <a>
                        <img src={prevbtn} />
                    </a>
                </li>
                <li>
                    <ul>
                    {paging?.pagingItems.map((i) => (
                        <li onClick={(e) => loadList(i)} key={i}>
                            <a className={cx({ on: paging?.pageNo === i })}>{i}</a>
                        </li>
                    ))}
                    </ul>
                </li>
                <li onClick={(e) => loadList(paging?.nextPageNo)}>
                    <a>
                        <img src={nextbtn} />
                    </a>
                </li>
                <li onClick={(e) => loadList(paging?.endPageNo)}>
                    <a>
                        <img src={nextUpperbtn} />
                    </a>
                </li>
            </ul>
        </div>
    )
}