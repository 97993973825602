import { createGlobalStyle, ThemeProvider as Provider } from "styled-components";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "filepond/dist/filepond.min.css";
import "react-summernote/dist/react-summernote.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "video-react/dist/video-react.css";

import React, { Fragment, useContext } from "react";
import {TotalStyle} from './css/IndexStyle';


const theme = {
    platformType : "console"
    , color: {
        primary: "#2c3e50",
        secondary: "#34495e",
        background: "#ecf0f1",
    }
};
const globalStyle = createGlobalStyle`
    * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: none !important;
    }
    input, textarea {
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }

    /* 설문 사이드바메뉴 */

    .page-hamburger-header {
        margin-top: 1rem;
        position: relative;
        display: flex;
        min-height: 40px;
        justify-content: space-between;
        align-items: center; }
    .page-hamburger-header h5 {
        margin-bottom: 1rem; }
    @media screen and (max-width: 991px) {
        .page-hamburger-header {
        padding-left: 30px; padding-right: 5px; } }
    .page-hamburger-header .hamburger {
        display: none;
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-top: -3px; }
    .page-hamburger-header .hamburger span {
    position: absolute;
    width: 20px;
    height: 3px;
    background-color: #0030AA;
    top: calc(50% - 1px); }
    .page-hamburger-header .hamburger span::before, .page-hamburger-header .hamburger span::after {
        content: '';
        position: absolute;
        display: inline-block;
        width: 20px;
        height: 3px;
        background-color: #0030AA; }
    .page-hamburger-header .hamburger span::before {
        margin-top: -7px; }
    .page-hamburger-header .hamburger span::after {
        margin-top: 7px; }
    @media screen and (max-width: 991px) {
    .page-hamburger-header .hamburger {
        display: block; } }
    @media screen and (max-width: 767px) {
    .page-hamburger-header .hamburger {
        margin-top: -4px; } }

    nav.gnb .menu .sidebar {
        /* padding: 0;
        list-style: none;
        width: 100px;
        position: absolute;
        left: -100px;
        top: 40px;
        flex-flow: column;
        background-color: #3b4146;
        z-index: 10000;
        */
        transition: opacity 1s ease-out;
        opacity: 0;
        display : none;
    }
    nav.gnb .menu .toggle-menu-survey-pc {
        margin-left: 10px;
        cursor: pointer;
    }
    
    nav.gnb .menu.active-survey .sidebar {
        z-index: 9999;

        left: 0;
        padding-left: 15px;
        width: 300px;
        font-size: .9375rem;
        position: absolute;
        background-color: #f5f5f5;
        padding-left: 20px;
        box-shadow: -1px 0 #f1f1f1 inset;
        transition: left .4s ease;
        
        -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

        opacity: 1;
        display : block;
    }
    
    @media screen and (max-width: 567px){
        .page div.site nav.gnb .menu.active-survey .sidebar {
            margin-top: 20px;
        }
    }


    
    nav.gnb .menu.active-survey .sidebar .sidebar-header {
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        padding-right: 20px;
        position: relative;
    }
    
    nav.gnb .menu.active-survey .sidebar .side-menu.first {
        position: relative;
        display: flex;
        padding: 1.5rem 0;
    }
    
    /* 투표 사이드바메뉴 */
    nav.gnb .menu .toggle-menu-vote-pc {
        margin-left: 10px;
        cursor: pointer;
    }

    nav.gnb .menu .sidebar-vote {
        /* padding: 0;
        list-style: none;
        width: 100px;
        position: absolute;
        left: -120px;
        top: 40px;
        flex-flow: column;
        background-color: #3b4146;
        z-index: 10000;
        */
        -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
        -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        display : none;
    }
    
    nav.gnb .menu.active-vote .sidebar-vote {
        z-index: 9999;

        left: 0;
        padding-left: 15px;
        width: 300px;
        font-size: .9375rem;
        position: absolute;
        background-color: #f5f5f5;
        padding-left: 20px;
        box-shadow: -1px 0 #f1f1f1 inset;
        transition: left .4s ease;
        
        -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        display : block;
    }
    
    @media screen and (max-width: 567px){
        .page div.site nav.gnb .menu.active-vote .sidebar {
            margin-top: 20px;
        }
    }


    
    nav.gnb .menu.active-vote .sidebar-vote .sidebar-header {
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        padding-right: 20px;
        position: relative;
    }
    
    nav.gnb .menu.active-vote .sidebar-vote .side-menu.first {
        position: relative;
        display: flex;
        padding: 1.5rem 0;
    }

    /* 토글 체크박스 */
    label.switch {
        position: relative;
        width: 34px;
        height: 18px;
        border-radius: 14px;
        background: #202026;
        cursor: pointer;
    }
    label.switch input[type='checkbox'] {
        display:none;}
    label.switch span {
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
    }
    label.switch span::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #999999;
        border-radius: 14px; }
        label.switch span::after {
        content: '';
        position: absolute;
        display: block;
        width: 12px;
        height: 12px;
        background-color: #fff;
        border-radius: 50%;
        top: 50%;
        left: 4px;
        transform:translateY(-50%);
        transition: left .3s ease;
        box-shadow: 0 2px 4px 0 rgb(90 90 90 / 50%);
    }
    label.switch input:checked ~ span::before {
        border: 1px solid #7758fa;
        background-color: #7758fa; }
    label.switch input:checked ~ span::after {
        left: 18px; }
    // label.switch input:focus ~ span::before {
    //     border: 1px solid #5f86eb;
    //     box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

    ${props => (props.header ? props.header : '')};

`;

export const Theme = theme;
export const ThemeProvider = Provider;
export default globalStyle;

/* SITE - HEADER (dynamically) */
const glbStyles = (css) => {

    return createGlobalStyle`
        ${css}
    `
}

export const DynamicStyles = (props) => {
    const customStyle = () => { return TotalStyle};

    const Style = glbStyles(customStyle);
        return (
            <Fragment>
                <Style />
            </Fragment>
        )
    }