import React, { Fragment, useState, useRef, useEffect, useContext, } from "react";
import { Link } from "react-router-dom";
import {MuiSelects} from "../../../../components/MUI/console/Select"
import cx from "classnames";
import { Helmet } from "react-helmet";
import { AppContext } from "../../../../components/AppProvider";
import { _CFG } from "../../../../modules/config";
import { _API, _CK, _U } from "../../../../modules/utils";
import { styled } from '@mui/system';
import InvitationList from "./InvitationList"
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import exImg from "../../../../resources/preReg/images/icon/ex_img.svg"
import Paging from '../../../../components/pagination/pagination';

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import Layout, { Wrapper, HiddenWrapper, } from "../Layouts/Layout";
import { SideBarTypes } from "../Layouts/Layout.Sidebar";
import { Footer } from "../Layouts/Layout.Footer";

import moment from "moment";

import NoneImg from "../../../../resources/console/images/icon/noneimg.png";
import NoneData from "../../../../resources/images/metaverse/6349.svg";
import useStore from "../../../../modules/utils/store";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default (props) => {
  const { auth, iam } = useContext(AppContext);
  const { saveAuth, saveIam } = useContext(AppContext);
  const { history, match } = props;
  // 사이트 정보
  const { siteInfo } = useStore();
  // 이벤트 정보
  const [eventInfo, setEventInfo] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [info, setInfo] = useState({});
  const eventIdx = match?.params.eventIdx;

  return (
    <Fragment>
      <Layout>
          <Wrapper>
            <InviteForm siteventIdx={eventIdx} />
          </Wrapper>
        </Layout>
    </Fragment>
  );
};

const InviteForm = (props) => {
  const { auth, saveAuth, saveIam, iam } = useContext(AppContext);
  const { siteInfo } = useStore();
  const {  } = useContext(AppContext);
  const [info, setInfo] = useState({});
  const [list, setList] = useState([]);
  const [paging, setPaging] = useState(null);
  const [preview, setPreview] = useState("");

  const onPageChange = (page) => {
    loadList(page);
  };
  
  const onChangeSmsAgree = (preregInfoIdx, smsAgree) => {
    const req = {
      path: '/v1/event/schedule/smsagree/save',
      data: {
        idx: preregInfoIdx,
        passCode: iam.passCode,
        smsAgree: smsAgree ?? 'Y'
      }
    };
    _API.post(req).then((res) => {
      const { data } = res;
      if (data.affectedRows > 0) {
          alert("저장되었습니다.");
          loadList();
      } else {
          alert("저장 중 오류가 발생하였습니다.");
      }
    });

  }

  const loadList = (page) => {
    const req = {
      path: "/v1/event/schedule/list",
      data: {
        siteIdx: iam.siteIdx,
        passCode: iam.passCode,
        page: page ?? 1,
        numPerPage: 10,
        pagePerBlock: 5,
      },
    };
    _API.post(req).then((res) => {
      const { data, paging } = res.data;
      setList(data);

      const pagingItems = [];
      
      if (paging) {
        for (let i = paging.startPageNo; i <= paging.endPageNo; i++) {
          pagingItems.push(i);
        }
      }
      paging.pagingItems = pagingItems;

      setPaging(paging);
    });
  };
  
  useEffect(() => {
    loadList();
  }, [iam?.siteIdx]);
  console.log(list);
  return (
    <Fragment>
      <div className="mypage_header_bnr">
        <h2>My page</h2>
      </div>
      <div className="mypage_tab_menu_wrapper">
        <div className="mypage_tab_menu">
          <div className="mypage_tab">
            <Swiper
              spaceBetween={0}
              slidesPerView={"auto"}
              className="mypage_list"
            >
              <SwiperSlide className="active">
                <Link to="/invitation" className="link">
                  Planned to participate
                </Link>
              </SwiperSlide>
           {/*    {siteInfo?.compUseYn === 'Y' && (
              <SwiperSlide>
                <Link to="/join/symposium" className="link">
                  Participation details
                </Link>
              </SwiperSlide>
              )} */}
              <SwiperSlide>
                <Link to="/mypage" className="link">
                  Personal information
                </Link>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="mypage_container">
        <div className="wid_1180">
          <div className="mypage_select_box">
          </div>
          <div className="attend_table">
            <div className="attend_table_header">
              <ul>
                <li><span>D-Day</span></li>
                <li><span>Events to attend</span></li>
                <li><span>Receive information</span></li>
              </ul>
            </div>
            {list != null && list.length > 0 ? (
                list?.map((item, i) => {
                  return(
                    <div className="attend_table_content">
                      <ul>
                        <li>
                          <div className="attend_table_content_inner">
                            <a className="thumbnail">
                              <img src={exImg} />
                            </a>
                            <ul>
                              <li>
                                <div style={{display:"flex", alignItems:"center"}}>
                                  {item.serviceTypes.split(',').map((item) => {
                                    return (
                                      <>
                                        {item == '라이브' && (
                                          <span className="livebtn">
                                            <small>LIVE</small>
                                          </span>
                                        )}
                                        {item == '온디맨드' && (
                                          <span className="ondemandbtn">
                                            <small>On Demand</small>
                                          </span>
                                        )}
                                      </>
                                    )
                                  })}
                                </div>
                              </li>
                              <li><a className="event_title">{item.name}</a></li>
                              <li><span className="event_date">{moment(item.startDate).locale("en").format('ddd, DD MMM YYYY HH:mm')} ~ {moment(item.endDate).locale("en").format('ddd, DD MMM YYYY HH:mm')+" KST"}</span></li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <label className="switch" style={{ marginRight: "0" }}>
                            <input
                              type="checkbox"
                              name="modalPopupOpen"
                              checked={item.smsAgree === 'Y' ? true : false}
                              onChange={e => onChangeSmsAgree(item.preregInfoIdx, e.target.checked ? 'Y' : 'N')}
                            />
                            <span></span>
                          </label>
                          <span>Receive information</span>
                        </li>
                      </ul>
                    </div>
                  )
                })
              ) : (
              <div className="attend_table_content">
                <span>There are no events scheduled to participate.</span>
              </div>
              )}
          </div>
        </div>
        <Paging paging={paging} loadList={loadList} pageCallback={onPageChange} />
      </div>
    </Fragment>
  );
};

