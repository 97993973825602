import enquire from "enquire-js";

const initPage = () => {
  const $dom = $(document);
  const $win = $(window);
  const $body = $("body");
  const targetVideo = $(".video-wrap");
  const targetAlert = $(".alert-wrap");
  const section = $(".section-content");
  const gnb = $(".gnb");
  let isMobile = false;
  const $menu = $(".gnb .menu");

  const menubtn = document.querySelector(".menubtn");
  const menubox = document.querySelector(".menu_box");
  const header = document.querySelector("#header");
  const headerFreeze = document.getElementById("header-freeze");

  //메뉴 관련
  menubtn.addEventListener("click", (e) => {
    if (-1 !== menubox.className.indexOf("on")) {
      menubtn.classList.remove("on");
      menubox.classList.remove("on");
      headerFreeze.classList.remove("on");
    } else {
      menubtn.classList.add("on");
      menubox.classList.add("on");
      headerFreeze.classList.add("on");
    }
  });

  headerFreeze.addEventListener("click", (e) => {
    menubtn.click();
  });
  // 기본 메뉴 토글
  $(".gnb .toggle-menu, .gnb .toggle-menu-pc").on("click", function () {
    var $this = $(this),
      $target = $this.closest(".menu");
    $target.toggleClass("active");
  });

  // 서베이 메뉴 토글
  $(".gnb .toggle-menu-survey-pc").on("click", function () {
    if ($menu.hasClass("active-vote")) {
      $menu.toggleClass("active-vote");
    }

    var $this = $(this),
      $target = $this.closest(".menu");
    $target.toggleClass("active-survey");
  });

  // 투표 메뉴 토글
  $(".gnb .toggle-menu-vote-pc").on("click", function () {
    if ($menu.hasClass("active-survey")) {
      $menu.toggleClass("active-survey");
    }

    var $this = $(this),
      $target = $this.closest(".menu");
    $target.toggleClass("active-vote");
  });

  // chat menu
  $(".say .toggle-menu").on("click", function (e) {
    var $this = $(this),
      $allMenu = $(".say"),
      $target = $this.parents(".say");
    if ($target.hasClass("active")) {
      $target.toggleClass("active");
    } else {
      $allMenu.removeClass("active");
      $target.toggleClass("active");
    }
  });

  // 토글 라디오
  $(".cb-value").click(function () {
    var mainParent = $(this).parent(".toggle-btn");
    if ($(mainParent).find("input.cb-value").is(":checked")) {
      $(mainParent).addClass("active");
    } else {
      $(mainParent).removeClass("active");
    }
  });

  // qna replay
  var $targetFocus = $body.find(".input-group .form-control");
  var winHeight;
  var maxHeight = 450;
  $targetFocus
    .focus(function () {
      winHeight = $win.height();
      if (isMobile || winHeight < maxHeight) {
        targetVideo.css("opacity", 0);
      }
    })
    .focusout(function () {
      winHeight = $win.height();
      if (isMobile || winHeight < maxHeight) {
        targetVideo.css("opacity", 1);
      }
    });
  $(".btn-replay").on("click", function () {
    var $this = $(this),
      $target = $this.parents(".say").find(".say__footer");
    $target.slideToggle("fast");
  });

  $(".has-reply > .say-wrap").on("click", function () {
    var $this = $(this),
      $target = $this.siblings(".reply-wrap-group");
    $this.parents(".has-reply").toggleClass("active");
    $target.slideToggle();
  });

  $(".alert-wrap .btn-close").on("click", function () {
    var $this = $(this),
      $target = $this.parents(".alert-wrap");
    $target.fadeOut();
    if (isMobile) {
      targetVideo.css("top", gnb.outerHeight());
      section.css("padding-top", targetVideo.outerHeight() + gnb.outerHeight());
    }
  });

  let didScroll = false,
    lastScrollTop = 0,
    offPoint = $(".card-title-position").offset(),
    offPointTop = parseInt(offPoint.top) + 25,
    offPointTopEff = offPointTop + 5,
    delta = 5,
    $btnGoTop = $(".go-to-top"),
    $footerArrowWrap = $(".footer-arrow-wrap");
  $win.scroll(function (e) {
    didScroll = true;
  });

  /*모바일 탭 스크립트*/
  if (document.body.clientWidth + 15 < 768) { 
    $("#tab2").hide();
    $("#tab3").hide();
    $("#tab4").hide();
    $("#tab5").hide();
    $(".m_list_wrapper ul li:first-child").addClass('active');
    if ($(".m_list_wrapper ul li").hasClass('active')) { 
      var activeTab = $(".m_list_wrapper ul li:first-child").attr("data-n")
      $("#" + activeTab).fadeIn();
    };
    $(".m_list_wrapper ul li").click(function () {
      $(".m_list_wrapper ul li").removeClass("active");
      $(this).addClass("active");
      $("#tab1").hide();
      $("#tab2").hide();
      $("#tab3").hide();
      $("#tab4").hide();
      $("#tab5").hide();
      var activeTab = $(this).attr("data-n");
      $("#" + activeTab).fadeIn();
    });
  }

  /*모바일 Q&A 전체보기 나만보기*/
  $(".m_qna_container .question_list_inner_box").hide();
  $(".m_qna_container .question_list_inner_box:first").show();
  $(".m_qna_container .question_list_box_title ul li").click(function () {
    $(".m_qna_container .question_list_box_title ul li").removeClass("on");
    $(this).addClass("on");

    $(".m_qna_container .question_list_inner_box").hide();
    var qnaTab = $(this).attr("data-m");
    $("#" + qnaTab).fadeIn();
  });

  /*admin qna 댓글 더보기*/
  $(".question_list_box_desc .ad_question_morebtn").on("click", function () {
  
  });


  /*admin 양쪽 콘솔 사이드바*/
  $(".checkbox_bar").click(function () {
    $(this).toggleClass("on");
    $(this).children("span").toggleClass("on");
  });
  
  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {
    var st = $win.scrollTop();
    // if mobils size
    $body[(isMobile ? "add" : "remove") + "Class"]("is-mobile");

    lastScrollTop = st;

    if (!isMobile) {
      if (lastScrollTop > offPointTop) {
        targetVideo.addClass("video-sticky").addClass("in");
      } else {
        targetVideo.removeClass("video-sticky").removeClass("in");
      }

      if (lastScrollTop > offPointTopEff) {
        targetVideo.addClass("in");
      } else {
        targetVideo.removeClass("in");
      }
    }

    if (lastScrollTop > 10) {
      $footerArrowWrap.addClass("active");
    } else {
      $footerArrowWrap.removeClass("active");
      targetVideo.css("opacity", 1);
      $(":focus").blur();
    }    
  }

  $win.resize(function () {
    if (isMobile) {
      targetAlert.css("top", gnb.outerHeight());
      targetVideo.css("top", gnb.outerHeight() + targetAlert.outerHeight());
      section.css(
        "padding-top",
        targetAlert.outerHeight() +
          targetVideo.outerHeight() +
          gnb.outerHeight()
      );
    }
  });

  $btnGoTop.on("click", function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  });

  const enquirePc = () => {
      isMobile = false;
      $body.removeClass('is-mobile');
      targetAlert.css('top', 0);
      targetVideo.css('top', 0);

      $('html, body').animate({scrollTop: targetVideo.offset().top}, 'fast');
  };

  const enquireMobile = () => {
      isMobile = true;
      $body.addClass('is-mobile');

      targetVideo.removeClass('video-sticky').removeClass('in');
      if ( targetAlert.is(':visible') === true) {
          targetVideo.css('top', gnb.outerHeight() + targetAlert.outerHeight());
      } else {
          targetVideo.css('top', 0);
      }
  };

  $dom.ready(function() {
    enquire.register('screen and (orientation: portrait)', {
        match: function() {
            enquireMobile();
        },
        unmatch: function() {
            enquirePc();
        },
    });

    setTimeout( function() {
        $win.trigger('resize');
    }, 3000);

  });
};

export { initPage }