import React, { useState,useEffect,useRef} from "react";
import { Typography, Box, InputBase, MenuItem, FormControl,Stack } from "@mui/material";
import MuiSelect from "@mui/material/Select"
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import requiredImg from "../../../../../resources/images/campaign/required.png"
export const Select = (props) => {
  const { value, onChange, children, defaultValue } = props;
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <MuiSelect
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        renderValue={(selected) => {
          if (selected.length == 0) {
            return <em>{defaultValue}</em>;
          }
          return selected;
        }}
        displayEmpty
        size="small"
        isDisabled
      >
        {children}
      </MuiSelect>
   
   </FormControl>
  );
};

export const MuiSelects = (props) => {
  const [position, setPosition] = useState(0);
  const { name, value, onChange, items, size, defaultValue, pageList, originSortNumber, isDisabled,title,space,required,hint,children } = props;
  const inputComponent = useRef < HTMLInputElement > (null);
  const [maxTextWidth, setMaxTextWidth] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener('resize', handleResize);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const mobile = windowSize.width <= 787;
  useEffect(() => {
    setPosition(inputComponent?.current? (inputComponent?.current?.getBoundingClientRect()?.left + 30): 0);
  }, [inputComponent]);
  useEffect(() => {
    // 선택된 항목 중 가장 긴 텍스트의 너비를 측정합니다.
    const items = document.querySelectorAll('.dropdown-content');
    let maxWidth = 0;

    items.forEach((item) => {
      const textWidth = item.getBoundingClientRect().width;
      if (textWidth > maxWidth) {
        maxWidth = textWidth;
      }
    });
    setMaxTextWidth(maxWidth);
  }, []);
  const StyleInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #D7D7D7',
      fontSize: 15,
      height: "40px !important",
      lineHeight: "40px",
      padding: 0,
      boxSizing: 'border-box',
      paddingLeft:"12px",
      color: "#464F60",
      fontWeight:"400"
    },
    '& .MuiSvgIcon-root': {
      width: "15px",
      height: "20px", 
      color: "#111",
      right: "10px",
      fontSize: "20px",
    },
  }));

  return (
    <Stack direction={`${!mobile?'row':'column'}`} alignItems='flex-start' sx={{columnGap:"16px", rowGap:"8px"}}>
      {(title || space) && (
        <Typography component="h4" variant="h4" sx={{ minWidth: `${!mobile?"160px":"100%"}`,padding:`${!mobile?"11px 0":"0"}` }}>
          {title} {required ? <img src={requiredImg} style={{marginTop:"4px", marginLeft:"1px",verticalAlign: "top"}} /> : ""}
        </Typography>
      )}
      <FormControl sx={{display: "flex", flexDirection: "column", gap: "8px", width:"100%"}}>
        <MuiSelect
          inputRef={inputComponent || null}
          name={name}
          value={value}
          displayEmpty={true}
          onChange={onChange}
          defaultValue={defaultValue}
          renderValue={(selected) => {
            const selectedItem = items.find((item) => item.value === selected);
            return selectedItem ? selectedItem.label : '';
          }}
          sx={{
            height: "40px",
            width: `${!mobile?"300px":"100%"}`,
            "& .MuiList-root": { background: "#666" }
          }}
          input={<StyleInput />}
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            PaperProps: {
              sx: {
                  width: maxTextWidth ? `${maxTextWidth}px` : 'auto',
                marginTop:"3px",
                border:"1px solid #d7d7d7",
                borderRadius: "4px",
                boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.08)",
                '& .MuiMenu-list': {
                  paddingTop: "3px",
                  paddingBottom:"3px"
                }, 
              },
            },
          }}
        >
            {items.map((item, i) => (
              <MenuItem 
                key={`${name}_select_${i}`}
                value={item?.value}
                className={`dropdown-content ${value === item.value ? 'Mui-selected' : ''}`}
                disabled={isDisabled &&
                  pageList?.filter((p) => p?.sortNumber == item?.value)
                    ?.length > 0 && item.value != originSortNumber
                }
                sx={{ fontSize:"15px",height:"40px",minHeight:"40px",lineHeight:"18px",paddingTop:"6px", paddingBottom:"6px",fontWeight:"400", "&.Mui-selected": {backgroundColor:"rgba(245, 245, 255, 1) !important"},"&:hover":{backgroundColor:"rgba(245, 245, 255, 1)"} }}
              >
                {item?.label}
              </MenuItem>
            ))}
        </MuiSelect>
        {Boolean(hint) && (<Hint>{hint}</Hint>)}
        {children}
      </FormControl>
    </Stack>
  );
};
export const Hint = (props) => {
  return (
    <Typography component="small" variant="summary">{props.children}</Typography>
  );
};
export default Select;
