import React, { Fragment} from 'react';
import Layout, { Wrapper } from "../Layouts/Layout";

import { Link } from "react-router-dom";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";

import NoneImg from "../../../../resources/console/images/icon/noneimg.png";

export default (props) => { 
  const { m,i,action } = props;
  return (
    <Fragment key={i}>
      <li className="main_area_li">
        <div
          className="main_area_img_box"
          onClick={action.bind(this, m)}
        >
            <img
            className="main_area_img"
            src={
              m?.signedUrlThumbnail
                ? m?.signedUrlThumbnail
                : NoneImg
            }
            alt={m?.eventName}
          />
          {m?.onAirYn == 'Y' && (
            <div className="onair_btn">
              <small>ON-AIR</small>
            </div>
          )}
        </div>
        <div className="main_area_text_box">
          {m.ondemandCnt > 0 && m.liveCnt > 0 && (
            <Fragment>
              <div className="state_btn_box">
                <span className="livebtn">
                  <small>LIVE</small>
                </span>
                <span className="ondemandbtn">
                  <small>On Demand</small>
                </span>
              </div>
            </Fragment>
          )}
          {m.liveCnt > 0 && m.ondemandCnt == 0 && (
            <Fragment>
              <div className="state_btn_box">
                <span className="livebtn">
                  <small>LIVE</small>
                </span>
              </div>
            </Fragment>
          )}
          {m.ondemandCnt > 0 && m.liveCnt == 0 && (
            <Fragment>
              <div className="state_btn_box">
                <span className="ondemandbtn">
                  <small>On Demand</small>
                </span>
              </div>
            </Fragment>
          )}
          <h4>
            <a href="" onClick={action.bind(this, m)}>
              {m.eventName}
            </a>
          </h4>
          {(m?.startDate || m?.endDate) && (
            <p>
              {/* Wednesday, January 20, 2021 00:00 ~ 23:59 */}
              {moment(m?.startDate).locale("en").format('ddd, DD MMM YYYY HH:mm')}{" "}
              ~
              {" "}{moment(m?.endDate).locale("en").format(`${moment(m?.startDate).format('YYYY.MM.DD') == moment(m?.endDate).format('YYYY.MM.DD') ? 'HH:mm' : 'ddd, DD MMM YYYY HH:mm'}`)+" KST"}
            </p>
          )}
        </div>
      </li>
    </Fragment>
  )
}