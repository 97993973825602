import React,{useState, Fragment,useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {Stack,Grid,Box}  from '@mui/material';
import moment from "moment";
import Badge from "../../../TypeA/component/UI/Badge"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import closeImg from '../../../../../resources/images/campaign/modal_close.svg'
import thumbImg from '../../../../../resources/images/campaign/thumb.svg'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    width: "100%",
    maxWidth: "900px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    margin: "0"
  },
  '.MuiDialogTitle-root': {
    padding: "0px",
    textAlign:"right"
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2.5),
    borderTop: "0px",
    borderBottom:"1px solid #eee",
  },
  '& .MuiDialogContent-root h3': {
      fontSize: "22px",
      fontWeight:"bold"
  },
  '& .MuiDialogContent-root h4': {
      fontWeight:"bold"
  },
  '& .MuiDialogContent-root b': {
      fontSize: "30px",
      fontWeight: "bold",
      wordBreak: "keep-all"
  },
  '& .MuiDialogContent-root .MuiBox-root': {
    background: "#f7f7fa",
    borderRadius: "6px",
    padding:"20px"
  },
  
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2.5),
  },
  '.MuiButton-root': {
    backgroundColor: "#6a6af8",
    color: "#fff",
    borderRadius: "4px",
    lineHeight:"40px",
    minWidth: "100px",
    height: "40px",
    fontSize:"15px"
  },
  '.MuiButton-root:hover': {
    backgroundColor: "#6a6af8",
  },
  '.subtitleText': {
    minWidth:"60px"
  },
  '.subtitleDesc:before': {
    content: "''",
    display: "inline-block",
    width: "1px",
    height: "12px",
    background: "#efefef",
    margin: "0 16px",
  },
  '.modal_btn': {
    display: "block",
    padding: "8px 16px",
    borderRadius: "4px",
    backgroundColor:"#5959EF"
  },
  '@media (max-width:767px)': {
    '.MuiDialog-container': {
      alignItems:"flex-start",
    },
    '.MuiPaper-root': {
      borderRadius: "0px",
      height:"auto"
    },
    '.subtitleDesc:before': {
      content: 'none',
    },

  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            padding:"0px"
          }}
        >
          <img src={closeImg}/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EventDialog(props) {
  const { open, setOpen} = props;
  const [step, setStep] = useState(1);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
  };
  return (
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
      >
        <div style={{display:"flex",alignItems:"center", justifyContent:"space-between", padding: "20px 30px",marginBottom:"8px" }}>
          <Typography component="h2" variant="event_h2">이벤트정보</Typography>
          <button><img src={closeImg} onClick={handleClose} /></button>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "40px", padding: "0px 30px 40px",overflowY:"auto" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                <Typography component="h3" variant="event_h3">GSK Lamictal On-Air Webinar</Typography>
                <Typography component="span" variant="event_p">2022.11.23(월) 00:00 ~ 2020.11.24(금) 23:59 KST</Typography>
              </div>
              <Typography component="span" variant="event_span" sx={{paddingBottom:"20px", borderBottom:"1px solid #EFEFEF"}}>서울장소 청실룸</Typography>
            </div>
            <div>
              <Typography component="p" variant="event_p">
                이벤트 소개 입니다. 이벤트 소개 입니다. 이벤트 소개 입니다. 이벤트 소개 입니다.
                주제에 적합한 연사 후보군 제안 및 초청, 참가자 관리, 행사 운영, 차기 행사를 위한 행사 데이터 분석 등 행사 운영에 필요한 모든 것을 제공합니다.

                웨비나스는 이름처럼 웨비나 기술을 기반으로 하여 완성도 높은 온 · 오프라인 이벤트 통합 서비스를 제공합니다. 주제에 적합한 연사 후보군 제안 및 초청, 참가자 관리, 행사 운영, 차기 행사를 위한 행사 데이터 분석 등 행사 운영에 필요한 모든 것을 제공합니다. 웨비나스는 이름처럼 웨비나 기술을 기반으로 하여 완성도 높은 온 · 오프라인 이벤트 통합 서비스를 제공합니다. 주제에 적합한 연사 후보군 제안 및 초청, 참가자 관리, 행사 운영, 차기 행사를 위한 행사 데이터 분석 등 행사 운영에 필요한 모든 것을 제공합니다.
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Typography component="h4" variant="event_h4" sx={{borderBottom:"1px solid #D9D9D9", padding:"8px 0"}}>세션정보</Typography>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px",paddingBottom:"24px",borderBottom:"1px solid #EFEFEF" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Badge text="ONLINE"/>
                <Typography component="span" variant="event_time">10:00 ~ 10:50</Typography>
              </div>
              <div>
                <Typography component="h5" variant="event_h5" sx={{marginBottom:"20px"}}>Palliative therapy로 드물지만 CR을 보일때 얼마나 치료를 얼마나</Typography>
                <div style={{display:"flex", flexDirection: "row", alignItems:"flex-start", gap: "53px",flexWrap:"wrap"}}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems:"flex-start", gap: "8px" }}>
                    <img src={thumbImg} alt="썸네일"/>
                    <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                      <Typography component="span" variant="event_text">좌장</Typography>
                      <Typography component="h6" variant="event_h6">홍길동 교수</Typography>
                      <Typography component="span" variant="event_text">고려대학교 구로병원</Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </BootstrapDialog>
  );
}
