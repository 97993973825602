import React, { useEffect,Fragment, useState, useContext, useRef} from 'react';
import Layout, { Wrapper} from "../Layouts/Layout";

import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";

import SessionListDetailView from "./SessionList.Detail.View"

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import iconNotice from "../../../../resources/preReg/images/icon/icon-notice.svg";
import iconGuide from "../../../../resources/preReg/images/icon/icon-guide.svg";
import useStore from '../../../../modules/utils/store';
import { _API } from '../../../../modules/utils';
import { AppContext } from '../../../../components/AppProvider';

export default (props) => {
  const { auth, iam } = useContext(AppContext);
  const { siteInfo } = useStore();
  
  const { eventInfo,setEventInfo,sessionList,setSessionList } = props;
  const { setAccompanySessionIdx,setAccompanyAttendModal} = props;
  const { setSessionMgrInfoModalShow ,setManagerInfo } = props;
  const { handleItemClick, allBtnSelected, tags } = props;
  let showRef = useRef([]);
  let showRefMobile = useRef([]);
  let showIntroRef = useRef();
  let showIntroRefMobile = useRef();

  const [introMoreView, setIntroMoreView] = useState("N");

  const onOff = function (item) {
    const now = moment().format("YYYY/MM/DD HH:mm:ss");
    const start = moment(item?.startDate).format("YYYY/MM/DD HH:mm:ss");
    const end = moment(item?.endDate).format("YYYY/MM/DD HH:mm:ss");

    const accessTime = item.cAccessTime;
    const accessMs = accessTime * 60 * 1000;
    const ms =
      moment(start, "YYYY/MM/DD HH:mm:ss").diff(
        moment(now, "YYYY/MM/DD HH:mm:ss")
      ) - accessMs;

    if (ms > 0) {
      return "BEFORE";
    } else if (ms <= 0 && start > now) {
      return "ENTER_OK";
    } else if (start <= now && now < end) {
      return "ONAIR";
    } else {
      return "END";
    }
  };
  

  const waitTimer = function (item) {
    const now = moment().format("YYYY/MM/DD HH:mm:ss");
    const start = moment(item?.startDate).format("YYYY/MM/DD HH:mm:ss");
    const end = moment(item?.endDate).format("YYYY/MM/DD HH:mm:ss");

    const accessTime = item.cAccessTime;
    const accessMs   = accessTime * 60 * 1000;
    const ms         = moment(start).diff(moment(now)) - accessMs;
    const remainTime = moment.duration(ms);

    const leftYear   = remainTime.years() > 0 ? remainTime.years() + "년 " : "";
    const leftMonth  = remainTime.months() > 0 ? remainTime.months() + "개월 " : "";
    const leftDay    = remainTime.days() > 0 ? remainTime.days() + "일 " : "";
    const leftHour   = remainTime.hours() > 0 ? remainTime.hours() + "시간 " : "";
    const leftMin    = remainTime.minutes() >= 0 ? remainTime?.minutes() + 1 + "분" : "";

    return leftYear + leftMonth + leftDay + leftHour + leftMin ;
  };

  const moreView1 = (e) => {
    setIntroMoreView(introMoreView === "Y" ? "N" : "Y");
  };

  const moreView = (i) => {
    setSessionList((o) => {
      const nb = [...o];
      for (let j in nb) {
        if (i == j) {
          nb[j].moreView = nb[j]?.moreView === true ? false : true;
        }
      }
      return nb;
    });
  };
  
  const moreViewSetHeight = function () {
    setSessionList((o) => {
      const nb = [...o];
      for (let i in nb) {
        nb[i].moreViewUseYn =
          showRef?.current[i]?.scrollHeight > 50 ? `Y` : `N`;
        nb[i].moreViewMobileUseYn =
          showRefMobile?.current[i]?.scrollHeight > 50 ? `Y` : `N`;
      }
      return nb;
    });
    setEventInfo((o) => {
      return {
        ...o,
        [`introMoreViewYn`]:
          showIntroRef?.current?.scrollHeight > 50 ? `Y` : `N`,
        ["introMoreMobileViewYn"]:
          showIntroRefMobile?.current?.scrollHeight > 50 ? `Y` : `N`,
      };
    });
  };

  const saveBannerLog = (banner) => {
    if(!banner?.targetLink || banner?.targetLink?.length <= 0){
      return false;
    }
    const req = {
      path: "/v1/event/session/console/banner/log/save",
      data: {
        eventIdx: iam.eventIdx,
        preregIdx: iam.preregIdx,
        name: iam.name,
        passCode: iam.passCode,
        fileIdx: banner.idx,
        filename: banner.name,
        fileUrl: banner.targetLink,
      },
    };

    _API.post(req).then((res) => {
      let { data } = res.data;
      window.open(`${banner?.targetLink}`)
    });
  };

  useEffect(() => {
    setTimeout(() => {
      moreViewSetHeight();
    }, 1500);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSessionList((o) => {
        const nb = [...o];
        for (let i in nb) {
          nb[i].waitTimeStr = waitTimer(nb[i]);
        }
        return nb;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [sessionList]);

  return (
    <Layout {...props} eventInfo={eventInfo} bodyClass="page-registration">
      <Wrapper>
        <section className="sch_section">
          <div className="sch_wrap">
            <div className="sch_wrapper">
              <div
                className="main_session_img_box"
                style={{ 
                  backgroundColor: 
                  eventInfo?.eventDetailBgColor != null ? eventInfo?.eventDetailBgColor : 
                  (siteInfo?.eventBgColor != null ? siteInfo?.eventBgColor : "#fff" )
                }}
              >
                <img 
                  className="main_session_img_box_img"
                  src={`${
                    eventInfo?.signedUrlMainVisual  != null ? eventInfo?.signedUrlMainVisual 
                    : (siteInfo?.eventMainImgUrl != null ? siteInfo.eventMainImgUrl
                      : `https://cdn.onoffevent.com/bg/TypeBEventbg.png`)
                  }`}
                />
                {eventInfo?.eventNameCheck === 'Y' ?
                  <div className="sub_bg_img_hd">
                    <h1 style={{ color: eventInfo?.titleColor }}>
                      {eventInfo?.eventName} 
                    </h1>
                  </div>
                  : ""
                }
              </div>
              {!['MEDIGATE','ICOD'].includes(iam?.targetType) && (
                <div className="main_inform">
                  <div className="main_inform_text wid_1180">
                    <div className="main_inform_list">
                      <ul>
                        {siteInfo?.subPageList.map((item, i) => {
                          return(
                            <>
                            {item.isShortcutShow == 1 && (
                              item.pageType == "LINK" ? (
                                <li key={`sub_${i}`}>
                                    <a href={`https://${item.link}`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} >
                                      <img src={iconGuide} />
                                      {item.name}
                                    </a>
                                </li>
                              ) : (
                                <li key={`sub_${i}`}>
                                  <a href={`/#/site/${siteInfo.idx}/subpage/${item.pageId}`} className="link" target={item.openType == "POPUP" ? "_blank" : ""} >
                                    <img src={iconGuide} />
                                    {item.name} 
                                  </a> 
                                </li>
                              )
                            )}
                            </>
                          )
                        })}
                      </ul>      
                    </div>
                  </div>
                </div>
              )}
              <div className="sch_wrapper_inner">
                {(eventInfo?.eventNameCheck === 'N' || eventInfo?.eventIntroUse === 'Y') && (
                  <div className="sch_a_con">
                    <div className="event_inform">
                      {eventInfo?.eventNameCheck === 'N' && (
                        <h2 style={{ color: eventInfo?.titleColor }}>{eventInfo?.eventName}</h2>
                      )}
                      {eventInfo?.eventIntroUse === 'Y' && (
                        <div>
                          <div
                            className={cx({
                              more_btn_p: true,
                              on: introMoreView === "Y",
                            })}
                            ref={(element) => (showIntroRef.current = element)}
                            style={{
                              maxHeight: introMoreView === "Y" ? `none` : `50px`,
                              overflowY: introMoreView === "Y" ? `auto` : `hidden`,
                            }}
                          >
                            {eventInfo?.intro}
                          </div>
                          {eventInfo?.introMoreViewYn === "Y" && (
                            <span
                              className={cx({
                                more_btn_span: true,
                                on: introMoreView === "Y",
                              })}
                              onClick={(e) => moreView1(e)}
                            >
                              {introMoreView === "Y" ? "간략히" : "더보기"}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {(tags && tags?.length > 0) && ( 
                  <div className="hashtag_list_cont">
                    <p>브랜드를 선택해 주세요</p>
                    <ul className="hashtag_list">
                      <li 
                        onClick={() =>handleItemClick(null)}
                        className={cx({ active: allBtnSelected})}
                      ><span>전체</span></li>
                      {tags.map((item, i) => (
                        <li
                          onClick={() => handleItemClick(item)}
                          className={cx({ active: item.selected == 1})}
                        >
                          <span>{item.tagName}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {sessionList && (
                  <div className="session_list">
                    {sessionList?.map((item, i) => (
                      <SessionListDetailView
                        key={i}
                        eventInfo={eventInfo}
                        sessionList={sessionList}
                        setAccompanySessionIdx={setAccompanySessionIdx}
                        setAccompanyAttendModal={setAccompanyAttendModal}
                        waitTimer={waitTimer}
                        item={item}
                        i={i}
                        onOff={onOff}
                        showRef={showRef}
                        setSessionMgrInfoModalShow={setSessionMgrInfoModalShow}
                        setManagerInfo={setManagerInfo}
                        moreView={moreView}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="bnr_right">
              {eventInfo?.bannerFiles?.length > 0 && (
                <div className="detail_banner_box_con">
                  {eventInfo?.bannerFiles?.length <= 1 ? (
                    <div className="slider_banner_box_con">
                      {eventInfo.bannerFiles?.map((m, k) => (
                        <div className="slider_banner_box" key={k}>
                          <a onClick={(e) => saveBannerLog(m)} style={{cursor:m?.targetLink ? 'pointer':'default'}}>
                            <img src={m?.signedUrl} alt={"signedUrl"} />
                          </a>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="banner_box_swiper_box">
                      <Swiper
                        spaceBetween={10}
                        observer={true}
                        observeParents={true}
                        loop={true}
                        autoplay={{
                          delay: eventInfo?.rollingInterval || 2000,
                          disableOnInteraction: false,
                        }}
                        allowTouchMove={false}
                        touchRatio={0}
                      >
                        {eventInfo.bannerFiles?.map((m, k) => (
                          <Fragment key={k}>
                            {m && m.signedUrl && (
                              <SwiperSlide>
                                <div className="slider_banner_box">
                                  <a onClick={(e) => saveBannerLog(m)} style={{cursor:m?.targetLink ? 'pointer':'default'}}>
                                    <img src={m?.signedUrl} alt={"signedUrl"} />
                                  </a>
                                </div>
                              </SwiperSlide>
                            )}
                          </Fragment>
                        ))}
                      </Swiper>
                    </div>
                  )}
                </div>
              )}
              {(siteInfo?.csName || siteInfo?.csDescription) && (
                <div className="r_notice">
                  <h2>{siteInfo?.csName}</h2>
                  {siteInfo?.csDescription == null && (
                    <p dangerouslySetInnerHTML={{ __html: siteInfo?.csDescription }} />
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      </Wrapper>
      
    </Layout>
  )
}