import React, { useEffect, useState } from 'react';
import useStore from '../../../../modules/utils/store';
import Layout, { Wrapper } from "../Layouts/Layout";
export default (props) => { 
  const { siteInfo } = useStore();
  const { history, location, match } = props;
  const pathName = window.location.hash;
  let pageId = "";
  if(pathName){
    let pathInfos = pathName.indexOf("?") === -1 ? pathName.split("/") : pathName.split("?")[0].split("/");
    pageId = pathInfos[4]
  }

  useEffect(() => {
    let isAble = siteInfo?.subPageList.filter((i) => i.pageId == pageId)[0].isUse;
    if(isAble == 0){
      window.location.href = "/";
    }
  },[]);
  
  return (
    <Layout {...props} bodyClass="page-registration">
      <Wrapper>
        <div className="mypage_header_bnr">
          <h2 className="subpage_title wid_1180">{siteInfo?.subPageList.filter((i) => i.pageId == pageId)[0].name}</h2>
        </div>
        <div className="subpage_container">
          <div className="subpage_wrapper wid_1180" dangerouslySetInnerHTML={
            {__html:siteInfo?.subPageList.filter((i) => i.pageId == pageId).length > 0 ? siteInfo?.subPageList.filter((i) => i.pageId == pageId)[0].content : ""}
          }/>
        </div>
      </Wrapper>
    </Layout>
  )
}