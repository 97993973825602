import { Input, Typography } from "@mui/material";
import React from "react";

export default class PreRegManagerInput extends React.Component {
  handleChange = (e) => {  // <- input값으로 text 변경 함수
    this.setState({
      text: e.target.value,
    });
  };
  render() {
    return (
      <>
        <input 
          name="preregManager" 
          onChange={this.handleChange} 
          id='preregManager' 
          placeholder="담당자를 입력해 주세요."
          style={{position: "relative",
                  border: "none",
                  borderBottom: "1px solid #ccc",
                  borderRadius: "0",
                  fontSize: "15px",
                  padding: "9px 26px 9px 0px",
                  background: "none",
                  width: "100%"}}
          defaultValue={this.state?.value}
        />
      </>
    );
  }
}