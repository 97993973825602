import React, { useEffect, useState, useContext } from "react";
import GlobalStyles, {
  ThemeProvider,
  Theme,
  DynamicStyles,
} from "./views/common/Resources/GlobalStyle";

// App Provider
import { AppProvider, AppContext } from "./components/AppProvider";

// Apollo Cache Enable
import { InMemoryCache } from "apollo-cache-inmemory";
import { persistCache } from "apollo-cache-persist";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloOptions } from "./modules/config";

// Utils
import { _CK, _API } from "./modules/utils";

// UI
import { Loading } from "./components/UI/Loading";
import LoadingOverlay from "react-loading-overlay";
// Router
import Views from "./views";
import useStore from "./modules/utils/store";
import { delay } from "lodash";

export default (props) => {
  const [loaded, setLoaded] = useState(false);
  const [auth, setAuth] = useState("");
  const [iam, setIam] = useState(null);
  const [client, setClient] = useState(null);
  const hostname = window.location.hostname;
  const pathName = window.location.hash;
  const {setSiteInfo,setSiteTemplateInfo, setCampaignInfo} = useStore();

  let pathInfos = [];
  let campaignIdx = "";

  if (pathName) {
    pathInfos = pathName.indexOf("?") === -1 ? pathName.split("/") : pathName.split("?")[0].split("/");
    if(pathInfos[1] == 'campaign') {
      campaignIdx = pathInfos[2];
    }
  }

  const campaignLoad = async () => {

    const req = {
      path: "/v1/campaign/info",
      data:{
        idx : campaignIdx,
        noAuthorCheck: true
      }
    };

    _API.post(req).then((res) => {
      const { data } = res.data;
      setCampaignInfo(data);
    });

  }

  const templateLoad = async () => {
    const req = {
      path: "/v1/template/event/template/info",
      data:{
        domain : hostname
      }
    };

    _API.post(req).then((res) => {
      setSiteTemplateInfo(res)
    });
  }

  const preLoad = async () => {
    const sqs = {
      path: "/v1/client/sqs",
      data:{
        domain : hostname
      }
    };
    await _API.post(sqs).then((res) => {
      if (res.data) {
        console.log('sqs currentConcurrency ', Number(res.data.data));
        if(Number(res.data.data) > 1){
          alert("대기열에 남은 수 : "+ res.data.data );
        }
      } else {
        
      }
    });
    try {
      const cache = new InMemoryCache();
      await persistCache({ cache, storage: window.localStorage });
      const ao = ApolloOptions();
      const client = new ApolloClient({
        cache,
        ...ao,
      });

      if (_CK.isLogin()) {
        const iamRes = await _API.iam();
        const { data: newIam, accessKey } = iamRes.data;
        if (newIam) {
          _CK.setAuth(accessKey);
          if (_CK.getLogKey()) {
            newIam.logKey = _CK.getLogKey();
          }
          console.log(newIam)
          setAuth(accessKey);
          setIam(newIam);
        } else {
          _CK.clearAuth();
        }
      }

      const req = {
        path: "/v1/template/event/template/info",
        data:{
          domain : hostname
        }
      };
      await _API.post(req).then((res) => {
        if (res.data) {
          setSiteInfo(res.data)
          $('.web-title').text(res.data?.title);
        } else {
          setLoaded(true);
          throw "사이트를 찾지못했습니다.";
        }
      });

      setClient(client);
      setLoaded(true);
    } catch (e) {
      console.log("APP PreLoad Error", e);
      _CK.clearAuth();
      document.location.href = "/";
    }
  };

  useEffect(() => {
    templateLoad();
    if(pathInfos[1] == 'campaign') {
      campaignLoad();
    }
    preLoad();
    console.log(iam)
  }, []);

  if (!loaded) {
    return (
      <ThemeProvider theme={Theme}>
        {/* <Loading /> */}
        <div>
          <img
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              width: "200px",
              height: "200px",
              display: "block",
              transform: "translate(-50%,-50%)",
            }}
            className="loading_img loading_img1"
            src="https://cdn.onoffevent.com/common/loading.gif"
          />
        </div>
        <GlobalStyles />
      </ThemeProvider>
    );
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={Theme}>
        <AppProvider iam={iam}>
          <Views />
        </AppProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};
